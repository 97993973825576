import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';

import Moment from 'react-moment';
import moment from 'moment';
import { map } from 'lodash';

import Pagination from 'reactstrap/lib/Pagination';
import PaginationItem from 'reactstrap/lib/PaginationItem';
import PaginationLink from 'reactstrap/lib/PaginationLink';
import * as constants from '../../../constants/constants';
import DateFormat from '../UI/DateFormat';
import { Button, PopoverBody, UncontrolledPopover } from 'reactstrap';

class AdminActionLogs extends React.Component {
  static propTypes = {
    fetchAdminActionLog: PropTypes.func.isRequired,
    logsCount: PropTypes.number,
    adminActionData: PropTypes.shape({}),
    t: PropTypes.func.isRequired,
  }

  static defaultProps = {
    logsCount: 0,
    adminActionData: {},
  }

  constructor(props) {
    super(props);
    this.state = {
      currentPage: 0,
      pageSize: 50,
    };
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    const { fetchAdminActionLog, logsCount } = this.props;

    const start = logsCount - 49;
    const end = logsCount;

    // fetchAdminActionLog(start, end);
    fetchAdminActionLog(start, end);
  }

  handleClick = (e, index) => {
    e.preventDefault();
    const { fetchAdminActionLog, logsCount } = this.props;

    const start = logsCount - (50 * index) - 49;
    const end = logsCount - (50 * index);

    fetchAdminActionLog(start, end);

    this.setState({
      currentPage: index,
    });
  }

  table = () => {
    const {
      adminActionData,
      logsCount,
    } = this.props;
    const {
      currentPage,
      pageSize,
    } = this.state;

    console.log('adminActionData', adminActionData);

    if (adminActionData) {
      const pagesCount = Math.ceil(logsCount / pageSize);
      return (
        <Fragment>
          {adminActionData && map(adminActionData, (item, key) => (
            <Fragment key={key}>
              <tr
                key={key}
                className={`admin-action-log-row ${item.type}`}
                style={{ backgroundColor: `${constants.adminActionTypes.filter(actionType => actionType.id === item.type)[0] ? constants.adminActionTypes.filter(actionType => actionType.id === item.type)[0].bgColor.toString() : '#f9803057'}` }}
              >
                <td className="allUsers-table-col">
                  { item.adminEmail || item.adminId}
                </td>
                <td className="allUsers-table-col">
                  { item.adminName }
                </td>
                <td className="allUsers-table-col">
                  { item.type }
                </td>
                <td className="allUsers-table-col">
                  {
                    constants.adminActionTypes.map(actionType => (
                      actionType.id === item.type && (
                        actionType.changeKey.map((keyData) => (
                          actionType.changeData === constants.adminActionTypesData ?
                            `${keyData}: ${(item && item.data) ? ((item.type === 'editAnnouncement' || item.type === 'addNewAnnouncement') ?
                              (item.data[keyData]?.en) : (item.data[keyData])) : ''} ,`
                            :
                            `${keyData}: ${item ? item[keyData] : ''} ,`
                        )))
                    ))
                  }
                </td>
                <td className="allUsers-table-col">
                  <DateFormat formatType={constants.FORMAT_DATE_TYPE.fullDateMinute} date={item.date} />
                </td>
                <td className="allUsers-table-col">
                  { item.userId || ''}
                </td>
                <td className="allUsers-table-col">
                  { item.userName || '' }
                </td>
              </tr>
            </Fragment>
          ))}
          {pagesCount && logsCount > pageSize && (
          <div className="pagination-wrapper">
            <Pagination aria-label="Page navigation example">
              <PaginationItem disabled={currentPage === 0}>
                <PaginationLink style={{ color: '#000' }} onClick={e => this.handleClick(e, 0)} href="#">
                  {`‹‹‹`}
                </PaginationLink>
              </PaginationItem>

              <PaginationItem disabled={currentPage <= 0}>
                <PaginationLink
                  style={{ color: '#000' }}
                  onClick={e => this.handleClick(e, currentPage - 1)}
                  previous
                  href="#"
                />
              </PaginationItem>

              {[...Array(pagesCount)].map((page, i) => {
                if (i > currentPage - 3 && i < currentPage + 3) {
                  return (
                    <PaginationItem active={i === currentPage} key={page}>
                      <PaginationLink style={{ color: '#000' }} onClick={e => this.handleClick(e, i)} href="#">
                        {i + 1}
                      </PaginationLink>
                    </PaginationItem>
                  );
                }

                return null;
              })}

              <PaginationItem disabled={currentPage >= pagesCount - 1}>
                <PaginationLink
                  style={{ color: '#000' }}
                  onClick={e => this.handleClick(e, currentPage + 1)}
                  next
                  href="#"
                />
              </PaginationItem>

              <PaginationItem disabled={currentPage >= pagesCount - 1}>
                <PaginationLink style={{ color: '#000' }} onClick={e => this.handleClick(e, pagesCount - 1)} href="#">
                  {`›››`}
                </PaginationLink>
              </PaginationItem>
            </Pagination>
          </div>
          )}
        </Fragment>
      );
    }
  }

  render() {
    const { t } = this.props;

    return (
      <Fragment>
        <UncontrolledPopover popperClassName={`popover`} placement="right" trigger="focus" target="admin-help-button-admin-action-logs">
          <PopoverBody className="popover-body">
            A list of almost all actions done by the administration in the admin panel. Currently, the data are being kept forever.
          </PopoverBody>
        </UncontrolledPopover>

        <div className="admin-page-content">
          <h2>
            Admin Actions
            <Button className="admin-help-button" id="admin-help-button-admin-action-logs">
              ?
            </Button>
          </h2>
          <table style={{ width: '100%', fontSize: 14 }}>
            <colgroup>
              <col span="1" className="" />
            </colgroup>
            <thead>
              <tr>
                <th className="allUsers-table-col">
                  Id
                </th>
                <th className="allUsers-table-col">
                  Name
                </th>
                <th className="allUsers-table-col">
                  Actions
                </th>
                <th className="allUsers-table-col">
                  Content
                </th>
                <th className="allUsers-table-col">
                  Date
                </th>
                <th className="allUsers-table-col">
                  Player Id
                </th>
                <th className="allUsers-table-col">
                  Name
                </th>
              </tr>
            </thead>
            <tbody>
              {this.table()}
            </tbody>
          </table>
        </div>
      </Fragment>
    );
  }
}

export default withTranslation('admin')(AdminActionLogs);
