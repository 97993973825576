import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';

import Label from 'reactstrap/lib/Label';
import Input from 'reactstrap/lib/Input';
import Button from 'reactstrap/lib/Button';
import Modal from 'reactstrap/lib/Modal';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import { PopoverBody, UncontrolledPopover } from 'reactstrap';

class AdminsList extends React.Component {
  static propTypes = {
    admins: PropTypes.shape({}),
    fetchAdmins: PropTypes.func.isRequired,
    manualyVerifyUser: PropTypes.func.isRequired,
    updateRole: PropTypes.func.isRequired,
  }

  static defaultProps = {
    admins: {},
  }

  constructor(props) {
    super(props);
    this.state = {
      roleModalOpen: false,
      editUid: null,
    };
  }


  componentDidMount() {
    const { fetchAdmins } = this.props;

    fetchAdmins();
  }

    // edit existing admin functions
  openRoleModal = (id) => {
    const { admins } = this.props;
    if (admins) {
      const userData = admins[id];

      if (userData && userData.role) {
        this.setState({ roleModalOpen: true, editUid: id, selectedRole: userData.role });
      }
    }
  }

  closeRoleModal = () => {
    this.setState({ roleModalOpen: false, editUid: null, selectedRole: null });
  }

  changeSelectedRole = (e) => {
    this.setState({ selectedRole: e.target.value });
  }

  updateRole = () => {
    const { updateRole } = this.props;
    const { selectedRole, editUid } = this.state;

    if (editUid && selectedRole) {
      updateRole(editUid, selectedRole);
      this.setState({ roleModalOpen: false, editUid: null, selectedRole: null });
    }
  }


  // Add new admin functions
  openNewAdminModal = () => {
    this.setState({ newAdminModalOpen: true, editUid: null, selectedRole: 'admin' });
  }

  closeNewAdminModal = () => {
    this.setState({ newAdminModalOpen: false, editUid: null, selectedRole: null });
  }

  changeNewAdminUid = (e) => {
    this.setState({ newAdminUid: e.target.value });
  }

  addNewAdmin = () => {
    const { updateRole } = this.props;
    const { selectedRole, newAdminUid } = this.state;

    if (newAdminUid && selectedRole) {
      updateRole(newAdminUid, selectedRole);
      this.setState({ newAdminModalOpen: false, newAdminUid: null, selectedRole: null });
    }
  }

  manualyVerifyUser = () => {
    const { manualyVerifyUser } = this.props;
    const { email } = this.state;

    manualyVerifyUser(email);
  }

  handleChange = (event) => {
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

    this.setState({
      [event.target.name]: value,
    });
  }

  table() {
    const {
      t,
      admins,
    } = this.props;

    const { email } = this.state;

    return (
      <Fragment>
        {admins && Object.keys(admins).sort((a, b) => {
          if (admins[a].role === 'tester' && admins[b].role === 'admin') {
            return 1;
          }

          if (admins[a].role === 'admin' && admins[b].role === 'tester') {
            return -1;
          }

          return 0;
        }).map((key, index) => (
            <Fragment key={key}>
              <tr key={key} className={`allUsers-table-row ${index % 2 === 0 ? ('odd') : ('even')}`}>
                <td className="allUsers-table-col">
                  {key}
                </td>
                <td className="allUsers-table-col">
                  {admins[key].name}
                </td>
                <td className="allUsers-table-col">
                  {admins[key].role}
                </td>
                <td className="allUsers-table-col">
                  <Button color="primary" onClick={() => this.openRoleModal(key)}>
                    Change Permissions
                  </Button>
                </td>
              </tr>
            </Fragment>
          ))}

          <Label for="email">
            {t('email')}
          </Label>
          <Input
            type="text"
            name="email"
            id="email"
            value={email}
            onChange={this.handleChange}
          />
          <Button onClick={this.manualyVerifyUser}> Verify </Button>
      </Fragment>
    );
  }

  render() {
    const {
      t,
    } = this.props;

    const {
      roleModalOpen,
      selectedRole,
      newAdminModalOpen,
      newAdminUid,
    } = this.state;

    return (
      <Fragment>
        <UncontrolledPopover popperClassName={`popover`} placement="right" trigger="focus" target="admin-help-button-admins">
          <PopoverBody className="popover-body">
            A list of all users who have administrator or tester permissions. The administrator can add a new admin and change the permissions. Currently, the tester role is not being actively used, however, it's supposed to be used for users who don't need to access sensitive details like emails or payments. 
          </PopoverBody>
        </UncontrolledPopover>
        <div className="admin-page-content">
          <h2>
            {/* {t('admins')} */}
            Admins
            <Button className="admin-help-button" id="admin-help-button-admins">
              ?
            </Button>
          </h2>
          <Button color="primary" onClick={this.openNewAdminModal}>
            {/* {t('addNewAdminUser')} */}
            Add new Admin
          </Button>
          <table style={{ width: '100%', fontSize: 12 }}>
            <colgroup>
              <col span="1" className="" />
            </colgroup>
            <thead>
              <tr>
                <th style={{ textAlign: 'center' }}>
                  Id
                </th>
                <th style={{ textAlign: 'center' }}>
                  Name
                </th>
                <th style={{ textAlign: 'center' }}>
                  Permissions
                </th>
                <th style={{ textAlign: 'center' }}>
                  Change Permissions
                </th>
              </tr>
            </thead>
            <tbody>
              {this.table()}
            </tbody>
          </table>

        </div>
        <Modal container={`div > div`} returnFocusAfterClose={false} size="lg" isOpen={roleModalOpen} toggle={this.closeRoleModal}>
          <ModalHeader toggle={this.closeRoleModal}>
            Change Permissions
            {/* {t('changeRole')} */}
          </ModalHeader>
          <ModalBody>
            <Label for="role">
              Permissions
              {/* {t('role')} */}
            </Label>
            <Input type="select" name="role" className="game-bet-select" value={selectedRole} onChange={this.changeSelectedRole}>
              <option style={{ backgroundColor: '#222' }} value="admin">Admin</option>
              <option style={{ backgroundColor: '#222' }} value="tester">Tester</option>
              <option style={{ backgroundColor: '#222' }} value="player">Player</option>
            </Input>
          </ModalBody>
          <ModalFooter>
            <Button className="modal-footer-button" onClick={this.updateRole}>Change Permissions</Button>
            <Button className="modal-footer-button" onClick={this.closeRoleModal}>Close</Button>
          </ModalFooter>
        </Modal>


        <Modal container={`div > div`} returnFocusAfterClose={false} size="lg" isOpen={newAdminModalOpen} toggle={this.closeNewAdminModal}>
          <ModalHeader toggle={this.closeNewAdminModal}>
            Add New Administrator
          </ModalHeader>
          <ModalBody>
            <Label for="newAdminUid">
              Id
            </Label>
            <Input
              type="text"
              name="newAdminUid"
              value={newAdminUid}
              onChange={this.changeNewAdminUid}
            />
            <Label for="role">
              Permissions
            </Label>
            <Input type="select" name="role" className="game-bet-select" value={selectedRole} onChange={this.changeSelectedRole}>
              <option style={{ backgroundColor: '#222' }} value="admin">Admin</option>
              <option style={{ backgroundColor: '#222' }} value="tester">Tester</option>
            </Input>
          </ModalBody>
          <ModalFooter>
            <Button className="modal-footer-button" onClick={this.addNewAdmin}>Add</Button>
            <Button className="modal-footer-button" onClick={this.closeNewAdminModal}>Close</Button>
          </ModalFooter>
        </Modal>
      </Fragment>
    );
  }
}

export default  withTranslation('admin')(AdminsList);
