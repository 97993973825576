import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';

import { connect } from 'react-redux';
import classNames from 'classnames';
import { Helmet } from 'react-helmet';
// import ReactGA from 'react-ga';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Button from 'reactstrap/lib/Button';
import Media from 'reactstrap/lib/Media';
import Modal from 'reactstrap/lib/Modal';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';
// import Popover from 'reactstrap/lib/Popover';
// import PopoverBody from 'reactstrap/lib/PopoverBody';
// import Spinner from 'reactstrap/lib/Spinner';
import Input from 'reactstrap/lib/Input';

import ScrollAreaWrapper from '../UI/ScrollAreaWrapper';

import Leaderboard from './TournamentLeaderboard';
import CustomDate from '../UI/CustomDate';

import tournamentsImg from '../../../images/icons/tournaments.webp';
import coinImg from '../../../images/coin.svg';
import closeImg from '../../../images/icons/close.png';
// import keyImg from '../../../images/icons/private.svg';
// import speedImg from '../../../images/icons/fast_game.svg';

import tutorialImage from '../../../images/icons/help2.webp';

// import infoImg from '../../../images/icons/info_chats.svg';

import {
  getTournamentPlayers,
  getTournamentActiveRooms,
  joinTournament,
  leaveTournament,
  returnToTournament,
  pauseTournament,
  getTournaments,
} from '../../../actions/tournaments';

import {
  FONT_MODE,
  MENU_NAVIGATION,
  CALC_TOURNAMENT_ROUND_LENGTH,
  PAYMENT_SOURCE,
} from '../../../constants/constants';
// import { Label } from 'reactstrap';

import {
  getActiveTournaments, getMyActiveTournaments, getOtherActiveTournaments, getTournamentById, getTournamentPlayersTitle, getTournamentTitle, getTournamentType,
} from '../../../utils/tournamentUtils';
import TournamentsHeader from './TournamentsHeader';
import { FormGroup, Label } from 'reactstrap';
import { addPaymentInfo, updatePaymentInfoStatus } from '../../../actions/shop';

class TournamentTimer extends React.Component {
  static propTypes = {
    time: PropTypes.number,
    offset: PropTypes.number,
    // t: PropTypes.func.isRequired,
    // fontMode: PropTypes.string,
  }

  static defaultProps = {
    time: 0,
    offset: 0,
    // fontMode: FONT_MODE.normal,
  }

  constructor(props) {
    super(props);
    this.state = {
      minutes: 0,
      seconds: 0,
    };
  }

  componentDidMount() {
    const { time } = this.props;

    if (time) {
      const gameLength = time - (Date.now() + this.props.offset);
      const minutes = Math.floor((gameLength % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((gameLength % (1000 * 60)) / 1000);

      if (minutes >= 0 && seconds >= 0) {
        this.setState({
          minutes: minutes < 10 ? `0${minutes}` : minutes,
          seconds: seconds < 10 ? `0${seconds}` : seconds,
        });
      }
    }

    this.intervalID = setInterval(() => {
      const { offset } = this.props;

      if (time) {
        const gameLength = time - (Date.now() + offset);
        const minutes = Math.floor((gameLength % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((gameLength % (1000 * 60)) / 1000);

        if (minutes >= 0 && seconds >= 0) {
          this.setState({
            minutes: minutes < 10 ? `0${minutes}` : minutes,
            seconds: seconds < 10 ? `0${seconds}` : seconds,
          });
        }
      }
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  render() {
    const { time } = this.props;
    const { minutes, seconds } = this.state;

    if (!time) return null;

    return (
      `${minutes}:${seconds}`
    );
  }
}


class PopoverEndTimer extends React.Component {
  static propTypes = {
    offset: PropTypes.number,
    tournamentId: PropTypes.string,
    tournaments: PropTypes.shape({}),
    fetchTournamentActiveRooms: PropTypes.func.isRequired,
  }

  static defaultProps = {
    offset: 0,
    tournamentId: '',
    tournaments: {},
  }

  constructor(props) {
    super(props);
    this.state = {
      minutes: 0,
      seconds: 0,
      time: 0,
      popoverRoomsCount: 0,
      popoverPartiesCount: 0,
    };
  }

  componentDidMount() {
    const { fetchTournamentActiveRooms, tournaments, tournamentId, offset: offsetVal } = this.props;

    fetchTournamentActiveRooms(tournamentId).then((res) => {
      const tournament = tournaments.find(element => element.id === tournamentId);

      console.log('fetchTournamentActiveRooms res.data', res.data);

      if (res && res.data) {
        const roomsCount = Object.keys(res.data).length;
        let partiesCount = 0;

        Object.keys(res.data).map((key) => {
          if (res.data[key] && res.data[key].party) {
            partiesCount += parseInt(res.data[key].party, 10);
          }
          return null;
        });

        console.log('gameStartTime tournament', tournament);

        const { roundLength, gameStartTime, lightningGame, fastGame } = tournament;
        let { roundLengthTime } = tournament;
        const averageParties = partiesCount / roomsCount;
        const averagePartiesLeft = Math.round(parseInt(roundLength, 10) - averageParties);

        console.log('gameStartTime', gameStartTime);

        let speed = 'normal';

        if (fastGame) {
          speed = 'atra';
        } else if (lightningGame) {
          speed = 'lightning';
        }

        if (!roundLengthTime) {
          roundLengthTime = CALC_TOURNAMENT_ROUND_LENGTH(roundLength, speed);
        }

        let gameEndTime = gameStartTime + (roundLengthTime * 60 * 1000);

        /*if (roundLength > 24) {
          gameEndTime = gameStartTime + (60 * 60 * 1000);
        } else if (roundLength > 12) {
          gameEndTime = gameStartTime + (40 * 60 * 1000);
        } */

        console.log('gameEndTime', gameEndTime);

        if (gameEndTime) {
          const gameLength = gameEndTime - (Date.now() + offsetVal);
          const minutes = Math.floor((gameLength % (1000 * 60 * 60)) / (1000 * 60));
          const seconds = Math.floor((gameLength % (1000 * 60)) / 1000);

          if (minutes >= 0 && seconds >= 0) {
            this.setState({
              minutes: minutes < 10 ? `0${minutes}` : minutes,
              seconds: seconds < 10 ? `0${seconds}` : seconds,
            });
          }
        }

        this.intervalID = setInterval(() => {
          const { offset } = this.props;

          console.log('time', gameEndTime);

          if (gameEndTime) {
            const gameLength = gameEndTime - (Date.now() + offset);
            const minutes = Math.floor((gameLength % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((gameLength % (1000 * 60)) / 1000);

            if (minutes >= 0 && seconds >= 0) {
              this.setState({
                minutes: minutes < 10 ? `0${minutes}` : minutes,
                seconds: seconds < 10 ? `0${seconds}` : seconds,
              });
            }
          }
        }, 1000);

        this.setState({
          popoverOpen: tournamentId,
          time: gameEndTime,
          popoverRoomsCount: roomsCount,
          popoverPartiesCount: roomsCount > 0 ? averagePartiesLeft : 0,
        });
      }
    });
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  render() {
    const { minutes, seconds, time } = this.state;

    if (!time) return null;

    return (
      `${minutes}:${seconds}`
    );
  }
}

class Tournaments extends React.Component {
  static propTypes = {
    tournaments: PropTypes.arrayOf(PropTypes.shape()),
    tournamentPlayers: PropTypes.shape(),
    myTournamentsData: PropTypes.shape(),
    registeredTournament: PropTypes.shape(),
    offset: PropTypes.number,
    uid: PropTypes.string,
    t: PropTypes.func.isRequired,
    joinTournamentFunc: PropTypes.func.isRequired,
    leaveTournamentFunc: PropTypes.func.isRequired,
    returnToTournamentFunc: PropTypes.func.isRequired,
    pauseTournamentFunc: PropTypes.func.isRequired,
    changeTab: PropTypes.func.isRequired,
    fetchTournamentPlayers: PropTypes.func.isRequired,
    fetchTournamentActiveRooms: PropTypes.func.isRequired,
    toggleShowTournamentTutorial: PropTypes.func.isRequired,
    fetchTournaments: PropTypes.func.isRequired,
    fontMode: PropTypes.string,
    finishedTournamentLastRound: PropTypes.bool,
    setAddPaymentInfo: PropTypes.func.isRequired,
    setHandlePaymentInfoStatus: PropTypes.func.isRequired,
    name: PropTypes.string,
  }

  static defaultProps = {
    tournaments: [],
    tournamentPlayers: null,
    myTournamentsData: null,
    registeredTournament: null,
    uid: null,
    offset: 0,
    fontMode: FONT_MODE.normal,
    finishedTournamentLastRound: false,
    name: '',
  }

  constructor(props) {
    super(props);
    this.state = {
      openModal: false,
      openJoinModal: false,
      tournamentId: '',
      // scrollPos: 0,
      tournamentToJoin: null,
      tournamentToJoinFee: null,
      registerPopoverOpen: {},
      privateTournamentConfirm: false,
      privateTournamentConfirmError: '',
      tournamentConfirmError: '',
      privateTournamentId: '',
      password: [],
      lowBalanceError: false,
      tournamentInfoModalOpen: false,
      selectTournament: {},
      popoverEndTime: 0,
      pauseConfirmationOpen: false,
      returnConfirmationOpen: false,
      confirmationTournamentId: null,
      leaveReason: '',
      curLength: 0,
    };

    this.digit1 = React.createRef();
    this.digit2 = React.createRef();
    this.digit3 = React.createRef();
    this.digit4 = React.createRef();
  }

  componentWillMount() {
    const { fetchTournaments } = this.props;

    const { hostname } = window.location;

    let devMode = false;

    if (hostname && (hostname.includes('dev') || hostname.includes('localhost'))) {
      devMode = true;
    }

    fetchTournaments(devMode);
  }

  componentWillMount() {

  }

  handleChangeDigit = (e) => {
    const { password } = this.state;

    console.log('handleChangeDigit', password);

    const newPassword = [...password];

    if (e.target) {
      const { value, name } = e.target;

      const newVal = value.charAt(value.length - 1);

      if (!isNaN(newVal) || !newVal) {
        newPassword[name] = newVal;

        if (newVal) {
          if (name === 0 || name === '0') {
            if (this.digit2) {
              this.digit2.current.focus();
            }
          }
          if (name === 1 || name === '1') {
            if (this.digit3) {
              this.digit3.current.focus();
            }
          }
          if (name === 2 || name === '2') {
            if (this.digit4) {
              this.digit4.current.focus();
            }
          }
        }

        console.log('handleChangeDigit 2', newPassword);

        this.setState({
          password: newPassword,
        });
      }
    }
  }

  joinTournamentNotification = (tournamentId, fee) => {
    const { tournaments, registeredTournament, joinTournamentFunc } = this.props;

    const tournament = tournaments.find(element => element.id === tournamentId);

    if (tournament && !(tournament.registeredPlayers >= tournament.maxPlayers) && !registeredTournament) {
      joinTournamentFunc('init');

      this.setState({
        openJoinModal: true,
        tournamentToJoin: tournamentId,
        tournamentToJoinFee: fee,
      });
    }
  }

  toggleJoin = () => {
    this.setState(prevState => ({
      openJoinModal: !prevState.openJoinModal,
    }));
  }


  closePrivateTournamentEnter = () => {
    this.setState({
      password: [], privateTournamentConfirm: false, privateTournamentId: '', privateTournamentConfirmError: '',
    });
  }

  joinPrivateTournamentConfirm = (tournamentId, fee) => {
    const { tournaments, registeredTournament, joinTournamentFunc } = this.props;

    const tournament = tournaments.find(element => element.id === tournamentId);

    if (tournament && !(tournament.registeredPlayers >= tournament.maxPlayers) && !registeredTournament) {
      joinTournamentFunc('init');

      this.setState({
        tournamentToJoin: tournamentId,
        tournamentToJoinFee: fee,
        privateTournamentConfirm: true,
        privateTournamentId: tournamentId,
        privateTournamentConfirmError: '',
      });
    }
  }


  joinPrivateTournamentClicked = () => {
    const { joinTournamentFunc } = this.props;
    const { privateTournamentId, password } = this.state;

    if (!privateTournamentId) {
      return null;
    }

    if (password && password.length === 4) {
      const passString = password.join('');

      joinTournamentFunc(privateTournamentId, passString).then((res) => {
        if (res && res.status === 'success') {
          this.setState({
            password: [], privateTournamentConfirm: false, privateTournamentId: '', privateTournamentConfirmError: '',
          });

          setTimeout(() => {
            this.setState({
              openJoinModal: false,
              tournamentToJoin: '',
              tournamentToJoinFee: '',
            });
          }, 250);
        } else if (res.error === 'wrong password') {
          this.setState({ privateTournamentConfirmError: 'tournamentWrongPassword' }); // Nepareizs kods
        } else if (res.error === 'multiAccount') {
          this.setState({ privateTournamentConfirmError: 'multiAccount', multiAccountErrorName: res.name }); // multiAccount bocked
        } else if (res.error === 'tournament already registered') {
          this.setState({
            password: [], privateTournamentConfirm: false, privateTournamentId: '', privateTournamentConfirmError: '',
          });

          setTimeout(() => {
            this.setState({
              openJoinModal: false,
              tournamentToJoin: '',
              tournamentToJoinFee: '',
            });
          }, 250);
        } else if (res.error === 'tournament max players') {
          this.setState({ privateTournamentConfirmError: 'tournamentFull' }); // Turnīrs pilns
        } else if (res.error === 'insuf bal tournament') {
          const { tournaments } = this.props;
          this.setState({ privateTournamentConfirmError: 'noBalanceTournament', lowBalanceError: true, selectTournament: { ...tournaments.filter(tournament => tournament.id === privateTournamentId)[0] } });
        } else {
          this.setState({ privateTournamentConfirmError: 'tournamentError' }); // Radās kļūda pievienojoties, mēģini vēlreiz
        }
      });
    } else {
      //  console.log('no password');
    }

    return null;
  }

  joinTournament = (tournamentId) => {
    const { joinTournamentFunc } = this.props;

    if (tournamentId) {
      joinTournamentFunc(tournamentId).then((res) => {
        if (res && res.status === 'success') {
          setTimeout(() => {
            this.setState({
              openJoinModal: false,
              tournamentToJoin: '',
              tournamentToJoinFee: '',
            });
          }, 250);
        } else if (res.error === 'tournament already registered') {
          setTimeout(() => {
            this.setState({
              openJoinModal: false,
              tournamentToJoin: '',
              tournamentToJoinFee: '',
            });
          }, 250);
        } else if (res.error === 'tournament max players') {
          this.setState({ tournamentConfirmError: 'tournamentFull' }); // Turnīrs pilns
        } else if (res.error === 'insuf bal tournament') {
          this.setState({ tournamentConfirmError: 'noBalanceTournament', lowBalanceError: true });
        } else {
          this.setState({ tournamentConfirmError: 'tournamentError' }); // Radās kļūda pievienojoties, mēģini vēlreiz
        }
      });
    }

    setTimeout(() => {
      this.setState({
        openJoinModal: false,
        tournamentToJoin: '',
        tournamentToJoinFee: '',
      });
    }, 250);
  }

  closeLowBalanceError = () => {
    this.setState({
      lowBalanceError: false,
      tournamentConfirmError: '',
    });
  }

  buyMoney = () => {
    const { changeTab, uid, name, setAddPaymentInfo } = this.props;
    const { lowBalanceError } = this.state;

    if (lowBalanceError) {
      setAddPaymentInfo({
        uid,
        name,
        source: PAYMENT_SOURCE.joinTournament,
      });
    }

    changeTab(MENU_NAVIGATION.buyPageMoneyPacks);
  }

  openModal = (id) => {
    console.log('openModal', id);

    this.setState({
      tournamentId: id,
      openModal: true,
    });
  }

  toggle = () => {
    this.setState(prevState => ({
      openModal: !prevState.openModal,
    }));
  }

  toggleLeave = () => {
    this.setState(prevState => ({
      openLeaveModal: !prevState.openLeaveModal,
    }));
  }

  leaveTournamentNotification = (tournamentId) => {
    const { leaveTournamentFunc } = this.props;

    if (tournamentId) {
      leaveTournamentFunc('init');
    }

    this.setState({
      openLeaveModal: true,
      tournamentToLeave: tournamentId,
    });
  }

  leaveTournament = (tournamentId) => {
    const { leaveTournamentFunc } = this.props;
    const { leaveReason } = this.state;

    if (tournamentId) {
      leaveTournamentFunc(tournamentId, leaveReason);
    }

    setTimeout(() => {
      this.setState({
        openLeaveModal: false,
        tournamentToLeave: '',
        leaveReason: '',
      });
    }, 250);
  }

  fetchTournamentPlayers = (tournamentId) => {
    const { fetchTournamentPlayers } = this.props;

    console.log('fetchTournamentPlayers', tournamentId);

    if (tournamentId) {
      fetchTournamentPlayers(tournamentId).then(() => {
        this.setState({
          openModal: true,
          tournamentId: tournamentId,
        });
      });
    }
  }

  fetchTournamentActiveRooms = (tournamentId) => {
    const { fetchTournamentActiveRooms, tournaments } = this.props;
    const { popoverOpen } = this.state;

    if (popoverOpen && popoverOpen === tournamentId) {
      this.setState({ popoverOpen: null });
    } else if (tournamentId) {
      fetchTournamentActiveRooms(tournamentId).then((res) => {
        const tournament = tournaments.find(element => element.id === tournamentId);

        console.log('fetchTournamentActiveRooms res.data', res.data);

        if (res && res.data) {
          const roomsCount = Object.keys(res.data).length;
          let partiesCount = 0;

          Object.keys(res.data).map((key) => {
            if (res.data[key] && res.data[key].party) {
              partiesCount += parseInt(res.data[key].party, 10);
            }
            return null;
          });

          console.log('gameStartTime tournament', tournament);

          const { roundLength, gameStartTime, lightningGame, fastGame } = tournament;
          let { roundLengthTime } = tournament;
          const averageParties = partiesCount / roomsCount;
          const averagePartiesLeft = Math.round(parseInt(roundLength, 10) - averageParties);

          console.log('gameStartTime', gameStartTime);

          let speed = 'normal';

          if (fastGame) {
            speed = 'atra';
          } else if (lightningGame) {
            speed = 'lightning';
          }

          if (!roundLengthTime) {
            roundLengthTime = CALC_TOURNAMENT_ROUND_LENGTH(roundLength, speed);
          }

          let gameEndTime = gameStartTime + (roundLengthTime * 60 * 1000);

          /*if (roundLength > 24) {
            gameEndTime = gameStartTime + (60 * 60 * 1000);
          } else if (roundLength > 12) {
            gameEndTime = gameStartTime + (40 * 60 * 1000);
          } */

          console.log('gameEndTime', gameEndTime);

          this.setState({
            popoverOpen: tournamentId,
            popoverEndTime: gameEndTime,
            popoverRoomsCount: roomsCount,
            popoverPartiesCount: roomsCount > 0 ? averagePartiesLeft : 0,
          });
        }
      });
    }
  }

  togglePopover = () => {
    this.setState({ popoverOpen: null });
  }

  /*
  updateScrollPos = (val) => {
    if (val.topPosition || val.topPosition === 0) {
      this.setState({ scrollPos: val.topPosition || 0 });
    }
  } */

  scroll = (newVal) => {
    this.tableScrollbar.scrollYTo(newVal);
  }

  onRegisterHover = (id) => {
    const { registerPopoverOpen } = this.state;
    if (registerPopoverOpen && !registerPopoverOpen[id]) {
      this.setState(prevState => ({
        registerPopoverOpen: {
          ...prevState.registerPopoverOpen,
          [id]: true,
        },
      }));
    }
  }

  onRegisterHoverLeave = (id) => {
    this.setState(prevState => ({
      registerPopoverOpen: {
        ...prevState.registerPopoverOpen,
        [id]: false,
      },
    }));
  }

  openTournamentInfoModal = (selectId) => {
    const { tournaments } = this.props;

    this.setState({
      tournamentInfoModalOpen: true,
      selectTournament: {
        ...tournaments.filter(tournament => tournament.id === selectId)[0],
      },
    });
  }

  closeTournamentInfoModal = () => {
    this.setState({
      tournamentInfoModalOpen: false,
    });
  }

  toggleReturnConfirmationOpen = (tournamentId) => {
    const { returnConfirmationOpen } = this.state;
    this.setState({
      confirmationTournamentId: returnConfirmationOpen ? null : tournamentId,
      returnConfirmationOpen: !returnConfirmationOpen,
    })
  }

  togglePauseConfirmationOpen = (tournamentId) => {
    const { pauseConfirmationOpen } = this.state;
    this.setState({
      confirmationTournamentId: pauseConfirmationOpen ? null : tournamentId,
      pauseConfirmationOpen: !pauseConfirmationOpen,
    })
  }

  handleLeaveReason = (event) => {
    this.setState({ leaveReason: event.target.value, curLength: event.target.value.length });
  }

  render() {
    const {
      t,
      tournaments,
      myTournamentsData,
      //  member,
      registeredTournament,
      uid,
      offset,
      changeTab,
      tournamentPlayers,
      returnToTournamentFunc,
      pauseTournamentFunc,
      toggleShowTournamentTutorial,
      fontMode,
      fetchTournamentActiveRooms,
      finishedTournamentLastRound,
    } = this.props;

    const {
      openModal,
      tournamentId,
      tournamentToJoin,
      tournamentToJoinFee,
      openJoinModal,
      tournamentToLeave,
      openLeaveModal,
      //  scrollPos,
      registerPopoverOpen,
      privateTournamentConfirm,
      privateTournamentConfirmError,
      tournamentConfirmError,
      password,
      popoverOpen,
      popoverRoomsCount,
      popoverPartiesCount,
      popoverEndTime,
      lowBalanceError,
      selectTournament,
      tournamentInfoModalOpen,
      pauseConfirmationOpen,
      returnConfirmationOpen,
      confirmationTournamentId,
      leaveReason,
      curLength,
    } = this.state;

    const activeTournaments = getActiveTournaments(tournaments);

    console.log('activeTournaments', activeTournaments);

    const myActiveTournaments = getMyActiveTournaments(activeTournaments, myTournamentsData);
    const otherActiveTournaments = getOtherActiveTournaments(activeTournaments, myTournamentsData);

    if (privateTournamentConfirm) {
      if (this.digit1.current && (!password[0] || password[0] === '')) {
        this.digit1.current.focus();
      } else if (password[0] && password[0] !== '' && password[1] && password[1] !== '' && password[2] && password[2] !== '' && password[3] && password[3] !== '') {
        this.joinPrivateTournamentClicked();
      }
    }

    return (
      <>
        <Helmet>
          <title>
            {`Zole - ${t('route.tournaments')}`}
          </title>
        </Helmet>
        {/*  <table className="tournaments-table">
          <colgroup>
            <col span="1" />
          </colgroup>
          <thead className="tournaments-table-header">
            <tr>
              <th />
              <th className="tournaments-table-header-col">
                {t('tournaments.title')}
              </th>
              <th className="tournaments-table-header-col">
                {t('tournaments.gameType')}
              </th>
              <th className="tournaments-table-header-col">
                {t('tournaments.currentState')}
              </th>
              <th className="tournaments-table-header-col">
                {t('tournaments.rounds')}
              </th>
              <th className="tournaments-table-header-col">
                {t('tournaments.startTime')}
              </th>
              <th className="tournaments-table-header-col">
                {t('tournaments.players')}
              </th>
            </tr>
          </thead>
          <tbody />
        </table>  */}
        {/*  <ScrollAreaButtons
          scrollPos={scrollPos}
        //  show={this.tableScrollbar && this.tableScrollbar.state && this.tableScrollbar.state.containerHeight < this.tableScrollbar.state.realHeight ? true : null}
          show={tournaments && tournaments.length > 7 ? true : null}
          scroll={this.scroll}
          speed={30}
          topOffset={50}
          bottomOffset={38}
        />
        <ScrollArea
          speed={0.55}
          className="tournaments-scrollarea"
          contentClassName="tournaments-scrollarea-body"
          smoothScrolling
          stopScrollPropagation
          verticalContainerStyle={{
            background: 'transparent',
            opacity: 1,
            width: 11,
          }}
          verticalScrollbarStyle={{
            background: '#fff',
            borderRadius: 1,
            width: 10,
            minHeight: 10,
            minScrollSize: 25,
          }}
          horizontal={false}
          onScroll={this.updateScrollPos}
          ref={(el) => {
            this.tableScrollbar = el;
          }}
        > */}

        {(activeTournaments && activeTournaments.length > 0) ? (
          <ScrollAreaWrapper
            className={`tournaments-scrollarea tournaments-scrollarea-font-based-margin-${fontMode}`}
            contentClassName="tournaments-scrollarea-body"
            rightOffset={17}
            topOffset={55}
            bottomOffset={16}
            disableAutoHeight={activeTournaments && activeTournaments.length === 0}
          >
            <Fragment>
              {(myActiveTournaments && myActiveTournaments.length > 0) ? (
                <Fragment>
                  <span>{t('tournaments.activeMyTournament')}</span>
                  {(myActiveTournaments.map((tournament) => {
                    const { startDate, registerTime } = tournament;
                    let registerDate;
                    if (startDate && registerTime) {
                      registerDate = startDate - (registerTime * 1000 * 60);
                    }
                    return (
                      <Fragment key={tournament.id}>
                        <div className="active-tournaments">
                          <div className="active-tournaments-info">
                            <div className="active-tournaments-info-section">
                              <div className="active-tournaments-info-section-title">
                                <div className="active-tournaments-info-section-title-left">
                                  <Media src={tutorialImage} onClick={() => this.openTournamentInfoModal(tournament.id)} className="active-tournaments-info-section-title-left-img" alt="x" />
                                  <span className="active-tournaments-info-section-title-left-title">{getTournamentTitle(tournament)}</span>
                                  <span className="active-tournaments-info-section-title-left-private first-capitial">
                                    {getTournamentType(tournament, t)}
                                  </span>
                                </div>
                                <div className='d-flex flex-row gap-5'>
                                  {tournament?.restrictions && (
                                    <div className={classNames('active-tournaments-info-section-title-right history-tournaments-info-section-title-right')}>
                                      <div>{t(`common.restrictions`)}</div>
                                    </div>
                                  )}
                                  <div className="active-tournaments-info-section-title-right">
                                    <div>
                                      {/**participant view */}
                                      {tournament.currentState === 'play' ? (

                                        tournament.rounds !== tournament.currentRound ? (
                                          <>
                                            {t('tournaments.activeNewRoundAfter', { round: tournament.currentRound })}
                                            <PopoverEndTimer fetchTournamentActiveRooms={fetchTournamentActiveRooms} tournamentId={tournament.id} tournaments={tournaments} time={popoverEndTime || 0} offset={offset} />
                                          </>
                                        ) : (
                                          <>
                                            {t('tournaments.activeTournamentEndsAfter')}
                                            <PopoverEndTimer fetchTournamentActiveRooms={fetchTournamentActiveRooms} tournamentId={tournament.id} tournaments={tournaments} time={popoverEndTime || 0} offset={offset} />
                                          </>
                                        )
                                      ) : (
                                        tournament.rounds !== tournament.currentRound ? (
                                          <>
                                            {t('tournaments.pausedNewRoundAfter', { round: tournament.currentRound })}
                                            <TournamentTimer t={t} time={tournament.pauseUntil || 0} offset={offset} />
                                          </>
                                        ) : (
                                          <>
                                            {t('tournaments.pausedNewRoundAfter', { round: tournament.currentRound })}
                                            <TournamentTimer t={t} time={tournament.pauseUntil || 0} offset={offset} />
                                          </>
                                        )
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="active-tournaments-info-section-time">
                                <span className="active-tournaments-info-section-time-rounds">{`${t('tournaments.rounds')}:`}</span>
                                <span className="active-tournaments-info-section-time-roundsValue">{tournament.rounds}</span>
                                <span className="active-tournaments-info-section-time-label">{`${t('tournaments.beginning')}:`}</span>
                                <span className="active-tournaments-info-section-time-content"><CustomDate format="DD.MM.YYYY hh:mm" date={startDate} /></span>
                              </div>
                            </div>
                            <div className="active-tournaments-info-button">
                              <Button color="link" className="btn notification-footer-button" onClick={() => this.fetchTournamentPlayers(tournament.id)}>{t('tournaments.players')}</Button>
                              {registeredTournament === tournament.id && myTournamentsData && myTournamentsData.tournamentId === tournament.id && tournament.currentState && (tournament.currentState === 'play' || tournament.currentState === 'pause') && (
                                <Fragment>
                                  {myTournamentsData.paused ? (
                                    <Button color="link" disabled={/*tournament.rounds === tournament.currentRound*/finishedTournamentLastRound} className="btn notification-footer-button" onClick={(e) => { this.toggleReturnConfirmationOpen(tournament.id); }}>
                                      {t('tournaments.return')}
                                    </Button>
                                  ) : (
                                    <Button color="link" disabled={/*tournament.rounds === tournament.currentRound*/finishedTournamentLastRound} className="btn notification-footer-button" onClick={(e) => { this.togglePauseConfirmationOpen(tournament.id); }}>
                                      {t('tournaments.pause')}
                                    </Button>
                                  )}
                                </Fragment>
                              )}

                              <Button color="link" disabled={finishedTournamentLastRound} className="btn notification-footer-button" onClick={() => this.leaveTournamentNotification(tournament.id)}>
                                {t('tournaments.leave')}
                              </Button>

                            </div>
                          </div>
                          <div className={
                            classNames('active-tournaments-sponsor', {
                              'active-tournaments-sponsor-two': tournament?.sponsorImg && tournament?.prizeImg
                            })}
                          >
                            {
                              tournament?.sponsorImg && (
                                <div className={
                                  classNames('active-tournaments-sponsor-section', {
                                    'active-tournaments-sponsor-section-two': tournament?.sponsorImg && tournament?.prizeImg
                                  })}
                                >
                                  <span>{t('tournaments.sponsorTournaments')}</span>
                                  <Media src={tournament?.sponsorImg} alt="x" />
                                </div>
                              )
                            }
                            {
                              tournament?.prizeImg && (
                                <div className={
                                  classNames('active-tournaments-sponsor-section', {
                                    'active-tournaments-sponsor-section-two': tournament?.sponsorImg && tournament?.prizeImg
                                  })}
                                >
                                  <span>{t('tournaments.mainPrize')}</span>
                                  <Media src={tournament?.prizeImg} alt="x" />
                                </div>
                              )
                            }
                          </div>
                        </div>
                      </Fragment>
                    );
                  }))}
                </Fragment>
              ) : null}
            </Fragment>
            <Fragment>
              {(activeTournaments && activeTournaments.length > 0) ? otherActiveTournaments.length > 0 && (
                <Fragment>
                  {console.log('myTournamentsData 2')}
                  {/* <span>{t('tournaments.activeOtherTournaments')}</span> */}
                  {(otherActiveTournaments.map((tournament) => {
                    const { startDate, registerTime } = tournament;
                    let registerDate;

                    if (startDate && registerTime) {
                      registerDate = startDate - (registerTime * 1000 * 60);
                    }

                    return (
                      <Fragment key={tournament.id}>
                        <div className="active-tournaments">
                          <div className="active-tournaments-info">
                            <div className="active-tournaments-info-section">
                              <div className="active-tournaments-info-section-title">
                                <div className="active-tournaments-info-section-title-left">
                                  <Media src={tutorialImage} onClick={() => this.openTournamentInfoModal(tournament.id)} className="active-tournaments-info-section-title-left-img" alt="x" />
                                  <span className="active-tournaments-info-section-title-left-title">{getTournamentTitle(tournament)}</span>
                                  <span className="active-tournaments-info-section-title-left-private">
                                    {getTournamentType(tournament, t)}
                                  </span>
                                </div>
                                <div className="active-tournaments-info-section-title-right">
                                  <div>
                                    {/**non participant view */}
                                    {tournament.currentState === 'play' ? (
                                      tournament.rounds !== tournament.currentRound ? (
                                        <>
                                          {t('tournaments.activeNewRoundAfter', { round: tournament.currentRound })}
                                          <PopoverEndTimer fetchTournamentActiveRooms={fetchTournamentActiveRooms} tournamentId={tournament.id} tournaments={tournaments} time={popoverEndTime || 0} offset={offset} />
                                        </>
                                      ) : (
                                        <>
                                          {t('tournaments.activeTournamentEndsAfter')}
                                          <PopoverEndTimer fetchTournamentActiveRooms={fetchTournamentActiveRooms} tournamentId={tournament.id} tournaments={tournaments} time={popoverEndTime || 0} offset={offset} />
                                        </>
                                      )
                                    ) : (
                                      tournament.rounds !== tournament.currentRound ? (
                                        <>
                                          {t('tournaments.pausedNewRoundAfter', { round: tournament.currentRound })}
                                          <TournamentTimer t={t} time={tournament.pauseUntil || 0} offset={offset} />
                                        </>
                                      ) : (
                                        <>
                                          {t('tournaments.pausedNewRoundAfter', { round: tournament.currentRound })}
                                          <TournamentTimer t={t} time={tournament.pauseUntil || 0} offset={offset} />
                                        </>
                                      )
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="active-tournaments-info-section-time">
                                <span className="active-tournaments-info-section-time-rounds">{`${t('tournaments.rounds')}:`}</span>
                                <span className="active-tournaments-info-section-time-roundsValue">{tournament.rounds}</span>
                                <span className="active-tournaments-info-section-time-label">{`${t('tournaments.beginning')}:`}</span>
                                <span className="active-tournaments-info-section-time-content"><CustomDate format="DD.MM.YYYY hh:mm" date={startDate} /></span>
                              </div>
                            </div>
                            <div className="active-tournaments-info-button">
                              <Button color="link" className="btn notification-footer-button" onClick={() => this.fetchTournamentPlayers(tournament.id)}>{t('tournaments.players')}</Button>
                              {registeredTournament === tournament.id && myTournamentsData && myTournamentsData.tournamentId === tournament.id && tournament.currentState && (tournament.currentState === 'play' || tournament.currentState === 'pause') && (
                                <Fragment>
                                  {myTournamentsData.paused ? (
                                    <Button color="link" disabled={/*tournament.rounds === tournament.currentRound*/false} className="btn notification-footer-button" onClick={(e) => { this.toggleReturnConfirmationOpen(tournament.id); }}>
                                      {t('tournaments.return')}
                                    </Button>
                                  ) : (
                                    <Button color="link" disabled={/*tournament.rounds === tournament.currentRound*/false} className="btn notification-footer-button" onClick={(e) => { this.togglePauseConfirmationOpen(tournament.id); }}>
                                      {t('tournaments.pause')}
                                    </Button>
                                  )}
                                </Fragment>
                              )}
                            </div>
                          </div>
                          <div className={
                            classNames('active-tournaments-sponsor', {
                              'active-tournaments-sponsor-two': tournament?.sponsorImg && tournament?.prizeImg
                            })}
                          >
                            {
                              tournament?.sponsorImg && (
                                <div className={
                                  classNames('active-tournaments-sponsor-section', {
                                    'active-tournaments-sponsor-section-two': tournament?.sponsorImg && tournament?.prizeImg
                                  })}
                                >
                                  <span>{t('tournaments.sponsorTournaments')}</span>
                                  <Media src={tournament?.sponsorImg} alt="x" />
                                </div>
                              )
                            }
                            {
                              tournament?.prizeImg && (
                                <div className={
                                  classNames('active-tournaments-sponsor-section', {
                                    'active-tournaments-sponsor-section-two': tournament?.sponsorImg && tournament?.prizeImg
                                  })}
                                >
                                  <span>{t('tournaments.mainPrize')}</span>
                                  <Media src={tournament?.prizeImg} alt="x" />
                                </div>
                              )
                            }
                          </div>
                        </div>
                      </Fragment>
                    );
                  }))}
                </Fragment>
              ) : null}
            </Fragment>
          </ScrollAreaWrapper>
        ) : (
          <div className="d-flex align-items-center h-100 future-tournaments-empty-entries-old">{t('tournaments.activeTournamentsEmptyEntries')}</div>
        )}
        <Modal container={'div > div'} size="lg" isOpen={tournamentInfoModalOpen} toggle={this.closeTournamentInfoModal} className={`old-modal root-font-${fontMode}`}>
          <ModalHeader
            toggle={this.closeTournamentInfoModal}
            close={
              <Media src={closeImg} className="notification-header-close" alt="X" onClick={this.closeTournamentInfoModal} />
            }
          >
            {getTournamentTitle(selectTournament)}
          </ModalHeader>
          <ModalBody>
            <div className="tournament-info-section">
              <div className={classNames('tournament-info-section-main', {})}>
                {(selectTournament?.sponsorImg || selectTournament?.prizeImg) && (
                  <div className="tournament-info-section-main-row">
                    <div className="d-flex justify-content-center w-100">
                      {selectTournament?.sponsorImg && (
                        <div className="tournament-info-section-main-row-image-container">
                          <span>{t('tournaments.sponsorTournaments')}</span>
                          <Media className="" src={selectTournament?.sponsorImg} alt="x" />
                        </div>
                      )}
                      {selectTournament?.prizeImg && (
                        <div className="tournament-info-section-main-row-image-container">
                          <span>{t('tournaments.mainPrize')}</span>
                          <Media className="" src={selectTournament?.prizeImg} alt="x" />
                        </div>
                      )}
                    </div>
                  </div>
                )}
                <div className="tournament-info-section-main-row">
                  <div className="tournament-info-section-main-row-content w-50">
                    {t('tournaments.gameType')}
                  </div>
                  <div className="tournament-info-section-main-row-value w-50 first-capitial">
                    {getTournamentType(selectTournament, t)}
                  </div>
                </div>
                <div className="tournament-info-section-main-row">
                  <div className="tournament-info-section-main-row-content w-50">
                    {t('tournaments.bet')}
                  </div>
                  <div className="tournament-info-section-main-row-value w-50">
                    {`${selectTournament?.bet}`}
                  </div>
                </div>
                <div className="tournament-info-section-main-row">
                  <div className="tournament-info-section-main-row-content w-50">
                    {t('tournaments.joiningFee')}
                  </div>
                  <div className="tournament-info-section-main-row-value w-50">
                    {`${selectTournament?.entryFee}`}
                  </div>
                </div>
                <div className="tournament-info-section-main-row">
                  <div className="tournament-info-section-main-row-content w-50">
                    {t('tournaments.bonus')}
                  </div>
                  <div className="tournament-info-section-main-row-value w-50">
                    {`${selectTournament?.bonus}`}
                  </div>
                </div>
                <div className="tournament-info-section-main-row">
                  <div className="tournament-info-section-main-row-content w-50">
                    {t('tournaments.intervalBetweenRounds')}
                  </div>
                  <div className="tournament-info-section-main-row-value w-50">
                    {`${selectTournament?.newRoundPause} ${t('tournaments.minutes')}`}
                  </div>
                </div>
                <div className="tournament-info-section-main-row">
                  <div className="tournament-info-section-main-row-content w-50">
                    {t('tournaments.numberOfRounds')}
                  </div>
                  <div className="tournament-info-section-main-row-value w-50">
                    {`${selectTournament?.rounds}`}
                  </div>
                </div>
                <div className="tournament-info-section-main-row">
                  <div className="tournament-info-section-main-row-content w-50">
                    {t('tournaments.numberOfGamesPerRound')}
                  </div>
                  <div className="tournament-info-section-main-row-value w-50">
                    {`${selectTournament?.roundLength}`}
                  </div>
                </div>
                <div className="tournament-info-section-main-row">
                  <div className="tournament-info-section-main-row-content w-50">
                    {t('tournaments.startOfTournament')}
                  </div>
                  <div className="tournament-info-section-main-row-value w-50">
                    <CustomDate format="DD.MM.YYYY hh:mm" date={selectTournament?.startDate} />
                  </div>
                </div>
                <div className="tournament-info-section-main-row">
                  <div className="tournament-info-section-main-row-content w-50">
                    {t('tournaments.startOfRegistration')}
                  </div>
                  <div className="tournament-info-section-main-row-value w-50">
                    <CustomDate format="DD.MM.YYYY hh:mm" date={selectTournament?.startDate - (selectTournament?.registerTime * 1000 * 60)} />
                  </div>
                </div>
                <div className="tournament-info-section-main-row">
                  <div className="tournament-info-section-main-row-content w-50">
                    {t('tournaments.maxNumOfPlayers')}
                  </div>
                  <div className="tournament-info-section-main-row-value w-50">
                    {`${selectTournament?.maxPlayers}`}
                  </div>
                </div>
                <div className='tournament-info-section-main-row'>
                  <div className='tournament-info-section-main-row-content w-50'>
                    {t('tournaments.playerPerRoomLimit')}
                  </div>
                  <div className='tournament-info-section-main-row-value w-50'>
                    {`${selectTournament?.playerPerRoomLimit ? (selectTournament.playerPerRoomLimit === 4 ? t('tournaments.playerPerRoomLimit4Only') : t('tournaments.playerPerRoomLimit3Only')) : t('tournaments.playerPerRoomLimitClasic')}`}
                  </div>
                </div>
                <div className="tournament-info-section-main-row">
                  <div className="tournament-info-section-main-row-content w-50">
                    {t('tournaments.winningPercent')}
                  </div>
                  <div className="tournament-info-section-main-row-value w-50">
                    {`${selectTournament?.winnerPercent}%`}
                  </div>
                </div>
                {selectTournament?.restrictions && (
                  <div className="tournament-info-section-main-row">
                    <div className={classNames('tournament-info-section-main-row-content w-50 active-tournaments-info-section-title-right history-tournaments-info-section-title-right')}>
                      <div>{t(`common.restrictions`)}</div>
                    </div>
                    <div className="tournament-info-section-main-row-value w-50 d-flex flex-column">
                      {selectTournament?.levelFrom && selectTournament?.levelTo && (
                        <span>{`${t('common.level')}: ${selectTournament?.levelFrom}–${selectTournament?.levelTo}`}</span>
                      )}
                      {selectTournament?.pointsFrom && selectTournament?.pointsTo && (
                        <span>{`${t('common.points')}: ${selectTournament?.pointsFrom}–${selectTournament?.pointsTo}`}</span>
                      )}
                      {selectTournament?.balanceFrom && selectTournament?.balanceTo && (
                        <span>{`${t('common.balance')}: ${selectTournament?.balanceFrom}–${selectTournament?.balanceTo}`}</span>
                      )}
                      {selectTournament?.playedGamesFrom && selectTournament?.playedGamesTo && (
                        <span>{`${t('common.playedGames')}: ${selectTournament?.playedGamesFrom}–${selectTournament?.playedGamesTo}`}</span>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="link" className="modal-footer-button" onClick={this.closeTournamentInfoModal}>{t('tournaments.close')}</Button>
          </ModalFooter>
        </Modal>
        {/*  </ReactTableContainer>  */}
        {uid && tournamentPlayers && openModal ? (
          <Modal container={'div > div'} returnFocusAfterClose={false} size="lg" isOpen={openModal} toggle={this.toggle} className={`old-modal root-font-${fontMode}`}>
            <ModalHeader
              toggle={this.toggle}
              close={
                <Media src={closeImg} className="notification-header-close" alt="X" onClick={this.toggle} />
              }
            >
              {getTournamentPlayersTitle(tournamentId, tournaments, t)/*t('tournaments.top')*/}
            </ModalHeader>
            <ModalBody>
              <Leaderboard tournament={getTournamentById(tournaments, tournamentId)} leaderboard={tournamentPlayers} tournamentId={tournamentId} uid={uid} />
            </ModalBody>
            <ModalFooter>
              <Button color="link" className="modal-footer-button" onClick={this.toggle}>{t('tournaments.close')}</Button>
            </ModalFooter>
          </Modal>
        ) : (null)}

        <Modal container={'div > div'} isOpen={privateTournamentConfirm} toggle={() => this.closePrivateTournamentEnter()} className={`old-modal notification root-font-${fontMode}`} >
          <ModalHeader
            className="notification-header"
            close={
              <Media src={closeImg} className="notification-header-close" alt="X" onClick={() => this.closePrivateTournamentEnter()} />
            }
          >
            <Row>
              <Col>
                <div className="tournaments-join-message">
                  {t('tournaments.joinTournament')}
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                {t('tournaments.joinTournamentMessage', { entryFee: tournamentToJoinFee })}
                <Media className="tournaments-join-message-image" src={coinImg} />
              </Col>
            </Row>
            <Row>
              <Col>
                {t('tournaments.enterCode')}
              </Col>
            </Row>
          </ModalHeader>
          <ModalBody className="notification-body">
            <Row>
              <Col>
                {privateTournamentConfirmError && (
                  <div className="room-password-error">
                    {t(`notifications:${privateTournamentConfirmError}`)}
                  </div>
                )}
              </Col>
            </Row>
            <Input
              className="room-password-digit"
              type="text"
              name={0}
              id="digit1"
              value={password[0] || ''}
              onChange={this.handleChangeDigit}
              innerRef={this.digit1}
            />
            <Input
              className="room-password-digit"
              type="text"
              name={1}
              id="digit2"
              value={password[1] || ''}
              onChange={this.handleChangeDigit}
              innerRef={this.digit2}
            />
            <Input
              className="room-password-digit"
              type="text"
              name={2}
              id="digit3"
              value={password[2] || ''}
              onChange={this.handleChangeDigit}
              innerRef={this.digit3}
            />
            <Input
              className="room-password-digit"
              type="text"
              name={3}
              id="digit4"
              value={password[3] || ''}
              onChange={this.handleChangeDigit}
              innerRef={this.digit4}
            />
          </ModalBody>
          <ModalFooter className="notification-footer">
            <Button color="link" className="btn notification-footer-button" onClick={() => this.joinPrivateTournamentClicked()}>
              {t('tournaments.register')}
            </Button>
            <Button className="btn notification-footer-button" onClick={() => this.closePrivateTournamentEnter()}>
              {t('common.close')}
            </Button>
          </ModalFooter>
        </Modal>

        <Modal container={'div > div'} returnFocusAfterClose={false} isOpen={openJoinModal} toggle={this.toggleJoin} className={`old-modal root-font-${fontMode}`}>
          <ModalHeader
            toggle={this.toggleJoin}
            close={
              <Media src={closeImg} className="notification-header-close" alt="X" onClick={this.toggleJoin} />
            }
          >
            {t('tournaments.joinTournament')}
          </ModalHeader>
          <ModalBody>

            {tournamentConfirmError && (
              <Row>
                <Col>
                  <div className="room-password-error">
                    {t(`notifications:${tournamentConfirmError}`)}
                  </div>
                </Col>
              </Row>
            )}

            <div className="tournaments-join-message">
              {t('tournaments.joinTournamentMessage', { entryFee: tournamentToJoinFee })}
              <Media className="tournaments-join-message-image" src={coinImg} />
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="link" className="modal-footer-button" onClick={() => this.joinTournament(tournamentToJoin)}>
              {t('tournaments.register')}
            </Button>
            <Button color="link" className="modal-footer-button" onClick={this.toggleJoin}>{t('tournaments.close')}</Button>
          </ModalFooter>
        </Modal>


        <Modal container={'div > div'} returnFocusAfterClose={false} isOpen={openLeaveModal} toggle={this.toggleLeave} className={`notification old-modal root-font-${fontMode}`}>
          <ModalHeader
            toggle={this.toggleLeave}
            close={
              <Media src={closeImg} className="notification-header-close" alt="X" onClick={this.toggleLeave} />
            }
          >
            {t('tournaments.leaveTournament')}
          </ModalHeader>
          <ModalBody className="notification-body text-center">
            {t('tournaments.leaveTournamentBody')}
            <p>{t('tournaments.leaveTournamentWarning')}</p>

            <FormGroup className="mb-0">
              <Label for="leaveReason">{t('tournaments.leaveTournamentReason')}</Label>
              <div>
                <Input
                  type="textarea"
                  name="leaveReason"
                  id="leaveReason"
                  placeholder=""
                  value={leaveReason}
                  maxLength={500}
                  onChange={this.handleLeaveReason}
                  rows={3}
                  style={{ resize: 'none' }}
                />
                <div className="float-right">
                  {curLength}/500
                </div>
              </div>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="link" className="modal-footer-button" onClick={() => this.leaveTournament(tournamentToLeave)}>
              {t('tournaments.leave')}
            </Button>
            <Button color="link" className="modal-footer-button" onClick={this.toggleLeave}>{t('tournaments.close')}</Button>
          </ModalFooter>
        </Modal>


        <Modal container={'div > div'} isOpen={lowBalanceError} toggle={() => this.closeLowBalanceError()} className={`notification old-modal root-font-${fontMode}`}>
          <ModalHeader
            className="notification-header"
            close={
              <Media src={closeImg} className="notification-header-close" alt="X" onClick={() => this.closeLowBalanceError()} />
            }
          >
            {t('common:home.notifications')}
          </ModalHeader>
          <ModalBody className="notification-body">
            {t('notifications:noMoneyTournamentDescription',
              {
                feeMaxLoss: selectTournament && selectTournament.bet && selectTournament.entryFee + 16 * parseInt(selectTournament.bet.split(':')[1]),
                bet: selectTournament?.bet, fee: selectTournament?.entryFee
              })}
          </ModalBody>
          <ModalFooter className="notification-footer">
            <Button className="btn notification-footer-button" color="link" onClick={this.buyMoney}>{t('notifications:buyMoney')}</Button>
            <Button color="link" className="btn notification-footer-button" onClick={() => this.closeLowBalanceError()}>
              {t('common.cancel')}
            </Button>
          </ModalFooter>
        </Modal>

        <Modal container={'div > div'} size="md" isOpen={pauseConfirmationOpen} toggle={this.togglePauseConfirmationOpen} className={`old-modal root-font-${fontMode}`}>
          <ModalHeader
            toggle={this.togglePauseConfirmationOpen}
            close={
              <Media src={closeImg} className="notification-header-close" alt="X" onClick={this.togglePauseConfirmationOpen} />
            }
          >
            {t('home.confirm')}
          </ModalHeader>
          <ModalBody className="text-center">
            {t('tournaments.pauseConfirmation')}
          </ModalBody>
          <ModalFooter>
            <Button color="link" className="modal-footer-button" style={{width: 65}} onClick={(e) => { pauseTournamentFunc(confirmationTournamentId); e.target.blur(); this.togglePauseConfirmationOpen(); }}>{t('common.yes')}</Button>
            <Button color="link" className="modal-footer-button" style={{width: 65}} onClick={this.togglePauseConfirmationOpen}>{t('common.no')}</Button>
          </ModalFooter>
        </Modal>

        <Modal container={'div > div'} size="md" isOpen={returnConfirmationOpen} toggle={this.toggleReturnConfirmationOpen} className={`old-modal root-font-${fontMode}`}>
          <ModalHeader
            toggle={this.toggleReturnConfirmationOpen}
            close={
              <Media src={closeImg} className="notification-header-close" alt="X" onClick={this.toggleReturnConfirmationOpen} />
            }
          >
            {t('home.confirm')}
          </ModalHeader>
          <ModalBody className="text-center">
            {t('tournaments.returnConfirmation')}
          </ModalBody>
          <ModalFooter>
            <Button color="link" className="modal-footer-button" style={{width: 65}} onClick={(e) => { returnToTournamentFunc(confirmationTournamentId); e.target.blur(); this.toggleReturnConfirmationOpen(); }}>{t('common.yes')}</Button>
            <Button color="link" className="modal-footer-button" style={{width: 65}} onClick={this.toggleReturnConfirmationOpen}>{t('common.no')}</Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = state => ({
  finishedTournamentLastRound: state.member.finishedTournamentLastRound,
  registeredTournament: state.member.registeredTournament,
  uid: state.member.uid || '',
  offset: state.member.offset || 0,
  tournaments: (state.tournaments && state.tournaments.tournaments) ? state.tournaments.tournaments : [],
  myTournamentsData: (state.tournaments && state.tournaments.myTournamentsData) ? state.tournaments.myTournamentsData : {},
  tournamentPlayers: (state.tournaments && state.tournaments.tournamentPlayers) ? state.tournaments.tournamentPlayers : {},
  name: state.member.name,
});

const mapDispatchToProps = {
  pauseTournamentFunc: pauseTournament,
  returnToTournamentFunc: returnToTournament,
  leaveTournamentFunc: leaveTournament,
  joinTournamentFunc: joinTournament,
  fetchTournamentPlayers: getTournamentPlayers,
  fetchTournamentActiveRooms: getTournamentActiveRooms,
  fetchTournaments: getTournaments,
  setAddPaymentInfo: addPaymentInfo,
  setHandlePaymentInfoStatus: updatePaymentInfoStatus,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation(['common', 'notifications'])(Tournaments));
