import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import {
  UncontrolledPopover, PopoverBody, Button,
} from 'reactstrap/lib';

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import HelpEditorSection from './HelpEditorSection';

const HelpEditor = ({ showNotification }) => (
  <Fragment>
    <UncontrolledPopover popperClassName={`popover`} placement="right" trigger="focus" target="admin-help-editor">
      <PopoverBody className="popover-body">
        <div>This editor allows to change the Game Help in all available interface languages.</div>

        <div>Note: the changes are saved on dev, and are being deployed on the prod as other normal code changes.</div>
      </PopoverBody>
    </UncontrolledPopover>
    <div className="admin-page-content">
      <h2>
        Help Editor
        <Button className="admin-help-button" id="admin-help-editor">
          ?
        </Button>
      </h2>
      <DndProvider backend={HTML5Backend}>
        <HelpEditorSection showNotification={showNotification} />
      </DndProvider>
    </div>
  </Fragment>
);

HelpEditor.propTypes = {
  showNotification: PropTypes.func.isRequired,
};

HelpEditor.defaultProps = {
};

export default HelpEditor;
