import React, { Fragment, useCallback, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { useSelector, useDispatch } from 'react-redux';

import classNames from 'classnames';

import { filter, map, orderBy, remove, size, sortBy, includes } from 'lodash';
import Input from 'reactstrap/lib/Input';
import { Button, Col, Form, FormGroup, Label, Media, Modal, ModalBody, ModalFooter, ModalHeader, Pagination, PaginationItem, PaginationLink, PopoverBody, Row } from 'reactstrap';

import 'react-widgets/dist/css/react-widgets.css';
import { DateTimePicker } from 'react-widgets';

import { FORMAT_DATE_TYPE, SURVEY_TYPE } from '../../../constants/constants';
import pencilImg from '../../../images/icons/pencil.svg';
import cancelImg from '../../../images/icons/close_old.png';
import checkImg from '../../../images/icons/check.png';
import closeImg from '../../../images/icons/close.png';

import {
  getSurveys, getSurveyHistory, createSurveyFunc, deleteSurveyFunc, editSurveyFunc
} from '../../../actions/admin';
import DateFormat from '../UI/DateFormat';
import { UncontrolledPopover } from 'reactstrap/lib';

const SurveyResults = ({}) => {

  const dispatch = useDispatch();

  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize] = useState(50);
  const [selectedSurvey, setSelectedSurvey] = useState(null);

  const surveys = useSelector(state => state.admin.allSurveys || {});
  const surveyHistory = useSelector(state => state.admin.surveyHistory || {});
  const loading = useSelector(state => state.admin.loading || false);
  const pagesCount = Math.ceil(size(surveys) / pageSize);

  console.log('surveys', surveys, surveyHistory);

  useEffect(() => {
    dispatch(getSurveys());
    dispatch(getSurveyHistory());
  }, []);

  const handleClick = (e, index) => {
    e.preventDefault();
    setCurrentPage(index);
  };

  const handleToggleSurvey = (key) => {

    if (selectedSurvey === key) {
      setSelectedSurvey(null);
    } else {
      setSelectedSurvey(key);
    }
  };

  return (
    <Fragment>
      <UncontrolledPopover popperClassName={`popover`} placement="right" trigger="focus" target="admin-help-button-survey-results">
        <PopoverBody className="popover-body">
          A list of all active and finished surveys together with their results.
        </PopoverBody>
      </UncontrolledPopover>
      <div className="admin-page-content">
        <Row>
          <Col md="6">
            <h2>
              {/* {t('Tournaments')} */}
              Survey Results
              <Button className="admin-help-button" id="admin-help-button-survey-results">
                ?
              </Button>
            </h2>
          </Col>
        </Row>
        <div className="d-flex flex-column">
          <table style={{ width: '100%', fontSize: 12 }}>
            <colgroup>
              <col span="1" className="" />
            </colgroup>
            <thead>
              <tr>
                <th style={{ textAlign: 'center' }}>
                  Start Date
                </th>
                <th style={{ textAlign: 'center' }}>
                  End Date
                </th>
                <th style={{ textAlign: 'center' }}>
                  Type
                </th>
                <th style={{ textAlign: 'center' }}>
                  Question
                </th>
                <th style={{ textAlign: 'center' }}>
                  Description
                </th>
                <th style={{ textAlign: 'center' }}>
                  Design
                </th>
                <th style={{ textAlign: 'center' }}>
                  Participants Number
                </th>
                <th style={{ textAlign: 'center' }}>
                  Answers
                </th>
                <th style={{ textAlign: 'center' }}>
                  Only Dev
                </th>
              </tr>
            </thead>
            <tbody>
              {surveys && Object.keys(surveys)
                .sort((a, b) => surveys[b].endDate - surveys[a].endDate)
                .slice(
                  currentPage * pageSize,
                  (currentPage + 1) * pageSize,
                )
                .map((key, index) => (
                  <Fragment key={key}>
                    <tr key={key} className={`allUsers-table-row ${index % 2 === 0 ? ('odd') : ('even')}`} onClick={() => handleToggleSurvey(key)}>
                      <td className="allUsers-table-col">
                        <DateFormat formatType={FORMAT_DATE_TYPE.fullDateMinute} date={surveys[key].startDate} />
                      </td>
                      <td className="allUsers-table-col">
                        <DateFormat formatType={FORMAT_DATE_TYPE.fullDateMinute} date={surveys[key].endDate} />
                      </td>
                      <td className='allUsers-table-col'>
                        {filter(SURVEY_TYPE, item => item.id === surveys[key].questionType)[0].label}
                      </td>
                      <td className="allUsers-table-col">
                        {surveys[key].question}
                      </td>
                      <td className="allUsers-table-col">
                        {surveys[key].description}
                      </td>
                      <td className="allUsers-table-col flex-column">
                        {surveys[key].selectedDesigns && map(surveys[key].selectedDesigns, (item) => (
                          <div>{item.label}</div>
                        ))}
                      </td>
                      <td className="allUsers-table-col">
                        {size(surveyHistory[key])}
                      </td>
                      <td className="allUsers-table-col flex-column">
                        {map(surveys[key].answerList, item => <div>{item.value}</div> )}
                      </td>
                      <td className="allUsers-table-col">
                        {surveys[key].devMode ? 'Yes' : 'No'}
                      </td>
                    </tr>
                    {
                      selectedSurvey && key === selectedSurvey && (
                        <Fragment>
                          <tr className='border'>
                            <td>
                              <div className='d-flex flex-row align-items-start'>
                                <h6 className='mr-4'>Number of Participants: </h6>
                                <h6>{surveyHistory[key] ? size(surveyHistory[key]) : 0}</h6>
                              </div>
                              <div className='d-flex flex-column align-start w-full'>
                                <h6>User List of Participants</h6>
                                {
                                  map(surveyHistory[key], (historyItem) => (
                                    <div className='d-flex flex-row mb-2 w-full'>
                                      <span className='mr-4 w-40 break-all'>{historyItem.uid}</span>
                                      <span className='mr-4 w-30'>{historyItem.userName}</span>
                                      <span className='w-30'>{historyItem.design === 'new' ? 'New Design' : 'Old Design'}</span>
                                    </div>
                                  ))
                                }
                              </div>
                            </td>
                            <td colSpan={2} className='border' style={{ verticalAlign: 'top' }}>
                              <div className='d-flex flex-column align-start w-full'>
                              {
                                surveys[key].questionType !== 'type1' && map(surveys[key].answerList, answerItem => (
                                  <div className='d-flex flex-row align-start w-full'>
                                    <h6 className='mr-2 mb-2 w-65'>{`${answerItem.value}: `}</h6>
                                    <h6 className='w-35'>
                                      {size(filter(surveyHistory[key], (historyItem, historyIdx) => includes(historyItem.checkedQuestionAnswer, answerItem.id)))}
                                      {size(filter(surveyHistory[key], (historyItem, historyIdx) => includes(historyItem.checkedQuestionAnswer, answerItem.id))) > 0 ? (
                                        ` (${
                                          (size(filter(surveyHistory[key], (historyItem, historyIdx) => includes(historyItem.checkedQuestionAnswer, answerItem.id)))
                                          / Object.values(surveyHistory[key]).reduce((acc, obj) => acc + (obj.checkedQuestionAnswer ? obj.checkedQuestionAnswer.length : 0), 0) * 100.0).toFixed(2)
                                        }%)`) : null}
                                    </h6>
                                  </div>
                                ))
                              }
                              
                              </div>
                            </td>
                            <td colSpan={3} style={{ verticalAlign: 'top' }}>
                              {
                                surveys[key].questionType === 'type1' ? (
                                  map(surveyHistory[key], (historyItem, idx) => (
                                    <div className='d-flex flex-column mb-4'>
                                    <span>{historyItem?.userName}</span> 
                                    <span>{historyItem?.additionalComment}</span> 
                                    </div>
                                  ))
                                ) : (
                                  <div className='d-flex flex-row align-items-center'>
                                    <h6 className='mr-4 mb-2'>Another Option: </h6> 
                                    <h6>{size(filter(surveyHistory[key], (historyItem, historyIdx) => historyItem.anotherAnswerOption))}</h6> 
                                  </div>
                                )
                              }
                            </td>
                          </tr>
                        </Fragment>
                      )
                    }
                  </Fragment>
                ))}
              {pagesCount && Object.keys(surveys).length > pageSize && (
                <div className="pagination-wrapper">
                  <Pagination aria-label="Page navigation example">
                    <PaginationItem disabled={currentPage === 0}>
                      <PaginationLink style={{ color: '#000' }} onClick={e => handleClick(e, 0)} href="#">
                        {`‹‹‹`}
                      </PaginationLink>
                    </PaginationItem>

                    <PaginationItem disabled={currentPage <= 0}>
                      <PaginationLink
                        style={{ color: '#000' }}
                        onClick={e => handleClick(e, currentPage - 1)}
                        previous
                        href="#"
                      />
                    </PaginationItem>

                    {[...Array(pagesCount)].map((page, i) => {
                      if (i > currentPage - 3 && i < currentPage + 3) {
                        return (
                          <PaginationItem active={i === currentPage} key={page}>
                            <PaginationLink style={{ color: '#000' }} onClick={e => handleClick(e, i)} href="#">
                              {i + 1}
                            </PaginationLink>
                          </PaginationItem>
                        );
                      }

                      return null;
                    })}

                    <PaginationItem disabled={currentPage >= pagesCount - 1}>
                      <PaginationLink
                        style={{ color: '#000' }}
                        onClick={e => handleClick(e, currentPage + 1)}
                        next
                        href="#"
                      />
                    </PaginationItem>

                    <PaginationItem disabled={currentPage >= pagesCount - 1}>
                        <PaginationLink style={{ color: '#000' }} onClick={e => handleClick(e, pagesCount - 1)} href="#">
                          {`›››`}
                        </PaginationLink>
                      </PaginationItem>
                  </Pagination>
                </div>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </Fragment>
  );
};

export default SurveyResults;
