import Store from '../store/state';

export const initialState = Store;


export default function shopReducer(state = initialState, action) {
  switch (action.type) {
    case 'EVENTS_BANNER_REPLACE': {
      return {
        ...state,
        eventsData: action.data,
      };
    }

    case 'CAMPAIGN_OPENROOM_BANNER_REPLACE': {
      return {
        ...state,
        campaigOpenRoomData: action.data,
      };
    }

    case 'CAMPAIGN_LOADING_BANNER_REPLACE': {
      return {
        ...state,
        campaignLoadingData: action.data,
      };
    }

    case 'CAMPAIGN_LOBBY_BANNER_REPLACE': {
      return {
        ...state,
        campaignLobbyData: action.data,
      };
    }

    case 'GET_DISCOUNTS': {
      return {
        ...state,
        discounts: action.data,
      };
    }

    case 'LAST_SHOP_TAB': {
      return {
        ...state,
        lastShopTab: action.data,
      };
    }

    case 'TRANSACTION_COMPLETE': {
      return {
        ...state,
        transactionComplete: action.data,
      };
    }

    case 'ADD_PAYMENT': {
      return {
        ...state,
        paymentInfo: { ...state.paymentInfo, ...action.data },
      };
    }

    case 'UPDATE_PAYMENT_STATUS': {
      return {
        ...state,
        paymentInfo: { ...state.paymentInfo, status: action.data.status, lastScreen: action.data.lastScreen },
      };
    }

    default:
      return state;
  }
}
