import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';

import Moment from 'react-moment';
import moment from 'moment';

import ScrollArea from 'react-scrollbar';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Media from 'reactstrap/lib/Media';
import Form from 'reactstrap/lib/Form';
import Input from 'reactstrap/lib/Input';
import Label from 'reactstrap/lib/Label';
import Button from 'reactstrap/lib/Button';
import Pagination from 'reactstrap/lib/Pagination';
import PaginationItem from 'reactstrap/lib/PaginationItem';
import PaginationLink from 'reactstrap/lib/PaginationLink';
import Modal from 'reactstrap/lib/Modal';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';

import Message from './AdminSupportMessage';
import { map, size, get, isEqual } from 'lodash';
import * as constants from '../../../constants/constants';
import { errorMessages } from '../../../constants/messages';
import computerImg from '../../../images/icons/computer.png';
import androidImg from '../../../images/icons/android.svg';
import iosImg from '../../../images/icons/apple.svg';
import draugiemImg from '../../../images/icons/draugiem.svg';
import facebookImg from '../../../images/icons/facebook.svg';
import inboxImg from '../../../images/icons/inbox.svg';
import tabletImg from '../../../images/icons/tablet.svg';
import phoneImg from '../../../images/icons/phone.svg';
import mobileWeb from '../../../images/icons/mobile-browser-development.svg';
import { PopoverBody, UncontrolledPopover } from 'reactstrap';

class UserMessages extends React.Component {
  static propTypes = {
    member: PropTypes.shape({
      email: PropTypes.string,
    }),
    //  allChats: PropTypes.arrayOf(PropTypes.shape({})),
    chatMessages: PropTypes.shape({}),
    chatMessagesUserLastLanguage: PropTypes.string,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    getUserMessages: PropTypes.func.isRequired,
    cancelUserMessages: PropTypes.func.isRequired,
    answerSupportMessage: PropTypes.func.isRequired,
    supportMessageFileUpload: PropTypes.func.isRequired,
    setSupportMessageAsResponded: PropTypes.func.isRequired,
    showNotification: PropTypes.func.isRequired,
    messageAll: PropTypes.func.isRequired,
    readMessages: PropTypes.arrayOf(PropTypes.shape({
      lastResponse: PropTypes.string,
      responded: PropTypes.bool,
      active: PropTypes.bool,
      name: PropTypes.string,
      autoRespond: PropTypes.bool,
    })),
    /*  unreadMessages: PropTypes.arrayOf(PropTypes.shape({
        lastResponse: PropTypes.string,
        responded: PropTypes.bool,
        active: PropTypes.bool,
        name: PropTypes.string,
      })), */
    //  readMessages: PropTypes.shape(),
    unreadMessages: PropTypes.shape(),
    t: PropTypes.func.isRequired,
    fontMode: PropTypes.string,
  }

  static defaultProps = {
    member: {},
    chatMessages: {},
    readMessages: [],
    unreadMessages: {},
    fontMode: constants.FONT_MODE.normal,
  }

  constructor(props) {
    super(props);
    this.state = {
      openModal: false,
      currentPage: 0,
      pageSize: 50,
      uid: '',
      inputMessage: '',
      //  readMessages: [],
      unreadMessages: [],
      messageAllInput: '',
      messageAllModal: false,
      fileUploadUrl: '',
      fileName: '',
      ableToSend: true,
      filterPlayers: 'all',
      languageType: constants.sendLanguage.all,
      messageLvInput: '',
      messageRuInput: '',
      messageEnInput: '',
    };

    this.openModal = this.openModal.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.hiddenMessageFileInput = React.createRef(null);
  }

  componentWillMount() {
    const { unreadMessages } = this.state;
    const { props } = this;

    //  if (props.readMessages && readMessages
    //    && readMessages.length !== props.readMessages.length) {
    //    this.setState({ readMessages: props.readMessages });
    //  }

    /*  if (props.unreadMessages && unreadMessages
        && unreadMessages.length !== props.unreadMessages.length) {
      //  console.log('are equal');

        this.setState({ unreadMessages: props.unreadMessages });
      } */

    if (props.unreadMessages && unreadMessages
      && Object.keys(unreadMessages).length !== Object.keys(props.unreadMessages).length) {
      console.log('are not equal');

      const unreadMessagesArr = [];
      Object.keys(props.unreadMessages).map((key) => {
        unreadMessagesArr.push({
          ...props.unreadMessages[key],
          key,
        });
        return null;
      });

      console.log('unreadMessagesArr');
      console.log(unreadMessagesArr);

      unreadMessagesArr.sort((a, b) => b.lastResponse - a.lastResponse);

      this.setState({ unreadMessages: unreadMessagesArr });
    }
  }

  componentWillUpdate(nextProps) {
    const { unreadMessages } = this.props;
    const { props } = this;
    /*  if (nextProps.readMessages && readMessages
        && readMessages.length !== nextProps.readMessages.length) {
        console.log('are not equal');

        this.setState({ readMessages: nextProps.readMessages });
      } */

    

    if (nextProps.unreadMessages && unreadMessages
      // && Object.keys(unreadMessages).length !== Object.keys(nextProps.unreadMessages).length) {
      && (!isEqual(nextProps.unreadMessages, unreadMessages) || !isEqual(nextProps.readMessages, props.readMessages))) {
      console.log('are not equal');

      const unreadMessagesArr = [];
      Object.keys(nextProps.unreadMessages).map((key) => {
        unreadMessagesArr.push({
          ...nextProps.unreadMessages[key],
          key,
        });
        return null;
      });

      unreadMessagesArr.sort((a, b) => b.lastResponse - a.lastResponse);

      this.setState({ unreadMessages: unreadMessagesArr });
    }
  }

  scrollToBottom = () => {
    if (this.messagesScrollbar) {
      setTimeout(() => {
        if (this.messagesScrollbar) {
          this.messagesScrollbar.scrollBottom();
        }
      }, 20);
    }
  }

  handleChange = (event) => {
    if (event.key !== 'Enter' || (event.key === 'Enter' && event.shiftKey)) {
      const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

      this.setState({
        [event.target.name]: value,
      });
    }
  }

  handleSendChange = (e) => {
    console.log(e.target.value, "check");
    this.setState({ languageType: e.target.value });
  }

  handleEnter = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      this.submitMessage();
    }
  }

  submitMessage = () => {
    //  e.preventDefault();
    const { answerSupportMessage, getUserMessages } = this.props;
    const { inputMessage, uid, fileUploadUrl, fileName } = this.state;

    let screen = 'dWeb'
    if (window.innerWidth <= 1280 && window.innerWidth > 832) {
      screen = 'tablet'
    } else if (window.innerWidth <= 832) {
      screen = 'mWeb'
    }

    if (inputMessage != "" || fileUploadUrl != '') {
      answerSupportMessage({ uid, message: inputMessage, fileUploadUrl: fileUploadUrl, fileName: fileName, screen }).then(() => {

        getUserMessages(uid).then((res) => {
          this.scrollToBottom();
        });

        // this.scrollToBottom();
        this.setState({ inputMessage: '', fileUploadUrl: '', fileName: '' });
      });
    }
  }

  chooseFile = (e) => {
    e.preventDefault();

    const { showNotification, t } = this.props;
    const target = e.target.files[0];
    console.log("file type: ", target.type)
    const typeList = '.jpg,.jpeg,.png,.pdf,.msword,.doc,.rtf,.txt,ain';
    if (!typeList.includes((target.type).substring((target.type).length - 3)) || target.type == '') { return showNotification(t('error'), t('unsupportedFile'), 'danger'); }
    if (target.size / 1024 > 1536) {
      showNotification(t('warning'), t('cantUpload'), 'warning');
      this.setState({
        fileUploadUrl: '',
        fileName: '',
      });
    } else {
      this.setState({
        ableToSend: false,
      });
      const { supportMessageFileUpload } = this.props;
      supportMessageFileUpload(target).then((data) => {
        this.setState({
          fileUploadUrl: data.url,
          fileName: target.name,
          ableToSend: true,
        });
        showNotification('Success!', 'File successfully attached!', 'success');
      });
    }
  }

  setSupportMessageAsResponded = () => {
    const { setSupportMessageAsResponded } = this.props;
    const { uid } = this.state;

    setSupportMessageAsResponded({ uid }).then(() => {
      this.setState({
        openModal: false,
        uid: '',
      });
    });
  }

  toggleMessageAll = () => {
    this.setState(prevState => ({
      messageAllModal: !prevState.messageAllModal,
      messageAllInput: '',
      messageEnInput: '',
      messageRuInput: '',
      messageLvInput: '',
    }));
  }


  toggle = () => {
    const { openModal, uid } = this.state;

    if (openModal) {
      const { cancelUserMessages } = this.props;

      cancelUserMessages(uid);
    }

    this.setState(prevState => ({
      openModal: !prevState.openModal,
      uid: '',
    }));
  }


  handleChangeMessageAll = (event) => {
    if (event.key !== 'Enter' || (event.key === 'Enter' && event.shiftKey)) {
      const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

      this.setState({
        [event.target.name]: value,
      });
    }
  }

  handleEnterMessageAll = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      //  this.submitMessage();
    }
  }

  handleChangeLvMessageAll = (event) => {
    if (event.key !== 'Enter' || (event.key === 'Enter' && event.shiftKey)) {
      const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

      this.setState({
        [event.target.name]: value,
      });
    }
  }

  handleChangeEnMessageAll = (event) => {
    if (event.key !== 'Enter' || (event.key === 'Enter' && event.shiftKey)) {
      const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

      this.setState({
        [event.target.name]: value,
      });
    }
  }

  handleChangeRuMessageAll = (event) => {
    if (event.key !== 'Enter' || (event.key === 'Enter' && event.shiftKey)) {
      const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

      this.setState({
        [event.target.name]: value,
      });
    }
  }

  messageAll = () => {
    const { messageAll, showNotification, t } = this.props;
    const { messageAllInput, filterPlayers, messageEnInput, messageLvInput, messageRuInput, languageType } = this.state;

    if (languageType === constants.sendLanguage.all) {
      if (!messageEnInput || !messageLvInput || !messageRuInput || messageEnInput === '' || messageLvInput === '' || messageRuInput === '') {
        return showNotification('Error', "Missed the data.", constants.FROUNT_SIDE_STATUS.danger);
      }
    } else {
      if (!messageAllInput || messageAllInput === '') {
        return showNotification('Error', "Missed the data.", constants.FROUNT_SIDE_STATUS.danger);
      }
    }

    messageAll(messageAllInput, filterPlayers, messageLvInput, messageEnInput, messageRuInput, languageType).then((res) => {
      console.log('messageAll', res);

      if (res && res.status === constants.SERVER_STATUS_CODE.success) {
        return showNotification('Success', "Message sent succesfully.", constants.FROUNT_SIDE_STATUS.success);
      } else {
        return showNotification('Error', "Function failed to send.", constants.FROUNT_SIDE_STATUS.danger);
      }

      this.setState({ messageAllInput: '', messageEnInput: '', messageLvInput: '', messageRuInput: '', });
    }).catch((err) => {
      return showNotification('Error', "Function failed to send.", constants.FROUNT_SIDE_STATUS.danger);

      this.setState({ messageAllInput: '', messageEnInput: '', messageLvInput: '', messageRuInput: '', });
    });
  }

  changeFilterPlayers = (e) => {
    this.setState({ filterPlayers: e.target.value });
  }

  _handleMessageFileClick = () => {
    this.hiddenMessageFileInput.current.click();
  };

  handleClick(e, index) {
    e.preventDefault();

    this.setState({
      currentPage: index,
    });
  }

  openModal(uid, name, displayUid) {
    const { getUserMessages } = this.props;

    getUserMessages(uid).then((res) => {
      this.scrollToBottom();
    });

    this.setState({
      openModal: true,
      openModalName: name,
      openModalUid: uid,
      openModalDisplayUid: displayUid || null,
      uid,
      fileUploadUrl: '',
      fileName: '',
    });
  }

  openPage = (uid) => {
    const { host } = window.location;
    window.open(`https://${host}/admin/${uid}`, '_blank', 'noopener,noreferrer');
  }

  renderDeviceIcon = (device, message) => {
    let deviceInfo = device ? device.toLowerCase() : '';
    if (!deviceInfo && (message.env === 'ios' || message.env === 'android')) {
      deviceInfo = message.env.toLowerCase();
    }

    switch (deviceInfo) {
      case 'tablet':
        return <Media src={tabletImg} className="allUsers-table-col-img" />
      case 'web':
        return <Media src={computerImg} className="allUsers-table-col-img" />
      case 'dweb':
        return <Media src={computerImg} className="allUsers-table-col-img" />
      case 'mobile':
        return <Media src={phoneImg} className="allUsers-table-col-img" />
      case 'android':
        return <Media src={androidImg} className="allUsers-table-col-img" />
      case 'ios':
        return <Media src={iosImg} className="allUsers-table-col-img" />
      default:
        return <Media src={computerImg} className="allUsers-table-col-img" />;
    }
  }

  renderPlatformIcon = (platform) => {
    const platformInfo = platform.toLowerCase();
    switch (platformInfo) {
      case 'draugiem':
        return <Media src={draugiemImg} className="allUsers-table-col-img" />
      case 'facebook':
        return <Media src={facebookImg} className="allUsers-table-col-img" />
      case 'inbox':
        return <Media src={inboxImg} className="allUsers-table-col-img" />
      case 'android':
        return 'ANDROID'
      case 'ios':
        return 'IOS'
      case 'mweb':
        return 'WEB';
      case 'web':
        return 'WEB';
      default:
        return 'WEB';
    }
  }

  table() {
    const {
      t,
      readMessages,
    } = this.props;
    const {
      currentPage,
      pageSize,
      unreadMessages,
      languageType,
    } = this.state;

    let messagesLength = 0;
    let unreadLength = 0;
    if (readMessages && unreadMessages) {
      unreadLength = unreadMessages.length;
      messagesLength = Object.keys(readMessages).length + Object.keys(unreadMessages).length;
    }

    const pagesCount = Math.ceil(messagesLength / pageSize);
    console.log(unreadMessages, "checkReadMessages")

    return (
      <Fragment>
        <Fragment>
          {currentPage * pageSize < unreadLength ? (
            <Fragment>
              {(unreadLength - (currentPage * pageSize)) >= pageSize ? (
                <Fragment>
                  {unreadMessages && unreadMessages.length && unreadMessages
                    .slice(
                      currentPage * pageSize - unreadLength,
                      (currentPage + 1) * pageSize,
                    )
                    .map((message, index) => (
                      <Fragment key={message.key}>
                        <tr key={message.key} className={`allUsers-table-row ${index % 2 === 0 ? ('odd') : ('even')}`}>
                          <td className="allUsers-table-col-env">
                            {this.renderDeviceIcon(message.device ? message.device.toLowerCase() : '', message)}
                          </td>
                          <td className='text-center'>
                            {this.renderPlatformIcon(message.env ? message.env.toLowerCase() : '')}
                          </td>
                          <td>
                            {message.design.toUpperCase()}
                          </td>
                          <td className="allUsers-table-col">
                            {message?.title ? message.email : message.key}
                          </td>
                          <td className="allUsers-table-col">
                            {message.name}
                          </td>
                          <td className="allUsers-table-col">
                            {message.lastResponse && (
                              <Moment format="DD-MM-YYYY, HH:mm" locale="lv">
                                {moment(message.lastResponse)}
                              </Moment>
                            )}
                          </td>
                          <td className="allUsers-table-col">
                            {message.responded ? (message.autoRespond ? 'Auto Answered' : 'Answered') : 'Not Answered'}
                          </td>
                          {/*  <td className="allUsers-table-col">
                            {message.active ? 'Aktīvs' : 'Neaktīvs'}
                          </td>  */}
                          <td className="allUsers-table-col">
                            <Button color="primary" onClick={() => this.openModal(message.key, message.name, message.title ? message.email : null)}>
                              {/* {t('open')} */}
                              Open
                            </Button>
                          </td>
                        </tr>
                      </Fragment>
                    ))}

                </Fragment>
              ) : (
                <Fragment>
                  {unreadMessages && unreadMessages.length && unreadMessages
                    .slice(
                      currentPage * pageSize - unreadLength,
                      (currentPage + 1) * pageSize,
                    )
                    .map((message, index) => (
                      <Fragment key={message.key}>
                        <tr key={message.key} className={`allUsers-table-row ${index % 2 === 0 ? ('odd') : ('even')}`}>
                          <td className="allUsers-table-col-env">
                            {this.renderDeviceIcon(message.device ? message.device.toLowerCase() : '', message)}
                          </td>
                          <td className='text-center'>
                            {this.renderPlatformIcon(message.env ? message.env.toLowerCase() : '')}
                          </td>
                          <td>
                            {message.design.toUpperCase()}
                          </td>
                          <td className="allUsers-table-col">
                            {message?.title ? message.email : message.key}
                          </td>
                          <td className="allUsers-table-col">
                            {message.name}
                          </td>
                          <td className="allUsers-table-col">
                            {message.lastResponse && (
                              <Moment format="DD-MM-YYYY, HH:mm" locale="lv">
                                {moment(message.lastResponse)}
                              </Moment>
                            )}
                          </td>
                          <td className="allUsers-table-col">
                            {message.responded ? (message.autoRespond ? 'Auto Answered' : 'Answered') : 'Not Answered'}
                          </td>
                          {/*  <td className="allUsers-table-col">
                            {message.active ? 'Aktīvs' : 'Neaktīvs'}
                          </td>   */}
                          <td className="allUsers-table-col">
                            <Button color="primary" onClick={() => this.openModal(message.key, message.name, message.title ? message.email : null)}>
                              {/* {t('open')} */}
                              Open
                            </Button>
                          </td>
                        </tr>
                      </Fragment>
                    ))}
                  {readMessages && Object.keys(readMessages)
                    .slice(
                      0,
                      (currentPage + 1) * pageSize - unreadLength,
                    )
                    .map((key, index) => (
                      <Fragment key={key}>
                        <tr key={key} className={`allUsers-table-row ${unreadLength % 2 ? (index % 2 === 1 ? ('odd') : ('even')) : (index % 2 === 0 ? ('odd') : ('even'))}`}>
                          <td className="allUsers-table-col-env">
                            {this.renderDeviceIcon(readMessages[key].device ? readMessages[key].device.toLowerCase() : '', readMessages[key])}
                          </td>
                          <td className='text-center'>
                            {this.renderPlatformIcon(readMessages[key].env ? readMessages[key].env.toLowerCase() : '')}
                          </td>
                          <td>
                            {readMessages[key].design.toUpperCase()}
                          </td>
                          <td className="allUsers-table-col">
                            {readMessages[key].title ? readMessages[key].email : key}
                          </td>
                          <td className="allUsers-table-col">
                            {readMessages[key].name}
                          </td>
                          <td className="allUsers-table-col">
                            {readMessages[key].lastResponse && (
                              <Moment format="DD-MM-YYYY, HH:mm" locale="lv">
                                {moment(readMessages[key].lastResponse)}
                              </Moment>
                            )}
                          </td>
                          <td className="allUsers-table-col">
                            {readMessages.responded ? (readMessages.autoRespond ? 'Auto Answered' : 'Answered') : 'Not Answered'}
                          </td>
                          {/*  <td className="allUsers-table-col">
                            {readMessages[key].active ? 'Aktīvs' : 'Neaktīvs'}
                          </td>   */}
                          <td className="allUsers-table-col">
                            <Button color="primary" onClick={() => this.openModal(key, readMessages[key].name, readMessages[key].title ? readMessages[key].email : null)}>
                              {/* {t('open')} */}
                              Open
                            </Button>
                          </td>
                        </tr>
                      </Fragment>
                    ))}
                </Fragment>
              )}
            </Fragment>
          ) : (
            <Fragment>
              {readMessages && Object.keys(readMessages)
                .slice(
                  currentPage * pageSize - unreadLength,
                  (currentPage + 1) * pageSize - unreadLength,
                )
                .map((key, index) => (
                  <Fragment key={key}>
                    <tr key={key} className={`allUsers-table-row ${index % 2 === 0 ? ('odd') : ('even')}`}>
                      <td className="allUsers-table-col-env">
                        {this.renderDeviceIcon(readMessages[key].device ? readMessages[key].device.toLowerCase() : '', readMessages[key])}
                      </td>
                      <td className='text-center'>
                        {this.renderPlatformIcon(readMessages[key].env ? readMessages[key].env.toLowerCase() : '')}
                      </td>
                      <td>
                        {readMessages[key].design.toUpperCase()}
                      </td>
                      <td className="allUsers-table-col">
                        {readMessages[key].title ? readMessages[key].email : key}
                      </td>
                      <td className="allUsers-table-col">
                        {readMessages[key].name}
                      </td>
                      <td className="allUsers-table-col">
                        {readMessages[key].lastResponse && (
                          <Moment format="DD-MM-YYYY, HH:mm" locale="lv">
                            {moment(readMessages[key].lastResponse)}
                          </Moment>
                        )}
                      </td>
                      <td className="allUsers-table-col">
                        {readMessages[key].responded ? (readMessages[key].autoRespond ? 'Auto Answered' : 'Answered') : 'Not Answered'}
                      </td>
                      {/*    <td className="allUsers-table-col">
                        {readMessages[key].active ? 'Aktīvs' : 'Neaktīvs'}
                      </td>   */}
                      <td className="allUsers-table-col">
                        <Button color="primary" onClick={() => this.openModal(key, readMessages[key].name, readMessages[key].title ? readMessages[key].email : null)}>
                          {/* {t('open')} */}
                          Open
                        </Button>
                      </td>
                    </tr>
                  </Fragment>
                ))}
            </Fragment>
          )}


          {/*  <Fragment>
              {unreadMessages && Object.keys(unreadMessages)
                .slice(
                  currentPage * pageSize,
                  (currentPage + 1) * pageSize,
                )
                .map((chat, index) => (
                  <Fragment key={chat.key}>
                    <tr key={chat.key} className={`allUsers-table-row ${index % 2 === 0 ? ('odd') : ('even')}`}>
                      <td className="allUsers-table-col">
                        {chat.key}
                      </td>
                      <td className="allUsers-table-col">
                        {chat.name}
                      </td>
                      <td className="allUsers-table-col">
                        {chat.lastResponse && (
                        <Moment format="DD-MM-YYYY, HH:mm" locale="lv">
                          {moment(chat.lastResponse)}
                        </Moment>
                        )}
                      </td>
                      <td className="allUsers-table-col">
                        {chat.responded ? 'Atbildēts' : 'Nav Atbildēts'}
                      </td>
                      <td className="allUsers-table-col">
                        {chat.active ? 'Aktīvs' : 'Neaktīvs'}
                      </td>
                      <td className="allUsers-table-col">
                        <Button color="primary" onClick={() => this.openModal(chat.key)}>
                      Atvērt
                        </Button>
                      </td>
                    </tr>
                  </Fragment>
                ))} */}
        </Fragment>

        {/*
        {allChats && allChats.length > 0 && allChats
          .slice(
            currentPage * pageSize,
            (currentPage + 1) * pageSize,
          )
          .map((chat, index) => (
            <Fragment key={chat.key}>
              <tr key={chat.key} className={`allUsers-table-row ${index % 2 === 0 ? ('odd') : ('even')}`}>
                <td className="allUsers-table-col">
                  {chat.key}
                </td>
                <td className="allUsers-table-col">
                  {chat.name}
                </td>
                <td className="allUsers-table-col">
                  {chat.lastResponse && (
                  <Moment format="DD-MM-YYYY, HH:mm" locale="lv">
                    {moment(chat.lastResponse)}
                  </Moment>
                  )}
                </td>
                <td className="allUsers-table-col">
                  {chat.responded ? 'Atbildēts' : 'Nav Atbildēts'}
                </td>
                <td className="allUsers-table-col">
                  {chat.active ? 'Aktīvs' : 'Neaktīvs'}
                </td>
                <td className="allUsers-table-col">
                  <Button color="primary" onClick={() => this.openModal(chat.key)}>
                    Atvērt
                  </Button>
                </td>
              </tr>
            </Fragment>
          ))}
          */}


        {messagesLength > pageSize && (
          <div className="pagination-wrapper">
            <Pagination aria-label="Page navigation example">
              <PaginationItem disabled={currentPage === 0}>
                <PaginationLink style={{ color: '#000' }} onClick={e => this.handleClick(e, 0)} href="#">
                  {`‹‹‹`}
                </PaginationLink>
              </PaginationItem>

              <PaginationItem disabled={currentPage <= 0}>
                <PaginationLink
                  style={{ color: '#000' }}
                  onClick={e => this.handleClick(e, currentPage - 1)}
                  previous
                  href="#"
                />
              </PaginationItem>

              {[...Array(pagesCount)].map((page, i) => {
                if (i > currentPage - 3 && i < currentPage + 3) {
                  return (
                    <PaginationItem active={i === currentPage} key={page}>
                      <PaginationLink style={{ color: '#000' }} onClick={e => this.handleClick(e, i)} href="#">
                        {i + 1}
                      </PaginationLink>
                    </PaginationItem>
                  );
                }

                return null;
              })}

              <PaginationItem disabled={currentPage >= pagesCount - 1}>
                <PaginationLink
                  style={{ color: '#000' }}
                  onClick={e => this.handleClick(e, currentPage + 1)}
                  next
                  href="#"
                />
              </PaginationItem>

              <PaginationItem disabled={currentPage >= pagesCount - 1}>
                <PaginationLink style={{ color: '#000' }} onClick={e => this.handleClick(e, pagesCount - 1)} href="#">
                  {`›››`}
                </PaginationLink>
              </PaginationItem>
            </Pagination>
          </div>
        )}
      </Fragment>
    );
  }



  table2() {
    const {
      t,
      readMessages,
    } = this.props;
    const {
      currentPage,
      pageSize,
      unreadMessages,
    } = this.state;

    let messagesLength = 0;
    let unreadLength = 0;
    if (readMessages && unreadMessages) {
      unreadLength = unreadMessages.length;
      messagesLength = Object.keys(readMessages).length + unreadMessages.length;
    }

    const pagesCount = Math.ceil(messagesLength / pageSize);
    console.log(unreadMessages, readMessages, "messCheck21111111111111");

    const readMessagesCopy = {};
    Object.keys(readMessages).map((key) => {
      readMessagesCopy[key] = { ...readMessages[key], key };
    })

    const allMessagesTemp = [...unreadMessages, ...Object.values(readMessagesCopy)];

    const allMessages = Array.from(
      allMessagesTemp
        .reduce((map, msg) => {
          if (!map.has(msg.key) || map.get(msg.key).lastResponse < msg.lastResponse) {
            map.set(msg.key, msg);
          }
          return map;
        }, new Map())
        .values()
    );

    allMessages.sort((a, b) => {
      // unresponded messages first
      if (a.responded === false && b.responded !== false) return -1;
      if (b.responded === false && a.responded !== false) return 1;

      // auto responded messages
      if (a.responded === true && a.autoRespond === true &&
        !(b.responded === true && b.autoRespond === true)) return -1;
      if (b.responded === true && b.autoRespond === true &&
        !(a.responded === true && a.autoRespond === true)) return 1;

      if (!b.lastResponse) return -1;

      //other messages
      return b.lastResponse - a.lastResponse;
    });

    // console.log('allMessages');

    return (
      <Fragment>
        <table style={{ width: '100%', fontSize: 12, color: '#fff' }}>
          <colgroup>
            <col span="1" className="" />
          </colgroup>
          <thead>
            <tr>
              <th style={{ textAlign: 'center' }}>
                Device
              </th>
              <th style={{ textAlign: 'center' }}>
                Platform
              </th>
              <th style={{ textAlign: 'center' }}>
                Design
              </th>
              <th style={{ textAlign: 'center' }}>
                Player Id
              </th>
              <th style={{ textAlign: 'center' }}>
                Name
              </th>
              <th style={{ textAlign: 'center' }}>
                Last Message
              </th>
              <th style={{ textAlign: 'center' }}>
                Answered
              </th>
              <th style={{ textAlign: 'center' }}>
                View User
              </th>
              {/*  <th style={{ textAlign: 'center' }}>
                Aktīvs
              </th>   */}
            </tr>
          </thead>
          <tbody>
            {allMessages
              .slice(currentPage * pageSize, (currentPage + 1) * pageSize)
              .map((message, index) => (
                <tr key={message.key} className={`allUsers-table-row ${index % 2 === 0 ? ('odd') : ('even')}`}>
                  <td className="allUsers-table-col-env">
                    {this.renderDeviceIcon(message.device ? message.device.toLowerCase() : '', message)}
                  </td>
                  <td className='text-center'>
                    {this.renderPlatformIcon(message.env ? message.env.toLowerCase() : '')}
                  </td>
                  <td className="allUsers-table-col">
                    {message.design ? message.design.toUpperCase() : 'OLD'}
                  </td>
                  <td className="allUsers-table-col">
                    {message?.title ? message.email : message.key}
                  </td>
                  <td className="allUsers-table-col">
                    {message.name}
                  </td>
                  <td className="allUsers-table-col">
                    {message.lastResponse && (
                      <Moment format="DD-MM-YYYY, HH:mm" locale="lv">
                        {moment(message.lastResponse)}
                      </Moment>
                    )}
                  </td>
                  <td className="allUsers-table-col">
                    {message.responded ? (message.autoRespond ? 'Auto Answered' : 'Answered') : 'Not Answered'}
                  </td>
                  <td className="allUsers-table-col">
                    <Button color="primary" onClick={() => this.openPage(message.key)}>
                      View
                    </Button>
                  </td>
                  <td className="allUsers-table-col">
                    <Button color="primary" onClick={() => this.openModal(message.key, message.name, message.title ? message.email : null)}>
                      Open
                    </Button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>

        {allMessages.length > pageSize && (
          <div className="pagination-wrapper">
            <Pagination aria-label="Page navigation example">
              <PaginationItem disabled={currentPage === 0}>
                <PaginationLink style={{ color: '#000' }} onClick={e => this.handleClick(e, 0)} href="#">
                  {`‹‹‹`}
                </PaginationLink>
              </PaginationItem>

              <PaginationItem disabled={currentPage <= 0}>
                <PaginationLink
                  style={{ color: '#000' }}
                  onClick={e => this.handleClick(e, currentPage - 1)}
                  previous
                  href="#"
                />
              </PaginationItem>

              {[...Array(pagesCount)].map((page, i) => {
                if (i > currentPage - 3 && i < currentPage + 3) {
                  return (
                    <PaginationItem active={i === currentPage} key={`${page}-${i}`}>
                      <PaginationLink style={{ color: '#000' }} onClick={e => this.handleClick(e, i)} href="#">
                        {i + 1}
                      </PaginationLink>
                    </PaginationItem>
                  );
                }

                return null;
              })}

              <PaginationItem disabled={currentPage >= pagesCount - 1}>
                <PaginationLink
                  style={{ color: '#000' }}
                  onClick={e => this.handleClick(e, currentPage + 1)}
                  next
                  href="#"
                />
              </PaginationItem>

              <PaginationItem disabled={currentPage >= pagesCount - 1}>
                <PaginationLink style={{ color: '#000' }} onClick={e => this.handleClick(e, pagesCount - 1)} href="#">
                  {`›››`}
                </PaginationLink>
              </PaginationItem>
            </Pagination>
          </div>
        )}
      </Fragment>
    );
  }

  render() {

    const {
      t,
      chatMessages,
      chatMessagesUserLastLanguage,
      fontMode,
    } = this.props;

    const {
      openModal,
      openModalName,
      openModalUid,
      openModalDisplayUid,
      inputMessage,
      messageAllModal,
      messageAllInput,
      ableToSend,
      filterPlayers,
      fileName,
      languageType,
      messageEnInput,
      messageRuInput,
      messageLvInput,
    } = this.state;
    return (
      <Fragment>
        <UncontrolledPopover popperClassName={`popover`} placement="right" trigger="focus" target="admin-help-button-user-messages">
          <PopoverBody className="popover-body">
            A list of all messages between the administration and players via Technical Support chat. The message can be initiated by the player or administration. Every message contains also some details of the player's current environment (from which the message has been sent). The messages are being kept only for 14 days.
          </PopoverBody>
        </UncontrolledPopover>
        <div className="admin-page-content">
          <Row>
            <Col md="6">
              <h2>
                {/* {t('supportChats')} */}
                Conversations
                <Button className="admin-help-button" id="admin-help-button-user-messages">
                  ?
                </Button>
              </h2>
            </Col>
            <Col sm="1">
              <div>
                <Input type="select" name="show" className="game-bet-select" value={filterPlayers} onChange={this.changeFilterPlayers}>
                  <option style={{ backgroundColor: '#222' }} value="all">All</option>
                  <option style={{ backgroundColor: '#222' }} value="web">WEB</option>
                  <option style={{ backgroundColor: '#222' }} value="mobile">APP</option>
                </Input></div>
            </Col>
            <Col md="3">
              <Button type="button" color="primary" onClick={this.toggleMessageAll}>Write Message To All Players</Button>
            </Col>
          </Row>
          {this.table2()}
        </div>
        <Modal container={`div > div`} isOpen={openModal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>
            {`Chat - ${openModalName} - ${openModalDisplayUid || openModalUid}`}

          </ModalHeader>
          <ModalBody>
            <Row className="chat-body">
              <Col className="chat-body-wrapper" md="12">
                <ScrollArea
                  speed={0.65}
                  className="admin-chat-scroll-area"
                  contentClassName="admin-chat-scroll-content"
                  //  smoothScrolling
                  verticalContainerStyle={{
                    background: 'transparent',
                    opacity: 1,
                    width: 10,
                  }}
                  verticalScrollbarStyle={{
                    background: '#fff',
                    borderRadius: 2,
                    width: 6,
                    minHeight: 30,
                    minScrollSize: 35,
                  }}
                  horizontal={false}
                  ref={(el) => { this.messagesScrollbar = el; }}
                >
                  {chatMessages && Object.keys(chatMessages).map(key => (
                    <Row key={key}>
                      <Col md="12">
                        <Message message={chatMessages[key]} chatMessagesUserLastLanguage={chatMessagesUserLastLanguage} />
                      </Col>
                    </Row>
                  ))}
                  {/*  <div
                    style={{ float: 'left', clear: 'both' }}
                    ref={(el) => { this.messagesEnd = el; }}
                  /> */}
                </ScrollArea>
              </Col>
            </Row>
            <Row className="chat-footer" style={{ height: 60 }}>
              <Form style={{ width: '100%', height: '100%' }}>
                <Col md="12" style={{ height: '100%' }}>
                  <Input
                    className={`h-100 chat-footer-input chat-footer-input-admin admin-input-message-${fontMode}`}
                    type="textarea"
                    name="inputMessage"
                    id="inputMessage"
                    autoComplete="off"
                    placeholder="Write Message..."
                    style={{

                    }}
                    value={inputMessage}
                    onChange={this.handleChange}
                    onKeyPress={this.handleEnter}
                  />

                </Col>
              </Form>
            </Row>
            <Row>
              <Button
                className="btn-warning admin-file-upload-button"
                onClick={this._handleMessageFileClick}
              >
                {fileName === '' ? '+ Choose File' : fileName}
              </Button>
              <input
                id="files"
                hidden
                ref={this.hiddenMessageFileInput}
                className="chat-footer-input chat-footer-input-admin admin-file-upload-input"
                accept=".jpg,.png,.pdf,.doc,.rtf,.txt"
                type="file"
                onChange={this.chooseFile}
              />
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button type="button" color="secondary" onClick={this.setSupportMessageAsResponded}>Mark As Read</Button>
            <Button className="contact-support-footer-button" disabled={!ableToSend} color="primary" onClick={this.submitMessage}>
              {/* {t('send')} */}
              Send
            </Button>
            <Button type="button" color="secondary" onClick={this.toggle}>Close</Button>
          </ModalFooter>
        </Modal>


        <Modal container={`div > div`} isOpen={messageAllModal} toggle={this.toggleMessageAll}>
          <ModalHeader toggle={this.toggleMessageAll}>
            {/* {t('messageAll')} */}
            Write To All
          </ModalHeader>
          <ModalBody>
            <Row className="chat-footer" style={{ height: '80%' }}>
              <Form style={{ width: '100%' }}>
                <Col md="12">
                  <Row>
                    <Col sm="12">
                      <Input type="select" name="languageType" className="game-bet-select lang-all-message" value={languageType} onChange={this.handleSendChange}>
                        {
                          map(constants.sendLanguageType, (item, key) => (
                            <option className="background-222" value={key.toString()}>{item}</option>
                          ))
                        }
                      </Input>
                    </Col>
                  </Row>
                  {
                    languageType === 'all' ? (
                      <Row>
                        <Label for="messageLvInput">{constants.sendLanguageType.lv}</Label>
                        <Input
                          className="chat-footer-input chat-footer-input-admin chat-footer-input-admin-message-all"
                          type="textarea"
                          name="messageLvInput"
                          id="messageLvInput"
                          autocomplete="off"
                          placeholder={"Write message..."}
                          value={messageLvInput}
                          onChange={this.handleChangeLvMessageAll}
                          onKeyPress={this.handleEnterMessageAll}
                        />
                        <Label for="messageENInput">{constants.sendLanguageType.en}</Label>
                        <Input
                          className="chat-footer-input chat-footer-input-admin chat-footer-input-admin-message-all"
                          type="textarea"
                          name="messageEnInput"
                          id="messageEnInput"
                          autocomplete="off"
                          placeholder={"Write message..."}
                          value={messageEnInput}
                          onChange={this.handleChangeEnMessageAll}
                          onKeyPress={this.handleEnterMessageAll}
                        />
                        <Label for="messageRuInput">{constants.sendLanguageType.ru}</Label>
                        <Input
                          className="chat-footer-input chat-footer-input-admin chat-footer-input-admin-message-all"
                          type="textarea"
                          name="messageRuInput"
                          id="messageRuInput"
                          autocomplete="off"
                          placeholder={"Write message..."}
                          value={messageRuInput}
                          onChange={this.handleChangeRuMessageAll}
                          onKeyPress={this.handleEnterMessageAll}
                        />
                      </Row>
                    ) : (
                      <Row>
                        <Label for="messageAllInput">{constants.sendLanguageType[languageType]}</Label>
                        <Input
                          className="chat-footer-input chat-footer-input-admin chat-footer-input-admin-message-all"
                          type="textarea"
                          name="messageAllInput"
                          id="messageAllInput"
                          autocomplete="off"
                          placeholder={"Write message..."}
                          value={messageAllInput}
                          onChange={this.handleChangeMessageAll}
                          onKeyPress={this.handleEnterMessageAll}
                        />
                      </Row>
                    )
                  }
                </Col>
              </Form>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.messageAll}>
              {/* {t('sendFilteredPlayers', { languageType: get(constants.sendLanguageType, `${languageType}`) })} */}
              {`Send ${get(constants.sendLanguageType, languageType)} Players`}
            </Button>
            <Button type="button" color="secondary" onClick={this.toggleMessageAll}>Close</Button>
          </ModalFooter>
        </Modal>
      </Fragment>
    );
  }
}

export default withTranslation('admin')(UserMessages);
