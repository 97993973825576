import React, { useEffect, useState } from 'react';

// import { Splide, SplideSlide } from '@splidejs/react-splide';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { filter, size } from 'lodash';

import {
  Button, Input, Media, Modal, ModalBody, ModalFooter, ModalHeader,
} from 'reactstrap';

import moment from 'moment';
import {
  toggleShowHint,
  getAllAvailableHintsOld,
} from '../../../actions/member';
import * as constants from '../../../constants/constants';
import closeImg from '../../../images/icons/close.png';

const Hints = ({ fontMode }) => {
  const { i18n, t } = useTranslation('common');
  const dispatch = useDispatch();

  const [openHintModal, setOpenHintModal] = useState(true);
  const [userShowHint, setUserShowHint] = useState(false);
  const [randomCount, setRandomCount] = useState(0);
  const [devMode, setDevMode] = useState(false);

  const showHint = useSelector(state => (state.member && state.member.showHint) || false);
  const checkedHintDate = useSelector(state => (state.member && state.member.checkedHintDate) || '');
  const { hostname } = window.location;

  const tDay = (new Date()).getDay() + 2;
  const allOldHints = useSelector(state => state.member.allOldHints || []);
  const filteredHints = size(allOldHints) > 0 ? filter(allOldHints, item => (item.showOptions === constants.SHOW_HINT_EVERY_DAY || item.showOptions === tDay.toString()) && item.devMode === devMode) : [];

  const checkedHintDay = checkedHintDate ? moment(checkedHintDate).format('YYYY-MM-DD') : '';
  const nowDay = moment(new Date()).format('YYYY-MM-DD');

  useEffect(() => {
    dispatch(getAllAvailableHintsOld());

    if (!devMode && (hostname.includes('dev.') || hostname.includes('localhost'))) {
      setDevMode(true);
    }
  }, []);

  useEffect(() => {
    if (size(filteredHints) > 0) {
      const randomCnt = Math.floor(Math.random() * size(filteredHints));
      setRandomCount(randomCnt);
    }
  }, [allOldHints]);


  const toggleUserShowHint = () => {
    setUserShowHint(!userShowHint);
  };

  const changeShowHint = () => {
    dispatch(toggleShowHint(userShowHint, filteredHints[randomCount].key));
    setOpenHintModal(!openHintModal);
  };

  return (
    <Modal
      container={'div > div'}
      isOpen={!showHint && size(filteredHints) > 0 && (!checkedHintDay || (checkedHintDay !== nowDay)) && openHintModal && filteredHints[randomCount]}
      toggle={() => changeShowHint}
      size="lg"
      className={`old-modal root-font-${fontMode}`}
    >
      <ModalHeader
        toggle={changeShowHint}
        close={
          <Media src={closeImg} className="notification-header-close" alt="X" onClick={changeShowHint} />
        }
      >
        {t('common.dailyTip')}
      </ModalHeader>
      <ModalBody>
        <div className="my-hints-old">
          {
            size(filteredHints) > 0 && filteredHints[randomCount] && (
              <div className="my-hints-old-item">
                <div className="my-hints-old-item-title mb-2">
                  {
                    i18n.language === constants.sendLanguage.en
                      ? filteredHints[randomCount].titleEN
                      : i18n.language === constants.sendLanguage.lv
                        ? filteredHints[randomCount].titleLV
                        : i18n.language === constants.sendLanguage.ru
                          ? filteredHints[randomCount].titleRU
                          : filteredHints[randomCount].titleLV
                  }
                </div>
                <div className="my-hints-old-item-img">
                  <Media
                    className="slide-img"
                    src={
                      i18n.language === constants.sendLanguage.en
                        ? filteredHints[randomCount].enImageUrl
                        : i18n.language === constants.sendLanguage.lv
                          ? filteredHints[randomCount].lvImageUrl
                          : i18n.language === constants.sendLanguage.ru
                            ? filteredHints[randomCount].ruImageUrl
                            : filteredHints[randomCount].enImageUrl
                    }
                    alt=""
                  />
                </div>
                <div className="my-hints-old-item-content mt-4 mb-2">
                  {
                    i18n.language === constants.sendLanguage.en
                      ? filteredHints[randomCount].descriptionEN
                      : i18n.language === constants.sendLanguage.lv
                        ? filteredHints[randomCount].descriptionLV
                        : i18n.language === constants.sendLanguage.ru
                          ? filteredHints[randomCount].descriptionRU
                          : filteredHints[randomCount].descriptionLV
                  }
                </div>
              </div>
            )
          }
          <div className="d-flex flex-center align-items-center mt-2 my-hints-old-item-check-show">
            <div className="d-flex align-items-center">
              <Input type="checkbox" onClick={toggleUserShowHint} checked={userShowHint} readOnly />
              <span className="checkmark" />
            </div>
            <div className="event-type-text mt-1">
              {t('common.dailyTipShowOption')}
              {/* Vairs nerādīt dienas padomus (aktivizējams profila iestatījumos) */}
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          type="button"
          className="contact-support-modal-footer-button"
          color="link"
          onClick={changeShowHint}
        >
          {t('common.ok')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default Hints;
