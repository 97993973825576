import React, { useEffect, useState } from 'react';
import { getUserName } from '../../../../actions/admin';

const TransactionPlayerName = ({ userId }) => {
  const [playerName, setPlayerName] = useState('Loading...');

  useEffect(() => {
    const fetchPlayer = async () => {
      try {
        const user = await getUserName(userId);
        setPlayerName(user?.name || '');
      } catch (error) {
        setPlayerName('Error');
      }
    };

    if (userId) {
      fetchPlayer();
    }
  }, [userId]);

  return <>{playerName}</>;
};

export default TransactionPlayerName;
