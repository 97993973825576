import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { filter } from 'lodash';
import {
  Col, NavLink,
} from 'reactstrap';
import { MENU_NAVIGATION, MENU_NAVIGATION_TYPE } from '../../../../../../constants/constants';

const MyInfoSubHeader = ({ changeTab, activeTab, joyrideRun }) => {
  const { t } = useTranslation('common');

  useEffect(() => {
    const element = document.getElementById(filter(MENU_NAVIGATION_TYPE, item => (item.id === activeTab.toString()))[0].label);
    console.log("NotActiveMenu: ", activeTab, element)
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }, [activeTab]);

  return (
    <Col xs="12" className={`layout-mobile-subheader-navmenu ${joyrideRun && 'layout-mobile-subheader-navmenu-joyride'}`}>
      <div className="layout-mobile-subheader-navmenu-link" xs={3} id="myInfo">
        <NavLink
          color="link"
          className={classNames('layout-mobile-subheader-navmenu-link-text', {
            'layout-mobile-subheader-navmenu-link-text-active': activeTab === MENU_NAVIGATION.myInfo,
          })}
          onClick={() => changeTab(MENU_NAVIGATION.myInfo)}
        >
          {t('common:myInfo.myInfo')}
        </NavLink>
      </div>

      <div className="layout-mobile-subheader-navmenu-link my-info-friends-button my-info-friends-button-2" xs={3} id="friends">
        <NavLink
          color="link"
          className={classNames('layout-mobile-subheader-navmenu-link-text', {
            'layout-mobile-subheader-navmenu-link-text-active': activeTab === MENU_NAVIGATION.friends,
          })}
          onClick={() => changeTab(MENU_NAVIGATION.friends)}
        >
          {t('common:myInfo.friends')}
        </NavLink>
      </div>
      <div className="layout-subheader-navmenu-link my-info-ignoredPlayers-button my-info-ignoredPlayers-button-2" xs={3} id="ignoredUsers">
        <NavLink
          color="link"
          className={classNames('layout-mobile-subheader-navmenu-link-text', {
            'layout-mobile-subheader-navmenu-link-text-active': activeTab === MENU_NAVIGATION.ignoredUsers,
          })}
          onClick={() => changeTab(MENU_NAVIGATION.ignoredUsers)}
        >
          {t('common:myInfo.ignoredPlayers')}
        </NavLink>
      </div>
      <div className="layout-mobile-subheader-navmenu-link my-info-achievements-button" xs={3} id="archievements">
        <NavLink
          id="my-info-achievements-id"
          color="link"
          className={classNames('layout-mobile-subheader-navmenu-link-text', {
            'layout-mobile-subheader-navmenu-link-text-active': activeTab === MENU_NAVIGATION.archievements,
          })}
          onClick={() => changeTab(MENU_NAVIGATION.archievements)}
        >
          {t('common:myInfo.achievements')}
        </NavLink>
      </div>
      <div className="layout-mobile-subheader-navmenu-link my-info-results-button" xs={3} id="gameHistory">
        <NavLink
          id="my-info-results-id"
          color="link"
          className={classNames('layout-mobile-subheader-navmenu-link-text', {
            'layout-mobile-subheader-navmenu-link-text-active': activeTab === MENU_NAVIGATION.gameHistory,
          })}
          onClick={() => changeTab(MENU_NAVIGATION.gameHistory)}
        >
          {t('common:myInfo.results')}
        </NavLink>
      </div>
      <div className="layout-mobile-subheader-navmenu-link my-info-weekly-status-button" xs={3} id="weeklyStatus">
        <NavLink
          id="my-info-weekly-status-id"
          color="link"
          className={classNames('layout-mobile-subheader-navmenu-link-text', {
            'layout-mobile-subheader-navmenu-link-text-active': activeTab === MENU_NAVIGATION.weeklyStatus,
          })}
          onClick={() => changeTab(MENU_NAVIGATION.weeklyStatus)}
        >
          {t('common:myInfo.weeklyStatus')}
        </NavLink>
      </div>
      <div className="layout-mobile-subheader-navmenu-link my-info-game-logs-sub-menu-button" xs={3} id="gameLogs">
        <NavLink
          id="my-info-game-logs-id"
          color="link"
          className={classNames('layout-mobile-subheader-navmenu-link-text', {
            'layout-mobile-subheader-navmenu-link-text-active': activeTab === MENU_NAVIGATION.gameLogs,
          })}
          onClick={() => changeTab(MENU_NAVIGATION.gameLogs)}
        >
          {t('common:myInfo.gameLogs')}
        </NavLink>
      </div>
      <div className="layout-mobile-subheader-navmenu-link my-info-gifts-sub-menu-button" xs={3} id="gifts">
        <NavLink
          id="my-info-gifts-id"
          color="link"
          className={classNames('layout-mobile-subheader-navmenu-link-text', {
            'layout-mobile-subheader-navmenu-link-text-active': activeTab === MENU_NAVIGATION.myInfoGifts,
          })}
          onClick={() => changeTab(MENU_NAVIGATION.myInfoGifts)}
        >
          {t('common:myInfo.gifts')}
        </NavLink>
      </div>
    </Col>
  );
};

export default MyInfoSubHeader;
