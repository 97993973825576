// MyComponent.js
import React, { Fragment, useState, useEffect } from 'react';
import { Button, Col, Input, PopoverBody, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { addProfanity, deleteProfanity, getProfanitiesList } from '../../../actions/admin';
import { UncontrolledPopover } from 'reactstrap/lib';

const Profanities = ({ profanitiesList, fetchProfanitiesList, addProf, deleteProf }) => {
  const [word, setWord] = useState('');
  const [err, setErr] = useState('');
  const [exact, setExact] = useState(false);

  useEffect(() => {
    fetchProfanitiesList();
  }, []);

  const addProfanity = () => {
    if (!word) {
      setErr('Enter word');
    } else if (profanitiesList[word]) {
      setErr('Word already added');
    } else {
      console.log('addProfanity', word, exact);
      addProf(word, exact).then(() => {
        setWord('');
        setExact(false);
        setErr('');
        fetchProfanitiesList();
      });
    }
  }

  const deleteProfanity = (word) => {
    if (word) {
      deleteProf(word).then(() => {
        fetchProfanitiesList();
      });
    }
  }

  const toggleExact = () => {
    if (exact) {
      setExact(false);
    } else {
      setExact(true);
    }
  }

  return (
    <Fragment>
      <UncontrolledPopover popperClassName={`popover`} placement="right" trigger="focus" target="admin-help-button-profanities">
        <PopoverBody className="popover-body">
          The admin can add or delete the keywords that are being counted as profanities in the game and support chats. The posted profanity is displayed as a string of stars, but the full text can be seen on hover. The author of the profanity is being penalized by deducting coins or by muting him/her in the chat for a certain time.
        </PopoverBody>
      </UncontrolledPopover>
      <div className="admin-page-content">
        <h2>
          Profanities List
          <Button className="admin-help-button" id="admin-help-button-profanities">
            ?
          </Button>
        </h2>
        <div style={{ width: '650px' }}>
          <Row>
            <Col sm="5">
              Name <span style={{ color: '#FF574F' }}>{err && `ERR: ${err}`}</span>
            </Col>
            <Col sm="2">
              Count
            </Col>
            <Col sm="2">
              Exact word
            </Col>
            <Col sm="3">

            </Col>
          </Row>
          <Row style={{ marginBottom: 3 }}>
            <Col sm="5">
              <Input
                type="text"
                name="word"
                id="word"
                style={{ padding: '2px 12px' }}
                value={word}
                onChange={(event) => setWord(event.target.value)}
              />
            </Col>
            <Col sm="2">

            </Col>
            <Col sm="2">
              <Input
                type="checkbox"
                name="exact"
                id="exact"
                style={{ padding: '2px 12px' }}
                value={exact}
                onChange={() => toggleExact()}
              />
              {/* <Button style={{ padding: '2px 12px' }} color={exact ? "primary" : 'danger'} onChange={() => toggleExact()}>
                Exact
              </Button> */}

            </Col>
            <Col sm="3">
              <Button style={{ padding: '2px 12px' }} color="primary" onClick={() => addProfanity()}>
                Add
              </Button>
            </Col>
          </Row>
          {profanitiesList ? (Object.keys(profanitiesList).map((key, index) => (
            <Row style={{ marginBottom: 3 }}>
              <Col sm="5">
                <div style={{ paddingBottom: 2, paddingTop: 4 }}>
                  {profanitiesList[key].word}
                </div>
              </Col>
              <Col sm="2">
                <div style={{ paddingBottom: 2, paddingTop: 4 }}>
                  {profanitiesList[key].count}
                </div>
              </Col>
              <Col sm="2">
                <div style={{ paddingBottom: 2, paddingTop: 4 }}>
                  {profanitiesList[key].exact ? 'Yes' : 'No'}
                </div>
              </Col>
              <Col sm="3">
                <Button style={{ padding: '2px 12px' }} color="primary" onClick={() => deleteProfanity(profanitiesList[key].word)}>
                  Delete
                </Button>
              </Col>
            </Row>
          ))) : (null)}
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    profanitiesList: state.admin.profanitiesList || {},
  };
};

const mapDispatchToProps = {
  fetchProfanitiesList: getProfanitiesList,
  addProf: addProfanity,
  deleteProf: deleteProfanity,
};

export default connect(mapStateToProps, mapDispatchToProps)(Profanities);
