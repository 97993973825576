import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Container from 'reactstrap/lib/Container';

import { Helmet } from 'react-helmet';

import { Link, withRouter } from 'react-router-dom';

import { filter } from 'lodash';
import { Media } from 'reactstrap';

// import ZoleContainer from '../../../containers/Game/Zole';
// import ZoleComponent from '../Game/Zole';

import Zole2Container from '../../../../../containers/Game2.0/StudentZole';
import Zole2Component from '../../Game/StudentZole';

import config from '../../../../../constants/config';

import {
  FONT_MODE,
  SCREEN_MODE,
} from '../../../../../constants/constants';

import { getCampaignLobbyBanner, getCampaignOpenRoomBanner } from '../../../../../actions/shop';
import { clickCampaign } from '../../../../../actions/member';

import mobileCloseImg from '../../../../../images/redesign/components/modal/mobileClose.png';

const isInAppFrame = config.isInAppFrame();

const Template = React.memo((props) => {
  const {
    i18n,
    fourPRoom,
    newDesign,
    fontMode,
    toggleFont,
    pageTitle,
    isLoading,
    isFullscreen,
    notificationSettings,
    showNotification,
    toggleFullscreen,
    isWebVersion,
    toggleNewDesign,
    toggleScreenMode,
    screenMode,
    lastRound,
    fetchCampaign,
    campaignData,
    clickACampaign,
    adsHidden,
    loggedInUserDataFetched,
    match,
    designPlatform,
  } = props;

  const bannerRef = useRef(null);
  const bannerRef2 = useRef(null);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [bannerHeight, setBannerHeight] = useState(0);
  const [externalBannerSet, setExternalBannerSet] = useState(false);
  const [bannerFetched, setBannerFetched] = useState(false);


  const [campaignClosed, setCampaignClosed] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const roomId = match && match.params && match.params.id ? match.params.id : null;

  console.log('Template game', roomId, props);
  let devMode = false;

  try {
    const { hostname } = window.location;
    if (hostname && (hostname.includes('dev') || hostname.includes('localhost'))) {
      devMode = true;
    }
  } catch (err) { }

  const handleResize = () => {
    if (bannerRef && bannerRef.current) {
      const { offsetHeight } = bannerRef.current;
      setBannerHeight(offsetHeight || 0);
    }

    setWindowHeight(window.innerHeight);
    setWindowWidth(window.innerWidth);
  };

  const handleClickCloseCampaign = () => {
    setCampaignClosed(true);
  }

  const handleClickCampaign = (targetLink, campaignKey, design) => {
    console.log('handleClickCampaign', targetLink, campaignKey, design);


    window.open(targetLink, '_blank')
    clickACampaign(campaignKey, 'openRoom', design, devMode);
  }

  const handleClickCampaignExternal = () => {
    console.log('handleClickCampaignExternal', newDesign, devMode, campaignData);

    if (campaignData && campaignData[0] && campaignData[0]?.key) {
      clickACampaign(campaignData[0]?.key, 'openRoom', newDesign ? 'new' : 'old', devMode);
    }
  }

  let campaignShow = false;

  if (loggedInUserDataFetched && (!adsHidden || adsHidden < Date.now())) {
    if (campaignData) {
      console.log('campaignShow devMode', devMode);
      if (devMode && filter(campaignData.selectedEnvs, item => item.value === 'dev')[0]) {
        console.log('campaignShow test 2');
        campaignShow = true;
      } else if (!devMode && filter(campaignData.selectedEnvs, item => item.value === 'prod')[0]) {
        console.log('campaignShow test 3');
        campaignShow = true;
      }
      console.log('campaignShow filter', filter(campaignData[0]?.selectedDesigns, item => newDesign ? item?.value === 'new' : item?.value === 'old')[0]);

      campaignShow = filter(campaignData[0]?.selectedDesigns, item => newDesign ? item?.value === 'new' : item?.value === 'old')[0] ? true : false;
    }
  }

  useEffect(() => {
    const design = newDesign ? 'new' : 'old';

    window.addEventListener('resize', handleResize);

    console.log('fetchCampaign(openRoom, design, devMode) 2', 'openRoom', design, devMode);

    fetchCampaign('openRoom', design, devMode).then((fetchCampaignRes) => {
      setBannerFetched(true);
    });

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  console.log('externalBannerSet', bannerFetched, externalBannerSet);

  if (!externalBannerSet && bannerFetched) {
    console.log('externalBannerSet2', campaignData, adsHidden, loggedInUserDataFetched);

    if (loggedInUserDataFetched && (!adsHidden || adsHidden < Date.now())) {
      if (campaignData && campaignData[0] && campaignData[0]?.externalBanner) {
        const scriptTag = document.createElement("script");
        console.log('campaignData[0]?.adFormMeasure', campaignData[0]?.adFormMeasure);

        if (campaignData[0]?.adFormMeasure) {
          const targetLink2 = `${campaignData[0]?.externalBanner}${campaignData[0]?.adFormMeasure}`;

          scriptTag.src = targetLink2;
          scriptTag.async = true;
        } else {
          scriptTag.src = campaignData[0]?.externalBanner;
          scriptTag.async = true;
        }

        console.log('campaignData[0]?.externalBanner', campaignData[0]?.externalBanner, externalBannerSet);

        if (!externalBannerSet) {
          scriptTag.addEventListener('load', () => {
            const promise1 = new Promise((r) => setTimeout(r, 50));

            try {
              Promise.all([promise1]).then(() => {
                const inputs = document.getElementsByTagName('iframe');
                let bannerIframe = null;

                if (inputs) {
                  for (var i = 0; i < inputs.length; i++) {
                    console.log('input', inputs[i].clientHeight, inputs[i].clientWidth, inputs[i].offsetHeight);

                    if (inputs[i].clientHeight === 70 && inputs[i].clientWidth === 1000) {
                      bannerIframe = inputs[i];
                    }
                  }
                }
                console.log('scriptTag load bannerIframe', bannerIframe, externalBannerSet);

                if (!externalBannerSet && bannerIframe && bannerIframe.contentDocument) {
                  console.log('add mousedown', bannerIframe.contentDocument);
                  setExternalBannerSet(true);
                  bannerIframe.contentDocument.body.addEventListener('mousedown', handleClickCampaignExternal);
                } else if (!externalBannerSet && bannerIframe && bannerIframe.document) {
                  console.log('add click', bannerIframe.document);
                  setExternalBannerSet(true);
                  bannerIframe.document.addEventListener("click", handleClickCampaignExternal);
                }
              });
            } catch (err) {
              console.log(err);
            }

            setExternalBannerSet(true);
          });
        }

        const bannerRef3 = document.getElementById('bannerRef');

        if (campaignShow && bannerRef3 && campaignData[0]?.externalBanner) {
          bannerRef3.appendChild(scriptTag);
          setExternalBannerSet(true);
        }
      } else {
        setExternalBannerSet(true);
      }
    } else if (loggedInUserDataFetched && (adsHidden && adsHidden > Date.now())) {
      setExternalBannerSet(true);
    }
  }

  console.log(bannerHeight, "checkBannerHeiht");

  // if (newDesign) {
    return (
      <div className={`root-class-design20 root-class-design20-${screenMode} root-font-${fontMode} ${config.isInFacebookAppFrame() ? 'app-wrapper-facebook-new' : ''} ${isInAppFrame ? 'root-is-app-frame' : 'root-is-not-app-frame'}`} style={(isWebVersion || isFullscreen) ? { height: '100vh', width: '100vw ' } : {}}>
        <div className={`${(isWebVersion || isFullscreen) ? 'overflow-manage-redesign' : ''}`}>
          <Container className={`${isFullscreen ? 'app-wrapper-fullscreen root-class-design20-fullscreen' : 'app-wrapper-not-fullscreen'} app-wrapper ${!campaignClosed && campaignShow && 'campaign-game-section'}`} >
            <div className={`game-bg ${isLoading ? 'loading-bg' : ''}`} />
            <div className={`game-bg-table game-bg-table-two-player ${fourPRoom ? 'game-bg-table-four-player' : ''} ${windowWidth <= 1280 ? 'game-bg-table-mobile' : ''}`} />
            <Helmet>
              <title>{pageTitle}</title>
            </Helmet>
            <Zole2Container
              {...props}
              key={`room${roomId}`}
              Layout={Zole2Component}
              showNotification={showNotification}
              toggleFullscreen={toggleFullscreen}
              isFullscreen={isFullscreen}
              isFullscreenEnabled={isFullscreen}
              isWebVersion={isWebVersion}
              toggleNewDesign={toggleNewDesign}
              toggleScreenMode={toggleScreenMode}
              screenMode={screenMode}
              fontMode={fontMode}
              toggleFont={toggleFont}
              isInAppFrame={isInAppFrame}
              lastRound={lastRound}
            />

            <div
              id="bannerRef"
              ref={bannerRef2}
              className="externalBannerRef"
              style={{
                height: (externalBannerSet && !campaignClosed && campaignShow && campaignData && campaignData[0] && campaignData[0]?.externalBanner) ? '70px' : '0px',
                display: (externalBannerSet && !campaignClosed && campaignShow && campaignData && campaignData[0] && campaignData[0]?.externalBanner) ? 'block' : 'none',
                background: campaignData[0]?.overflowBackgroundColor ? campaignData[0]?.overflowBackgroundColor : 'unset',
              }}
            />
            {(externalBannerSet && !campaignClosed && campaignShow && campaignData && campaignData[0] && campaignData[0]?.externalBanner) ? (
              <Media className="game-banner-close" src={mobileCloseImg} alt="X" onClick={() => handleClickCloseCampaign()} />
            ) : (null)}

            {(!campaignClosed && campaignShow && campaignData && campaignData[0] && !campaignData[0]?.externalBanner) && (
              <a onClick={() => handleClickCampaign(campaignData[0]?.pageToOpen, campaignData[0]?.key, 'new')} >
                <div
                  style={{
                    width: '100%',
                    height: '70px',
                    position: 'fixed',
                    right: '0px',
                    bottom: '0px',
                    background: `url(${i18n.language === 'lv' ? campaignData[0].imageUrl : i18n.language === 'en' ? campaignData[0].imageUrlEn : i18n.language === 'ru' ? campaignData[0].imageUrlRu : campaignData[0].imageUrl})`,
                    backgroundSize: 'contain'
                  }}
                />
                <Media className="game-banner-close" src={mobileCloseImg} alt="X" onClick={() => handleClickCloseCampaign()} />
              </a>
            )}
          </Container>
        </div>
      </div>
    );
  // }

    /*
  const { hostname } = window.location;
    return (
      <div className="test" style={(isWebVersion || isFullscreen || config.isInFacebookAppFrame()) ? { height: '100vh', width: '100vw ' } : {}}>
        <div className={`${(isWebVersion || isFullscreen || config.isInFacebookAppFrame()) ? 'overflow-manage' : ''}`}>
          <Container
            className={`${(isWebVersion || isFullscreen || config.isInFacebookAppFrame()) ? 'app-wrapper-fullscreen' : 'app-wrapper-not-fullscreen'} app-wrapper ${config.isInInboxAppFrame() ? 'app-wrapper-inbox' : ''} ${config.isInFacebookAppFrame() ? 'app-wrapper-facebook' : ''} root-font-${fontMode} ${isInAppFrame ? 'root-is-app-frame' : 'root-is-not-app-frame'}`}
          >
            <div className={`game-bg ${isLoading ? 'loading-bg' : ''}`} />
            {(isWebVersion || isFullscreen || config.isInFacebookAppFrame()) ? (
              <div className="room-bg" />
            ) : (null)}
            <Helmet>
              <title>{pageTitle}</title>
            </Helmet>

            <Row className={(!campaignClosed && campaignShow && campaignData && campaignData[0] && (externalBannerSet || bannerHeight > 0)) ? 'old-zole-game-section-campaign' : 'old-zole-game-section'} style={{ minWidth: 600 }}>
              <Col sm="12" style={{ padding: 0 }}>
                <ZoleContainer
                  {...props}
                  key={`room${roomId}`}
                  Layout={ZoleComponent}
                  showNotification={showNotification}
                  toggleFullscreen={toggleFullscreen}
                  isFullscreen={isFullscreen}
                  isFullscreenEnabled={isFullscreen}
                  isWebVersion={isWebVersion}
                  toggleNewDesign={toggleNewDesign}
                  toggleScreenMode={toggleScreenMode}
                  fontMode={fontMode}
                  toggleFont={toggleFont}
                  isInAppFrame={isInAppFrame}
                  lastRound={lastRound}
                  designPlatform={designPlatform}
                />
              </Col>
            </Row>

            <div
              id="bannerRef"
              ref={bannerRef2}
              className="externalBannerRef"
              style={{
                height: (externalBannerSet && !campaignClosed && campaignShow && campaignData && campaignData[0] && campaignData[0]?.externalBanner) ? '70px' : '0px',
                display: (externalBannerSet && !campaignClosed && campaignShow && campaignData && campaignData[0] && campaignData[0]?.externalBanner) ? 'block' : 'none',
                background: (externalBannerSet && !campaignClosed && campaignShow && campaignData && campaignData[0] && campaignData[0]?.externalBanner && campaignData[0]?.overflowBackgroundColor) ? campaignData[0]?.overflowBackgroundColor : 'unset',
              }}
            >
              {(externalBannerSet && !campaignClosed && campaignShow && campaignData && campaignData[0] && campaignData[0]?.externalBanner) ? (
                <Media className="game-banner-close" src={mobileCloseImg} alt="X" onClick={() => handleClickCloseCampaign()} />
              ) : (null)}
            </div>

            {(!campaignClosed && campaignShow && campaignData && campaignData[0] && !campaignData[0]?.externalBanner) && (
              <a ref={bannerRef} onClick={() => handleClickCampaign(campaignData[0]?.pageToOpen, campaignData[0]?.key, 'old')} >
                <div
                  style={{
                    width: '100%',
                    height: '70px',
                    position: 'fixed',
                    right: '0px',
                    bottom: '0px',
                    background: `url(${i18n.language === 'lv' ? campaignData[0].imageUrl : i18n.language === 'en' ? campaignData[0].imageUrlEn : i18n.language === 'ru' ? campaignData[0].imageUrlRu : campaignData[0].imageUrl})`,
                    backgroundSize: 'contain'
                  }}
                />
                <Media className="game-banner-close" src={mobileCloseImg} alt="X" onClick={() => handleClickCloseCampaign()} />
              </a>
            )}
          </Container>
        </div>
      </div>
    );
    */
});

Template.propTypes = {
  pageTitle: PropTypes.string,
  isLoading: PropTypes.bool,
  toggleNewDesign: PropTypes.func.isRequired,
  toggleScreenMode: PropTypes.func.isRequired,
  fontMode: PropTypes.string,
  toggleFont: PropTypes.func.isRequired,
  newDesign: PropTypes.bool,
  lastRound: PropTypes.bool,
  campaignData: PropTypes.shape(),
  fetchCampaign: PropTypes.func.isRequired,
  i18n: PropTypes.shape(),
  clickACampaign: PropTypes.func.isRequired,
};

Template.defaultProps = {
  pageTitle: 'Zole',
  isLoading: false,
  fontMode: FONT_MODE.normal,
  newDesign: false,
  lastRound: false,
  campaignData: {},
  i18n: {},
};

const mapStateToProps = state => ({
  isLoading: state.state.isLoading || null,
  adsHidden: state.member.adsHidden || null,
  loggedInUserDataFetched: state.member.loggedInUserDataFetched || null,
  fourPRoom: (state.game && state.game.globalParams) ? state.game.globalParams.fourPRoom : false,
  notificationSettings: state.userSettings[state.member.uid] ? (state.userSettings[state.member.uid].notificationSettings) : (state.userSettings.default ? state.userSettings.default.notificationSettings : {}),
  screenMode: state.member.screenMode ? state.member.screenMode : SCREEN_MODE.normal,
  lastRound: state.game.globalParams.lastRound,
  campaignData: state.shop.campaigOpenRoomData || {},
});

const mapDispatchToProps = {
  fetchCampaign: getCampaignOpenRoomBanner,
  clickACampaign: clickCampaign,
};

export default connect(mapStateToProps, mapDispatchToProps)(Template);
