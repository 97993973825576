import React, { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { connect } from 'react-redux';
import { constants } from 'react-redux-firebase';
import {
  Row, Col, Media, Form, Input, Label, Button,
  Pagination, PaginationItem, PaginationLink,
  Modal, ModalHeader, ModalBody, ModalFooter,
  FormGroup,
  PopoverBody,
} from 'reactstrap';
import {
  sendLanguageType, NOTIFICATION_SHOW_OPTIONS, FORMAT_DATE_TYPE, sendLanguage, SERVER_STATUS_CODE
} from '../../../constants/constants'
import { map } from 'lodash';
import DateTimePicker from 'react-widgets/lib/DateTimePicker';
import {
  getNotifications,
  addNotification,
  editNotification,
  deleteNotification,
} from '../../../actions/admin';
import DateFormat from '../UI/DateFormat';
import closeImg from '../../../images/icons/close.png';
import { UncontrolledPopover } from 'reactstrap/lib';

const Notifications = ({ showAlertNotification, fetchNotifications, addNotificationFunc, editNotificationFunc, deleteNotificationFunc }) => {
  const { t, i18n } = useTranslation('admin');
  const dispatch = useDispatch();
  const [addNotificationModalOpen, setAddNotificationModalOpen] = useState(false);
  const [languageType, setLanguageType] = useState('all');
  const [lvTitle, setLvTitle] = useState('');
  const [enTitle, setEnTitle] = useState('');
  const [ruTitle, setRuTitle] = useState('');
  const [lvText, setLvText] = useState('');
  const [enText, setEnText] = useState('');
  const [ruText, setRuText] = useState('');
  const [loading, setLoading] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationDate, setNotificationDate] = useState(new Date());
  const [notificationType, setNotificationType] = useState(NOTIFICATION_SHOW_OPTIONS.oneTime);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(50);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectNotificationId, setSelectNotificationId] = useState('');
  const [oldData, setOldData] = useState({});
  const [notifyIdentifier, setNotifyIdentifier] = useState('');

  useEffect(() => {
    dispatch(getNotifications());
  }, []);

  const allNotifications = useSelector(state => state.admin.allNotifications || {});

  const openEditModal = (id) => {
    const selectNotification = allNotifications[id];

    setSelectNotificationId(id);
    setLvTitle(selectNotification.enTitle);
    setEnTitle(selectNotification.lvTitle);
    setRuTitle(selectNotification.ruTitle);
    setLvText(selectNotification.lvText);
    setEnText(selectNotification.enText);
    setRuText(selectNotification.ruText);
    setNotificationDate(new Date(selectNotification.notificationDate));
    setNotificationType(selectNotification.notificationType);
    setShowNotification(selectNotification.showNotification);
    setEditModalOpen(true);
    setDeleteModal(false);
    setAddNotificationModalOpen(false);
    setOldData(allNotifications[id]);
    setNotifyIdentifier(selectNotification.notifyIdentifier);
  }

  // Add new admin functions
  const openAddNotificationModal = () => {
    setLvTitle('');
    setEnTitle('');
    setRuTitle('');
    setLvText('');
    setEnText('');
    setRuText('');
    setNotificationDate(new Date());
    setNotificationType(NOTIFICATION_SHOW_OPTIONS.oneTime);
    setShowNotification(false);
    setNotifyIdentifier('');
    setEditModalOpen(false);
    setDeleteModal(false);
    setAddNotificationModalOpen(true);
  }

  const toggleAddNotification = () => {
    setAddNotificationModalOpen(!addNotificationModalOpen);
  }

  const handleNotificationLanguage = (event) => {
    setLanguageType(event.target.value);
  }

  const handleChange = (event) => {
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

    if (event.target.name === 'lvTitle') setLvTitle(value);
    if (event.target.name === 'enTitle') setEnTitle(value);
    if (event.target.name === 'ruTitle') setRuTitle(value);
    if (event.target.name === 'lvText') setLvText(value);
    if (event.target.name === 'enText') setEnText(value);
    if (event.target.name === 'ruText') setRuText(value);
    if (event.target.name === 'notifyIdentifier') setNotifyIdentifier(value);
  }

  const toggleShowNotification = () => {
    setShowNotification(!showNotification)
  }

  const addNotificationSubmit = () => {
    if (!lvTitle || lvTitle.trim().length === 0) {
      return showAlertNotification('Error', "Missed the title by Latvian.", 'danger');
    } 
    if (!enTitle || enTitle.trim().length === 0) {
      return showAlertNotification('Error', "Missed the title by English.", 'danger');
    }
    if (!ruTitle || ruTitle.trim().length === 0) {
      return showAlertNotification('Error', "Missed the title by Russian.", 'danger');
    }
    if (!lvText || lvText.trim().length === 0) {
      return showAlertNotification('Error', "Missed the text by Latvian.", 'danger');
    } 
    if (!enText || enText.trim().length === 0) {
      return showAlertNotification('Error', "Missed the text by English.", 'danger');
    }
    if (!ruText || ruText.trim().length === 0) {
      return showAlertNotification('Error', "Missed the text by Russian.", 'danger');
    }
    if (!notifyIdentifier || notifyIdentifier.trim().length === 0) {
      return showAlertNotification('Error', "Missed Notification Identifier.", 'danger');
    }
    const data = {
      lvTitle,
      enTitle,
      ruTitle,
      lvText,
      enText,
      ruText,
      notifyIdentifier,
      notificationDate,
      notificationType,
      showNotification
    }

    addNotificationFunc(data).then(res => {
      if (res.status === SERVER_STATUS_CODE.success) {
        return showAlertNotification(SERVER_STATUS_CODE.success, "Notification was added successfully.", SERVER_STATUS_CODE.success);
      }
    })
  }

  const handleChangeDate = (date) => {
    setNotificationDate(date);
  }

  const handleNoficationType = () => {
    setNotificationType();
  }

  const toggleDeleteModal = (key) => {
    if (deleteModal) {
      setSelectNotificationId('');
    } else {
      setSelectNotificationId(key);
    }
    setDeleteModal(!deleteModal);
  }

  const toggleEditModal = () => {
    setEditModalOpen(!editModalOpen);
  }

  const editNotificationSubmit = () => {
    if (!lvTitle || lvTitle.trim().length === 0) {
      return showAlertNotification('Error', "Missed the title by Latvian.", 'danger');
    } 
    if (!enTitle || enTitle.trim().length === 0) {
      return showAlertNotification('Error', "Missed the title by English.", 'danger');
    }
    if (!ruTitle || ruTitle.trim().length === 0) {
      return showAlertNotification('Error', "Missed the title by Russian.", 'danger');
    }
    if (!lvText || lvText.trim().length === 0) {
      return showAlertNotification('Error', "Missed the text by Latvian.", 'danger');
    } 
    if (!enText || enText.trim().length === 0) {
      return showAlertNotification('Error', "Missed the text by English.", 'danger');
    }
    if (!ruText || ruText.trim().length === 0) {
      return showAlertNotification('Error', "Missed the text by Russian.", 'danger');
    }
    if (!notifyIdentifier || notifyIdentifier.trim().length === 0) {
      return showAlertNotification('Error', "Missed Notification Identifier.", 'danger');
    }

    const data = {
      selectNotificationId,
      lvTitle,
      enTitle,
      ruTitle,
      lvText,
      enText,
      ruText,
      notifyIdentifier,
      notificationDate,
      notificationType,
      showNotification
    }

    editNotificationFunc(data).then(res => {
      if (res.status === SERVER_STATUS_CODE.success) {
        return showAlertNotification(SERVER_STATUS_CODE.success, "Notification was updated successfully.", SERVER_STATUS_CODE.success);
      }
    })
  }

  const deleteNotificationSubmit = () => {
    if (!selectNotificationId) {
      toggleDeleteModal()
      return showAlertNotification('Error', "Please try again.", 'danger');
    }

    deleteNotificationFunc(selectNotificationId).then(res => {
      if (res.status === SERVER_STATUS_CODE.success) {
        toggleDeleteModal()
        return showAlertNotification(SERVER_STATUS_CODE.success, "Notification was deleted successfully.", SERVER_STATUS_CODE.success);
      }
    })
  }

  const table = () => {

    const pagesCount = Math.ceil(Object.keys(allNotifications).length / pageSize);

    return (
      <Fragment>
        {allNotifications && Object.keys(allNotifications)
          .slice(
            currentPage * pageSize,
            (currentPage + 1) * pageSize,
          )
          .map((key, index) => (
            <Fragment key={key}>
              <tr key={key} className={`allUsers-table-row ${index % 2 === 0 ? ('odd') : ('even')}`}>
                <td className="allUsers-table-col">
                  {i18n.language === 'en' ? allNotifications[key].enTitle : (i18n.language === 'lv' ? allNotifications[key].lvTitle : (i18n.language === 'ru' ? allNotifications[key].ruTitle : allNotifications[key].lvTitle))}
                </td>
                <td className="allUsers-table-col">
                  {i18n.language === 'en' ? allNotifications[key].enText : (i18n.language === 'lv' ? allNotifications[key].lvText : (i18n.language === 'ru' ? allNotifications[key].ruText : allNotifications[key].lvText))}
                </td>
                <td className="allUsers-table-col">
                  {allNotifications[key].notifyIdentifier}
                </td>
                <td className="allUsers-table-col">
                  <DateFormat formatType={FORMAT_DATE_TYPE.fullDateMinute} date={allNotifications[key].notificationDate} />
                </td>
                <td className="allUsers-table-col">
                  {allNotifications[key].notificationType}
                </td>
                <td className="allUsers-table-col">
                  {allNotifications[key].showNotification ? 'Show' : 'Not Show'}
                </td>
                <td className="allUsers-table-col">
                  <Button color="primary" onClick={() => openEditModal(key)}>
                    {/* {t('edit')} */}
                    Edit
                  </Button>
                </td>
                <td className="allUsers-table-col">
                  <Button color="primary" onClick={() => toggleDeleteModal(key)}>
                    {/* {t('delete')} */}
                    Delete
                  </Button>
                </td>
              </tr>
            </Fragment>
          ))}
        {pagesCount && Object.keys(allNotifications).length > pageSize && (
          <div className="pagination-wrapper">
            <Pagination aria-label="Page navigation example">
              <PaginationItem disabled={currentPage === 0}>
                <PaginationLink style={{ color: '#000' }} onClick={e => this.handleClick(e, 0)} href="#">
                  {`‹‹‹`}
                </PaginationLink>
              </PaginationItem>

              <PaginationItem disabled={currentPage <= 0}>
                <PaginationLink
                  style={{ color: '#000' }}
                  onClick={e => this.handleClick(e, currentPage - 1)}
                  previous
                  href="#"
                />
              </PaginationItem>

              {[...Array(pagesCount)].map((page, i) => {
                if (i > currentPage - 3 && i < currentPage + 3) {
                  return (
                    <PaginationItem active={i === currentPage} key={page}>
                      <PaginationLink style={{ color: '#000' }} onClick={e => this.handleClick(e, i)} href="#">
                        {i + 1}
                      </PaginationLink>
                    </PaginationItem>
                  );
                }

                return null;
              })}

              <PaginationItem disabled={currentPage >= pagesCount - 1}>
                <PaginationLink
                  style={{ color: '#000' }}
                  onClick={e => this.handleClick(e, currentPage + 1)}
                  next
                  href="#"
                />
              </PaginationItem>

              <PaginationItem disabled={currentPage >= pagesCount - 1}>
                  <PaginationLink style={{ color: '#000' }} onClick={e => this.handleClick(e, pagesCount - 1)} href="#">
                    {`›››`}
                  </PaginationLink>
                </PaginationItem>
            </Pagination>
          </div>
        )}
      </Fragment>
    );
  }

  return (
    <Fragment>
      <UncontrolledPopover popperClassName={`popover`} placement="right" trigger="focus" target="admin-help-button-notifications">
        <PopoverBody className="popover-body">
          A list of all set notifications for the mobile apps. The admin can add a new notification, it requires a title and text in all of the listed languages, and also the identifier. The notifications can be set up as one-time or repetitive. 
        </PopoverBody>
      </UncontrolledPopover>
      <div className="admin-page-content">
        <Row>
          <Col md="6">
            <h2>
              Notifications
              <Button className="admin-help-button" id="admin-help-button-notifications">
                ?
              </Button>
            </h2>
          </Col>
          <Col md="3">
            <Button type="button" color="primary" onClick={openAddNotificationModal}>
              Add Notifications
            </Button>
          </Col>
        </Row>
      </div>
      <div>
      <table style={{ width: '100%', fontSize: 12 }}>
        <colgroup>
          <col span="1" className="" />
        </colgroup>
        <thead>
          <tr>
            <th style={{ textAlign: 'center' }}>
              Title
            </th>
            <th style={{ textAlign: 'center' }}>
              Text
            </th>
            <th style={{ textAlign: 'center' }}>
              Identifier
            </th>
            <th style={{ textAlign: 'center' }}>
              Date
            </th>
            <th style={{ textAlign: 'center' }}>
              Type
            </th>
            <th style={{ textAlign: 'center' }}>
              Visible
            </th>
            <th style={{ textAlign: 'center' }}>
              {/* {t('edit')} */}
              Edit
            </th>
            <th style={{ textAlign: 'center' }}>
              {/* {t('delete')} */}
              Delete
            </th>
          </tr>
        </thead>
        <tbody>
          {table()}
        </tbody>
      </table>
      </div>
      <Modal container={`div > div`} isOpen={addNotificationModalOpen} toggle={toggleAddNotification}>
        <ModalHeader toggle={toggleAddNotification}>
          Notification
        </ModalHeader>
        <ModalBody>
          <Row style={{ height: '80%' }}>
            <Form style={{ width: '100%' }}>
              <Col md="12">
                <FormGroup>
                  <Label for="lvTitle">
                    {/* {t('lvTitle')} */}
                    Title By Latvian
                  </Label>
                  <Input
                    type="text"
                    name="lvTitle"
                    id="lvTitle"
                    value={lvTitle}
                    disabled={loading}
                    onChange={handleChange}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="enTitle">
                    {/* {t('enTitle')} */}
                    Title By English
                  </Label>
                  <Input
                    type="text"
                    name="enTitle"
                    id="enTitle"
                    value={enTitle}
                    disabled={loading}
                    onChange={handleChange}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="ruTitle">
                    {/* {t('ruTitle')} */}
                    Title By Russian
                  </Label>
                  <Input
                    type="text"
                    name="ruTitle"
                    id="ruTitle"
                    value={ruTitle}
                    disabled={loading}
                    onChange={handleChange}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="lvText">
                    {/* {t('lvTitle')} */}
                    Text By Latvian
                  </Label>
                  <Input
                    type="text"
                    name="lvText"
                    id="lvText"
                    value={lvText}
                    disabled={loading}
                    onChange={handleChange}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="enText">
                    {/* {t('enTitle')} */}
                    Text By English
                  </Label>
                  <Input
                    type="text"
                    name="enText"
                    id="enText"
                    value={enText}
                    disabled={loading}
                    onChange={handleChange}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="ruText">
                    {/* {t('ruTitle')} */}
                    Text By Russian
                  </Label>
                  <Input
                    type="text"
                    name="ruText"
                    id="ruText"
                    value={ruText}
                    disabled={loading}
                    onChange={handleChange}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="notifyIdentifier">
                    {/* {t('ruTitle')} */}
                    Notification Identifier
                  </Label>
                  <Input
                    type="text"
                    name="notifyIdentifier"
                    id="notifyIdentifier"
                    value={notifyIdentifier}
                    disabled={loading}
                    onChange={handleChange}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="startDate">
                    Date
                  </Label>
                  <Row>
                    <Col md="12">
                      <DateTimePicker
                        format={FORMAT_DATE_TYPE.pointDate}
                        culture={sendLanguage.lv}
                        onChange={handleChangeDate}
                        value={notificationDate}
                        disabled={loading}
                        defaultValue={new Date()}
                      />
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup>
                  <Label for="role">
                    Notification Type
                  </Label>
                  <Input type="select" name="role" className="open-page-select" value={notificationType} disabled={loading} onChange={handleNoficationType}>
                  {
                    map(NOTIFICATION_SHOW_OPTIONS, (item, key) => (
                      <option className="background-222" value={key}>{item}</option>
                    ))
                  }
                  </Input>
                </FormGroup>
                <Row>
                  <Col sm="12">
                    <Label className="notification-type-input" style={{ marginLeft: 40 }}>
                      <Input type="checkbox" onClick={toggleShowNotification} disabled={loading} checked={showNotification} readOnly />
                      <span className="checkmark" />
                      <div className="event-type-text">
                        {/* {t('showInBanner')} */}
                        Would you like to enable notification?
                      </div>
                    </Label>
                  </Col>
                </Row>
              </Col>
            </Form>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button className="contact-support-footer-button" color="primary" onClick={() => addNotificationSubmit()}>
            Add
          </Button>
          <Button type="button" color="secondary" onClick={toggleAddNotification}>Close</Button>
        </ModalFooter>
      </Modal>
      <Modal container={`div > div`} isOpen={editModalOpen} toggle={toggleEditModal}>
        <ModalHeader toggle={toggleEditModal}>
          Notification
        </ModalHeader>
        <ModalBody>
          <Row style={{ height: '80%' }}>
            <Form style={{ width: '100%' }}>
              <Col md="12">
                <FormGroup>
                  <Label for="lvTitle">
                    {/* {t('lvTitle')} */}
                    Title By Latvian
                  </Label>
                  <Input
                    type="text"
                    name="lvTitle"
                    id="lvTitle"
                    value={lvTitle}
                    disabled={loading}
                    onChange={handleChange}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="enTitle">
                    {/* {t('enTitle')} */}
                    Title By English
                  </Label>
                  <Input
                    type="text"
                    name="enTitle"
                    id="enTitle"
                    value={enTitle}
                    disabled={loading}
                    onChange={handleChange}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="ruTitle">
                    {/* {t('ruTitle')} */}
                    Title By Russian
                  </Label>
                  <Input
                    type="text"
                    name="ruTitle"
                    id="ruTitle"
                    value={ruTitle}
                    disabled={loading}
                    onChange={handleChange}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="lvText">
                    {/* {t('lvTitle')} */}
                    Text By Latvian
                  </Label>
                  <Input
                    type="text"
                    name="lvText"
                    id="lvText"
                    value={lvText}
                    disabled={loading}
                    onChange={handleChange}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="enText">
                    {/* {t('enTitle')} */}
                    Text By English
                  </Label>
                  <Input
                    type="text"
                    name="enText"
                    id="enText"
                    value={enText}
                    disabled={loading}
                    onChange={handleChange}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="ruText">
                    {/* {t('ruTitle')} */}
                    Text By Russian
                  </Label>
                  <Input
                    type="text"
                    name="ruText"
                    id="ruText"
                    value={ruText}
                    disabled={loading}
                    onChange={handleChange}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="notifyIdentifier">
                    {/* {t('ruTitle')} */}
                    Notification Identifier
                  </Label>
                  <Input
                    type="text"
                    name="notifyIdentifier"
                    id="notifyIdentifier"
                    value={notifyIdentifier}
                    disabled={loading}
                    onChange={handleChange}
                  />
                </FormGroup>
                <FormGroup></FormGroup>
                <FormGroup>
                  <Label for="startDate">
                    Date
                  </Label>
                  <Row>
                    <Col md="12">
                      <DateTimePicker
                        format={FORMAT_DATE_TYPE.pointDate}
                        culture={sendLanguage.lv}
                        onChange={handleChangeDate}
                        value={notificationDate}
                        disabled={loading}
                        defaultValue={new Date()}
                      />
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup>
                  <Label for="role">
                    Notification Type
                  </Label>
                  <Input type="select" name="role" className="open-page-select" value={notificationType} disabled={loading} onChange={handleNoficationType}>
                  {
                    map(NOTIFICATION_SHOW_OPTIONS, (item, key) => (
                      <option className="background-222" value={key}>{item}</option>
                    ))
                  }
                  </Input>
                </FormGroup>
                <Row>
                  <Col sm="12">
                    <Label className="notification-type-input" style={{ marginLeft: 40 }}>
                      <Input type="checkbox" onClick={toggleShowNotification} disabled={loading} checked={showNotification} readOnly />
                      <span className="checkmark" />
                      <div className="event-type-text">
                        {/* {t('showInBanner')} */}
                        Would you like to enable notification?
                      </div>
                    </Label>
                  </Col>
                </Row>
              </Col>
            </Form>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button className="contact-support-footer-button" color="primary" onClick={() => editNotificationSubmit()}>
            Edit
          </Button>
          <Button type="button" color="secondary" onClick={toggleEditModal}>Close</Button>
        </ModalFooter>
      </Modal>

      <Modal container={'div > div'} isOpen={deleteModal} toggle={() => toggleDeleteModal('')} className="notification">
        <ModalHeader
          toggle={() => toggleDeleteModal('')}
          className="notification-header"
          close={
            <Media src={closeImg} className="notification-header-close" alt="X" onClick={() => toggleDeleteModal('')} />
          }
        />
        <ModalBody className="notification-body">
          {/* {t('deleteEventQuiz')} */}
          Do you really want to delete the notification?
        </ModalBody>
        <ModalFooter className="notification-footer">
          <Button className="btn notification-footer-button" onClick={() => deleteNotificationSubmit()}>
            {/* {t('yes')} */}
            Yes
          </Button>
          <Button type="button" className="btn notification-footer-button" onClick={() => toggleDeleteModal('')}>
            {/* {t('no')} */}
            No
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );

}

const mapStateToProps = state => ({
  blogs: state.admin.blogs || {},
});

const mapDispatchToProps = {
  fetchNotifications: getNotifications,
  addNotificationFunc: addNotification,
  editNotificationFunc: editNotification,
  deleteNotificationFunc: deleteNotification,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Notifications);
