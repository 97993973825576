import React, { Fragment, useEffect, useState } from 'react';
import { map, size } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';

import RoomLogs from './RoomLogs';
import DateFormat from '../UI/DateFormat';

import { getRoomLog, getRoomOptions } from '../../../actions/admin';
import { FORMAT_DATE_TYPE } from '../../../constants/constants';

const PossibleBotPlayerData = ({ possibleBotPlayerData, bots }) => {
  const dispatch = useDispatch();
  const selectedRoomData = useSelector(state => state.admin.roomData || {});
  const selectedroomOptions = useSelector(state => state.admin.roomOptions || {});

  const [roomViewModal, setRoomViewModal] = useState(false);
  const [selectedRoomId, setSelectedRoomId] = useState('');

  const openRoomlViewModal = (roomId) => {
    setRoomViewModal(true);
    setSelectedRoomId(roomId);
  };

  useEffect(() => {
    if (selectedRoomId) {
      dispatch(getRoomLog(selectedRoomId));
      dispatch(getRoomOptions(selectedRoomId));
    }
  }, [selectedRoomId])

  const toggleRoom = () => {
    setRoomViewModal(false);
    setSelectedRoomId('');
  }

  return (
    <Fragment>
      <table style={{ width: '100%', fontSize: 12 }}>
        <colgroup>
          <col span="1" className="" />
        </colgroup>
        <thead>
          <tr>
            <th style={{ textAlign: 'center' }}>
              Date
            </th>
            <th style={{ textAlign: 'center' }}>
              Name
            </th>
            <th style={{ textAlign: 'center' }}>
              Player ID
            </th>
            <th style={{ textAlign: 'center' }}>
              Room ID
            </th>
            <th style={{ textAlign: 'center' }}>
              View
            </th>
          </tr>
        </thead>
        <tbody>
          {possibleBotPlayerData && map(possibleBotPlayerData, (item, key) => {
            if (size(item?.rooms) === 0) return;

            return (
              map(item.rooms, (roomId, idx) => (
                <tr key={key} className="allUsers-table-row">
                  <td className="allUsers-table-col">
                    <DateFormat formatType={FORMAT_DATE_TYPE.fullDateMinute} date={item.date} />
                  </td>
                  <td className="allUsers-table-col">
                    {item.name}
                  </td>
                  <td className="allUsers-table-col">
                    {item.uid}
                  </td>
                  <td className="allUsers-table-col">
                    {roomId}
                  </td>
                  <td className="allUsers-table-col">
                    <Button color="warning" onClick={() => openRoomlViewModal(roomId)}>
                      View
                    </Button>
                  </td>
                </tr>
              ))
            )
          })}
        </tbody>
      </table>
      <Modal container={`div > div`} isOpen={roomViewModal} toggle={toggleRoom} style={{ maxWidth: '65%' }}>
        <ModalHeader toggle={toggleRoom}>
          {selectedroomOptions ? `Room: ${selectedRoomId}, Bet: ${selectedroomOptions.bet}, ${selectedroomOptions.minGames ? `Min Rounds: ${selectedroomOptions.minGames}` : ''}, ${selectedroomOptions.fourPRoom ? '4' : '3'} Players` : ''}
        </ModalHeader>
        <ModalBody>
          <RoomLogs roomData={selectedRoomData} roomId={selectedRoomId} roomParams={selectedroomOptions} bots={bots} />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleRoom}>Close</Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default PossibleBotPlayerData;
