import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';

import { connect } from 'react-redux';

import Moment from 'react-moment';
// import moment from 'moment';

import ReactDateTime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';

import ScrollArea from 'react-scrollbar';
import Spinner from 'reactstrap/lib/Spinner';
import DateTimePicker from 'react-widgets/lib/DateTimePicker';

import Label from 'reactstrap/lib/Label';
import Input from 'reactstrap/lib/Input';
import Button from 'reactstrap/lib/Button';
import Modal from 'reactstrap/lib/Modal';
import Media from 'reactstrap/lib/Media';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import Form from 'reactstrap/lib/Form';
import FormGroup from 'reactstrap/lib/FormGroup';

import pencilImg from '../../../images/icons/pencil.svg';
import checkImg from '../../../images/icons/check.png';
import cancelImg from '../../../images/icons/close_old.png';
import defaultImage from '../../../images/Game/defaultImage.jpg';

import DateFormat from '../UI/DateFormat';

import Avatar from 'react-avatar-edit';

import UserGamesHistory from './UserGamesHistory';
import UserHistory from './UserHistory';

import classNames from 'classnames';
import { map } from 'lodash';
import * as constants from '../../../constants/constants';

import {
  editUserDob,
} from '../../../actions/admin';
import { PopoverBody, UncontrolledPopover } from 'reactstrap';

class BotsList extends React.Component {
  static propTypes = {
    bots: PropTypes.shape({}),
    fetchBots: PropTypes.func.isRequired,
    addPoints: PropTypes.func.isRequired,
    addBalance: PropTypes.func.isRequired,
    fetchUserGamesHistory: PropTypes.func.isRequired,
    fetchUserPointsHistory: PropTypes.func.isRequired,
    fetchUserBalanceHistory: PropTypes.func.isRequired,
    editUser: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    submitUserDobFuc: PropTypes.func.isRequired,
  //  manualyVerifyUser: PropTypes.func.isRequired,
  }

  static defaultProps = {
    bots: {},
  }

  constructor(props) {
    super(props);
    this.state = {
    //  roleModalOpen: false,
      openModal: false,
      editUserId: null,
      gamesHistoryModalOpen: false,
      firstName: '',
      lastName: '',
      preview: null,
      showCrop: false,
      ableToSave: true,
      photo: '',
      minDate: null,
      maxDate: null,
      birthday: null,
    };
  }


  componentDidMount() {
    const { fetchBots } = this.props;

    fetchBots();

    const minDate = new Date(new Date().getFullYear() - 100, 1, 1, 0, 0, 0);
    const maxDate = new Date();
    this.setState({
      minDate,
      maxDate,
    });
  }

  getUserGamesHistory = (userId) => {
    const { fetchUserGamesHistory } = this.props;
    if (userId) {

      fetchUserGamesHistory(userId);

      this.setState({
        gamesHistoryModalOpen: true,
        historyUid: userId,
      });
    }
  }

  getUserBalanceHistory = (userId) => {
    const { fetchUserBalanceHistory } = this.props;
    if (userId) {
      const startAt = Date.now() - (1000 * 60 * 60 * 24 * 7);
      const endAt = Date.now();

      fetchUserBalanceHistory(userId, startAt, endAt);

      this.setState({
        bilanceHistoryModalOpen: true,
        historyUid: userId,
      });
    }
  }

  getUserPointsHistory = (userId) => {
    const { fetchUserPointsHistory } = this.props;
    if (userId) {
      const startAt = Date.now() - (1000 * 60 * 60 * 24 * 14);
      const endAt = Date.now();

      fetchUserPointsHistory(userId, startAt, endAt);

      this.setState({
        pointsHistoryModalOpen: true,
        historyUid: userId,
      });
    }
  }

  closeGamesHistory = () => {
    this.setState({
      gamesHistoryModalOpen: false,
    });
  }

  closeBilanceHistory = () => {
    this.setState({
      bilanceHistoryModalOpen: false,
    });
  }

  closePointsHistory = () => {
    this.setState({
      pointsHistoryModalOpen: false,
    });
  }

  openModalOld = (uid) => {
    const { bots } = this.props;

    const user = bots[uid];

    this.setState({
      editUserId: uid,
      openModal: true,
      balance: 0,
      gamesPlayed: user.gPlayed,
      level: user.lvl,
      totalPnts: 0,
    });
  }

  editUserOld = () => {
    const { editUser } = this.props;
    const {
      editUserId, balance, level, gamesPlayed, totalPnts,
    } = this.state;

    editUser(editUserId, balance, level, gamesPlayed, totalPnts);
    this.setState({ openModal: false });
  }

  addBalance = () => {
    const { addBalance } = this.props;
    const { editUserId, balance } = this.state;

    addBalance(editUserId, balance);
  }

  addPoints = () => {
    const { addPoints } = this.props;
    const { editUserId, totalPnts } = this.state;

    addPoints(editUserId, totalPnts);
  }

  toggle = () => {
    this.setState(prevState => ({
      openModal: !prevState.openModal,
    }));
  }

  handleChange = (event) => {
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

    this.setState({
      [event.target.name]: value,
    });
  }

  table = () => {
    const {
      t,
      bots,
    } = this.props;

    /*
    .sort((a, b) => {
      if (bots[a].tournamentBotUser && !bots[b].tournamentBotUser) {
        return -1;
      } if (!bots[a].tournamentBotUser && bots[b].tournamentBotUser) {
        return 1;
      }
      return 0;
    }) */

    return (
      <Fragment>
        {bots && Object.keys(bots).sort((a, b) => ((bots[a].tournamentBotUser === bots[b].tournamentBotUser) ? 0 : bots[a].tournamentBotUser ? 1 : -1)).map((key, index) => (
          <Fragment key={key}>
            <tr key={key} className={classNames({ 'allUsers-table-row odd': (index % 2 === 0), 'allUsers-table-row even ': index % 2 !== 0 })}>
              <td className="allUsers-table-col">
                {bots[key].name}
              </td>
              <td className="allUsers-table-col">
                <Moment format="DD-MM-YYYY, HH:mm" locale="lv">
                  {bots[key].dateCreated}
                </Moment>
              </td>
              <td className="allUsers-table-col">
                {bots[key].lvl}
              </td>
              <td className="allUsers-table-col">
                {bots[key].activeRoom}
              </td>
              <td className="allUsers-table-col">
                {`${bots[key].bal}€`}
              </td>
              <td className="allUsers-table-col">
                {bots[key].totalPnts}
              </td>
              <td className="allUsers-table-col">
                {bots[key].gPlayed}
              </td>
              <td className="allUsers-table-col">
                {bots[key].email}
              </td>
              <td className="allUsers-table-col">
                {bots[key].tournamentBotUser ? 'true' : 'false'}
              </td>
              <td className="allUsers-table-col">
                {key}
              </td>
              <td className="allUsers-table-col">
                <Button color="primary" style={{ fontSize: 10 }} onClick={() => this.getUserGamesHistory(key)}>
                  {/* {t('results.results')} */}
                  Results
                </Button>
              </td>
              <td className="allUsers-table-col">
                <Button color="primary" style={{ fontSize: 10 }} onClick={() => this.getUserBalanceHistory(key)}>
                  {/* {t('balanceHistory')} */}
                  Balance History
                </Button>
              </td>
              <td className="allUsers-table-col">
                <Button color="primary" style={{ fontSize: 10 }} onClick={() => this.getUserPointsHistory(key)}>
                  {/* {t('pointsHistory')} */}
                  Points History
                </Button>
              </td>
              <td className="allUsers-table-col">
                <Button color="primary" onClick={() => this.openModal(key)}>
                  {/* {t('edit')} */}
                  Edit
                </Button>
              </td>

            </tr>
          </Fragment>
        ))}
      </Fragment>
    );
  }



  getUserGamesHistory = (userId) => {
    const { fetchUserGamesHistory } = this.props;
    if (userId) {

      fetchUserGamesHistory(userId);

      this.setState({
        gamesHistoryModalOpen: true,
        historyUid: userId,
      });
    }
  }

  getUserBalanceHistory = (userId) => {
    const { fetchUserBalanceHistory } = this.props;
    if (userId) {
      const startAt = Date.now() - (1000 * 60 * 60 * 24 * 7);
      const endAt = Date.now();

      fetchUserBalanceHistory(userId, startAt, endAt);

      this.setState({
        bilanceHistoryModalOpen: true,
        historyUid: userId,
      });
    }
  }

  getUserPointsHistory = (userId) => {
    const { fetchUserPointsHistory } = this.props;
    if (userId) {
      const startAt = Date.now() - (1000 * 60 * 60 * 24 * 14);
      const endAt = Date.now();

      fetchUserPointsHistory(userId, startAt, endAt);

      this.setState({
        pointsHistoryModalOpen: true,
        historyUid: userId,
      });
    }
  }

  openModal = (uid) => {
    const { allUsers, bots } = this.props;

      const user = bots[uid];
      console.log(user);

      this.setState({
        editUserId: uid,
        firstName: user.firstName || (user.name.split(' ')[0] || ''),
        lastName: user.lastName || (user.name.split(' ')[1] || ''),
        photo: user.photo ? user.photo : defaultImage,
        openModal: true,
        balance: 0,
        gamesPlayed: user.gPlayed,
        level: user.lvl,
        totalPnts: 0,
        updateFlag: 'all',
        birthday: user?.birthday ? new Date(user.birthday) : null,
      });
  }

  editUser = () => {
    const { editUser } = this.props;
    const {
      editUserId, balance, level, gamesPlayed, showCrop, totalPnts, firstName, lastName, photo, updateFlag, filter, filterType,
    } = this.state;
    const { showNotification, t } = this.props;

    const name = firstName + " " + lastName;
    const lowerCaseName = name.toLowerCase();
    const lowerCaseLastName = lastName.toLowerCase();

    if ((firstName.trim()).length == 0) {
      showNotification('Error!', "Missing first name", 'warning');
      return;
    }
    if ((lastName.trim()).length == 0) {
      showNotification('Error!', "Missing last name", 'warning');
      return;
    }
    if (!showCrop) {
      editUser(editUserId, balance, level, gamesPlayed, totalPnts, firstName, lastName, photo, updateFlag, filter, filterType, name, lowerCaseName, lowerCaseLastName);
      this.setState({ openModal: false });
    } else {
      showNotification('Error!', "If you don't apply or close changed the avatar, you can't change your profile.", 'warning');
    }
  }

  onCrop = (preview) => {
    this.setState({ preview });
  }

  handleEditPhoto = (value) => {
    this.setState({ showCrop: !value });
  }

  uploadAvatarFile = () => {
    const { updateAvatarFileUpload } = this.props;
    const { preview, editUserId } = this.state;
    this.setState({ ableToSave: false });
    if (preview !== null) {
      updateAvatarFileUpload(preview, editUserId).then((data) => {
        console.log('value@@@@@@@@@@@@@@@@', data);
        this.setState({
          photo: data.url,
          ableToSave: true,
        });
      });
    }
  }

  onBeforeFileLoad = (elem) => {
    try {
      const { showNotification, t } = this.props;

      console.log(elem.target.files[0].type);
      const typeList = 'image/jpg,image/png,image/jpeg, image/pjp, image/pjpeg, image/jfif';
      if (!typeList.includes(elem.target.files[0].type) || elem.target.files[0].type == '') {
        showNotification('Error!', "Unsupported File!", 'danger');
        return elem.target.value = '';
      }

      if (elem.target.files[0].size / 1024 > 3072) {
        showNotification('Warning', "Can't upload more than 1.5 MB", 'warning');
        elem.target.value = '';
      }
    } catch (e) {
      console.log('catch error', e.message);
      return elem.target.value = '';
    }
  }

  changeBirthday = (event) => {
    console.log('changeBirthday event', event);
    this.setState({ birthday: event });
  }

  handleEditUserDob = () => {
    const { editUserId, birthday, filter, filterType, nonVerified } = this.state;
    const { submitUserDobFuc, showNotification, t } = this.props;
    submitUserDobFuc(editUserId, birthday).then((res) => {
      console.log(res, "editUserDobStatus")
      if (res.status === constants.FROUNT_SIDE_STATUS.success) {
        return showNotification('Success!', "User's birthday has been successfully updated", 'success');
      }
      return showNotification('Error!', "User's birthday has been not successfully updated", 'danger');
    }).catch((err) => {
      console.log(err);
      return showNotification('Error!', "User's birthday has been not successfully updated", 'danger');
    });
    // fetchFilteredUsers(filter, filterType, nonVerified);
  }


  render() {
    const {
      t,
      i18n,
      fetchUserGamesHistory,
      fetchUserBalanceHistory,
      fetchUserPointsHistory,
      userGamesHistory,
      userBalanceHistory,
      userPointsHistory,
      loading,
    } = this.props;

    const {
      openModal,
      balance,
      level,
      gamesPlayed,
      endDate,
      reason,
      openBlockModal,
      //  userFilter,
      filterType,
      filter,
      bilanceHistoryModalOpen,
      pointsHistoryModalOpen,
      totalPnts,
      openDeleteConfirmation,
      historyUid,
      openChatModal,
      openChatModalName,
      openChatModalUid,
      inputMessage,
      openAchievementsModal,
      openAchievementsModalName,
      openAchievementsModalUid,
      openManualVerifyConfirmation,
      gamesHistoryModalOpen,
      editUserId,
      fileName,
      firstName,
      lastName,
      photo,
      preview,
      showCrop,
      success,
      minDate,
      maxDate,
      birthday,
    } = this.state;

    console.log('this.state', this.state);

    return (
      <Fragment>
        <UncontrolledPopover popperClassName={`popover`} placement="right" trigger="focus" target="admin-help-button-bots">
          <PopoverBody className="popover-body">
            The accounts that have been created by the administration are being used in regular rooms and tournaments in case there are not enough real players (humans) to fill out the rooms and tournaments. The password of each bot account: 321zole123  
            <br /><br />
            Note: please regularly check and add a balance to the bots for them to be able to join the rooms. 
          </PopoverBody>
        </UncontrolledPopover>
        <div className="admin-page-content">
          <h2>
            {/* {t('bots')} */}
            Bots
            <Button className="admin-help-button" id="admin-help-button-bots">
              ?
            </Button>
          </h2>
          <table style={{ width: '100%', fontSize: 12 }}>
            <colgroup>
              <col span="1" className="" />
            </colgroup>
            <thead>
              <tr>
                <th style={{ textAlign: 'center' }}>
                  {/* {t('name')} */}
                  Name
                </th>
                <th style={{ textAlign: 'center' }}>
                  {/* {t('dateCreated')} */}
                  Date created
                </th>
                <th style={{ textAlign: 'center' }}>
                  {/* {t('level')} */}
                  Level
                </th>
                <th style={{ textAlign: 'center' }}>
                  {/* {t('activeRoom')} */}
                  Active Room
                </th>
                <th style={{ textAlign: 'center' }}>
                  {/* {t('balance')} */}
                  Balance
                </th>
                <th style={{ textAlign: 'center' }}>
                  {/* {t('points')} */}
                  Points
                </th>
                <th style={{ textAlign: 'center' }}>
                  {/* {t('gPlayed')} */}
                  Games Played
                </th>
                <th style={{ textAlign: 'center' }}>
                  {/* {t('email')} */}
                  Email
                </th>
                <th style={{ textAlign: 'center' }}>
                  Tournament user
                </th>
                <th style={{ textAlign: 'center' }}>
                  {/* {t('playerId')} */}
                  Player Id
                </th>
                <th className="allUsers-table-col">
                  {/* {t('results.results')} */}
                  Results
                </th>
                <th style={{ textAlign: 'center' }}>
                  {/* {t('balanceHistory')} */}
                  Blance History
                </th>
                <th style={{ textAlign: 'center' }}>
                  {/* {t('pointsHistory')} */}
                  Points History
                </th>
                <th style={{ textAlign: 'center' }}>
                  {/* {t('edit')} */}
                  Edit
                </th>
              </tr>
            </thead>
            <tbody>
              {this.table()}
            </tbody>
          </table>

        </div>

        <Modal container={'div > div'} isOpen={openModal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>
            Edit
          </ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup style={{ marginTop: 40 }} className="notification">
                <FormGroup>
                  <img className="menu-player-avatar" src={preview || photo} alt="Preview" />
                  {!showCrop && (
                    <div className="menu-player-edit" style={success ? { position: 'absolute', top: 100, right: 180 } : { position: 'absolute', top: 40, right: 180 }}>
                      <Media onClick={() => { this.handleEditPhoto(showCrop); }} src={pencilImg} className="menu-player-edit-image" />
                    </div>
                  )}
                  {!showCrop && (
                    <div className="menu-player-edit" style={success ? { position: 'absolute', top: 140, right: 180 } : { position: 'absolute', top: 80, right: 180 }}>
                      <Media onClick={() => { this.setState({ photo: defaultImage }); }} src={cancelImg} className="menu-player-edit-image" />
                    </div>
                  )}
                  {showCrop && (
                    <div className="menu-player-edit" style={success ? { position: 'absolute', top: 100, right: 180 } : { position: 'absolute', top: 40, right: 180 }}>
                      <Media onClick={() => { this.handleEditPhoto(showCrop); this.uploadAvatarFile(); }} src={checkImg} className="menu-player-edit-image" />
                    </div>
                  )}
                  {showCrop && (
                    <div className="menu-player-edit" style={success ? { position: 'absolute', top: 140, right: 180 } : { position: 'absolute', top: 80, right: 180 }}>
                      <Media onClick={() => { this.handleEditPhoto(showCrop); this.setState({ preview: null }); }} src={cancelImg} className="menu-player-edit-image" />
                    </div>
                  )}
                  {showCrop && (
                    <div style={{
                      width: 150, height: 150, margin: 'auto', marginTop: 20,
                    }}
                    >
                      <Avatar
                        width={150}
                        height={150}
                        imageWidth={150}
                        imageHeight={150}
                        onCrop={this.onCrop}
                        onBeforeFileLoad={this.onBeforeFileLoad}
                        label={'Upload a Photo'}
                        labelStyle={{ fontSize: 15, fontVariant: 'all-petite-caps' }}
                      />
                    </div>
                  )
                  }
                  <Input
                    type="text"
                    name="photo"
                    id="photo"
                    placeholder=""
                    hidden
                    value={photo}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="firstName">Name</Label>
                  <Input
                    type="text"
                    name="firstName"
                    id="firstName"
                    placeholder=""
                    disabled={loading}
                    value={firstName}
                    maxlength="20"
                    onChange={this.handleChange}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="lastName">Surname</Label>
                  <Input
                    type="text"
                    name="lastName"
                    id="lastName"
                    placeholder=""
                    disabled={loading}
                    value={lastName}
                    maxlength="20"
                    onChange={this.handleChange}
                  />
                  <Input
                    type="hidden"
                    name="editUserId"
                    id="editUserId"
                    placeholder=""
                    value={editUserId}
                    maxlength="20"
                  />
                </FormGroup>
                <Button
                  color="link"
                  className="update-profile-button"
                  style={{ marginTop: 20, marginBottom: 20, width: 200 }}
                  onClick={this.editUser}
                >
                  Save
                </Button>
              </FormGroup>
            </Form>
            {/* <Button color="primary" onClick={this.editUser}>{t('edit')}</Button> */}

            <FormGroup>
              <Label for="balance">
                {/* {t('addBalance')} */}
                Add Balance
              </Label>
              <Input
                type="text"
                name="balance"
                id="balance"
                value={balance}
                onChange={this.handleChange}
              />
              <Button color="primary" onClick={this.addBalance}>Add Balance</Button>
            </FormGroup>

            <FormGroup>
              <Label for="totalPnts">
                {/* {t('addPoints')} */}
                Add Points
              </Label>
              <Input
                type="text"
                name="totalPnts"
                id="totalPnts"
                value={totalPnts}
                onChange={this.handleChange}
              />
              <Button color="primary" onClick={this.addPoints}>Add Points</Button>
            </FormGroup>
            <FormGroup>
              <Label for="birthday">Birthday</Label>
              <DateTimePicker
                format={constants.FORMAT_DATE_TYPE.slashFullDate}
                culture={i18n.language}
                time={false}
                onChange={this.changeBirthday}
                value={birthday}
                min={minDate}
                max={maxDate}
                // placeholder={t('common:home.datePlaceholder')}
                placeholder='dd/mm/yyyy'
              />
              <Button color="primary" onClick={this.handleEditUserDob}>Edit Birthday</Button>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.toggle}>Close</Button>
          </ModalFooter>
        </Modal>

        {/*
        <Modal container={'div > div'} isOpen={openModal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>
            Edit
          </ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup>
                <Label for="level">
                  Level
                </Label>
                <Input
                  type="text"
                  name="level"
                  id="level"
                  value={level}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="gamesPlayed">
                  Games Played
                </Label>
                <Input
                  type="text"
                  name="gamesPlayed"
                  id="gamesPlayed"
                  value={gamesPlayed}
                  onChange={this.handleChange}
                />
              </FormGroup>
            </Form>
            <Button color="primary" onClick={this.editUser}>{t('edit')}</Button>

            <FormGroup>
              <Label for="balance">
                Add Balance
              </Label>
              <Input
                type="text"
                name="balance"
                id="balance"
                value={balance}
                onChange={this.handleChange}
              />
              <Button color="primary" onClick={this.addBalance}>Add Balance</Button>
            </FormGroup>

            <FormGroup>
              <Label for="totalPnts">
                Add Points
              </Label>
              <Input
                type="text"
                name="totalPnts"
                id="totalPnts"
                value={totalPnts}
                onChange={this.handleChange}
              />
            </FormGroup>

          </ModalBody>
          <ModalFooter>
          </ModalFooter>
        </Modal> */}

        {gamesHistoryModalOpen ? (
          <UserGamesHistory fetchFunction={fetchUserGamesHistory} userUid={historyUid} closeFunc={this.closeGamesHistory} modalOpen={gamesHistoryModalOpen} type="games" history={userGamesHistory} />
        ) : (null)}

        {bilanceHistoryModalOpen ? (
          <UserHistory fetchFunction={fetchUserBalanceHistory} userUid={historyUid} closeFunc={this.closeBilanceHistory} modalOpen={bilanceHistoryModalOpen} type="balance" history={userBalanceHistory} />
        ) : (null)}

        {pointsHistoryModalOpen ? (
          <UserHistory fetchFunction={fetchUserPointsHistory} userUid={historyUid} closeFunc={this.closePointsHistory} modalOpen={pointsHistoryModalOpen} type="points" history={userPointsHistory} />
        ) : (null)}
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  userAchievements: state.admin.userAchievements || {},
});
const mapDispatchToProps = {
//  fetchUserAchievements: getUserAchievements,
  submitUserDobFuc: editUserDob,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('admin')(BotsList));

// export default withTranslation('admin')(BotsList);
