import React from 'react';
import PropTypes from 'prop-types';

import Media from 'reactstrap/lib/Media';
import { size } from 'lodash';

import { withTranslation } from 'react-i18next';
import isEqual from 'react-fast-compare';

import ScrollArea from 'react-scrollbar';
import ScrollAreaButtons from '../../UI/ScrollAreaButtons';

import coinImg from '../../../../images/coin.svg';

import ScoreTableRow from './ScoreTableRow';
// import EndResultPules from '../EndResultModal/EndResultPules';
// import EndResultFines from '../EndResultModal/EndResultFines';

class ScoreTable extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    player1ShortName: PropTypes.string,
    player2ShortName: PropTypes.string,
    player3ShortName: PropTypes.string,
    player1Uid: PropTypes.string,
    player2Uid: PropTypes.string,
    player3Uid: PropTypes.string,
    points: PropTypes.shape(),
    totalPnts: PropTypes.shape({
      player1: PropTypes.number,
      player2: PropTypes.number,
      player3: PropTypes.number,
      player4: PropTypes.number,
    }),
    party: PropTypes.number,
    bet: PropTypes.string,
    studentRoom: PropTypes.bool,
    gameType: PropTypes.string,
  };

  static defaultProps = {
    player1ShortName: null,
    player2ShortName: null,
    player3ShortName: null,
    player1Uid: null,
    player2Uid: null,
    player3Uid: null,
    points: {},
    totalPnts: {},
    party: null,
    bet: null,
    gameType: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      scrollPos: 0,
    };
  }

  componentDidMount() {
    this.scrollToBottom();
  }

  shouldComponentUpdate(nextProps) {
    if (!isEqual(nextProps, this.props)) {
      this.scrollToBottom();
      return true;
    }

    return false;
  }

  componentWillUnmount() {
    clearTimeout(this.timeoutID);
  }

  scrollToBottom = () => {
    if (this.pointsScrollbar) {
      this.timeoutID = setTimeout(() => {
        if (this.pointsScrollbar) {
          this.pointsScrollbar.scrollBottom();
        }
      }, 20);
    }
  };

  setLastRef = (el) => {
    this.lastRef = el;
  };

  updateScrollPos = (val) => {
    this.setState({ scrollPos: val.topPosition || 0 });
  }

  scroll = (newVal) => {
    this.pointsScrollbar.scrollYTo(newVal);
  }

  renderPoints = (key, index) => {
    const {
      points,
      playerNames,
      party,
    } = this.props;

    console.log('renderPoints points.rounds', { points, key });

    return (
      <tr
        key={key}
        className="score-table-row"
        ref={(el) => {
          if (party === index + 2) {
            this.lastRef = el;
          }
        }}
      >
        <ScoreTableRow
          player1={points.rounds[key].player1}
          player2={points.rounds[key].player2}
          player3={points.rounds[key].player3}
          player4={points.rounds[key].player4}
          pule={points.rounds[key].pule}
          player1Name={playerNames.player1 || ''}
          player2Name={playerNames.player2 || ''}
          player3Name={playerNames.player3 || ''}
          player4Name={playerNames.player4 || ''}
          index={points.rounds[key].id || null}
          roundIndex={index}
          pulesTitle={(index + 1) === size(points?.rounds) && points.rounds[key].id === '-'}
        />
      </tr>
    );
  };

  /*  renderMissedRound = () => {
    const {
      player1Uid,
      player2Uid,
      player3Uid,
      //  points,
      player1ShortName,
      player2ShortName,
      player3ShortName,
      party,
    } = this.props;

    if (closeReason && closeReason.playerUid) {
      return (
        <tr
          key="missed-row"
          className="score-table-row"
          ref={(el) => { this.lastRef = el; }}
        >
          <ScoreTableRow
            player1={closeReason.playerUid === player1Uid ? -16 : 4}
            player2={closeReason.playerUid === player2Uid ? -16 : 4}
            player3={closeReason.playerUid === player3Uid ? -16 : 4}
            pule={null}
            player1ShortName={player1ShortName || ''}
            player2ShortName={player2ShortName || ''}
            player3ShortName={player3ShortName || ''}
            index="-"
            roundIndex={party - 1}
          />
        </tr>
      );
    }
    return null;
  }; */

  render() {
    const {
      t,
      playerNames,
      //  totalPnts,
      points,
      bet,
    //  isEndResultTable,
    //  gameType,
    //  closeReason,
    } = this.props;

    console.log('ScoreTable points', { points });

    const { scrollPos } = this.state;

    //  let betRatio;

    //  if (bet) {
    //    betRatio = parseInt(bet.replace('1:', ''), 10);
    //  }
    let player1Pules = 0;
    let player2Pules = 0;
    let player3Pules = 0;
    let player4Pules = 0;

    !!points?.pules?.private && Object.keys(points?.pules?.private).map((key) => {
      if (points?.pules?.private[key] && points?.pules?.private[key].status === 'active') {
        if (points?.pules?.private[key].player === 'player1') {
          player1Pules = player1Pules + 1;
        } else if (points?.pules?.private[key].player === 'player2') {
          player2Pules = player2Pules + 1;
        } else if (points?.pules?.private[key].player === 'player3') {
          player3Pules = player3Pules + 1;
        } else if (points?.pules?.private[key].player === 'player4') {
          player4Pules = player4Pules + 1;
        }
      }
    })

    const betNumber = bet ? parseInt(bet.split(':')[1]) : 0;
    return (
      <div className="score-table" style={{ fontSize: 14 }}>
        <table className="full-width header-full">
          <colgroup>
            <col span="1" className="" />
          </colgroup>
          <thead className="full-width">
            <tr style={{ borderBottom: '1px solid #FFF' }}>
              <th style={{ width: '10%' }}>#</th>
              <th style={{ width: '20%' }}>
                {playerNames.player1 || ''}
              </th>
              <th style={{ width: '20%' }}>
                {playerNames.player2 || ''}
              </th>
              {playerNames.player3 ? (
                <th style={{ width: '20%' }}>
                  {playerNames.player3 || ''}
                </th>
              ) : (null)}
              {playerNames.player4 ? (
                <th style={{ width: '20%' }}>
                  {playerNames.player4 || ''}
                </th>
              ) : (null)}
              <th style={{ width: '10%' }}>
                Pules
              </th>
            </tr>
          </thead>
          <tbody />
        </table>
        <div style={{ maxHeight: 150, position: 'relative' }}>
          <ScrollAreaButtons
            scrollPos={scrollPos}
            show
            scroll={this.scroll}
            speed={30}
            rightOffset={-1}
            topOffset={-1}
            bottomOffset={-1}
            bgColor="#2d191a"
          />
          <ScrollArea
            speed={0.55}
            className="score-table-scroll-area"
            style={{ height: 150 }}
            contentClassName="score-table-scroll-content"
            verticalContainerStyle={{
              background: 'transparent',
              opacity: 1,
              width: 11,
              marginRight: 2,
            }}
            verticalScrollbarStyle={{
              background: '#fff',
              borderRadius: 1,
              width: 10,
            }}
            minScrollSize={25}
            horizontal={false}
            onScroll={this.updateScrollPos}
            ref={(el) => {
              this.pointsScrollbar = el;
            }}
          >
            <table style={{ width: '100%' }}>
              <thead />
              <tbody>
                {points && points.rounds && Object.keys(points.rounds).map(this.renderPoints)}
              </tbody>
            </table>
          </ScrollArea>
        </div>
        <table style={{ width: '100%', borderTop: '1px solid #FFF' }}>
          <thead />
          <tbody style={{ width: '100%' }}>
            {points?.pules?.private && (
              <tr>
                <th style={{ width: '10%' }}>
                  <div>Active Pules</div>
                </th>
                <th style={{ width: '20%' }}>
                  {player1Pules}
                </th>
                <th style={{ width: '20%' }}>
                  {player2Pules}
                </th>
                <th style={{ width: '20%' }}>
                  {player3Pules}
                </th>
                {playerNames.player4 ? (
                  <th style={{ width: '20%' }}>
                    {player4Pules}
                  </th>
                ) : (null)}
                <th style={{ width: '10%' }} />
              </tr>
            )}
            <tr>
              <th style={{ width: '10%' }}>
                <div>Points</div>
              </th>
              <th style={{ width: '20%' }}>
                {points.total ? points.total.player1 : ''}
              </th>
              <th style={{ width: '20%' }}>
                {points.total ? points.total.player2 : ''}
              </th>
              <th style={{ width: '20%' }}>
                {points.total ? points.total.player3 : ''}
              </th>
              {playerNames.player4 ? (
                <th style={{ width: '20%' }}>
                  {points.total ? points.total.player4 : ''}
                </th>
              ) : (null)}
              <th style={{ width: '10%' }} />
            </tr>
            <tr>
              <th style={{ width: '10%' }}>
                <div>Coins (won/lost)</div>
              </th>
              <th style={{ width: '20%' }}>
                {points.bal ? points.bal.player1 : ''}
              </th>
              <th style={{ width: '20%' }}>
                {points.bal ? points.bal.player2 : ''}
              </th>
              <th style={{ width: '20%' }}>
                {points.bal ? points.bal.player3 : ''}
              </th>
              {(points.bal && points.bal.player4) ? (
                <th style={{ width: '20%' }}>
                  {points.bal ? points.bal.player4 : ''}
                </th>
              ) : (null)}
              <th style={{ width: '10%' }} />
            </tr>
            <tr>
              <th style={{ width: '10%' }}>
                <div>Coins (penalty)</div>
              </th>
              <th style={{ width: '20%' }}>
                {points.penalty ? points.penalty.player1 : '0'}
              </th>
              <th style={{ width: '20%' }}>
                {points.penalty ? points.penalty.player2 : '0'}
              </th>
              <th style={{ width: '20%' }}>
                {points.penalty ? points.penalty.player3 : '0'}
              </th>
              {playerNames.player4 ? (
                <th style={{ width: '20%' }}>
                  {points.penalty ? points.penalty.player4 : '0'}
                </th>
              ) : (null)}
              <th style={{ width: '10%' }} />
            </tr>
            <tr>
              <th style={{ width: '10%' }}>
                <div>Coins (total)</div>
              </th>
              <th style={{ width: '20%' }}>
                {points.bal ? (points.penalty ? points.bal.player1 + points.penalty.player1 : points.bal.player1) : ''}
              </th>
              <th style={{ width: '20%' }}>
                {points.bal ? (points.penalty ? points.bal.player2 + points.penalty.player2 : points.bal.player2) : ''}
              </th>
              <th style={{ width: '20%' }}>
                {points.bal ? (points.penalty ? points.bal.player3 + points.penalty.player3 : points.bal.player3) : ''}
              </th>
              {playerNames.player4 ? (
                <th style={{ width: '20%' }}>
                  {points.bal ? (points.penalty ? points.bal.player4 + points.penalty.player4 : points.bal.player4) : ''}
                </th>
              ) : (null)}
              <th style={{ width: '10%' }} />
            </tr>
          </tbody>
        </table>
        {/*  <table className="score-table-sum">
          <thead />
          <tbody style={{ width: '100%' }}>
            <tr>
              <th className="score-table-sum-col-id">
                <div>
                  <Media src={coinImg} className="player-balance-coin" />
                </div>
              </th>
              <th className="score-table-points-col">
                {points.totalPnts && points.totalPnts.player2 && betRatio
                  ? betRatio * points.totalPnts.player2
                  : 0}
              </th>
              <th className="score-table-points-col">
                {points.totalPnts && points.totalPnts.player1 && betRatio
                  ? betRatio * points.totalPnts.player1
                  : 0}
              </th>
              <th className="score-table-points-col">
                {points.totalPnts && points.totalPnts.player3 && betRatio
                  ? betRatio * points.totalPnts.player3
                  : 0}
              </th>
              <th className="score-table-sum-col-pules" />
            </tr>
          </tbody>
        </table>  */}
      </div>
    );
  }
}

export default withTranslation('game')(ScoreTable);
