import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import isEqual from 'react-fast-compare';

import Col from 'reactstrap/lib/Col';
import Media from 'reactstrap/lib/Media';
import Button from 'reactstrap/lib/Button';

import pro from '../../../../images/icons/pro.svg';
import speed from '../../../../images/icons/fast_game.svg';
import lightning from '../../../../images/icons/fast_game_lightning.svg';
import unlimited from '../../../../images/redesign/common/Room types unlimited.svg';
import keyImg from '../../../../images/icons/private.svg';
import fourPImg from '../../../../images/icons/4_spēlētāji.svg';
import minGamesImg from '../../../../images/icons/min_games.svg';
import lockImg from '../../../../images/redesign/rooms-table/lock.svg';

const RoomsTableJoinedRender = React.memo(({
  joinedRoom, uid, leaveRoom, // activeTournament,
}) => {
  const { t } = useTranslation('common');
  if (joinedRoom && joinedRoom.key && joinedRoom.globalParams && joinedRoom.playersList) {
    return (
      <tr key={joinedRoom.key} className="rooms-table-row rooms-table-row-joined">
        <td className="rooms-table-col">
          {joinedRoom.globalParams.bet}
        </td>
        <td className="rooms-table-col">
          {joinedRoom.globalParams.gameType === 'P' ? (
            <Fragment>
              {joinedRoom.globalParams.smallGame ? (
                <div className="gameType">
                  <p className="gameType-text">
                    PM
                  </p>
                </div>
              ) : (
                <div className="gameType">
                  <p className="gameType-text">
                    P
                  </p>
                </div>
              )}
            </Fragment>
          ) : (
            <Fragment>
              {joinedRoom.globalParams.smallGame ? (
                <div className="gameType">
                  <p className="gameType-text">
                    MG
                  </p>
                </div>
              ) : (
                <div className="gameType">
                  <p className="gameType-text">
                    G
                  </p>
                </div>
              )}
            </Fragment>
          )}
        </td>
        <td className="rooms-table-col">
          {joinedRoom.globalParams.privateRoom && (
          <Fragment>
            <Media className="label-private" src={keyImg} alt="Privāta" />
          </Fragment>
          )}
        </td>
        <td className="rooms-table-col" style={{ padding: 0, paddingTop: 5, paddingLeft: 10 }}>
          {joinedRoom.globalParams.fastGame && (
            <Fragment>
              <Media className="label-speed" src={speed} alt="Ātrā" />
            </Fragment>
          )}
          {joinedRoom.globalParams.lightningGame && (
            <Fragment>
              <Media className="label-speed" src={lightning} alt="Ātrā" />
            </Fragment>
          )}
          {joinedRoom.globalParams.unlimitedGame && ( // TODO 'unlimitedGame' path might change
            <Fragment>
              <Media className="label-speed" src={unlimited} alt="Bez limita" />
            </Fragment>
          )}
        </td>
        <td className="rooms-table-col">
          {joinedRoom.globalParams.proGame && (
          <Fragment>
            <Media className="label-pro" src={pro} alt="Pro" />
          </Fragment>
          )}
        </td>
        <td className="rooms-table-col">
          <Fragment>

            <div className="minGames">
              <Media className="minGames-img" src={minGamesImg} alt="4 Spēlētāji" />
              <p className="minGames-text">
                {joinedRoom.globalParams.minGames ? (
                  joinedRoom.globalParams.minGames
                ) : (
                  '1'
                )}
              </p>
            </div>
          </Fragment>
        </td>
        <td className="rooms-table-col">
          {joinedRoom.globalParams.fourPRoom && (
          <Fragment>
            <Media className="label-pro" src={fourPImg} alt="4 Spēlētāji" />
          </Fragment>
          )}
        </td>

        <td className="rooms-table-col">
          {joinedRoom.playersList && (
          <Fragment>
            {joinedRoom.globalParams.fourPRoom ? (
              ['player1', 'player2', 'player3', 'player4'].map(pkey => (
                <Col xs="3" sm="3" key={`rtc-${pkey}`} className="rooms-table-col-player-4">
                  {joinedRoom.playersList[pkey] ? (
                    <div className="rooms-table-player-4">
                      <div className="rooms-table-player-4-lvl">
                        <div className="rooms-table-player-4-lvl-text">
                          {`${joinedRoom.playersList[pkey].lvl || 1}`}
                        </div>
                      </div>
                      <div className="rooms-table-player-4-name">
                        {`${joinedRoom.playersList[pkey].shortName || joinedRoom.playersList[pkey].name}`}
                      </div>
                    </div>
                  ) : (
                    <div className="rooms-table-player-4-waiting">
                      {(t('roomsTable.waiting'))}
                    </div>
                  )}
                </Col>
              ))) : (
              ['player1', 'player2', 'player3'].map(pkey => (
                <Col xs="4" sm="4" key={`rtc-${pkey}`} className="rooms-table-col-player-3">
                  {joinedRoom.playersList[pkey] ? (
                    <div className="rooms-table-player">
                      <div className="rooms-table-player-lvl">
                        <div className="rooms-table-player-lvl-text">
                          {`${joinedRoom.playersList[pkey].lvl || 1}`}
                        </div>
                      </div>
                      <div className="rooms-table-player-name">
                        {`${joinedRoom.playersList[pkey].shortName || joinedRoom.playersList[pkey].name}`}
                      </div>
                    </div>
                  ) : (
                    <div className="rooms-table-player-waiting">
                      {(t('roomsTable.waiting'))}
                    </div>
                  )}
                </Col>
              )))}
          </Fragment>
          )}
        </td>

        <td className="rooms-table-col">
          {((joinedRoom.playersList.player1 && joinedRoom.playersList.player1.uid === uid)
                    || (joinedRoom.playersList.player2 && joinedRoom.playersList.player2.uid === uid)
                    || (joinedRoom.playersList.player3 && joinedRoom.playersList.player3.uid === uid)
                    || (joinedRoom.playersList.player4 && joinedRoom.playersList.player4.uid === uid))
                    && (
                    <Fragment>
                      
                      {joinedRoom.playersList.player1 && joinedRoom.playersList.player2 && joinedRoom.playersList.player3
                        && (!joinedRoom.globalParams.fourPRoom || (joinedRoom.globalParams.fourPRoom && joinedRoom.playersList.player4)) ? (
                          <Link to={`/zole/${joinedRoom.key}`} className="join-room-button button btn">
                            {t('roomsTable.join')}
                          </Link>
                        ) : (
                          <Button color="link" disabled={joinedRoom.filled} className="join-room-button join-room-button-alreadyJoined" onClick={() => leaveRoom(joinedRoom.key)}>
                            {joinedRoom.globalParams.privateRoom && (
                              <Media className="join-room-button-privateKey" src={lockImg} alt="Privāta" />
                            )}
                            {t('roomsTable.leave')}
                          </Button>
                        )}
                    </Fragment>
                    )}
        </td>
      </tr>
    );
  }
  return null;

// })
}, (prevProps, nextProps) => {
  if (!isEqual(prevProps.joinedRoom, nextProps.joinedRoom)) {
    return false;
  }

  if (prevProps.uid !== nextProps.uid) {
    return false; // props are not equal -> update the component
  }

  //  if (prevProps.activeTournament !== nextProps.activeTournament) {
  //    return false; // props are equal
  //  }

  return true; // props are not equal -> update the component
});

RoomsTableJoinedRender.propTypes = {
  joinedRoom: PropTypes.shape(),
  uid: PropTypes.string,
  leaveRoom: PropTypes.func.isRequired,
};

RoomsTableJoinedRender.defaultProps = {
  joinedRoom: null,
  uid: null,
};

export default RoomsTableJoinedRender;
