import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import Col from 'reactstrap/lib/Col';

import { ErrorBoundary } from "react-error-boundary";

import ContactSupport from '../../ContactSupport/ContactSupport';
import { DWEB_PLATFORM } from '../../../../../constants/constants';
import { Tooltip } from 'reactstrap';

const BottomBar = (({
  t, supportChatStatus, toggleBottomBarTab, toggleSupport, supportModalOpen, screenMode, handleClickStart, platform
}) => {
  const footerRef1 = useRef(null);

  const handleStartJoyride = (e) => {
    handleClickStart(e);
  };

  return (
    <div className={`bottom-bar bottom-bar-${screenMode}`}>
      <Col sm="12" className="bottom-bar-links">
        <ErrorBoundary
          fallbackRender={null}
        >
          <a
            href="#"
            className="bottom-bar-links-help"
            onClick={() => toggleSupport()}
            id="footer-help-chat"
          >
            <span
              ref={footerRef1}
              className={`${supportChatStatus && supportChatStatus.read === false ? 'incoming-text' : ''}`}
            >
              {t('menu.help')}
            </span>
            <ContactSupport
              modalOpen={supportModalOpen}
              toggle={toggleSupport}
              screenMode={screenMode}
              platform={platform}
            />


              {(window.innerWidth >= 1280 && footerRef1.current) && (
                <Tooltip
                  isOpen={supportChatStatus && supportChatStatus.read === false}
                  className="player-receivedGift-tooltip-new-blink"
                  placement="top"
                  target="footer-help-chat"
                  container={'div > div'}
                >
                  {t('menu.newMessage')}
                </Tooltip>
            )}
          </a>
        </ErrorBoundary>
        <a href="#" onClick={(e) => handleStartJoyride(e)}>
          <span>
            {t('menu.tutorial')}
          </span>
        </a>
        <a href="#" onClick={() => toggleBottomBarTab('moneyHistory')}>
          <span>
            {t('menu.moneyHistory')}
          </span>
        </a>
        <a href="#" onClick={() => toggleBottomBarTab('pointsHistory')}>
          <span>
            {t('menu.pointsHistory')}
          </span>
        </a>
        <a href="#" onClick={() => toggleBottomBarTab('bannedUsers')}>
          <span>
            {t('menu.bannedUsers')}
          </span>
        </a>
        <a href="#" onClick={() => toggleBottomBarTab('helpPage')}>
          <span>
            {t('menu.helpPage')}
          </span>
        </a>
        <a href="#" onClick={() => toggleBottomBarTab('aboutPage')}>
          <span>
            {t('menu.aboutPage')}
          </span>
        </a>
        <a href="#" onClick={() => toggleBottomBarTab('termsOfUse')}>
          <span>
            {t('home.termsOfUse')}
          </span>
        </a>
        <a href="/landing" target='_blank'>
          <span>
            {t('menu.home')}
          </span>
        </a>
        <a href="/blog" target='_blank'>
          <span>
            {t('menu.blog')}
          </span>
        </a>
        <a href="/contacts" target='_blank'>
          <span>
            {t('menu.contacts')}
          </span>
        </a>
        <a href="/version-history" target='_blank'>
          <span>
            {t('menu.changelog')}
          </span>
        </a>
      </Col>
    </div>
  );
});


BottomBar.propTypes = {
  t: PropTypes.func.isRequired,
  toggleBottomBarTab: PropTypes.func.isRequired,
  toggleSupport: PropTypes.func.isRequired,
  supportChatStatus: PropTypes.shape(),
  supportModalOpen: PropTypes.bool,
  platform: PropTypes.string,
};

BottomBar.defaultProps = {
  supportChatStatus: {},
  supportModalOpen: false,
  platform: DWEB_PLATFORM,
};

export default BottomBar;
