import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { map, filter, size } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import {
  UncontrolledPopover, Button, Col, Form, FormGroup, Label, Media, Modal, ModalBody, ModalFooter, ModalHeader, Pagination, PaginationItem, PaginationLink, PopoverBody, Row, Input,
} from 'reactstrap/lib';
import { DateTimePicker } from 'react-widgets';
import DateFormat from '../../UI/DateFormat';
import { createFeedback, deleteFeedback, editFeedback, getFeedbacks, getFeedbacksResults } from '../../../../actions/admin';
import { CREATE, DELETE, EDIT, FEEDBACKVIEW, FORMAT_DATE_TYPE } from '../../../../constants/constants';
import closeImg from '../../../../images/icons/close.png';
import { FEEDBACK_PLATFORM, STATISTICS_DESIGN_MODE } from '../../../../constants/statisticsTypes';
import FeedbackHistory from './FeedbackHistory';

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    borderBottom: '1px dotted pink',
    color: state.isSelected ? 'red' : 'gray',
    padding: 10,
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';
    return { ...provided, opacity, transition };
  },
};

const MultipleFeedback = ({ showNotification }) => {
  const dispatch = useDispatch();
  const multipleFeedbacks = useSelector(state => state.admin.multipleFeedbacks || {});
  const multipleFeedbacksResults = useSelector(state => state.admin.multipleFeedbacksResults || {});

  const loading = useSelector(state => state.admin.multipleFeedbacksLoading || false);

  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(50);
  const [pageCount, setPageCount] = useState(0);

  const [modalType, setModalType] = useState('');

  const [selectedId, setSelectedId] = useState('');
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [selectedPlatforms, setSelectedPlatforms] = useState([]);
  const [selectedDesigns, setSelectedDesigns] = useState([]);
  const [devMode, setDevMode] = useState(false);
  const [dateError, setDateError] = useState(null);

  useEffect(() => {
    dispatch(getFeedbacks());
    dispatch(getFeedbacksResults());
  }, []);

  useEffect(() => {
    const count = Math.ceil(size(multipleFeedbacks) / pageSize);
    setPageCount(count);
  }, [multipleFeedbacks]);

  const toggleFeedbackHistoryView = (key) => {
    if (modalType === FEEDBACKVIEW) {
      setSelectedId('');
      setModalType('');
    } else {
      setModalType(FEEDBACKVIEW);
      setSelectedId(key);
    }
  };

  const toggleCreateModal = () => {
    if (modalType === CREATE) {
      setSelectedId('');
      setModalType('');
    } else {
      setModalType(CREATE);

      setStartDate(new Date());
      setEndDate(new Date());
      setSelectedDesigns([]);
      setSelectedPlatforms([]);
      setDevMode(true);
    }
  };

  const toggleEditModal = (key) => {
    if (modalType === EDIT) {
      setSelectedId('');
      setModalType('');
    } else {
      setModalType(EDIT);
      setSelectedId(key);

      setStartDate(new Date(multipleFeedbacks[key].startDate));
      setEndDate(new Date(multipleFeedbacks[key].endDate));
      setSelectedDesigns(multipleFeedbacks[key].designs);
      setSelectedPlatforms(multipleFeedbacks[key].platforms);
      setDevMode(multipleFeedbacks[key].devMode);
    }
  };

  const toggleDeleteModal = (key) => {
    if (modalType === DELETE) {
      setSelectedId('');
      setModalType('');
    } else {
      setModalType(DELETE);
      setSelectedId(key);
    }
  };

  const handleClick = (e, index) => {
    e.preventDefault();
    setCurrentPage(index);
  };

  const handleDeleteSurvey = () => {
    if (!selectedId) return showNotification('Error', 'Feedback Id does not exist.', 'danger');

    dispatch(deleteFeedback(selectedId)).then((res) => {
      if (res && res.status === 'success') {
        showNotification('Success', 'Feedback have been successfully deleted.', 'success');
      }

      if (res.status === 'error') {
        return showNotification('Error', res.message, 'danger');
      }

      setModalType('');
      setSelectedId('');
    });
  };

  const changeStartDate = (date) => {
    //if (Date.parse(date) >= Date.parse(endDate)) return showNotification('Error', 'Start Date was wrong.', 'danger');
    if (Date.parse(date) >= Date.parse(endDate)) return setDateError('start');
    setDateError(null);
    setStartDate(date);
  };

  const changeEndDate = (date) => {
    //if (Date.parse(date) <= Date.parse(startDate)) return showNotification('Error', 'End Date was wrong.', 'danger');
    if (Date.parse(date) <= Date.parse(startDate)) return setDateError('end');
    setDateError(null);
    setEndDate(date);
  };

  const handleChangeDesign = (design) => {
    setSelectedDesigns(design);
  };

  const handleChangePlatform = (platform) => {
    setSelectedPlatforms(platform);
  };

  const handleCreateFeedback = () => {
    if (!selectedPlatforms || size(selectedPlatforms) === 0) return showNotification('Error', 'Please select the platform', 'danger');
    if (!selectedDesigns || size(selectedDesigns) === 0) return showNotification('Error', 'Please select the design', 'danger');

    if (!startDate) return showNotification('Error!', "Please check Start date.", 'danger');
    if (!endDate) return showNotification('Error!', "Please check End date.", 'danger');
    if (/*Date.parse(startDate) >= Date.parse(endDate)*/dateError) {
      return showNotification('Error', 'End time cannot before start time', 'danger');
    }

    dispatch(createFeedback({
      startDate, endDate, platforms: selectedPlatforms, designs: selectedDesigns, devMode,
    })).then((res) => {
      if (res && res.status === 'success') {
        showNotification('Success', 'Feedback have been successfully created.', 'success');
      }

      if (res.status === 'error') {
        showNotification('Error', res.message, 'danger');
        return;
      }

      toggleCreateModal();
    }).catch(error => {
      showNotification('Error!', error.message, 'danger');
    });
  };

  const handleEditFeedback = () => {
    if (!selectedId) return;
    if (!selectedPlatforms || size(selectedPlatforms) === 0) {
      showNotification('Error', 'Please select the platform', 'danger');
      return;
    }
    if (!selectedDesigns || size(selectedDesigns) === 0) {
      showNotification('Error', 'Please select the design', 'danger');
      return;
    }
    if (!startDate) return showNotification('Error!', "Please check Start date.", 'danger');
    if (!endDate) return showNotification('Error!', "Please check End date.", 'danger');
    if (/*Date.parse(startDate) >= Date.parse(endDate)*/dateError) {
      return showNotification('Error', 'End time cannot before start time', 'danger');
    }

    dispatch(editFeedback({
      selectedId, startDate, endDate, platforms: selectedPlatforms, designs: selectedDesigns, devMode,
    })).then((res) => {
      if (res && res.status === 'success') {
        showNotification('Success', 'Changes have been successfully saved.', 'success');
      }

      if (res.status === 'error') {
        showNotification('Error', res.message, 'danger');
        return;
      }

      toggleEditModal();
    }).catch(error => {
      showNotification('Error!', error.message, 'danger');
    });
  };

  return (
    <Fragment>
      <UncontrolledPopover popperClassName="popover" placement="right" trigger="focus" target="admin-help-button-create-survey">
        <PopoverBody className="popover-body">
          {/* The admin can set up a feedback that is displayed to the players. The purpose is to get feedback about some particular questions, possible features, and similar things. */}
          The admin can start/end feedback events when a feedback modal is displayed for the players once per set time frame. The player is asked to rate the game with stars from 1 to 5 and also can leave a comment. The admin can trigger a conversation here in case any further feedback or details are needed.
        </PopoverBody>
      </UncontrolledPopover>
      <div className="admin-page-content">
        <h2>
          Feedback
          <Button className="admin-help-button" id="admin-help-button-create-survey">
            ?
          </Button>
        </h2>
        <div className="d-flex flex-column">
          <div
            className="mb-4"
            style={{
              width: '200px',
            }}
          >
            <Button color="secondary" onClick={() => toggleCreateModal()}>
              Create Feedback
            </Button>
          </div>
          <table style={{ width: '100%', fontSize: 12 }}>
            <colgroup>
              <col span="1" className="" />
            </colgroup>
            <thead>
              <tr>
                <th style={{ textAlign: 'center' }}>
                  Start Date
                </th>
                <th style={{ textAlign: 'center' }}>
                  End Date
                </th>
                <th style={{ textAlign: 'center' }}>
                  Platform
                </th>
                <th style={{ textAlign: 'center' }}>
                  Design
                </th>
                <th style={{ textAlign: 'center' }}>
                  Only Dev
                </th>
                <th style={{ textAlign: 'center' }}>
                  Average Rating
                </th>
                <th style={{ textAlign: 'center' }}>
                  Number of Responses
                </th>
                <th style={{ textAlign: 'center' }}>
                  Edit
                </th>
                <th style={{ textAlign: 'center' }}>
                  Delete
                </th>
                <th style={{ textAlign: 'center' }}>
                  View
                </th>
              </tr>
            </thead>
            <tbody>
              {multipleFeedbacks && Object.keys(multipleFeedbacks)
                .sort((a, b) => multipleFeedbacks[b].endDate - multipleFeedbacks[a].endDate)
                .slice(
                  currentPage * pageSize,
                  (currentPage + 1) * pageSize,
                )
                .map((key, index) => (
                  <Fragment key={key}>
                    <tr key={key} className={`allUsers-table-row ${index % 2 === 0 ? ('odd') : ('even')}`}>
                      <td className="allUsers-table-col">
                        <DateFormat formatType={FORMAT_DATE_TYPE.fullDateMinute} date={multipleFeedbacks[key].startDate} />
                      </td>
                      <td className="allUsers-table-col">
                        <DateFormat formatType={FORMAT_DATE_TYPE.fullDateMinute} date={multipleFeedbacks[key].endDate} />
                      </td>
                      <td className="allUsers-table-col">
                        <div className="d-flex flex-column">
                          {multipleFeedbacks[key].platforms && map(multipleFeedbacks[key].platforms, item => (
                            <span className="mb-2">{item.label}</span>
                          ))}
                        </div>
                      </td>
                      <td className="allUsers-table-col flex-column">
                        <div className="d-flex flex-column">
                          {multipleFeedbacks[key].designs && map(multipleFeedbacks[key].designs, item => (
                            <span className="mb-2">{item.label}</span>
                          ))}
                        </div>
                      </td>
                      <td className="allUsers-table-col">
                        {multipleFeedbacks[key].devMode ? 'Yes' : 'No'}
                      </td>
                      <td className="allUsers-table-col">
                        {multipleFeedbacksResults[key] ? (multipleFeedbacksResults[key].total / multipleFeedbacksResults[key].count).toFixed(2) : '-'}
                      </td>
                      <td className="allUsers-table-col">
                        {multipleFeedbacksResults[key] ? multipleFeedbacksResults[key].count : '-'}
                      </td>
                      <td className="allUsers-table-col">
                        <Button color="primary" onClick={() => toggleEditModal(key)}>
                          Edit
                        </Button>
                      </td>
                      <td className="allUsers-table-col">
                        <Button color="primary" onClick={() => toggleDeleteModal(key)}>
                          Delete
                        </Button>
                      </td>
                      <td className="allUsers-table-col">
                        <Button color="primary" onClick={() => toggleFeedbackHistoryView(key)}>
                          View
                        </Button>
                      </td>
                    </tr>
                  </Fragment>
                ))}
              {pageCount && Object.keys(multipleFeedbacks).length > pageSize && (
                <div className="pagination-wrapper">
                  <Pagination aria-label="Page navigation example">
                    <PaginationItem disabled={currentPage === 0}>
                      <PaginationLink style={{ color: '#000' }} onClick={e => handleClick(e, 0)} href="#">
                        {`‹‹‹`}
                      </PaginationLink>
                    </PaginationItem>

                    <PaginationItem disabled={currentPage <= 0}>
                      <PaginationLink
                        style={{ color: '#000' }}
                        onClick={e => handleClick(e, currentPage - 1)}
                        previous
                        href="#"
                      />
                    </PaginationItem>

                    {[...Array(pageCount)].map((page, i) => {
                      if (i > currentPage - 3 && i < currentPage + 3) {
                        return (
                          <PaginationItem active={i === currentPage} key={page}>
                            <PaginationLink style={{ color: '#000' }} onClick={e => handleClick(e, i)} href="#">
                              {i + 1}
                            </PaginationLink>
                          </PaginationItem>
                        );
                      }

                      return null;
                    })}

                    <PaginationItem disabled={currentPage >= pageCount - 1}>
                      <PaginationLink
                        style={{ color: '#000' }}
                        onClick={e => handleClick(e, currentPage + 1)}
                        next
                        href="#"
                      />
                    </PaginationItem>

                    <PaginationItem disabled={currentPage >= pageCount - 1}>
                        <PaginationLink style={{ color: '#000' }} onClick={e => handleClick(e, pageCount - 1)} href="#">
                          {`›››`}
                        </PaginationLink>
                      </PaginationItem>
                  </Pagination>
                </div>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <Modal container={'div > div'} isOpen={modalType === DELETE} toggle={() => toggleDeleteModal()} className="notification">
        <ModalHeader
          toggle={() => toggleDeleteModal()}
          className="notification-header"
          close={
            <Media src={closeImg} className="notification-header-close" alt="X" onClick={() => toggleDeleteModal()} />
          }
        >
          Delete Feedback
        </ModalHeader>
        <ModalBody className="notification-body">
          Do you really want to delete this feedback?
        </ModalBody>
        <ModalFooter className="notification-footer">
          <Button className="btn notification-footer-button" disabled={loading} onClick={() => handleDeleteSurvey()}>
            {loading ? 'Loading' : 'Yes'}
          </Button>
          <Button type="button" className="btn notification-footer-button" disabled={loading} onClick={() => toggleDeleteModal()}>
            {loading ? 'Loading' : 'No'}
          </Button>
        </ModalFooter>
      </Modal>
      <Modal container={'div > div'} isOpen={modalType === CREATE} toggle={() => toggleCreateModal()} className="notification">
        <ModalHeader
          toggle={() => toggleCreateModal()}
          className="notification-header"
          close={
            <Media src={closeImg} className="notification-header-close" alt="X" onClick={() => toggleCreateModal()} />
          }
        >
          Create Feedback
        </ModalHeader>
        <ModalBody className="notification-body">
          <Form className="form-style">
            <FormGroup>
              <Label for="startDate">
                Start Date
              </Label>
              <Row>
                <Col md="12">
                  <DateTimePicker
                    format="DD.MM.YYYY HH:mm"
                    culture="lv"
                    onChange={changeStartDate}
                    value={startDate}
                    disabled={loading}
                    defaultValue={new Date()}
                  />
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Label for="endDate">
                End Date
              </Label>
              <Row>
                <Col md="12">
                  <DateTimePicker
                    format="DD.MM.YYYY HH:mm"
                    culture="lv"
                    onChange={changeEndDate}
                    value={endDate}
                    maxLength={100}
                    disabled={loading}
                    defaultValue={new Date()}
                  />
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Label for="designs">
                Platforms
              </Label>
              <Select closeMenuOnSelect={false} options={FEEDBACK_PLATFORM} isMulti styles={customStyles} value={selectedPlatforms} onChange={handleChangePlatform} />
            </FormGroup>
            <FormGroup>
              <Label for="designs">
                Designs
              </Label>
              <Select closeMenuOnSelect={false} options={STATISTICS_DESIGN_MODE} isMulti styles={customStyles} value={selectedDesigns} onChange={handleChangeDesign} />
            </FormGroup>
            <FormGroup>
              <Row>
                <Col sm="12">
                  <Label className="event-type-input d-flex" style={{ marginLeft: 40 }}>
                    <Input type="checkbox" onClick={() => setDevMode(!devMode)} disabled={loading} checked={devMode} readOnly />
                    <span className="checkmark" />
                    <div className="event-type-text">
                      Only Dev
                    </div>
                  </Label>
                </Col>
              </Row>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter className="notification-footer">
          <Button className="btn notification-footer-button" disabled={loading} onClick={() => handleCreateFeedback()}>
            {loading ? 'Loading' : 'Save'}
          </Button>
          <Button type="button" className="btn notification-footer-button" onClick={() => toggleCreateModal()} disabled={loading}>
            {loading ? 'Loading' : 'Cancel'}
          </Button>
        </ModalFooter>
      </Modal>
      <Modal container={'div > div'} isOpen={modalType === EDIT} toggle={() => toggleEditModal()} className="notification">
        <ModalHeader
          toggle={() => toggleEditModal()}
          className="notification-header"
          close={
            <Media src={closeImg} className="notification-header-close" alt="X" onClick={() => toggleEditModal()} />
          }
        >
          Edit Feedback
        </ModalHeader>
        <ModalBody className="notification-body">
          <Form className="form-style">
            <FormGroup>
              <Label for="startDate">
                Start Date
              </Label>
              <Row>
                <Col md="12">
                  <DateTimePicker
                    format="DD.MM.YYYY HH:mm"
                    culture="lv"
                    onChange={changeStartDate}
                    value={startDate}
                    disabled={loading}
                    defaultValue={new Date()}
                  />
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Label for="endDate">
                End Date
              </Label>
              <Row>
                <Col md="12">
                  <DateTimePicker
                    format="DD.MM.YYYY HH:mm"
                    culture="lv"
                    onChange={changeEndDate}
                    value={endDate}
                    disabled={loading}
                    defaultValue={new Date()}
                  />
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Label for="designs">
                Platforms
              </Label>
              <Select closeMenuOnSelect={false} options={FEEDBACK_PLATFORM} isMulti styles={customStyles} value={selectedPlatforms} onChange={handleChangePlatform} />
            </FormGroup>
            <FormGroup>
              <Label for="designs">
                Designs
              </Label>
              <Select closeMenuOnSelect={false} options={STATISTICS_DESIGN_MODE} isMulti styles={customStyles} value={selectedDesigns} onChange={handleChangeDesign} />
            </FormGroup>
            <FormGroup>
              <Row>
                <Col sm="12">
                  <Label className="event-type-input d-flex" style={{ marginLeft: 40 }}>
                    <Input type="checkbox" onClick={() => setDevMode(!devMode)} disabled={loading} checked={devMode} readOnly />
                    <span className="checkmark" />
                    <div className="event-type-text">
                      Only Dev
                    </div>
                  </Label>
                </Col>
              </Row>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter className="notification-footer">
          <Button className="btn notification-footer-button" disabled={loading} onClick={() => handleEditFeedback()}>
            {loading ? 'Loading' : 'Save'}
          </Button>
          <Button type="button" className="btn notification-footer-button" onClick={() => toggleEditModal()} disabled={loading}>
            {loading ? 'Loading' : 'Cancel'}
          </Button>
        </ModalFooter>
      </Modal>
      <Modal container={'div > div'} isOpen={modalType === FEEDBACKVIEW} size="xlg" toggle={() => toggleFeedbackHistoryView()} className="notification">
        <ModalHeader
          toggle={() => toggleFeedbackHistoryView()}
          className="notification-header"
          close={
            <Media src={closeImg} className="notification-header-close" alt="X" onClick={() => toggleFeedbackHistoryView()} />
          }
        >
          Feedback History
        </ModalHeader>
        <ModalBody className="notification-body">
          <FeedbackHistory selectedId={selectedId} feedback={multipleFeedbacks[selectedId]} showNotification={showNotification} />
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

MultipleFeedback.propTypes = {
  showNotification: PropTypes.func.isRequired,
};

MultipleFeedback.defaultProps = {
};

export default MultipleFeedback;
