import { TOURNAMENT_STATE } from "../constants/constants";
import i18n from 'i18next';

export const getTournamentType = (tournament, t) => {
  if (!tournament) return 'ERR!';
  let tempStr = '';

  if (tournament?.tournamentType === 'bronze') {
    tempStr += t('roomsTable.bronze')  + ', '
  } else if (tournament?.tournamentType === 'silver') {
    tempStr += t('roomsTable.silver')  + ', '
  } else if (tournament?.tournamentType === 'gold') {
    tempStr += t('roomsTable.gold')  + ', '
  }

  tempStr += getTournamentTypeSimple(tournament, t) + ', '
  if (tournament.gameType === 'P') {
    if (tournament.smallGame) {
      tempStr += t('roomsTable.small') + ', '
    }
    tempStr += t('roomsTable.regular');
  } else if (tournament.gameType === 'PM') {
    tempStr += t('roomsTable.small') + ', ' + t('roomsTable.regular');
  } else if (tournament.gameType === 'G') {
    if (tournament.smallGame) {
      tempStr += t('roomsTable.galdsMaza');
    } else {
      tempStr += t('roomsTable.table');
    }
  } else if (tournament.gameType === 'MG') {
    tempStr += t('roomsTable.galdsMaza');
  }
  return tempStr;
}

export const getTournamentTitle = (tournament) => {
  if (!tournament) return '';

  if (!tournament && !tournament.name && !tournament.titleLV && !tournament.titleEN && !tournament.titleRU) return 'ERR!';

  const language = i18n.language || 'en';
  if (language == 'en' && tournament.titleEN) {
    return tournament.titleEN;
  }
  else if (language == 'lv' && tournament.titleLV) {
    return tournament.titleLV;
  }
  else if (language == 'ru' && tournament.titleRU) {
    return tournament.titleRU;
  }
  return tournament.name;
}

export const getTournamentTypeSimple = (tournament, t) => {
  if (!tournament) return 'ERR!';
  let tempStr = '';

  if (tournament.privateTournament) tempStr += t('tournaments.private') + ', ';

  if (tournament.fastGame) tempStr += t('tournaments.fast');
  else if (tournament.lightningGame) tempStr += t('tournaments.lightning');
  else if (tournament.unlimitedGame) tempStr += t('tournaments.unlimited');
  else tempStr += t('tournaments.slow');

  return tempStr;
}

export const getTournamentTypeEvent = (tournament, t) => {
  if (!tournament) return 'ERR!';

  let tempStr = '';

  if (tournament?.tournamentType === 'bronze') {
    tempStr += t('roomsTable.bronze')  + ', ';
  } else if (tournament?.tournamentType === 'silver') {
    tempStr += t('roomsTable.silver')  + ', ';
  } else if (tournament?.tournamentType === 'gold') {
    tempStr += t('roomsTable.gold')  + ', ';
  }

  if (tournament.gameType === 'P') {
    tempStr += t('roomsTable.regular') + ', ';
  } else if (tournament.gameType === 'G') {
    tempStr += t('roomsTable.table') + ', ';
  }

  if (tournament.smallGame) {
    tempStr += t('roomsTable.small') + ', ';
  }

  if (tournament.fastGame) tempStr += t('tournaments.fast');
  else if (tournament.lightningGame) tempStr += t('tournaments.lightning');
  else if (tournament.unlimitedGame) tempStr += t('tournaments.unlimited');
  else tempStr += t('tournaments.slow');

  if (tournament.privateTournament) tempStr += ', ' + t('tournaments.private');
  return tempStr;
}

export const getFutureTournaments = (tournaments) => {
  return tournaments && tournaments.length && tournaments.filter(tournament => (tournament.currentState !== TOURNAMENT_STATE.play && tournament.currentState !== TOURNAMENT_STATE.pause)).reverse();
}

export const getActiveTournaments = (tournaments) => {
  return tournaments && tournaments.length && tournaments.filter(tournament => (tournament.currentState === TOURNAMENT_STATE.play || tournament.currentState === TOURNAMENT_STATE.pause));
}

export const getMyActiveTournaments = (activeTournaments, myTournamentsData) => {
  return activeTournaments && activeTournaments.length && activeTournaments.filter && activeTournaments.filter(tournament => myTournamentsData && tournament.id === myTournamentsData?.tournamentId);
}

export const getOtherActiveTournaments = (activeTournaments, myTournamentsData) => {
  return activeTournaments && activeTournaments.length && activeTournaments.filter && activeTournaments.filter(tournament => myTournamentsData && tournament.id !== myTournamentsData?.tournamentId);
}

export const getTournamentById = (tournaments, id) => {
  if (!tournaments || !id) {
    return null;
  }
  return tournaments.find(tournament => tournament.id === id);
}

//modal before the first tournament round is completed (registration+1st round) - alias ‘Before’
//modal after the first tournament round is completed and until the tournament ending (begins when 1st round results is displayed) alias ‘Active’
//modal after tournament ending (when all tournament rooms are finished) - alias ‘Completed’
//subtype of the ‘Completed’ is a modal displayed for the tournament players immediately after tournament ending - alias ‘Congrats’
export const getTournamentPlayersAlias = (tournament) => {
  if (tournament && tournament.currentState) {
    if (tournament.currentState === 'registration' || (tournament.currentState === 'play' && tournament.currentRound === 1)) {
      return 'before';
    }
    if (tournament.currentState === 'play' || tournament.currentState === 'pause') {
      return 'active';
    }
  }
  return 'complete';
}

export const getTournamentPlayersTitle = (tournamentId, tournaments, t) => {
  const tournament = getTournamentById(tournaments, tournamentId);
  const alias = getTournamentPlayersAlias(tournament);
  if (alias === 'before') {
    return t('tournaments.participants');
  }
  if (alias === 'active') {
    return t('tournaments.standings', { roundNumber: tournament.currentRound - 1 });
  }
  return t('tournaments.complete');
}
