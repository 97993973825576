import { filter, size } from 'lodash';
import React, { Fragment } from 'react';
import Moment from 'react-moment';
import {
  Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row,
} from 'reactstrap';

const TournamentResults = ({
  isOpen,
  toggle,
  tournamentResults,
  bots,
}) => (
  <Modal container={'div > div'} size="xl" className="tournaments-results-modal" isOpen={isOpen} toggle={toggle}>
    <ModalHeader toggle={toggle}>
      Results
    </ModalHeader>
    <ModalBody>
      {tournamentResults && size(tournamentResults) > 0 && (
      <div className="tournaments-results">
        <Row className="tournaments-results-header-admin text-center">
          <Col className="tournaments-results-room-id">
            Room Id
          </Col>
          <Col sm="1" className="tournaments-results-room-id">
            Start time
          </Col>
          <Col sm="1" className="tournaments-results-room-id">
            End Time
          </Col>
          <Col className="tournaments-results-room-id">
            Player 1
          </Col>
          <Col className="tournaments-results-room-id">
            Player 2
          </Col>
          <Col className="tournaments-results-room-id">
            Player 3
          </Col>
          <Col className="tournaments-results-room-id">
            Player 4
          </Col>
        </Row>
        <Row className="tournaments-results-body-admin">
          <Col sm="12">
            {tournamentResults.map((round, index) => {
              if (index === 0) {
                return null;
              }

              return (
                <Fragment>
                  <Row className={`tournaments-results-round ${index % 2 === 0 ? ('odd') : ('even')}`}>
                    <Col sm="12" className="tournaments-results-round-text-admin">
                      {`Round ${index}`}
                    </Col>
                    <Col sm="12" className="tournaments-results-col">
                      {Object.keys(round).map((key) => {
                        let playersInRound = 0;

                        return (
                          <Fragment>
                            {round[key] && round[key].result && (
                            <Row key={key} className={`tournaments-results-room-row ${index % 2 === 0 ? ('odd') : ('even')}`}>
                              <Col className="tournaments-results-room-id">
                                {key}
                              </Col>
                              <Col sm="1" className="tournaments-results-room-id">
                                {round[key].result.startTime ? (
                                  <Moment format="DD-MM-YYYY HH:mm" locale="lv">
                                    {round[key].result.startTime}
                                  </Moment>
                                ) : (null)}
                              </Col>
                              <Col sm="1" className="tournaments-results-room-id">
                                {round[key].result.closeTime ? (
                                  <Moment format="DD-MM-YYYY HH:mm" locale="lv">
                                    {round[key].result.closeTime}
                                  </Moment>
                                ) : (null)}
                              </Col>
                              {console.log('round[key].result', round[key].result, bots)}
                              {Object.keys(round[key].result).map((key2) => {
                                if (key2 === 'startTime' || key2 === 'closeTime') {
                                  return null;
                                }
                                playersInRound += 1;

                                console.log('playersInRound', playersInRound);
                                return (
                                  <Col className="tournaments-results-room-player">
                                    <div className={`tournaments-results-room-player-name ${round[key].result[key2].disqualified ? 'tournaments-results-room-player-disqualified' : ''}`}>
                                      {`${size(filter(bots, item => key2 === item.uid)[0]) > 0 ? '(B)' : ''} ${round[key].result[key2].name}`}
                                    </div>
                                    <div className="tournaments-results-room-player-tourPoints d-flex flex-row">
                                      <span className="mr-2">{`Tourn. points: ${round[key].result[key2].tournamentPoints}`}</span>
                                      <span>{`Room points: ${round[key].result[key2].roomPoints}`}</span>
                                    </div>
                                    {/* <div className="tournaments-results-room-player-roomPoints">
                                                                                    {`Room points: ${round[key].result[key2].roomPoints}`}
                                                                                </div> */}
                                    {round[key].result[key2].endBal && round[key].result[key2].startBal && (
                                    <div className="tournaments-results-room-player-roomPoints">
                                      {`Coins: ${round[key].result[key2].endBal - round[key].result[key2].startBal}`}
                                    </div>
                                    )}

                                    {round[key].result[key2].autoPlayCount && (
                                    <div className="tournaments-results-room-player-roomPoints">
                                      <span>{`Auto Play: ${round[key].result[key2].autoPlayCount}`}</span>
                                    </div>
                                    )}
                                    {round[key].result[key2].disqualified ? (
                                      'disqualified'
                                    ) : (
                                      null
                                    )}
                                  </Col>
                                );
                              })}
                              {playersInRound <= 3 ? (
                                <Col className="tournaments-results-room-id" />
                              ) : (null)}
                            </Row>
                            )}
                          </Fragment>
                        );
                      })}
                    </Col>
                  </Row>
                </Fragment>
              );
            })}
          </Col>
        </Row>
      </div>
      )}
    </ModalBody>
    <ModalFooter>
      <Button color="secondary" onClick={toggle}>Close</Button>
    </ModalFooter>
  </Modal>
);

export default TournamentResults;
