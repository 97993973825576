import React, {
  Fragment, use, useEffect, useRef, useState,
} from 'react';
import {
  Button, Col, Form, FormGroup, Input, Label, Media, Modal, ModalBody, ModalFooter, ModalHeader, Pagination, PaginationItem, PaginationLink, PopoverBody, Row, UncontrolledPopover,
} from 'reactstrap';
import Select from 'react-select';

import { add, set, size } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import pencilImg from '../../../images/icons/pencil.svg';
import checkImg from '../../../images/icons/check.png';
import cancelImg from '../../../images/icons/close_old.png';
import closeImg from '../../../images/icons/close.png';
import FileUploadImage from './components/FileUploadImage';
import { addNewAnnouncement, deleteAnnouncement, editAnnouncement, getAnnouncements, supportMessageFileUpload } from '../../../actions/admin';
import {
  DESIGN_TYPE, ENV_MODE, FORMAT_DATE_TYPE, PLATFORMS_TYPE, RECEIVER_TYPE,
} from '../../../constants/constants';
import DateFormat from '../UI/DateFormat';

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    borderBottom: '1px dotted pink',
    color: state.isSelected ? 'red' : 'gray',
    padding: 10,
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';
    return { ...provided, opacity, transition };
  },
};

const Announcements = ({ showNotification }) => {
  const dispatch = useDispatch();
  const hiddenFileInput = useRef(null);

  const allAnnouncements = useSelector(state => state.admin.announcements);

  const [deleteModal, setDeleteModal] = useState(false);
  const [newOpenModal, setNewOpenModal] = useState(false);
  const [editOpenModal, setEditOpenModal] = useState(false);

  const [selectedId, setSelectedId] = useState('');

  const [lvTitle, setLvTitle] = useState('');
  const [enTitle, setEnTitle] = useState('');
  const [ruTitle, setRuTitle] = useState('');

  const [lvAnnouncement, setLvAnnouncement] = useState('');
  const [enAnnouncement, setEnAnnouncement] = useState('');
  const [ruAnnouncement, setRuAnnouncement] = useState('');

  const [preview, setPreview] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [showCrop, setShowCrop] = useState(false);
  const [ableToSave, setAbleToSave] = useState(false);
  const [loading, setLoading] = useState(false);
  const [uploadFile, setuploadFile] = useState(null);

  const [selectedPlatforms, setSelectedPlatforms] = useState([]);
  const [selectedDesigns, setSelectedDesigns] = useState([]);
  const [selectedEnvironments, setSelectedEnvironments] = useState([]);
  const [selectedReceivers, setSelectedReceivers] = useState([]);

  const [currentPage, setCurrentPage] = useState(0);
  const pageSize = 50;
  const pagesCount = Math.ceil(size(allAnnouncements).length / pageSize);

  useEffect(() => {
    dispatch(getAnnouncements());
  }, []);

  const toggleNewOpenModal = () => {
    if (!newOpenModal) {
      setLvTitle('');
      setEnTitle('');
      setRuTitle('');

      setLvAnnouncement('');
      setEnAnnouncement('');
      setRuAnnouncement('');

      setImageUrl('');
      setPreview('');

      setSelectedPlatforms([]);
      setSelectedDesigns([]);
      setSelectedEnvironments([]);
      setSelectedReceivers([]);
    }
    setNewOpenModal(!newOpenModal);
  };
  const toggleEditModal = (id) => {
    if (!editOpenModal && id) {
      const announcement = allAnnouncements[id];
      setLvTitle(announcement.title.lv);
      setEnTitle(announcement.title.en);
      setRuTitle(announcement.title.ru);

      setLvAnnouncement(announcement.announcement.lv);
      setEnAnnouncement(announcement.announcement.en);
      setRuAnnouncement(announcement.announcement.ru);

      setImageUrl(announcement.imageUrl);
      setPreview(announcement.imageUrl);

      setSelectedPlatforms(announcement.platforms);
      setSelectedDesigns(announcement.designs);
      setSelectedEnvironments(announcement.environments);
      setSelectedReceivers(announcement.receivers);

      setSelectedId(id);
    } else {
      setSelectedId('');
    }
    setEditOpenModal(!editOpenModal);
  };
  const toggleDeleteModal = (id) => {
    if (!deleteModal) {
      setSelectedId(id);
    } else {
      setSelectedId('');
    }
    setDeleteModal(!deleteModal)
  };

  const handleImageClick = () => {
    hiddenFileInput.current.click();
  };

  const _handleImageChange = (e) => {
    e.preventDefault();

    try {
      const typeList = 'image/jpg,image/png,image/jpeg, image/pjp, image/pjpeg, image/jfif';
      const reader = new FileReader();
      const file = e.target.files[0];

      if (!typeList.includes(file.type) || file.type === '') {
        showNotification('Error!', 'Unsupported file!', 'danger');
        return e.target.value === '';
      }

      if (file.size / 1024 > 3072) {
        showNotification('Warning', "You can't upload more than 3MB", 'warning');
        return e.target.value === '';
      }

      reader.onloadend = () => {
        setPreview(reader.result);
        setuploadFile(file);
      };
      reader.readAsDataURL(file);
    } catch (err) {
      showNotification('Error!', err.message, 'danger');

      return e.target.value === '';
    }
  };

  const uploadCampaignFile = () => {
    setAbleToSave(true);

    if (uploadFile !== null) {
      dispatch(supportMessageFileUpload(uploadFile)).then((data) => {
        setImageUrl(data.url);
        setAbleToSave(false);
      }).catch(() => {
        setImageUrl(null);
        setAbleToSave(false);
      });
    }
  };


  const handleDeleteAnnouncement = () => {
    if (selectedId !== '') {
      dispatch(deleteAnnouncement(selectedId)).then((res) => {
        if (res && res.status === 'success') {
          showNotification('Success', 'Announcement has been deleted successfully', 'success');
        }

        if (res.status === 'error') {
          return showNotification('Error', res.message, 'danger');
        }
        setDeleteModal(false);
        setSelectedId('');
      });
    }
  };

  const handlePreviewPhoto = (previewImg) => {
    setPreview(previewImg);
  };

  const handleReSetImage = () => {
    setPreview('');
    setImageUrl('');
  };

  const handleReSetPreviewImage = () => {
    setPreview(null);
  };

  const handleEditPhoto = (value) => {
    setShowCrop(!value);
  };

  const handleNewAnnouncement = () => {
    if (lvTitle === '' || enTitle === '' || ruTitle === '' || lvAnnouncement === '' || enAnnouncement === '' || ruAnnouncement === '' || imageUrl === '' || selectedPlatforms.length === 0 || selectedDesigns.length === 0 || selectedEnvironments.length === 0 || selectedReceivers.length === 0) {
      return showNotification('Error', 'Please fill all fields', 'danger');
    }
    const date = new Date();

    const data = {
      title: {
        lv: lvTitle,
        en: enTitle,
        ru: ruTitle,
      },
      announcement: {
        lv: lvAnnouncement,
        en: enAnnouncement,
        ru: ruAnnouncement,
      },
      imageUrl,
      platforms: selectedPlatforms,
      designs: selectedDesigns,
      receivers: selectedReceivers,
      environments: selectedEnvironments,
      date,
    };

    dispatch(addNewAnnouncement(data)).then((res) => {
      if (res && res.status === 'success') {
        setNewOpenModal(false);
        showNotification('Success', 'Announcement has been added successfully', 'success');
        return;
      }

      if (res.status === 'error') {
        showNotification('Error', res.message, 'danger');
        return;
      }
    });
  };

  const handleEditAnnouncement = () => {
    console.log({selectedId}, {lvTitle}, {enTitle}, {ruTitle}, {lvAnnouncement}, {enAnnouncement}, {ruAnnouncement}, {imageUrl}, {selectedPlatforms}, {selectedDesigns}, {selectedEnvironments}, {selectedReceivers}, "check edit announcement")
    if (selectedId === '' || lvTitle === '' || enTitle === '' || ruTitle === '' || lvAnnouncement === '' || enAnnouncement === '' || ruAnnouncement === '' || imageUrl === '' || selectedPlatforms.length === 0 || selectedDesigns.length === 0 || selectedEnvironments.length === 0 || selectedReceivers.length === 0) {
      return showNotification('Error', 'Please fill all fields', 'danger');
    }
    const data = {
      selectedId,
      title: {
        lv: lvTitle,
        en: enTitle,
        ru: ruTitle,
      },
      announcement: {
        lv: lvAnnouncement,
        en: enAnnouncement,
        ru: ruAnnouncement,
      },
      imageUrl,
      platforms: selectedPlatforms,
      designs: selectedDesigns,
      receivers: selectedReceivers,
      environments: selectedEnvironments,
      date: new Date(allAnnouncements[selectedId].date)
    };

    dispatch(editAnnouncement(data)).then((res) => {
      if (res && res.status === 'success') {
        showNotification('Success', 'Announcement has been edited successfully', 'success');
        return;
      }

      if (res.status === 'error') {
        showNotification('Error', res.message, 'danger');
        return;
      }

      setEditOpenModal(false);
    });
  };

  const handleClick = (e, index) => {
    e.preventDefault();
    setCurrentPage(index);
  };

  return (
    <Fragment>
      <UncontrolledPopover popperClassName="popover" placement="right" trigger="focus" target="admin-help-button-events-banner">
        <PopoverBody className="popover-body">
          The administration can create and overview the announcements to show a announcement modal for the players. It has to have a title and description in all listed languages, an image, platforms, designs, environments, and receivers.
        </PopoverBody>
      </UncontrolledPopover>
      <div className="admin-page-content">
        <Row>
          <Col sm="4">
            <h2>
              Announcements
              <Button className="admin-help-button" id="admin-help-button-events-banner">
                ?
              </Button>
            </h2>
          </Col>
          <Col sm="4">
            <Button color="primary" onClick={() => toggleNewOpenModal()}>
              Add Announcement
            </Button>
          </Col>
        </Row>
        <table className="w-full fs-4">
          <colgroup>
            <col span="1" className="" />
          </colgroup>
          <thead>
            <tr>
              <th className="text-center">
                Title
              </th>
              <th className="text-center">
                Date/Time
              </th>
              <th className="text-center">
                Platforms
              </th>
              <th className="text-center">
                Designs
              </th>
              <th className="text-center">
                Environments
              </th>
              <th className="text-center">
                Edit
              </th>
              <th className="text-center">
                Delete
              </th>
            </tr>
          </thead>
          <tbody>
            <Fragment>
              {allAnnouncements && size(allAnnouncements) > 0 ? Object.keys(allAnnouncements)
                .sort((a, b) => allAnnouncements[b].date - allAnnouncements[a].date)
                .slice(
                  currentPage * pageSize,
                  (currentPage + 1) * pageSize,
                )
                .map((key, index) => (
                  <Fragment key={key}>
                    <tr key={key} className={`allUsers-table-row ${index % 2 === 0 ? ('odd') : ('even')}`}>
                      <td className="allUsers-table-col">
                        {allAnnouncements[key].title?.en || ''}
                      </td>
                      <td className="allUsers-table-col">
                        <DateFormat formatType={FORMAT_DATE_TYPE.fullDateMinute} date={allAnnouncements[key].date} />
                      </td>
                      <td className="allUsers-table-col">
                        <div className="d-flex flex-column">
                          {allAnnouncements[key]?.platforms.map(platform => (
                            <span key={platform.value} className='mb-2'>
                              {platform.label}
                            </span>
                          ))}
                        </div>
                      </td>
                      <td className="allUsers-table-col">
                        <div className="d-flex flex-column">
                          {allAnnouncements[key]?.designs.map(design => (
                            <span key={design.value} className='mb-2'>
                              {design.label}
                            </span>
                          ))}
                        </div>
                      </td>
                      <td className="allUsers-table-col">
                        <div className="d-flex flex-column">
                          {allAnnouncements[key]?.environments.map(env => (
                            <span key={env.value} className='mb-2'>
                              {env.label}
                            </span>
                          ))}
                        </div>
                      </td>
                      <td className="allUsers-table-col">
                        <Button color="primary" onClick={() => toggleEditModal(key)}>
                          Edit
                        </Button>
                      </td>
                      <td className="allUsers-table-col">
                        <Button color="primary" onClick={() => toggleDeleteModal(key)}>
                          Delete
                        </Button>
                      </td>
                    </tr>
                  </Fragment>
                )) : (<></>)}
              {pagesCount && size(allAnnouncements) > 0 && size(allAnnouncements).length > pageSize ? (
                <div className="pagination-wrapper">
                  <Pagination aria-label="Page navigation example">
                    <PaginationItem disabled={currentPage === 0}>
                      <PaginationLink style={{ color: '#000' }} onClick={e => handleClick(e, 0)} href="#">
                        ‹‹‹
                      </PaginationLink>
                    </PaginationItem>

                    <PaginationItem disabled={currentPage <= 0}>
                      <PaginationLink
                        style={{ color: '#000' }}
                        onClick={e => handleClick(e, currentPage - 1)}
                        previous
                        href="#"
                      />
                    </PaginationItem>

                    {[...Array(pagesCount)].map((page, i) => {
                      if (i > currentPage - 3 && i < currentPage + 3) {
                        return (
                          <PaginationItem active={i === currentPage} key={page}>
                            <PaginationLink style={{ color: '#000' }} onClick={e => handleClick(e, i)} href="#">
                              {i + 1}
                            </PaginationLink>
                          </PaginationItem>
                        );
                      }

                      return null;
                    })}

                    <PaginationItem disabled={currentPage >= pagesCount - 1}>
                      <PaginationLink
                        style={{ color: '#000' }}
                        onClick={e => handleClick(e, currentPage + 1)}
                        next
                        href="#"
                      />
                    </PaginationItem>

                    <PaginationItem disabled={currentPage >= pagesCount - 1}>
                      <PaginationLink style={{ color: '#000' }} onClick={e => handleClick(e, pagesCount - 1)} href="#">
                        ›››
                      </PaginationLink>
                    </PaginationItem>
                  </Pagination>
                </div>
              ) : (<></>)}
            </Fragment>
          </tbody>
        </table>
      </div>
      {/* Delete Announcement modal */}
      <Modal container={'div > div'} isOpen={deleteModal} toggle={() => toggleDeleteModal()} className="notification">
        <ModalHeader
          toggle={() => toggleDeleteModal()}
          className="notification-header"
          close={
            <Media src={closeImg} className="notification-header-close" alt="X" onClick={() => toggleDeleteModal()} />
          }
        />
        <ModalBody className="notification-body">
          Do you really want to delete the announcement?
        </ModalBody>
        <ModalFooter className="notification-footer">
          <Button className="btn notification-footer-button" onClick={() => handleDeleteAnnouncement()}>
            Yes
          </Button>
          <Button type="button" className="btn notification-footer-button" onClick={() => toggleDeleteModal()}>
            No
          </Button>
        </ModalFooter>
      </Modal>

      {/* Add announcement modal */}
      <Modal container={'div > div'} returnFocusAfterClose={false} size="lg" isOpen={newOpenModal || editOpenModal} toggle={editOpenModal ? toggleEditModal : toggleNewOpenModal} className="event-modal">
        <ModalHeader toggle={editOpenModal ? toggleEditModal : toggleNewOpenModal}>
          {editOpenModal ? 'Edit Announcement' : 'Add Announcement'}
        </ModalHeader>
        <ModalBody>
          <Form className="form-style">
            <FormGroup>
              <Label for="name">
                Title By Latvian
              </Label>
              <Input
                type="text"
                name="lvTitle"
                id="lvTitle"
                value={lvTitle}
                disabled={loading}
                onChange={e => setLvTitle(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label for="name">
                Title By English
              </Label>
              <Input
                type="text"
                name="enTitle"
                id="enTitle"
                value={enTitle}
                disabled={loading}
                onChange={e => setEnTitle(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label for="name">
                Title By Russian
              </Label>
              <Input
                type="text"
                name="ruTitle"
                id="ruTitle"
                value={ruTitle}
                disabled={loading}
                onChange={e => setRuTitle(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label for="lvAnnouncement">
                Announcement By Latvian
              </Label>
              <Input
                type="textarea"
                name="lvAnnouncement"
                id="lvAnnouncement"
                value={lvAnnouncement}
                disabled={loading}
                onChange={e => setLvAnnouncement(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label for="enAnnouncement">
                Announcement By English
              </Label>
              <Input
                type="textarea"
                name="enAnnouncement"
                id="enAnnouncement"
                value={enAnnouncement}
                disabled={loading}
                onChange={e => setEnAnnouncement(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label for="ruAnnouncement">
                Announcement By Russian
              </Label>
              <Input
                type="textarea"
                name="ruAnnouncement"
                id="ruAnnouncement"
                value={ruAnnouncement}
                disabled={loading}
                onChange={e => setRuAnnouncement(e.target.value)}
              />
            </FormGroup>
            <FileUploadImage
              preview={preview}
              imageUrl={imageUrl}
              showCrop={showCrop}
              hiddenFileInput={hiddenFileInput}
              handleImageClick={handleImageClick}
              handleImageChange={_handleImageChange}
              handleEditPhoto={handleEditPhoto}
              uploadEventFile={uploadCampaignFile}
              loading={loading}
              handlePreviewPhoto={handlePreviewPhoto}
              handleReSetImage={handleReSetImage}
              handleReSetPreviewImage={handleReSetPreviewImage}
            />
            <FormGroup>
              <Label for="designs">
                Platforms
              </Label>
              <Select closeMenuOnSelect={false} options={PLATFORMS_TYPE} isMulti styles={customStyles} value={selectedPlatforms} onChange={platform => setSelectedPlatforms(platform)} />
            </FormGroup>
            <FormGroup>
              <Label for="designs">
                Designs
              </Label>
              <Select closeMenuOnSelect={false} options={DESIGN_TYPE} isMulti styles={customStyles} value={selectedDesigns} onChange={design => setSelectedDesigns(design)} />
            </FormGroup>
            <FormGroup>
              <Label for="designs">
                Environments
              </Label>
              <Select closeMenuOnSelect={false} options={ENV_MODE} isMulti styles={customStyles} value={selectedEnvironments} onChange={env => setSelectedEnvironments(env)} />
            </FormGroup>
            <FormGroup>
              <Label for="designs">
                Receivers
              </Label>
              <Select closeMenuOnSelect={false} options={RECEIVER_TYPE} isMulti styles={customStyles} value={selectedReceivers} onChange={receiver => setSelectedReceivers(receiver)} />
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          {
            editOpenModal ? (
              <Button className="modal-footer-button" disabled={ableToSave} onClick={() => handleEditAnnouncement()}>Save</Button>
            ) : (
              <Button className="modal-footer-button" disabled={ableToSave} onClick={() => handleNewAnnouncement()}>Add</Button>
            )
          }
          {
            editOpenModal ? (
              <Button className="modal-footer-button" disabled={ableToSave} onClick={() => toggleEditModal()}>Close</Button>
            ) : (
              <Button className="modal-footer-button" disabled={ableToSave} onClick={() => toggleNewOpenModal()}>Close</Button>
            )
          }
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default Announcements;
