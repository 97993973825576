import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

import { map, size, filter } from 'lodash';

import ScrollArea from 'react-scrollbar';

import { Col, Row, Button, Label, NavLink, Media } from 'reactstrap';

import { TwitterShareButton, FacebookShareButton, EmailShareButton, FacebookMessengerShareButton } from 'react-share';

import OtherBlogs from './OtherBlogs';
import DateFormat from '../../UI/DateFormat';
import * as constants from '../../../../constants/constants';
import TextEditor from '../../TextEditor/TextEditor';

import iconTwitter from '../../../../images/redesign/share/icon-x.png';
import iconFacebook from '../../../../images/redesign/share/icon-facebook.png';
import iconDraugiem from '../../../../images/redesign/share/icon-draugiem.png';
import iconMessenger from '../../../../images/redesign/share/icon-messenger.png';
import ScrollAreaWrapper from '../../UI/ScrollAreaWrapper';

const BlogArticle = ({ selectBlogUrlString, allBlogs, goToBlogList, selectBlog, handleReadArticle }) => {

  const { i18n, t } = useTranslation('common');

  const [shareUrl, setShareUrl] = useState('');
  const [blogTitle, setBlogTitle] = useState('');
  const [blogDescription, setBlogDescription] = useState('');
  const otherBlogs = filter(allBlogs, (item) => {
    return item.urlString !== selectBlogUrlString;
  });

  // const [divPosition, setDivPosition] = useState(0);
  // const [divPosition2, setDivPosition2] = useState(0);

  const [divPositionFinal, setDivPositionFinal] = useState(0);

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);

  const updateScreenSize = () => {
    setScreenWidth(window.innerWidth);
    setScreenHeight(window.innerHeight);
  };

  const updatePosition = () => {
    const divElement = document.getElementById('blog-content');
    const divElement2 = document.getElementById('other-articles');
    if (divElement && divElement2) {
      const { top, height } = divElement.getBoundingClientRect();
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const yFromTop = top + scrollTop + height;
      // setDivPosition(yFromTop);

      const { top: top2, height: height2 } = divElement2.getBoundingClientRect();
      const yFromTop2 = top2 + scrollTop + height2;
      // setDivPosition2(yFromTop2);
      if (yFromTop2 >= yFromTop) {
        setDivPositionFinal(yFromTop2);
      } else {
        setDivPositionFinal(yFromTop);
      }
    }
  };

  useEffect(() => {
    // Initial position update
    updatePosition();

    // Attach resize event listener
    window.addEventListener('resize', updatePosition);
    window.addEventListener('resize', updateScreenSize);
    window.addEventListener('scroll', updatePosition);

    // Cleanup the event listeners
    return () => {
      window.removeEventListener('resize', updatePosition);
      window.removeEventListener('resize', updateScreenSize);
      window.removeEventListener('scroll', updatePosition);
    };
  }, [otherBlogs]);

  useEffect(() => {
    const { location } = window.location;
    window.scrollTo(0 ,0);
    console.log(i18n.language, "checkCHangeLanguage")
    
    if (selectBlog && selectBlogUrlString) {
      if (i18n.language === constants.sendLanguage.en) {
        setShareUrl(`https://dev.spelezoli.lv/zoleBlog/en/${selectBlogUrlString}`);
        setBlogTitle(selectBlog.titleEN);
        setBlogDescription(selectBlog.descriptionEN)
      } else if (i18n.language === constants.sendLanguage.lv) {
        setShareUrl(`https://dev.spelezoli.lv/zoleBlog/lv/${selectBlogUrlString}`);
        setBlogTitle(selectBlog.titleLV);
        setBlogDescription(selectBlog.descriptionLV)
      } else if (i18n.language === constants.sendLanguage.ru) {
        setShareUrl(`https://dev.spelezoli.lv/zoleBlog/ru/${selectBlogUrlString}`);
        setBlogTitle(selectBlog.titleRU);
        setBlogDescription(selectBlog.descriptionRU)
      } else {
        setBlogTitle(selectBlog.titleLV);
        setBlogDescription(selectBlog.descriptionLV)
      }
    } else if (selectBlogUrlString) {
      setShareUrl(`https://dev.spelezoli.lv/zoleBlog/lv/${selectBlogUrlString}`);
    }
  }, [selectBlogUrlString, i18n.language]);

  const draugiemShareFunc = (title, url, titlePrefix ) => {
    window.open(
      'https://www.draugiem.lv/say/ext/add.php?title=' + encodeURIComponent( title ) +
      '&link=' + encodeURIComponent( shareUrl ) +
      ( titlePrefix ? '&titlePrefix=' + encodeURIComponent( titlePrefix ) : '' ),
      '',
      'location=1,status=1,scrollbars=0,resizable=0,width=530,height=400'
     );
     return false;
  }

  const isMobileLandscape = screenWidth <= 1280 && screenHeight < screenWidth;
  const isMobilePortrait = screenWidth <= 1280 && screenHeight <= 1280 && screenWidth < screenHeight;

  const dateShareStyle = isMobileLandscape ? {width: '50%', verticalAlign: 'bottom', marginBottom: 0, display: 'inline-block'} : isMobilePortrait ? {width: '100%'} : {};
  return (
    <>
    {/* <div style={{background: 'red', position: 'absolute', width: '5px', height: '5px', zIndex: '99999', right: '10px', top: divPosition}}></div>
    <div style={{background: 'yellow', position: 'absolute', width: '5px', height: '5px', zIndex: '99999', right: '20px', top: divPosition2}}></div> */}
      <Helmet>
        <title>
          {t('home.zoleblog')} - {blogTitle}
        </title>
        <meta name="description" content={blogDescription} />
      </Helmet>
      <div className="blog-article-section d-flex align-items-center" style={{minHeight: divPositionFinal + 70}}>
        <div className="blog-article-back">
          <div className='blog-article-back-dg' />
        </div>
        <Row className="blogs-article-page d-flex align-items-center">
          <Col sm="12" className="no-padding">
            <Label className="blogs-page-title">{t('menu.blog')}</Label>
          </Col>
          <Col sm="12" className="no-padding">
            <Row className="blogs-article-page-first-section d-flex flex-row" style={{marginLeft: 'auto', marginRight: 'auto'}}>
              <Col sm={12} className="no-padding">
                <Row className="blogs-article-page-first-section-item d-flex align-items-between flex-row" style={{marginLeft: '0px', marginRight: '0px'}}>
                  {/* <div className="blog-image">
                    <div className="blog-image-bg" style={{ backgroundImage: `url(${selectBlog.imageUrl})`, backgroundSize: 'cover', backgroundPosition: 'top', height: 350 }} />
                    <div className="blog-image-dg" />
                  </div> */}
                  <Col id="blog-content" className="blog-article-content pl-0" sm={isMobileLandscape || isMobilePortrait ? 12 : 7}>
                    <div className="blog-image">
                      <div className="blog-image-bg" style={{ backgroundImage: `url(${selectBlog.imageUrl})`, backgroundSize: 'cover', backgroundPosition: 'top', aspectRatio: '3 / 2' /*height: 350*/ }} />
                      <div className="blog-image-dg" />
                    </div>
                    <div className="title mt-4">
                      { blogTitle }
                    </div>
                    <div className="description mt-4 mb-4">
                      {blogDescription && (
                        <TextEditor addEditorClassName="blog-editor-read-only" viewEditorState={blogDescription} readOnly />
                      )}
                    </div>
                    {/* <div className="open-link">
                      {
                        selectBlog && selectBlog.openLink && (
                          <a href={selectBlog.openLink} target="_blank">{t('home.openLink')}</a>
                        )
                      }
                    </div> */}
                    <div className="d-flex align-items-start">
                      <Button className="load-more" onClick={goToBlogList}>{t('home.backToBlog')}</Button>
                    </div>
                  </Col>
                  <Col id="other-articles" className="blog-article-share mb-2" sm={isMobileLandscape || isMobilePortrait ? 12 : 4}>
                    <div className="date" style={dateShareStyle}>
                      <p>{t('home.date')}</p>
                      <DateFormat className="mb-2" formatType={constants.FORMAT_DATE_TYPE.pointsfullDate} date={selectBlog.blogDate} />
                    </div>
                    <div className="share mt-2" style={dateShareStyle}>
                      <p>{t('home.share')}</p>
                      <div className="share-link mb-4">
                        <div className="share-link-item share-link-item-twitter d-flex align-items-center mr-1">
                          <TwitterShareButton url={shareUrl} title={i18n.language === constants.sendLanguage.en ? selectBlog.titleEN : i18n.language === constants.sendLanguage.lv ? selectBlog.titleLV : i18n.language === constants.sendLanguage.ru ? selectBlog.titleRU : selectBlog.titleLV }>
                            <button className="btn btn-circle">
                              <Media src={iconTwitter} alt="x" />
                            </button>
                          </TwitterShareButton>
                        </div>
                        <div className="share-link-item share-link-item-facebook d-flex align-items-center mr-1">
                          <FacebookShareButton url={shareUrl} quote={i18n.language === constants.sendLanguage.en ? selectBlog.titleEN : i18n.language === constants.sendLanguage.lv ? selectBlog.titleLV : i18n.language === constants.sendLanguage.ru ? selectBlog.titleRU : selectBlog.titleLV }>
                            <button className="btn btn-circle">
                              <Media src={iconFacebook} alt="x" />
                            </button>
                          </FacebookShareButton>
                        </div>
                        <div className="share-link-item share-link-item-draugiem d-flex align-items-center mr-1">
                          <Media
                            src={iconDraugiem}
                            alt="x"
                            onClick={() => draugiemShareFunc(selectBlog.titleLV, selectBlog.imageUrl, selectBlog.titleLV)}
                          />
                        </div>
                        {/* <div className="share-link-item d-flex align-items-center mr-1">
                          <FacebookMessengerShareButton url={shareUrl} appId={constants.FB_APP_ID}>
                            <button className="btn btn-circle">
                              <Media src={iconMessenger} alt="x" />
                            </button>
                          </FacebookMessengerShareButton>
                        </div> */}
                        {/* <div className="share-link-item d-flex align-items-center mr-1"><Media src={iconTwitter} alt="x" /></div> */}
                        {/* <div className="share-link-item d-flex align-items-center mr-1"><Media src={iconFacebook} alt="x" /></div> */}
                        {/* <div className="share-link-item d-flex align-items-center mr-1"><Media src={iconDraugiem} alt="x" /></div> */}
                        {/* <div className="share-link-item d-flex align-items-center"><Media src={iconMessenger} alt="x" /></div> */}
                      </div>
                    </div>
                    <div className="other-articles mt-4">
                      <p>{t('home.otherArticles')}</p>
                      <div className="other-articles-list">

                        {isMobileLandscape ? (
                          <div style={{overflowX: 'scroll', overflowY: 'hidden', whiteSpace: 'nowrap'}}>
                            {/* <div style={{background: 'red', display: 'inline-block', width: '200px', height: '200px', marginRight: '30px'}}>
                            </div>
                            <div style={{background: 'green', display: 'inline-block', width: '200px', height: '200px', marginRight: '30px'}}>
                            </div>
                            <div style={{background: 'blue', display: 'inline-block', width: '200px', height: '200px', marginRight: '30px'}}>
                            </div>
                            <div style={{background: 'yellow', display: 'inline-block', width: '200px', height: '200px', marginRight: '30px'}}>
                            </div> */}
                            <OtherBlogs otherBlogs={otherBlogs} handleReadArticle={handleReadArticle} elementWidth="35%" />
                          </div>
                        ) : (
                          <OtherBlogs otherBlogs={otherBlogs} handleReadArticle={handleReadArticle} />
                        )}
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
};

BlogArticle.propTypes = {
  selectBlog: PropTypes.shape({}),
  selectBlogUrlString: PropTypes.string,
  allBlogs: PropTypes.arrayOf(PropTypes.shape({})),
  goToBlogList: PropTypes.func.isRequired,
  handleReadArticle: PropTypes.func.isRequired,
};

BlogArticle.defaultProps = {
  selectBlogUrlString: '',
  allBlogs: [],
  selectBlog: {},
};

export default BlogArticle;
