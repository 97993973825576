import Store from '../store/tournaments';

export const initialState = Store;

export default function tournamentsReducer(state = initialState, action) {
  switch (action.type) {
    case 'TOURNAMENTS_REPLACE': {
      const tournaments = [];
      let hasActiveRegistration = false;


      Object.keys(action.data).map((key) => {
        tournaments.push({ ...action.data[key], id: key });
        if (action.data[key] && action.data[key].currentState && action.data[key].currentState === 'registration') {
          if (action.data[key].restrictions) {
            const {
              lvl, bal, totalPnts, gPlayed,
            } = action.userData;
            const levelFrom = parseInt(action.data[key].levelFrom, 10);
            const levelTo = parseInt(action.data[key].levelTo, 10);
            const pointsFrom = parseInt(action.data[key].pointsFrom, 10);
            const pointsTo = parseInt(action.data[key].pointsTo, 10);
            const balanceFrom = parseInt(action.data[key].balanceFrom, 10);
            const balanceTo = parseInt(action.data[key].balanceTo, 10);
            const playedGamesFrom = parseInt(action.data[key].playedGamesFrom, 10);
            const playedGamesTo = parseInt(action.data[key].playedGamesTo, 10);

            if ((lvl >= levelFrom && lvl <= levelTo) && (totalPnts >= pointsFrom && totalPnts <= pointsTo) && (bal >= balanceFrom && bal <= balanceTo) && (gPlayed >= playedGamesFrom && gPlayed <= playedGamesTo)) {
              hasActiveRegistration = true;
            }
          } else {
            hasActiveRegistration = true;
          }
        }
        return null;
      });

      console.log('TOURNAMENTS_REPLACE 2', tournaments);

      tournaments.sort((a, b) => b.startDate - a.startDate);

      return {
        ...state,
        tournaments,
        hasActiveRegistration,
      };
    }

    case 'MY_TOURNAMENTS_DATA': {
      return {
        ...state,
        myTournamentsData: action.data,
      };
    }

    case 'TOURNAMENT_PLAYERS_REPLACE': {
      return {
        ...state,
        tournamentPlayers: action.data,
      };
    }


    case 'TOURNAMENTS_HISTORY_REPLACE': {
      console.log('TOURNAMENTS_HISTORY_REPLACE', action.data);

      const tournaments = [];

      Object.keys(action.data).map((key) => {
        tournaments.push({ ...action.data[key], id: key });
        return null;
      });

      tournaments.sort((a, b) => b.startDate - a.startDate);
      tournaments.sort((a, b) => b.startDate - a.startDate);
      return {
        ...state,
        tournamentsHistory: tournaments,
      };
    }

    case 'PLAYER_TOURNAMENTS_HISTORY_REPLACE': {
      return {
        ...state,
        playerTournamentsHistory: action.data,
      };
    }

    /*  case 'TOURNAMENT_PLAYER_HISTORY': {
      return {
        ...state,
        tournamentPlayerHistory: action.data,
      };
    }  */

    default:
      return state;
  }
}
