import React, { useState, useEffect, useRef } from 'react';
// import PropTypes from 'prop-types';
import classNames from 'classnames';

import { useTranslation } from 'react-i18next';

import { useSelector, useDispatch } from 'react-redux';
import Spinner from 'reactstrap/lib/Spinner';

import { Helmet } from 'react-helmet';
import { map, includes } from 'lodash';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Button from 'reactstrap/lib/Button';
import Media from 'reactstrap/lib/Media';
import Modal from 'reactstrap/lib/Modal';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import Label from 'reactstrap/lib/Label';
// import Input from 'reactstrap/lib/Input';
import ScrollAreaWrapper from '../UI/ScrollAreaWrapper';

import * as constants from '../../../constants/constants';

import infoImg from '../../../images/icons/info.svg';
import chartImg from '../../../images/icons/smallChart.svg';
import defaultImage from '../../../images/Game/defaultImage.jpg';
import coinImg from '../../../images/coin.svg';

import { getRankingsData } from '../../../actions/leaderboard';
import closeImg from '../../../images/icons/close.png';
import config from '../../../constants/config';

const PowerRankings = ({fontMode, parentActiveTab: activeTab, parentChangeTab: setActiveTab, checked, togglePWOption}) => {
  const { t } = useTranslation('common');
  const intvl = useRef(null);
  const dispatch = useDispatch();

  const rankings = useSelector(state => state.leaderboard.rankings);
  const isLoading = useSelector(state => state.leaderboard.isLoading);

  console.log('rankings', rankings);

  const [infoModal, setInfoModal] = useState(false);

  useEffect(() => {
    dispatch(getRankingsData(checked, menuNavIdToKey(activeTab)));
  }, [checked, activeTab]);

  const menuNavIdToKey = (menuNavId) => {
    return constants.POWER_RANKINGS_FILTER.filter(item => item.id === menuNavId)[0].key;
  }

  const handleChange = () => {
    if (!checked) {
      if (activeTab === constants.MENU_NAVIGATION.powerRankingsWinsCount) {
        setActiveTab(constants.MENU_NAVIGATION.powerRankingsLossCount);
      } else if (activeTab === constants.MENU_NAVIGATION.powerRankingsWinPercentage) {
        setActiveTab(constants.MENU_NAVIGATION.powerRankingsLossPercentage);
      }
    } else {
      if (activeTab === constants.MENU_NAVIGATION.powerRankingsLossCount) {
        setActiveTab(constants.MENU_NAVIGATION.powerRankingsWinsCount);
      } else if (activeTab === constants.MENU_NAVIGATION.powerRankingsLossPercentage) {
        setActiveTab(constants.MENU_NAVIGATION.powerRankingsWinPercentage);
      }
    }
    togglePWOption();
  };

  const changeTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const handleInputRef = () => {
    intvl.current.click();
  };

  const toggleInfoModal = () => {
    setInfoModal(!infoModal);
  };
  console.log({isLoading});

  return (
    <div className="power-rankings">
      <Helmet>
        <title>
          Zole - {t('route.powerRankings')}
        </title>
      </Helmet>
      <Row className="power-rankings-header">

        { config.isInAppFrame() ? (
          <Col xs="3" className="power-rankings-header-switch-section power-rankings-header-switch-section-iframe" id="power-rankings-switch-option">
            <div className="power-rankings-header-switch-section-iframe-left-section">
              <Label className="mb-1">{t(`powerRankings.${constants.POWER_RANKINGS_SWITCH_OPTION.best}`)}</Label>
              <Label>{t(`powerRankings.${constants.POWER_RANKINGS_SWITCH_OPTION.worst}`)}</Label>
            </div>
            <div className="power-rankings-header-switch-section-iframe-right-section">
              <Label className="switch-wrapper mb-1" onClick={() => handleInputRef} style={{transform: 'rotate(90deg)'}}>
                <input
                  type="checkbox"
                  name="switchOption"
                  checked={checked}
                  ref={intvl}
                  onClick={e => handleChange(e)}
                />
                <span
                  className={classNames('switch', {
                    'switch-best': checked,
                    'switch-worst': !checked,
                  })}
                >
                  <span className="switch-handle" />
                </span>
              </Label>
            </div>

          </Col>
        ) : (
          <Col xs="3" className="power-rankings-header-switch-section" id="power-rankings-switch-option">
            <Label className="mb-1">{t(`powerRankings.${constants.POWER_RANKINGS_SWITCH_OPTION.best}`)}</Label>
            <Label className="switch-wrapper mb-1" onClick={() => handleInputRef}>
              <input
                type="checkbox"
                name="switchOption"
                checked={checked}
                ref={intvl}
                onClick={e => handleChange(e)}
              />
              <span
                className={classNames('switch', {
                  'switch-best': checked,
                  'switch-worst': !checked,
                })}
              >
                <span className="switch-handle" />
              </span>
            </Label>
            <Label>{t(`powerRankings.${constants.POWER_RANKINGS_SWITCH_OPTION.worst}`)}</Label>
          </Col>
        )}

        <Col xs="8"> {/* className='d-flex' */ }
          <Row>
            {
              map(constants.POWER_RANKINGS_SUB_MENU, item => (
                !checked ? (
                  item !== constants.POWER_RANKINGS_SUB_MENU.lossCount && item !== constants.POWER_RANKINGS_SUB_MENU.lossPercentage && (
                    <Col className="menu-topTab">
                      <Button
                        color="link"
                        className={classNames('power-rankings-header-button', {
                          'power-rankings-header-button-active': item === activeTab,
                        })}
                        onClick={() => changeTab(item)}
                      >
                        {t(`powerRankings.${menuNavIdToKey(item)}`)}
                      </Button>
                    </Col>
                  )
                ) : (
                  item !== constants.POWER_RANKINGS_SUB_MENU.winsCount && item !== constants.POWER_RANKINGS_SUB_MENU.winPercentage && (
                    <Col className="menu-topTab">
                      <Button
                        color="link"
                        className={classNames('power-rankings-header-button', {
                          'power-rankings-header-button-active': item === activeTab,
                        })}
                        onClick={() => changeTab(item)}
                      >
                        {t(`powerRankings.${menuNavIdToKey(item)}`)}
                      </Button>
                    </Col>
                  )
                )
              ))
            }
          </Row>
        </Col>
        <Col xs="1">
          <Media className="power-rankings-header-tutorial" src={infoImg} onClick={toggleInfoModal} />
        </Col>
      </Row>
      {isLoading ? (
        <div className="power-rankings-loading-part d-flex align-items-center">
          <Spinner color="light" style={{ width: '5rem', height: '5rem' }} />
        </div>
      ) : (
        <Row className="power-rankings-list-part old-design-scrollbar" id="power-rankings-list">
          <Col xs="12">
            <div className="power-rankings-body">
              {
                map(rankings, (item, key) => (
                  <div className="power-rankings-body-item mb-2">
                    <div className="power-rankings-body-item-label">
                      <Label>{key + 1}</Label>
                    </div>
                    <div className="power-rankings-body-item-photo">
                      {
                        item.photo && includes(item.photo, 'graph.facebook.com') ? (
                          <Media src={`${item.photo || defaultImage}&redirect=true`} alt="x" />
                        ) : item.photo && !includes(item.photo, 'graph.facebook.com')? (
                          <Media src={item.photo || defaultImage} alt="x" />
                        ) : (null)
                      }
                      <Media src={item.photo || defaultImage} alt="x" />
                      <Label>
                        {
                          activeTab !== constants.MENU_NAVIGATION.powerRankingsLossPercentage && activeTab !== constants.MENU_NAVIGATION.powerRankingsWinPercentage ? (
                            !checked ? (
                              `+${item[constants.POWER_RANKINGS_FILTER.filter(tab => tab.id === activeTab)[0].filter] || 0}`
                            )
                              : (
                                item[constants.POWER_RANKINGS_FILTER.filter(tab => tab.id === activeTab)[0].filter] || 0
                              )
                          ) : (
                            `${Number.parseFloat(item[constants.POWER_RANKINGS_FILTER.filter(tab => tab.id === activeTab)[0].filter] * 100).toFixed(2) || 0}%`
                          )
                        }
                      </Label>
                    </div>
                    <div className="power-rankings-body-item-name">
                      {item.name || "Zole"}
                    </div>
                    <div className="power-rankings-body-item-score">
                      <div className="power-rankings-body-item-score-zole-coins">
                        <Media src={coinImg} alt="zole" />
                        <Label className="m-0">{item.totalBal || 0}</Label>
                      </div>
                      <div className="power-rankings-body-item-score-zole-points">
                        <Media src={chartImg} alt="zole" />
                        <Label className="m-0">{item.totalRating || '--' }</Label>
                      </div>
                    </div>
                  </div>
                ))
              }
            </div>
          </Col>
        </Row>
      )}
      <Modal container={'div > div > div'} isOpen={infoModal} size="md" toggle={toggleInfoModal} className={`old-modal root-font-${fontMode}`} >
        <ModalHeader
          toggle={toggleInfoModal}
          close={
            <Media src={closeImg} className="notification-header-close" alt="X" onClick={toggleInfoModal} />
            }
        >
          {t('powerRankings.infoHeader')}
        </ModalHeader>
        <ModalBody>
          <div>
            <p className="mb-3">{t('powerRankings.infoContent1')}</p>
            <p className="mb-3">{t('powerRankings.infoContent2')}</p>
            <p className="mb-3">{t('powerRankings.infoContent3')}</p>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button className="modal-footer-button mb-3" color="secondary" onClick={toggleInfoModal}>
            {t('common.close')}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default PowerRankings;
