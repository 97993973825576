import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Lottie from 'react-lottie';
import loadingAnimation from '../../../images/loadingAnimation.json';
import { withTranslation } from 'react-i18next';
import { Offline } from "./custom-react-detect-offline";
import { withRouter } from 'react-router-dom';

// import { Offline } from "react-detect-offline";

const polling = {
  //  enabled: true,
  url: "https://dra.spelezoli.lv/",
  interval: 5500,
  timeout: 3800
};

  class NetworkDetector extends Component {
    static propTypes = {
      t: PropTypes.func,
    }

    constructor(props) {
      super(props);
      this.state = {
        //  isDisconnected: false
      };
    }

    componentDidMount() {
      //    this.handleConnectionChange();
      //    window.addEventListener('online', this.handleConnectionChange);
      //    window.addEventListener('offline', this.handleConnectionChange);
    }

    componentWillUnmount() {
      //    window.removeEventListener('online', this.handleConnectionChange);
      //    window.removeEventListener('offline', this.handleConnectionChange);
    }


  /*  handleConnectionChange = () => {
      const condition = navigator.onLine ? 'online' : 'offline';

      if (condition === 'online') {
        const webPing = setInterval(
          () => {
            fetch('//google.com', {
              mode: 'no-cors',
              })
            .then(() => {
            //  const { isDisconnected } = this.state;
            //  if (isDisconnected) {
            //    console.log('call reloadPage');
            ///    console.log(isDisconnected);
            //    this.reloadPage();
            //  }

              this.setState({ isDisconnected: false }, () => {
                return clearInterval(webPing)
              });
            }).catch(() => this.setState({ isDisconnected: true }) )
          }, 2000);
        return;
      }

      return this.setState({ isDisconnected: true });
    }  */

    reloadPage = () => {
      const { isDisconnected } = this.state;
      //  console.log('reloadPage');
      //  console.log(isDisconnected);

      //  if (isDisconnected) {
      const el = document.getElementById('content_frame');
      if (el) {
        const src = el.src;
        el.src = '';
      //  console.log('has el');
       // console.log(src);
        setTimeout(() => {
          el.src = src
        }, 15);
      } else {
        window.location.reload();
      }
      //  }
    }

  //  <div className="internet-error">
  //    <p>Tiek atjaunots savienojums ar serveri</p>
  //    <Button className="internet-error-button" onClick={this.reloadPage}> Pārlādēt </Button>
  //  </div>

  /* <div className="internet-error-wrapper">
    {isDisconnected && (
      <Modal isOpen={isDisconnected} className="internet-error">
        <ModalHeader toggle={this.reloadPage}></ModalHeader>
        <ModalBody>
          <Spinner className="internet-error-spinner" color="secondary" />
          <p className="internet-error-text">Tiek atjaunots savienojums ar serveri</p>
        </ModalBody>
        <ModalFooter>
          <Button className="internet-error-button" onClick={this.reloadPage}> Pārlādēt </Button>
        </ModalFooter>
      </Modal>

    )}
  </div> */

    render() {
      //  const { isDisconnected } = this.state;
      const { t } = this.props;
      const { location } = this.props;

      return (
        <div>
          <Offline polling={polling}>
            <div className="internet-error-wrapper">
              <div className="internet-error-background" />
              <div className="internet-error">
              {/*  <Spinner className="internet-error-spinner" /> */}
                <Lottie
                  options={{
                    loop: true,
                    autoplay: true,
                    animationData: loadingAnimation,
                    rendererSettings: {
                      preserveAspectRatio: 'xMidYMid slice'
                    }
                  }}
                  height={100}
                  width={100}
                  isStopped={false}
                  isPaused={false}
                />
                <div className="internet-error-text" onClick={this.reloadPage}>
                  {location.pathname === '/admin' ? (
                    'The Internet connection is lost, it is being restored...'
                  ) : (
                    t('common.connectionLost2')
                  )}
                </div>
              </div>
            </div>
          </Offline>
        </div>
      );
    }
  }

export default withRouter(withTranslation('common')(NetworkDetector));
