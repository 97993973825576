import React from 'react';
import PropTypes from 'prop-types';

class GameTimerTournament extends React.Component {
  static propTypes = {
    gameStartTime: PropTypes.number,
    offset: PropTypes.number,
    maxGames: PropTypes.number,
    tournamentGameTimeout: PropTypes.number,
  }

  static defaultProps = {
    gameStartTime: 0,
    offset: 0,
    maxGames: 0,
    tournamentGameTimeout: null,
  }

  constructor(props) {
    super(props);
    this.state = {
      hours: '00',
      minutes: '00',
      seconds: '00',
    };
  }

  componentDidMount() {
    this.timeoutID = setTimeout(() => {
      const { gameStartTime, maxGames, tournamentGameTimeout } = this.props;

      this.intervalID = setInterval(() => {
        const { offset } = this.props;

        if (gameStartTime && tournamentGameTimeout) {
          let gameTimeout = tournamentGameTimeout;

          /*if (maxGames > 24) {
            gameTimeout = 60;
          } else if (maxGames > 12) {
            gameTimeout = 40;
          } */

          //  const gameLength = Date.now() + offset - gameStartTime;
          const gameEndTime = gameStartTime + (gameTimeout * 1000 * 60);

          //  console.log('gameEndTime', { gameEndTime });
          //  console.log('time to game end', { time: gameEndTime - Date.now() });

          const hours = Math.floor(((gameEndTime - (Date.now() + offset)) % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
          const minutes = Math.floor(((gameEndTime - (Date.now() + offset)) % (1000 * 60 * 60)) / (1000 * 60));
          const seconds = Math.floor(((gameEndTime - (Date.now() + offset)) % (1000 * 60)) / 1000);

          if (hours < 0 && minutes < 0 && seconds < 0) {
            this.setState({
              hours: '00',
              minutes: '00',
              seconds: '00',
            });
          } else {
            this.setState({
              hours: (hours < 10 && hours >= 0) ? `0${hours}` : hours,
              minutes: (minutes < 10 && minutes >= 0) ? `0${minutes}` : minutes,
              seconds: (seconds < 10 && seconds >= 0) ? `0${seconds}` : seconds,
            });
          }
        }
      }, 1000);
    }, 3000);
  }


  componentWillUnmount() {
    clearTimeout(this.timeoutID);
    clearInterval(this.intervalID);
  }

  render() {
    const {
      hours,
      minutes,
      seconds,
    } = this.state;

    return (
      <div className="game-stats-timer game-stats-timer-tournament">
        {`${hours}:${minutes}:${seconds}`}
      </div>
    );
  }
}

export default GameTimerTournament;
