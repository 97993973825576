import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Media, Button } from 'reactstrap';
import { size, map } from 'lodash';
import { useTranslation } from 'react-i18next';
import { checkedAnnouncements, getAnnouncements } from '../../../../actions/member';
import CustomModal from '../Components/Components/Modal';

const Announcements = () => {
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation('common');

  const announcements = useSelector(state => state.member.announcements || {});
  // console.log(announcements, 'check user side');
  useEffect(() => {
    dispatch(getAnnouncements('new'));
  }, []);

  const closeModal = (id) => {
    dispatch(checkedAnnouncements(id, 'new'));
    dispatch(getAnnouncements('new'));
  };

  return (
    <>
      {announcements && size(announcements) > 0 && map(announcements, (item) => {
        if (!item?.announcementId) return <></>;

        return (
          <CustomModal
            isOpen
            inlineClassName="new-version-modal-new"
            forceBackdropEnabled
            toggle={() => closeModal(item.announcementId)}
            size="lg"
            title={t('common.announcement')}
            body={(
              <div className="d-flex flex-column gap-10">
                <div className="d-flex flex-column gap-5">
                  <div className="text-center fs-8">{i18n.language === 'lv' ? item.title.lv : i18n.language === 'en' ? item.title.en : i18n.language === 'ru' ? item.title.ru : item.title.lv}</div>
                  <div className="text-left fs-5">{i18n.language === 'lv' ? item.announcement.lv : i18n.language === 'en' ? item.announcement.en : i18n.language === 'ru' ? item.announcement.ru : item.title.lv}</div>
                </div>
                {item.imageUrl && (
                  <div className="announcement-image video-ratio">
                    <Media className="blog-image-bg" src={item.imageUrl} alt="x" />
                  </div>
                )}
                <div />
              </div>
            )}
            footer={(
              <Button color="link" className="notification-footer-button" onClick={() => closeModal(item.announcementId)}>{t('common.ok')}</Button>
            )}
          />
        );
      })}
    </>
  );
};

export default Announcements;
