import React from 'react';
import PropTypes from 'prop-types';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Media from 'reactstrap/lib/Media';
import Button from 'reactstrap/lib/Button';

import { connect } from 'react-redux';
import PlayerProfile from './PlayerProfile';

import dailyBonusButton from '../../../../../images/redesign/Daily bonus button.svg';
import shopButton from '../../../../../images/redesign/Shop button.webp';
import { MENU_NAVIGATION, PAYMENT_SOURCE } from '../../../../../constants/constants';
import { addPaymentInfo } from '../../../../../actions/shop';

const LayoutFooter = React.memo(({
  t,
  notificationSettings,
  changeTab,
  screenMode,
  toggleScreenMode,
  requestDeleteUser,
  updateProfile,
  userAvatarFileUpload,
  isMobile,
  removeGiftFunc,
  getActiveGiftsFunc,
  getGiftsHistoryFunc,
  name,
  uid,
  setAddPaymentInfo,
}) => {
  const handleToBuyPage = () => {
    changeTab(MENU_NAVIGATION.buyPageMoneyPacks);

    setAddPaymentInfo({
      uid,
      name,
      source: PAYMENT_SOURCE.shop,
    });
  };

  return (
    <Row className={`layout-footer layout-footer-${screenMode}`}>
      <Col xs="3" className="layout-footer-bonus">
        <div className="layout-footer-link  layout-footer-bonus-link">
          <Media className="layout-footer-link-image" src={dailyBonusButton} onClick={() => changeTab('4')} />
          <Button className="layout-footer-link-button" onClick={() => changeTab('4')}>
            {t('headerRight.dailyBonus')}
          </Button>
        </div>
      </Col>
      <Col xs="6" className="d-flex justify-content-center">
        <PlayerProfile
          t={t}
          notificationSettings={notificationSettings}
          screenMode={screenMode}
          toggleScreenMode={toggleScreenMode}
          toggle={changeTab}
          requestDeleteUser={requestDeleteUser}
          updateProfile={updateProfile}
          userAvatarFileUpload={userAvatarFileUpload}
          isMobile={isMobile}
          removeGiftFunc={removeGiftFunc}
          getActiveGiftsFunc={getActiveGiftsFunc}
          getGiftsHistoryFunc={getGiftsHistoryFunc}
        />
      </Col>
      <Col xs="3" className="layout-footer-shop">
        <div className="layout-footer-link layout-footer-shop-link">
          <Media className="layout-footer-link-image" src={shopButton} onClick={() => handleToBuyPage()} />
          <Button className="layout-footer-link-button" onClick={() => changeTab('5.1')}>
            {t('headerRight.buy')}
          </Button>
        </div>
      </Col>
    </Row>
  );
});

LayoutFooter.propTypes = {
  t: PropTypes.func.isRequired,
  changeTab: PropTypes.func.isRequired,
  screenMode: PropTypes.string,
  toggleScreenMode: PropTypes.func.isRequired,
  name: PropTypes.string,
  uid: PropTypes.string,
  setAddPaymentInfo: PropTypes.func.isRequired,
};

LayoutFooter.defaultProps = {
  name: '',
  uid: '',
};

const mapStateToProps = state => ({
  balance: parseFloat(state.member.balance),
  lastBonusSpin: state.member.lastBonusSpin,
  offset: state.member.offset || 0,
  name: state.member.name,
  uid: state.member.uid,
});

const mapDispatchToProps = {
  setAddPaymentInfo: addPaymentInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(LayoutFooter);
