import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

import { map, get, size, isNull, filter } from 'lodash';

import {
  Input,
  Button,
  Media,
} from 'reactstrap';

import {
  toggleShowHint,
  getAllAvailableHintsNew,
} from '../../../../../actions/member';

import * as constants from '../../../../../constants/constants';
import CustomModal from '../../Components/Components/Modal';
import config from '../../../../../constants/config';

const Hints = () => {
  const { i18n, t } = useTranslation('common');
  const dispatch = useDispatch();

  const [openHintModal, setOpenHintModal] = useState(true);
  const [userShowHint, setUserShowHint] = useState(false);
  const [randomCount, setRandomCount] = useState(0);
  const [devMode, setDevMode] = useState(false);
  const { hostname } = window.location;

  const showHint = useSelector(state => state.member?.showHint || false);
  const checkedHintDate = useSelector(state => (state.member && state.member.checkedHintDate) || '');

  const tDay = (new Date()).getDay() + 2;
  const allNewHints = useSelector(state => state.member.allNewHints || []);
  const filteredHints = size(allNewHints) > 0 ? filter(allNewHints, (item) => {
    return (item.showOptions === constants.SHOW_HINT_EVERY_DAY || item.showOptions === tDay.toString()) && item.devMode === devMode;
  }) : [];

  const checkedHintDay = checkedHintDate ? moment(checkedHintDate).format('YYYY-MM-DD') : '';
  const nowDay = moment(new Date()).format('YYYY-MM-DD');

  useEffect(() => {
    if (!devMode && hostname && (hostname.includes('dev.') || hostname.includes('localhost'))) {
      try {
        setDevMode(true);
      } catch (err) {
        console.log(err);
      }
    }
  });


  useEffect(() => {
    dispatch(getAllAvailableHintsNew())
  }, []);

  useEffect(() => {
    if (size(filteredHints) > 0) {
      try {
        const randomCnt = Math.floor(Math.random() * size(filteredHints));
        setRandomCount(randomCnt);
      } catch (err) {
        console.log(err);
      }
    }
  }, [allNewHints])

  const toggleUserShowHint = () => {
    setUserShowHint(!userShowHint);
  }

  const changeShowHint = () => {
    dispatch(toggleShowHint(userShowHint, filteredHints[randomCount].key));
    setOpenHintModal(!openHintModal);
  }
  const isInAppFrame = config.isInAppFrame();
  console.log(checkedHintDay, showHint, filteredHints, randomCount, filteredHints[randomCount], "check=========================")

  return (
    <CustomModal
      isOpen={!showHint && filteredHints && size(filteredHints) > 0 && (!checkedHintDay || (checkedHintDay !== nowDay)) && openHintModal && filteredHints[randomCount]}
      toggle={changeShowHint}
      size={isInAppFrame ? 'md' : 'lg'}
      title={t('common.dailyTip')}
      body={
        size(filteredHints) > 0 && filteredHints[randomCount] && (
          <div className='my-hints'>
            <div className={`my-hints-item ${isInAppFrame ? 'my-hints-item-in-app-frame' : ''}`}>
              <div className="my-hints-item-title mb-2" >
                {
                  i18n.language === constants.sendLanguage.en ?
                    filteredHints[randomCount].titleEN
                    : i18n.language === constants.sendLanguage.lv ?
                      filteredHints[randomCount].titleLV
                      : i18n.language === constants.sendLanguage.ru ?
                        filteredHints[randomCount].titleRU
                        : filteredHints[randomCount].titleLV
                }
              </div>
              <div className="my-hints-item-img" >
                <Media
                  className="slide-img"
                  src={
                    i18n.language === constants.sendLanguage.en ?
                      filteredHints[randomCount].enImageUrl
                      : i18n.language === constants.sendLanguage.lv ?
                        filteredHints[randomCount].lvImageUrl
                        : i18n.language === constants.sendLanguage.ru ?
                          filteredHints[randomCount].ruImageUrl
                          : filteredHints[randomCount].enImageUrl
                  }
                  alt=""
                />
              </div>
              <div className="my-hints-item-content mt-4 mb-2">
                {
                  i18n.language === constants.sendLanguage.en ?
                    filteredHints[randomCount].descriptionEN
                    : i18n.language === constants.sendLanguage.lv ?
                      filteredHints[randomCount].descriptionLV
                      : i18n.language === constants.sendLanguage.ru ?
                        filteredHints[randomCount].descriptionRU
                        : filteredHints[randomCount].descriptionLV
                }
              </div>
            </div>
            <div className='d-flex flex-center align-items-center mt-2 my-hints-item-check-show'>
              <div className='d-flex align-items-center mr-2'>
                <Input type="checkbox" onClick={toggleUserShowHint} checked={userShowHint} readOnly />
                <span className="checkmark" />
              </div>
              <div className="event-type-text mt-1">
                {t('common.dailyTipShowOption')}
                {/* Vairs nerādīt dienas padomus (aktivizējams profila iestatījumos) */}
              </div>
            </div>
          </div>
        )
      }
      footer={
        <Button onClick={changeShowHint} >
          {t('common.ok')}
        </Button>
      }
    />
  );
}

export default Hints;
