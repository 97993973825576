import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { map, size, filter, find } from 'lodash';

import ScrollArea from 'react-scrollbar';

import { Col, Row, Button, Label, NavLink, Media } from 'reactstrap';

import TextEditor from '../../TextEditor/TextEditor';
import DateFormat from '../../UI/DateFormat';
import BlogArticle from './BlogArticle';
// import closeImg from '../../../../images/icons/close.png';
import * as constants from '../../../../constants/constants';

import {
  getAllBlogs,
} from '../../../../actions/member';

const Blogs = ({ history, blogArticleOpen, blogUrlString, blogArticleFunc }) => {
  const { i18n, t } = useTranslation('common');
  const dispatch = useDispatch();

  const allBlogs = useSelector(state => state.member.allBlogs);

  const [activeTab, setActiveTab] = useState('');
  const [selectBlogUrlString, setSelectBlogUrlString] = useState('');
  const [selectBlog, setSelectBlog] = useState({});
  const [pageShow, setPageShow] = useState(2);


  const [mobilePage, setMobilePage] = useState(1);
  const elementsPerMobilePage = 6;
  
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);

  const updateScreenSize = () => {
    setScreenWidth(window.innerWidth);
    setScreenHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener('resize', updateScreenSize);
    return () => {
      window.removeEventListener('resize', updateScreenSize);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getAllBlogs());
    const pathParts = history.location.pathname.split('/');
    const idFromPath = pathParts.length > 3 && pathParts[3];
    if (blogUrlString === '' && idFromPath && !blogArticleOpen) {
      blogUrlString = idFromPath;
      blogArticleFunc(true);
    }

    if (blogUrlString) {
      if (allBlogs && size(allBlogs) > 0) {

        const articleItem = find(allBlogs, (item, key) => { return item.urlString === blogUrlString; });
        if (size(articleItem) > 0) {
          setSelectBlog(articleItem);
          setSelectBlogUrlString(blogUrlString);
        }
      }
    } else {
      setSelectBlogUrlString('');
      setSelectBlog({});
    }
  }, [size(allBlogs)]);

  const toggleActiveTab = (key) => {
    if (activeTab !== key) {
      setActiveTab(key);
    }
  };

  const handleLoadMore = () => {
    setPageShow(prevPageShow => prevPageShow + constants.BLOG_SHOW_TYPES.firstSection);
  };

  const handleLoadMoreMobile = () => {
    setMobilePage(mobilePage + 1);
  };

  const handleReadArticle = (key) => {
    if (allBlogs) {
      const articleData = find(allBlogs, (item) => { return item.key === key });
      setSelectBlog(articleData);
      setSelectBlogUrlString(articleData.urlString);
      blogArticleFunc(true);
      history.push(`/blog/${i18n.language || constants.sendLanguage.lv}/${articleData.urlString}`);
      window.scrollTo(0, 0);
    }
  };

  const goToBlogList = () => {
    setSelectBlog({});
    setSelectBlogUrlString('');
    blogArticleFunc(false);
    history.push('/blog');
    window.scrollTo(0, 0);
  };

  const isMobileLandscape = screenWidth <= 1280 && screenHeight < screenWidth;
  const isMobile = screenWidth <= 1280 && screenHeight <= 1280;

  const maxArticlesShownMobile = mobilePage * elementsPerMobilePage;
  return (
    blogArticleOpen && selectBlogUrlString ? (
      <BlogArticle selectBlogUrlString={selectBlogUrlString} selectBlog={selectBlog} allBlogs={allBlogs} goToBlogList={goToBlogList} handleReadArticle={handleReadArticle}/>
    ) : (
      <Row className="blogs-page d-flex align-items-center">
        <Col sm="12" className="no-padding">
          <h1 className="blogs-page-title">{t('menu.blog')}</h1>
        </Col>
        <Col sm="12" className="no-padding">
          {isMobile ?
          (
            <Row className="blogs-page-first-section d-flex flex-row" style={{marginLeft: 0, marginRight: 0, marginBottom: 35}}>
              {
                allBlogs && Object.keys(allBlogs).slice(0, maxArticlesShownMobile).map((key, index) => (
                  <Col onClick={() => handleReadArticle(allBlogs[key].key)} sm={6} style={{marginBottom: 55}}>
                    <div className="blogs-page-first-section-item d-flex align-items-start flex-column">
                      <div className="blog-image cursor-pointer" onClick={() => handleReadArticle(allBlogs[key].key)}>
                        <Media className="blog-image-bg" src={allBlogs[key].imageUrl} alt="x" />
                        <div className="blog-image-dg" />
                      </div>
                      <div className="title mt-4 cursor-pointer" onClick={() => handleReadArticle(allBlogs[key].key)}>
                        {
                          i18n.language === constants.sendLanguage.en ?
                            allBlogs[key].titleEN 
                            : i18n.language === constants.sendLanguage.lv ?
                              allBlogs[key].titleLV 
                              : i18n.language === constants.sendLanguage.ru ?
                                allBlogs[key].titleRU 
                                : allBlogs[key].titleLV
                        }
                      </div>
                      <div className="description bg-description mt-4">
                        <TextEditor viewEditorState={allBlogs[key][constants.BLOG_RICH_TEXT[i18n.language || constants.sendLanguage.lv]]} readOnly />
                      </div>
                      {/* <div className="footer mt-4">
                        <Button className="read-article" onClick={() => handleReadArticle(allBlogs[key].key)}>{t('home.readArticle')}</Button>
                      </div> */}
                    </div>
                  </Col>
                ))
              }
              { allBlogs && Object.keys(allBlogs).length > maxArticlesShownMobile &&
                <Col sm={12} className="d-flex align-items-center mt-2 mb-5">
                  <Button className="load-more" onClick={handleLoadMoreMobile}>{t('home.loadMore')}</Button>
                </Col>
              }
            </Row>
          ) : (
            <Row className="blogs-page-first-section d-flex flex-row" style={{marginLeft: 0, marginRight: 0}}>
              {
                allBlogs && Object.keys(allBlogs).slice(0, constants.BLOG_SHOW_TYPES.firstSection).map((key, index) => (
                  <Col lg={6} sm={12}>
                    <div className="blogs-page-first-section-item d-flex align-items-start flex-column mt-4">
                      <div className="blog-image cursor-pointer" onClick={() => handleReadArticle(allBlogs[key].key)}>
                        <Media className="blog-image-bg" src={allBlogs[key].imageUrl} alt="x" />
                        <div className="blog-image-dg" />
                      </div>
                      <div className="title mt-4 cursor-pointer" onClick={() => handleReadArticle(allBlogs[key].key)}>
                        {
                          i18n.language === constants.sendLanguage.en ?
                            allBlogs[key].titleEN 
                            : i18n.language === constants.sendLanguage.lv ?
                              allBlogs[key].titleLV 
                              : i18n.language === constants.sendLanguage.ru ?
                                allBlogs[key].titleRU 
                                : allBlogs[key].titleLV
                        }
                      </div>
                      <div className="description bg-description mt-4">
                        <TextEditor viewEditorState={allBlogs[key][constants.BLOG_RICH_TEXT[i18n.language || constants.sendLanguage.lv]]} readOnly />
                      </div>
                      <div className="footer mt-4">
                        <Button className="read-article" onClick={() => handleReadArticle(allBlogs[key].key)}>{t('home.readArticle')}</Button>
                      </div>
                    </div>
                  </Col>
                ))
              }
              {
                allBlogs && size(allBlogs) > constants.BLOG_SHOW_TYPES.firstSection && Object.keys(allBlogs).slice(constants.BLOG_SHOW_TYPES.firstSection, constants.BLOG_SHOW_TYPES.firstSection + constants.BLOG_SHOW_TYPES.secondSection).map((key, index) => (
                  <Col lg={4} sm={12}>
                    <div className="blogs-page-first-section-item d-flex align-items-start flex-column mt-5">
                      <div className="blog-image medium-img cursor-pointer" onClick={() => handleReadArticle(allBlogs[key].key)} >
                        <Media className="blog-image-bg" src={allBlogs[key].imageUrl} alt="x" />
                        <div className="blog-image-dg" />
                      </div>
                      <div className="title md-title mt-4 cursor-pointer" onClick={() => handleReadArticle(allBlogs[key].key)}>
                        {
                          i18n.language === constants.sendLanguage.en ?
                            allBlogs[key].titleEN
                            : i18n.language === constants.sendLanguage.lv ?
                              allBlogs[key].titleLV
                              : i18n.language === constants.sendLanguage.ru ?
                                allBlogs[key].titleRU
                                : allBlogs[key].titleLV
                        }
                      </div>
                      <div className="description md-description mt-4">
                        <TextEditor viewEditorState={allBlogs[key][constants.BLOG_RICH_TEXT[i18n.language || constants.sendLanguage.lv]]} readOnly />
                      </div>
                      <div className="footer mt-4">
                        <Button className="read-article" onClick={() => handleReadArticle(allBlogs[key].key)}>{t('home.readArticle')}</Button>
                      </div>
                    </div>
                  </Col>
                ))
              }
              {
                allBlogs && size(allBlogs) > constants.BLOG_SHOW_TYPES.thirdSection + 1 && Object.keys(allBlogs).slice(constants.BLOG_SHOW_TYPES.firstSection + constants.BLOG_SHOW_TYPES.secondSection, constants.BLOG_SHOW_TYPES.firstSection + constants.BLOG_SHOW_TYPES.secondSection + constants.BLOG_SHOW_TYPES.thirdSection * pageShow).map((key, index) => (
                  <Col lg={3} sm={12}>
                    <div className="blogs-page-first-section-item d-flex align-items-start flex-column mt-5 mb-5">
                      <div className="blog-image sm-img cursor-pointer" onClick={() => handleReadArticle(allBlogs[key].key)} >
                        <Media className="blog-image-bg" src={allBlogs[key].imageUrl} alt="x"/>
                        <div className="blog-image-dg" />
                      </div>
                      <div className="title sm-title mt-4 cursor-pointer" onClick={() => handleReadArticle(allBlogs[key].key)}>
                        {
                          i18n.language === constants.sendLanguage.en ?
                            allBlogs[key].titleEN 
                            : i18n.language === constants.sendLanguage.lv ?
                              allBlogs[key].titleLV 
                              : i18n.language === constants.sendLanguage.ru ?
                                allBlogs[key].titleRU 
                                : allBlogs[key].titleLV
                        }
                      </div>
                      <div className="description sm-description mt-4">
                        <TextEditor viewEditorState={allBlogs[key][constants.BLOG_RICH_TEXT[i18n.language || constants.sendLanguage.lv]]} readOnly />
                      
                      </div>
                      <div className="footer mt-4">
                        <Button className="read-article" onClick={() => handleReadArticle(allBlogs[key].key)}>{t('home.readArticle')}</Button>
                      </div>
                    </div>
                  </Col>
                ))
              }
              {
                allBlogs && size(allBlogs) > constants.BLOG_SHOW_TYPES.firstSection + constants.BLOG_SHOW_TYPES.secondSection + constants.BLOG_SHOW_TYPES.thirdSection * pageShow && (
                  <Col sm={12} className="d-flex align-items-center mt-2 mb-5">
                    <Button className="load-more" onClick={handleLoadMore} disabled={!allBlogs || size(allBlogs) <= constants.BLOG_SHOW_TYPES.firstSection + constants.BLOG_SHOW_TYPES.secondSection + constants.BLOG_SHOW_TYPES.thirdSection * pageShow }>{t('home.loadMore')}</Button>
                  </Col>
                )
              }
            </Row>
          )
          }

        </Col>
      </Row>
    )
  );
};

Blogs.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  blogArticleOpen: PropTypes.bool,
  blogUrlString: PropTypes.string,
  blogArticleFunc: PropTypes.func.isRequired,
};

Blogs.defaultProps = {
  blogArticleOpen: false,
  blogUrlString: null,

};

export default Blogs;
