import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Modal, ModalBody, ModalFooter, ModalHeader, Media, Button,
} from 'reactstrap';
import { size, map } from 'lodash';
import { useTranslation } from 'react-i18next';
import closeImg from '../../../images/icons/close.png';
import { checkedAnnouncements, getAnnouncements } from '../../../actions/member';

const Announcements = ({ fontMode }) => {
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation('common');

  const announcements = useSelector(state => state.member.announcements || {});
  // console.log(announcements, 'check user side');
  useEffect(() => {
    dispatch(getAnnouncements('old'));
  }, []);

  const closeModal = (id) => {
    dispatch(checkedAnnouncements(id, 'old'));
    dispatch(getAnnouncements('old'));
  };

  return (
    <>
      {announcements && size(announcements) > 0 && map(announcements, item => {
        if (!item?.announcementId) return <></>;

        return (
          <Modal container={'div > div'} isOpen size="lg" toggle={() => closeModal(item.announcementId)} className={`old-modal root-font-${fontMode}`}>
            <ModalHeader
              toggle={() => closeModal(item.announcementId)}
              close={
                <Media src={closeImg} className="notification-header-close" alt="X" onClick={() => closeModal(item.announcementId)} />
              }
            >
              {t('common.announcement')}
            </ModalHeader>
            <ModalBody className="text-center">
              <div className="d-flex flex-column gap-10">
                <div className="d-flex flex-column gap-5">
                  <div className="text-center fs-8">{i18n.language === 'lv' ? item.title.lv : i18n.language === 'en' ? item.title.en : i18n.language === 'ru' ? item.title.ru : item.title.lv}</div>
                  <div className="text-left fs-5">{i18n.language === 'lv' ? item.announcement.lv : i18n.language === 'en' ? item.announcement.en : i18n.language === 'ru' ? item.announcement.ru : item.title.lv}</div>
                </div>
                {item.imageUrl && (
                  <div className="announcement-image video-ratio">
                    <Media className="blog-image-bg" src={item.imageUrl} alt="x" />
                  </div>
                )}
                <div />
              </div>

            </ModalBody>
            <ModalFooter>
              <Button className="payment-type-continue-button mb-3" color="secondary" onClick={() => closeModal(item.announcementId)}>
                Ok
              </Button>
            </ModalFooter>
          </Modal>
        )
      })}
    </>
  );
};

export default Announcements;
