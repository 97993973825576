import React from 'react';

import Popover from 'reactstrap/lib/Popover';
import PopoverBody from 'reactstrap/lib/PopoverBody';
import Button from 'reactstrap/lib/Button';
import { useTranslation } from 'react-i18next';

import * as constants from '../../../../../constants/constants';

const CustomPopover = ({
  onHover, onHoverLeave, switchTab, popoverOpen, balance, screenMode, changeTabAction,
}) => {
  const { t } = useTranslation('playerInfo');

  return (
    <Popover
      container={'div > div'}
      placement="left"
      isOpen={popoverOpen}
      target="player-info-player-balance-popover"
      popperClassName={`new-design-popover new-design-popover-${screenMode}`}
      onMouseEnter={onHover}
      onMouseLeave={onHoverLeave}
    >
      <PopoverBody
        onMouseLeave={onHoverLeave}
        className="new-design-popover-body negative-balance-new-design-popover-body"
      >
        <div className="new-design-popover-body-header">
          { balance < 0
            ? t('negativeBalance')
            : t('lowBalance')
          }
        </div>
        <div className="new-design-popover-body-main">
          <div>
            { balance < 0
              ? t('negativeBalanceContent')
              : t('lowBalanceContent')
            }
          </div>
        </div>
        <div className="new-design-popover-body-footer">
          <Button className="new-design-popover-body-footer-purchase-button position-relative new-design-popover-body-footer-highlighted-button" onClick={() => changeTabAction(constants.MENU_NAVIGATION.buyPageMoneyPacks)}>{t('purchase')}</Button>
          {
            balance < 0 && (
              <Button className="new-design-popover-body-footer-reset-button position-relative new-design-popover-body-footer-highlighted-button" onClick={() => changeTabAction(constants.MENU_NAVIGATION.buyPagePremiumPacks)}>{t('deleteNegativeBalance')}</Button>
            )
          }
        </div>
      </PopoverBody>
    </Popover>
  );
};

export default CustomPopover;
