import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';

import ScrollArea from 'react-scrollbar';
import { termsOfText } from '../../../../constants/termsOfUse';
import * as constants from '../../../../constants/constants';
import { isMobile } from 'react-device-detect';


// import closeImg from '../../../../images/icons/close.png';

class TermsOfUsePage extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    cookiesFrom: PropTypes.string,
  }

  static defaultProps = {
    cookiesFrom: '',
  }

  constructor(props) {
    super(props);
    this.state = {
    };
    // this.pointsScrollbar = React.createRef(null);
    this.termsScrollArea = React.createRef(null);
  }

  componentDidMount() {
    const { cookiesFrom } = this.props;
    console.log({ cookiesFrom });

    window.scrollTo(0, 0);
    if (cookiesFrom === constants.COOKIES_TERMS_OPEN.home || cookiesFrom === constants.COOKIES_TERMS_OPEN.login) {
      this.scrollToBottom();
    }


    const shouldScroll = localStorage.getItem('scrollToCookies');
  
    if (shouldScroll === 'true') {
      const element = document.getElementById("cookies-id");

      if (this.termsScrollArea && this.termsScrollArea.current && element) {
        this.termsScrollArea.current.scrollTop = element.offsetTop;
      }
  
      localStorage.removeItem('scrollToCookies');
    }
  }

  componentWillUnmount() {
    try {
      clearTimeout(this.endTimeoutId);
    } catch (err) {
      console.log('err componentWillUnmount');
    }
  }

  scrollToBottom = () => {
    const { cookiesFrom } = this.props;
    if (this.pointsScrollbar) {
      if (cookiesFrom === constants.COOKIES_TERMS_OPEN.login) {
        this.endTimeoutId = setTimeout(() => {
          if (this.pointsScrollbar) {
            this.pointsScrollbar.scrollYTo(3300);
          }
        }, 20);
      } else if (cookiesFrom === constants.COOKIES_TERMS_OPEN.home) {
        this.endTimeoutId = setTimeout(() => {
          if (this.pointsScrollbar) {
            this.pointsScrollbar.scrollYTo(2900);
          }
        }, 20);
      }
    }
  }

  render() {
    const { cookiesFrom, t, i18n } = this.props;

    return (
      !isMobile ? (
        <Row className="zole-rules-page" style={{height: 'calc(100vh - 304px)', minHeight: 220, maxHeight: 800}}>
          {/* <Col md="12">
            <h1 className="zole-rules-page-title">{t('menu.termsOfUse')}</h1>
          </Col> */}
          <Col xs="12" className="zole-rules-page-body terms-of-use-body">
            <div
              className="contact-support-chat-body-scroll-area contact-support-chat-body-scroll-area-content  zole-rules-page-body-scroll-area"
              ref={this.termsScrollArea}
            >
              <div
                dangerouslySetInnerHTML={{ __html: termsOfText[i18n.language || constants.sendLanguage.lv] }}
                style={{marginRight: '5px'}}
                ref={(el) => { this.messagesScrollbar = el; }}
              />
            </div>
          </Col>
        </Row>
      ) : (
        <div className="zole-rules-container">
          <Row className="zole-rules-page">
            {/* <Col md="12">
              <h1 className="zole-rules-page-title">{t('menu.termsOfUse')}</h1>
            </Col> */}
            <Col sm="12" className="zole-rules-page-content" style={{ paddingRight: 5 }}>
              <div
                className="contact-support-chat-body-scroll-area contact-support-chat-body-scroll-area-content  zole-rules-page-body-scroll-area"
                ref={this.termsScrollArea}
              >
                <div
                  dangerouslySetInnerHTML={{ __html: termsOfText[i18n.language || constants.sendLanguage.lv] }}
                  style={{marginRight: '5px'}}
                  ref={(el) => { this.messagesScrollbar = el; }}
                />
              </div>
            </Col>
          </Row>
        </div>
      )
    );
  }
}

export default withTranslation('common')(TermsOfUsePage);
