import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';

import { connect } from 'react-redux';

import Col from 'reactstrap/lib/Col';
import Button from 'reactstrap/lib/Button';
import Popover from 'reactstrap/lib/Popover';
import PopoverBody from 'reactstrap/lib/PopoverBody';
import Media from 'reactstrap/lib/Media';

import CustomModal from '../../../Components/Components/Modal';
import SwitchSelect from '../../../Components/Components/SwitchSelect';
import Toggle from '../../../Components/Components/Toggle';
import IconPopover from '../../../Components/Components/IconPopover';

import buttonClickedSound from '../../../../../../sounds/click_feedback.flac';
import infoImg from '../../../../../../images/redesign/player/info.svg';
import lightInfoImg from '../../../../../../images/redesign/light-mode/player/info.svg';

import {
  toggleNewGameParam, setNewGameBet, setNewMinGamesFunc, setNewGameSpeed,
} from '../../../../../../actions/userSettings';

import { createRoom, resetRoomError } from '../../../../../../actions/room';
import { updateAutoMergeRooms } from '../../../../../../actions/member';

import { size } from 'lodash';
import config from '../../../../../../constants/config';
import { MENU_NAVIGATION } from '../../../../../../constants/constants';

/*
const GameBetMap = {
  '1:1': '1:1',
  '1:5': '1:5',
  '1:10': '1:10',
  '1:25': '1:25',
  '1:50': '1:50',
  '1:100': '1:100',
  '1:500': '1:500',
  '1:1000': '1:1000',
  '1:5000': '1:5000',
  '1:10000': '1:10000',
};

const GameSpeedMap = {
  parasta: 'Parasta',
  atra: 'Ātrā',
  lightning: 'Zibensistaba',
};

const MinGamesMap = {
  1: '1',
  3: '3',
  5: '5',
  10: '10',
  15: '15',
  20: '20',
  25: '25',
};  */


/*
const GameBetMap = [
  { name: '1:1', value: '1:1' },
  { name: '1:5', value: '1:5' },
  { name: '1:10', value: '1:10' },
  { name: '1:25', value: '1:25' },
  { name: '1:50', value: '1:50' },
  { name: '1:100', value: '1:100' },
  { name: '1:500', value: '1:500' },
  { name: '1:1000', value: '1:1000' },
  { name: '1:5000', value: '1:5000' },
  { name: '1:10000', value: '1:10000' },
];

const GameSpeedMap = [
  { name: 'parasta', value: 'Parasta' },
  { name: 'atra', value: 'Ātrā' },
  { name: 'lightning', value: 'Zibensistaba' },
];

const MinGamesMap = [
  { name: 'noLimit', value: 1 },
  { name: '3', value: 3 },
  { name: '5', value: 5 },
  { name: '10', value: 10 },
  { name: '15', value: 15 },
  { name: '20', value: 20 },
  { name: '25', value: 25 },
];  */

class NewGame extends React.Component {
  static propTypes = {
    createRoomFunc: PropTypes.func.isRequired,
    changeTab: PropTypes.func.isRequired,
    closeNewGame: PropTypes.func.isRequired,
    privateRoomPassword: PropTypes.number,
    uid: PropTypes.string,
    activeRoom: PropTypes.string,
    activeTournament: PropTypes.string,
    finishedTournamentLastRound: PropTypes.bool,
    //  GameBetMap: PropTypes.shape(),
    //  MinGamesMap: PropTypes.shape(),
    t: PropTypes.func.isRequired,
    closePrivateRoomPassword: PropTypes.func.isRequired,
    setAutoMergeRooms: PropTypes.func.isRequired,
    userSettings: PropTypes.shape({
      parasta: PropTypes.bool,
      G: PropTypes.bool,
      //  atra: PropTypes.bool,
      pro: PropTypes.bool,
      maza: PropTypes.bool,
      privateRoom: PropTypes.bool,
      bet: PropTypes.string,
      minGames: PropTypes.string,
      speed: PropTypes.string,
      fourPRoom: PropTypes.bool,
      studentZole: PropTypes.bool,
      soundOn: PropTypes.bool,
    }),
    showPrivateRoomPassword: PropTypes.bool,
    toggleNewGameParameter: PropTypes.func.isRequired,
    setNewBet: PropTypes.func.isRequired,
    setNewSpeed: PropTypes.func.isRequired,
    setNewMinGames: PropTypes.func.isRequired,
    resetError: PropTypes.func.isRequired,
    //  playButtonSound: PropTypes.func.isRequired,
    tournaments: [],
    myTournamentsData: {},
    offset: 0,
  };

  static defaultProps = {
    uid: null,
    activeRoom: null,
    activeTournament: null,
    finishedTournamentLastRound: null,
    showPrivateRoomPassword: false,
    userSettings: {},
    privateRoomPassword: null,
    tournaments: [],
    myTournamentsData: {},
    offset: 0,
  };

  constructor(props) {
    super(props);

    const { t } = this.props;

    const { hostname } = window.location;

    let devMode = false;
    if (hostname.includes('dev.') || hostname.includes('localhost')) {
      devMode = true;
    }

    const GameBetMap = [
      { name: '1:10', value: '1:10' },
      { name: '1:25', value: '1:25' },
      { name: '1:50', value: '1:50' },
      { name: '1:100', value: '1:100' },
      { name: '1:250', value: '1:250' },
      { name: '1:500', value: '1:500' },
      { name: '1:1000', value: '1:1000' },
      { name: '1:5000', value: '1:5000' },
      { name: '1:10000', value: '1:10000' },
    ];

    const GameBetMapLowLevel = [
      // { name: '1:1', value: '1:1' },
      // { name: '1:5', value: '1:5' },
      { name: '1:10', value: '1:10' },
      { name: '1:25', value: '1:25' },
      { name: '1:50', value: '1:50' },
      { name: '1:100', value: '1:100' },
      { name: '1:250', value: '1:250' },
      { name: '1:500', value: '1:500' },
      { name: '1:1000', value: '1:1000' },
      { name: '1:5000', value: '1:5000' },
      { name: '1:10000', value: '1:10000' },
    ];

    let GameSpeedMap = [
      { name: t('common.slow'), value: 'parasta' },
      { name: t('newGame.atra'), value: 'atra' },
      { name: t('newGame.lightning'), value: 'lightning' },
    ];
    if (devMode) {
      GameSpeedMap = [
        { name: t('newGame.unlimitedGame'), value: 'unlimited' },
        { name: t('common.slow'), value: 'parasta' },
        { name: t('newGame.atra'), value: 'atra' },
        { name: t('newGame.lightning'), value: 'lightning' },
      ];
    }

    const MinGamesMap = [
      { name: t('newGame.noLimit'), value: 1 },
      { name: '3', value: 3 },
      { name: '5', value: 5 },
      { name: '10', value: 10 },
      { name: '15', value: 15 },
      { name: '20', value: 20 },
      { name: '25', value: 25 },
    ];

    this.state = {
      createRoomClicked: false,
      gameBetChoserOpen: false,
      gameSpeedChoserOpen: false,
      minGamesChoserOpen: false,
      popoverOpen: false,
      GameBetMap,
      GameBetMapLowLevel,
      GameSpeedMap,
      MinGamesMap,
    };

    this.buttonClickedAudio = new Audio(buttonClickedSound);
  }

  componentDidMount() {
    const { hostname } = window.location;

    let devMode = false;
    if (hostname.includes('dev.') || hostname.includes('localhost')) {
      devMode = true;
    }

    this.setState({ devMode });
  }

  componentDidUpdate(prevProps, prevState) {
    const { t, joinRoomError, closeNewGame, joinedRooms } = this.props;
    const { errorModalOpen } = this.state;

    console.log('componentDidUpdate', joinRoomError, errorModalOpen);

    if (!joinRoomError && joinedRooms && !prevProps.joinedRooms) {
      closeNewGame();
    }

    if (!errorModalOpen && joinRoomError && joinRoomError.error && !prevProps.joinRoomError) {
      console.log('set error');
      if (joinRoomError.error === 'insufficient balance') {
        this.setState({
          createRoomClicked: false, errorModalOpen: true, errorModalBuyMoney: true, errorModalText: t('notifications:noMoneyMenuDescription', { amount: joinRoomError.balNeeded, bet: joinRoomError.bet }), errorModalHeader: t('notifications:noMoneyMenuDescriptionHeader'),
        });
      } else if (joinRoomError.error === 'pro room') {
        this.setState({
          createRoomClicked: false, errorModalOpen: true, errorModalText: t('notifications:proRoomMenu'), errorModalHeader: t('notifications:proRoomMenuHeader'),
        });
      } else if (joinRoomError.error === 'pro bet') {
        this.setState({
          createRoomClicked: false, errorModalOpen: true, errorModalText: t('notifications:proBetMenu'), errorModalHeader: t('common:home.notifications'),
        });
      } else if (joinRoomError.error === 'email not verified') {
        this.setState({
          createRoomClicked: false, errorModalOpen: true, errorModalText: t('notifications:emailNotVerified'), errorModalHeader: t('notifications:emailNotVerifiedHeader'),
        });
      } else if (joinRoomError.error === 'in active tournament') {
        this.setState({
          createRoomClicked: false, errorModalOpen: true, errorModalText: t('notifications:inActiveTournament'), errorModalHeader: t('common:home.confirm'),
        });
      } else {
        this.setState({ createRoomClicked: false });
      }
    }
  }

  toggleAutoMergeRooms = () => {
    const { autoMergeRooms, setAutoMergeRooms } = this.props;

    if (autoMergeRooms) {
      setAutoMergeRooms(false);
    } else {
      setAutoMergeRooms(true);
    }
  }

  toggleParasta = () => {
    const { toggleNewGameParameter, uid } = this.props;

    this.playButtonSound();

    toggleNewGameParameter('parasta', uid);
  };

  toggleG = () => {
    const { toggleNewGameParameter, uid } = this.props;

    this.playButtonSound();

    toggleNewGameParameter('G', uid);
  };

  /*  toggleAtra = () => {
    const { toggleNewGameParameter, uid } = this.props;

    this.playButtonSound();

    toggleNewGameParameter('atra', uid);
  }; */

  togglePro = () => {
    const { toggleNewGameParameter, uid } = this.props;

    this.playButtonSound();

    toggleNewGameParameter('pro', uid);
  };

  toggleSmall = () => {
    const { toggleNewGameParameter, uid } = this.props;

    this.playButtonSound();

    toggleNewGameParameter('maza', uid);
  };

  togglePrivate = () => {
    const { toggleNewGameParameter, userSettings, uid } = this.props;
    const { privateRoom } = userSettings;

    this.playButtonSound();

    console.log('togglePrivate', { privateRoom });

    toggleNewGameParameter('privateRoom', uid);
  };

  toggleFourPRoom = () => {
    const { toggleNewGameParameter, uid } = this.props;

    this.playButtonSound();

    toggleNewGameParameter('fourPRoom', uid);
  };

  toggleFourPRoom2 = (value) => {
    const { toggleNewGameParameter, userSettings, uid } = this.props;
    const { fourPRoom, studentZole } = userSettings;

    this.playButtonSound();

    if (value === 2) {
      if (fourPRoom) {
        toggleNewGameParameter('fourPRoom', uid);
      }
      toggleNewGameParameter('studentZole', uid);
    } else if (value === 3) {
      if (fourPRoom) {
        toggleNewGameParameter('fourPRoom', uid);
      }
      if (studentZole) {
        toggleNewGameParameter('studentZole', uid);
      }
    } else if (value === 4) {
      if (studentZole) {
        toggleNewGameParameter('studentZole', uid);
      }
      toggleNewGameParameter('fourPRoom', uid);
    }

    // toggleNewGameParameter('fourPRoom', uid);
  };

  toggleStudentZole = () => {
    const { toggleNewGameParameter, uid } = this.props;

    this.playButtonSound();

    console.log('toggleStudentZole', uid);

    toggleNewGameParameter('studentZole', uid);
  };

  confirmTogglePrivate = () => {
    const { toggleNewGameParameter, uid } = this.props;

    this.playButtonSound();

    toggleNewGameParameter('privateRoom', uid);
    this.setState({ privateRoomConfirm: false });
  };

  selectBet = (newBet) => {
    const { setNewBet, uid } = this.props;

    this.playButtonSound();

    setNewBet(newBet, uid);
  };

  selectSpeed = (newSpeed) => {
    const { setNewSpeed, uid } = this.props;

    this.playButtonSound();

    setNewSpeed(newSpeed, uid);
  }

  selectMinGames = (newMinGames) => {
    const { setNewMinGames, uid } = this.props;

    this.playButtonSound();

    setNewMinGames(newMinGames, uid);
  };

  closePrivateConfirm = () => {
    this.setState({ privateRoomConfirm: false });
  };

  createRoomClicked = async () => {
    const {
      t, createRoomFunc, userSettings, activeTournament, finishedTournamentLastRound, closeNewGame, lvl, autoMergeRooms, setLastPlayerChangeModalClosed,
    } = this.props;
    const {
      createRoomClicked, devMode,
    } = this.state;

    if (userSettings.pro && lvl < 10) {
      this.setState({
        createRoomClicked: false, errorModalOpen: true, errorModalText: t('notifications:needLevelForPro'), errorModalHeader: t('game:message'),
      });
      return null;
    }

    if (activeTournament && !finishedTournamentLastRound) {
      return null;
    }

    const checkTournament = await this.checkTournamentLine();
    if (checkTournament) {
      this.setState({
        createRoomClicked: false, errorModalOpen: true, errorModalText: t('notifications:inActiveTournament'), errorModalHeader: t('common:home.confirm'),
      });
      return null;
    }

    const {
      parasta, G, pro, maza, privateRoom, bet, minGames, fourPRoom, speed, studentZole,
    } = userSettings;

    if (!createRoomClicked) {
      this.setState({ createRoomClicked: true });

      this.playButtonSound();

      await createRoomFunc(parasta, G, null, pro, bet, maza, privateRoom, minGames || '1', fourPRoom, speed, (autoMergeRooms), (studentZole && devMode)).then((res) => {
        console.log('joinRoomFunc res', { res });

        setLastPlayerChangeModalClosed(false);

        if (!res || !res.data) {
          this.setState({
            createRoomClicked: false,
          });
          closeNewGame();
        } else if (res.data.data.status === 'success') {
          if (res.data.data.password) {
            this.setState({
              createRoomClicked: false,
              privateRoomPassword: res.data.data.password,
              showPrivateRoomPassword: true,
            });

            closeNewGame();
          } else {
            this.setState({
              createRoomClicked: false,
            });

            closeNewGame();
          }
        } else if (res.data.data.status === 'error') {
          const { error, balNeeded } = res.data.data;

          if (error) {
            if (error === 'insufficient balance') {
              this.setState({
                createRoomClicked: false, errorModalOpen: true, errorModalBuyMoney: true, errorModalText: t('notifications:noMoneyMenuDescription', { amount: balNeeded, bet }), errorModalHeader: t('notifications:noMoneyMenuDescriptionHeader'),
              });
            } else if (error === 'pro room') {
              this.setState({
                createRoomClicked: false, errorModalOpen: true, errorModalText: t('notifications:proRoomMenu'), errorModalHeader: t('notifications:proRoomMenuHeader'),
              });
            } else if (error === 'pro bet') {
              this.setState({
                createRoomClicked: false, errorModalOpen: true, errorModalText: t('notifications:proBetMenu'), errorModalHeader: t('common:home.notifications'),
              });
            } else if (error === 'email not verified') {
              this.setState({
                createRoomClicked: false, errorModalOpen: true, errorModalText: t('notifications:emailNotVerified'), errorModalHeader: t('notifications:emailNotVerifiedHeader'),
              });
            } else if (error === 'in active tournament') {
              this.setState({
                createRoomClicked: false, errorModalOpen: true, errorModalText: t('notifications:inActiveTournament'), errorModalHeader: t('common:home.confirm'),
              });
            } else {
              this.setState({ createRoomClicked: false });
            }
          }
        }
        //  this.setState({ createRoomClicked: false });
      }).catch((err) => {
        console.log(err);
        this.setState({ createRoomClicked: false });
      });
    }

    return null;
  };

  checkTournamentLine = () => {
    const { tournaments, myTournamentsData, offset } = this.props;

    if (size(myTournamentsData) > 0) {
      if (size(tournaments) > 0) {

        if (myTournamentsData.tournamentId) {
          const curTournament = tournaments.find(item => item.id === myTournamentsData.tournamentId);

          if (curTournament && curTournament.currentState === 'registration') {
            if (curTournament && curTournament.startDate && curTournament.startDate - (1000 * 60 * 5) < (Date.now() + offset)) {
              return true;
            }
          }
        }
      }
    }
    return false;
  }

  closeErrorModal = () => {
    const { resetError } = this.props;
    resetError();

    this.setState({
      errorModalOpen: false, errorModalText: null, errorModalHeader: null, errorModalBuyMoney: false,
    });
  }

  buyMoney = () => {
    const { changeTab, resetError } = this.props;

    resetError();

    this.setState({
      errorModalOpen: false, errorModalText: null, errorModalHeader: null, errorModalBuyMoney: false,
    });

    changeTab(MENU_NAVIGATION.buyPageMoneyPacks);
  }

  toggleGameBetChoser = () => {
    this.setState(prevState => ({
      gameBetChoserOpen: !prevState.gameBetChoserOpen,
    }));
  }

  toggleGameSpeedChoser = () => {
    this.setState(prevState => ({
      gameSpeedChoserOpen: !prevState.gameSpeedChoserOpen,
    }));
  }

  toggleMinGamesChoser = () => {
    this.setState(prevState => ({
      minGamesChoserOpen: !prevState.minGamesChoserOpen,
    }));
  }

  onHover = () => {
    const { popoverOpen } = this.state;

    if (!popoverOpen) {
      this.setState({
        popoverOpen: true,
      });
    }
  }

  onHoverLeave = () => {
    this.setState({
      popoverOpen: false,
    });
  }

  playButtonSound = () => {
    const { soundOn, uid } = this.props;

    if (soundOn) {
      const playPromise = this.buttonClickedAudio.play();
      if (playPromise !== undefined) {
        playPromise
          .then(_ => {
            // Automatic playback started!
            // Show playing UI.
            console.log("audio played auto");
          })
          .catch(error => {
            // Auto-play was prevented
            // Show paused UI.
            console.log("playback prevented");
          });
      }
    }
  }

  mouseEnterMergePopover = () => {
    this.setState({ mergePopoverActive: true });
  }

  mouseLeaveMergePopover = () => {
    this.setState({ mergePopoverActive: false });
  }

  render() {
    const {
      t,
      lvl,
      privateRoomPassword,
      showPrivateRoomPassword,
      closePrivateRoomPassword,
      userSettings,
      activeRoom,
      activeTournament,
      finishedTournamentLastRound,
      joinRoomError,
      autoMergeRooms,
    } = this.props;

    const {
      privateRoomConfirm,
      popoverOpen,
      errorModalOpen,
      errorModalText,
      errorModalBuyMoney,
      errorModalHeader,
      GameBetMap,
      GameBetMapLowLevel,
      GameSpeedMap,
      MinGamesMap,
      devMode,
      mergePopoverActive,
    } = this.state;

    console.log('joinRoomError', joinRoomError);

    console.log('userSettingsuserSettings', { userSettings });

    console.log('new game autoMergeRooms', autoMergeRooms, devMode);

    let digitsArr = [];

    if (privateRoomPassword) {
      const digits = privateRoomPassword.toString().split('');
      digitsArr = digits.map((number, index) => ({ number, index }));
    }

    const isInAppFrame = config.isInAppFrame();
    const toggleStyle = isInAppFrame ? { display: 'inline-block', paddingLeft: 10, verticalAlign: 'baseline' } : {verticalAlign: 'baseline'};
    const marginStyle = {display: 'block', height: 17};
    return (
      <Fragment>
        <div className="new-game">
          <div className={`new-game-body ${isInAppFrame ? 'new-game-body-in-app-frame' : ''}`}>
            <div>
              <Col>
                <div className="new-game-title">
                  {t('common.gameType')}
                </div>
                <div>
                  <SwitchSelect innerClassName="create-room-switch display-inline" options={[{ name: t('newGame.regularType'), value: true }, { name: t('newGame.tableType'), value: false }]} selectedOption={userSettings.parasta} selectFunc={this.toggleParasta} />
                  <div className="display-inline mr-3 mb-1 mt-2 ml-3" style={toggleStyle}>
                    <Toggle checked={userSettings.maza} onChange={this.toggleSmall} text={t('newGame.smallGame')} />
                  </div>
                </div>
                <div style={marginStyle}/>

                <div className="new-game-title">{t('newGame.roomType')}</div>
                <SwitchSelect
                  innerClassName="create-room-switch display-inline"
                  // options={[{ name: t('newGame.fourPRoom'), value: true }, { name: t('newGame.threePRoom'), value: false }]}
                  options={[{ name: t('newGame.fourPRoom'), value: 4 }, { name: t('newGame.threePRoom'), value: 3 }, { name: t('newGame.studentZole'), value: 2 }]}
                  selectedOption={userSettings.studentZole ? 2 : (userSettings.fourPRoom ? 4 : 3)}
                  // selectFunc={this.toggleFourPRoom}
                  selectFunc={value => this.toggleFourPRoom2(value)}
                />

                {/*
                {devMode ? (
                  <div className="display-inline mr-3 mb-1 mt-2" style={toggleStyle}>
                    <Toggle checked={userSettings.studentZole} onChange={this.toggleStudentZole} text={t('newGame.studentZole')} />
                  </div>
                ) : (null)}
                */}

                <div className="display-inline mr-3 mb-1 mt-2 ml-3" style={toggleStyle}>
                  <Toggle checked={userSettings.privateRoom} onChange={this.togglePrivate} text={t('newGame.privateRoom')} />
                </div>

                <div className="display-inline mr-3 mb-1 mt-2" style={toggleStyle}>
                  <Toggle checked={userSettings.pro} onChange={this.togglePro} text={t('newGame.proGame')} />
                </div>
                <div style={marginStyle}/>
              </Col>
            </div>
            <div>
              <Col>
                <div className="new-game-title">{t('common.speed')}</div>
                <SwitchSelect innerClassName="create-room-switch display-inline" options={GameSpeedMap} selectedOption={userSettings.speed} selectFunc={this.selectSpeed} />
                <div style={marginStyle}/>
              </Col>
            </div>
            <div>
              <Col>
                <div className="new-game-title">{t('common.bet')}</div>
                <SwitchSelect innerClassName="create-room-switch display-inline" options={(lvl && lvl >= 3) ? GameBetMap : GameBetMapLowLevel} selectedOption={userSettings.bet} selectFunc={this.selectBet} />
                <div style={marginStyle}/>
              </Col>
            </div>
            <div>
              <Col>
                <div className="new-game-title">{t('common.minGames')}</div>
                <SwitchSelect innerClassName="create-room-switch display-inline" options={MinGamesMap} selectedOption={parseInt(userSettings.minGames)} selectFunc={this.selectMinGames} />
                <div style={marginStyle}/>
              </Col>
            </div>
          </div>
            <div className="display-inline mr-3 mb-1 mt-2" style={toggleStyle}>
              <Toggle checked={autoMergeRooms} onChange={this.toggleAutoMergeRooms} text={t('newGame.autoMergeRooms')} />
              <div id="merge-info-button" onMouseEnter={() => this.mouseEnterMergePopover()} onMouseLeave={() => this.mouseLeaveMergePopover()} className="new-game-info-imageWrapper">
                <Media className="new-game-info-image" src={infoImg} alt="" />
                {/* <div className={`new-game-info-hover ${mergePopoverActive ? null : 'display-none'}`}>
                  {t('newGame.autoMergeRoomsInfo')}
                </div> */}
                <IconPopover text={t('roomsTable.autoMergeRoomsInfo')} placement="right" popoverOpen={mergePopoverActive} targetId="merge-info-button" />
              </div>
            </div>

          <div className="modal-footer">
            <Button
              className={`layout-highlighted-button ${(activeTournament && !finishedTournamentLastRound) && 'disabled'}`}
              color="link"
              onClick={() => {
                this.createRoomClicked();
              }}
              id="newGame-button"
              onMouseEnter={this.onHover}
              onMouseLeave={this.onHoverLeave}
            >
              {t('newGame.startGame')}
            </Button>
            {((activeTournament && !finishedTournamentLastRound) && !activeRoom) ? (
              <IconPopover text={t('common.inTournamentMessage')} placement="top" popoverOpen={popoverOpen} targetId="newGame-button" />
            ) : (null)}
          </div>
        </div>

        <CustomModal
          isOpen={privateRoomConfirm}
          toggle={this.closePrivateConfirm}
          title={t('newGame.privateRoomConfirm')}
          footer={(
            <>
              <Button
                color="link"
                onClick={this.confirmTogglePrivate}
              >
                {t('common.yes')}
              </Button>
              <Button
                color="link"
                onClick={() => this.closePrivateConfirm()}
              >
                {t('common.no')}
              </Button>
            </>
          )}
        />

        <CustomModal
          isOpen={showPrivateRoomPassword}
          toggle={this.closePrivateRoomPassword}
          title={t('newGame.yourPassword')}
          body={(
            <div>
              {digitsArr.map(digit => (
                <div key={`digit2-${digit.number}-${digit.index}`} className="room-password-digit">{digit.number}</div>
              ))}
            </div>
          )}
          footer={(
            <>
              <Button
                color="link"
                onClick={() => closePrivateRoomPassword()}
              >
                {t('common.ok')}
              </Button>
            </>
          )}
        />

        <CustomModal
          isOpen={joinRoomError || errorModalOpen}
          toggle={this.closeErrorModal}
          size="sm"
          verticalCentering
          largeText
          title={errorModalHeader}
          body={(
            <div className="text-center">
              {errorModalText}
            </div>
          )}
          footer={(
            <>
              {errorModalBuyMoney ? (
                <Button color="link" onClick={this.buyMoney}>
                  {t('myInfo.buyMoney')}
                </Button>
              ) : (
                <Button color="link" onClick={this.closeErrorModal}>
                  {t('common.ok')}
                </Button>
              )}
            </>
          )}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  userSettings: state.userSettings[state.member.uid] || state.userSettings.default || {},
  soundOn: state.userSettings.soundOn,
  uid: state.member.uid,
  lvl: state.member.level,
  activeRoom: state.member.activeRoom,
  activeTournament: state.member.activeTournament || null,
  joinedRooms: state.member.joinedRooms || null,
  joinRoomError: state.member.joinRoomError,
  tournaments: (state.tournaments && state.tournaments.tournaments) ? state.tournaments.tournaments : [],
  myTournamentsData: (state.tournaments && state.tournaments.myTournamentsData) ? state.tournaments.myTournamentsData : {},
  offset: state.member.offset || 0,
  autoMergeRooms: state.member.autoMergeRooms || null,
});

const mapDispatchToProps = {
  toggleNewGameParameter: toggleNewGameParam,
  setNewBet: setNewGameBet,
  setNewSpeed: setNewGameSpeed,
  setNewMinGames: setNewMinGamesFunc,
  createRoomFunc: createRoom,
  resetError: resetRoomError,
  setAutoMergeRooms: updateAutoMergeRooms,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation(['common', 'notifications'])(NewGame));
