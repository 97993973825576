import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import {
  CALC_TOURNAMENT_ROUND_LENGTH,
} from '../../../../../../constants/constants';

class PopoverEndTimer extends React.Component {
    static propTypes = {
      offset: PropTypes.number,
      tournamentId: PropTypes.string,
      tournaments: PropTypes.shape({}),
      fetchTournamentActiveRooms: PropTypes.func.isRequired,
    }
  
    static defaultProps = {
      offset: 0,
      tournamentId: '',
      tournaments: {},
    }
  
    constructor(props) {
      super(props);
      this.state = {
        minutes: 0,
        seconds: 0,
        time: 0,
        popoverRoomsCount: 0,
        popoverPartiesCount: 0,
      };
    }
  
    componentDidMount() {
      const { fetchTournamentActiveRooms, tournaments, tournamentId, offset: offsetVal } = this.props;

      fetchTournamentActiveRooms(tournamentId).then((res) => {
        const tournament = tournaments.find(element => element.id === tournamentId);

        console.log('fetchTournamentActiveRooms res.data', res.data);

        if (res && res.data) {
          const roomsCount = Object.keys(res.data).length;
          let partiesCount = 0;

          Object.keys(res.data).map((key) => {
            if (res.data[key] && res.data[key].party) {
              partiesCount += parseInt(res.data[key].party, 10);
            }
            return null;
          });

          console.log('gameStartTime tournament', tournament);

          const { roundLength, gameStartTime, fastGame, lightningGame } = tournament;
          let { roundLengthTime } = tournament;
          const averageParties = partiesCount / roomsCount;
          const averagePartiesLeft = Math.round(parseInt(roundLength, 10) - averageParties);

          console.log('gameStartTime', gameStartTime);

          let speed = 'normal';

          if (fastGame) {
            speed = 'atra';
          } else if (lightningGame) {
            speed = 'lightning';
          }

          if (!roundLengthTime) {
            roundLengthTime = CALC_TOURNAMENT_ROUND_LENGTH(roundLength, speed);
          }

          let gameEndTime = gameStartTime + (roundLengthTime * 60 * 1000);

          /* if (roundLength > 24) {
            gameEndTime = gameStartTime + (60 * 60 * 1000);
          } else if (roundLength > 12) {
            gameEndTime = gameStartTime + (40 * 60 * 1000);
          } */

          console.log('gameEndTime', gameEndTime);

          if (gameEndTime) {
            const gameLength = gameEndTime - (Date.now() + offsetVal);
            const minutes = Math.floor((gameLength % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((gameLength % (1000 * 60)) / 1000);
      
            if (minutes >= 0 && seconds >= 0) {
              this.setState({
                minutes: minutes < 10 ? `0${minutes}` : minutes,
                seconds: seconds < 10 ? `0${seconds}` : seconds,
              });
            }
          }
      
          this.intervalID = setInterval(() => {
            const { offset } = this.props;
      
            console.log('time', gameEndTime);
      
            if (gameEndTime) {
              const gameLength = gameEndTime - (Date.now() + offset);
              const minutes = Math.floor((gameLength % (1000 * 60 * 60)) / (1000 * 60));
              const seconds = Math.floor((gameLength % (1000 * 60)) / 1000);
      
              if (minutes >= 0 && seconds >= 0) {
                this.setState({
                  minutes: minutes < 10 ? `0${minutes}` : minutes,
                  seconds: seconds < 10 ? `0${seconds}` : seconds,
                });
              }
            }
          }, 1000);

          this.setState({ popoverOpen: tournamentId, time: gameEndTime, popoverRoomsCount: roomsCount, popoverPartiesCount: roomsCount > 0 ? averagePartiesLeft : 0 });
        }
      });
    }
  
    componentWillUnmount() {
      clearInterval(this.intervalID);
    }
  
    render() {
      const { minutes, seconds, time } = this.state;
  
    //  console.log('time', time, minutes, seconds);
  
      if (!time) return null;
  
      return (
        `${minutes}:${seconds}`
      );
    }
  }

  export default (withTranslation(['common', 'notifications'])(PopoverEndTimer))
