import React, { Fragment, useRef } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Avatar from 'react-avatar-edit';
import * as EmailValidator from 'email-validator';
import moment from 'moment'
import {
  Alert,
  Button,
  Media,
  Label,
} from 'reactstrap';
import ReactGA from 'react-ga';

import pencilImg from '../../../../images/redesign/player/pencil.svg';

import CustomModal from '../Components/Components/Modal';
import { SwitchToggle } from '../Components/Components/SwitchToggle';
import { TextInput } from '../Components/Components/TextInput';
import SwitchSelect from '../Components/Components/SwitchSelect';

import Frame from '../../../../images/redesign/player/frame.svg';
import ToggleField from '../Components/Components/ToggleField';
import Profile from '../Components/Components/Profile';
import Footer from '../Components/Components/Footer';
import * as constants from '../../../../constants/constants';
import { errorMessages } from '../../../../constants/messages';
import {
  updateProfile,
  updateAvatarFileUpload,
  requestDeleteUser,
  logout,
  handleSetTheme,
} from '../../../../actions/member';
import defaultImage from '../../../../images/redesign/common/default_image.svg';
import defaultImageLight from '../../../../images/redesign/light-mode/common/default_image.svg';
import ErrorNotification from '../Components/Components/ErrorNotification';
import config from '../../../../constants/config';
import IconPopover from '../Components/Components/IconPopover';

const tggleVal = {
  profileData: 'profileData',
  settings: 'settings',
};

const stages = {
  profileData: 'profileData',
  changeEmail: 'changeEmail',
  addEmail: 'addEmail',
  changePass: 'changePass',
  deleteAccount: 'deleteAccount',
  confirmation: 'confirmation',
  settings: 'settings',
  promotion: 'promotion',
};

const titles = {
  addEmail: 'addEmail',
  changeEmail: 'changeEmail',
  changePass: 'changePass',
  confirmation: 'confirmation',
  settings: 'settings',
  profileData: 'profileData',
  promotion: 'promotion',
};

const triggerStyle = {
  display: 'flex',
  flex: 1,
  position: 'absolute',
  bottom: 6,
  zIndex: 100,
  width: 44,
  height: 44,
  borderRadius: 50,
  background: `linear-gradient(180deg, #333333 0%, #121212 100%)`,
  justifyContent: 'center',
  alignItems: 'center',
};

const checkBoxTitleStyle = {
  marginLeft: 6,
  fontFamily: 'Open Sans',
  color: 'white',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: 16,
};

const deleteProfileStyle = {
  height: 26,
  marginTop: 30,
  textAlign: 'center',
  color: '#D2110A',
  textDecoration: 'underline',
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontWeight: 400,
  cursor: 'pointer',
};

const deleteProfileContainerStyle = {
  display: 'flex',
  height: 26,
  flexDirection: 'row',
  width: 223,
  marginBottom: 40,
  justifyContent: 'space-between',
};

const deleteProfileContainerStyleCenter = {
  display: 'flex',
  height: 26,
  flexDirection: 'row',
  width: 223,
  marginBottom: 40,
  justifyContent: 'center',
};

const UpdateProfileComponent = (props) => {
  const { member, requestDeleteUser1, updateProfile1, updateAvatarFileUpload1, t, signOut, history, screenMode, toggleScreenMode, setThemeFunc, checkEmail, overlayMode, isMobile, loggedInUserDataFetched } = props;
  const emailAdded = (member.socProvider !== constants.EMAIL_PROVIDERS && !member.contactEmail) || (member.socProvider !== constants.EMAIL_PROVIDERS && member.contactEmail === constants.NO_EMAIL_USER) ? false : true;
  const [open, setOpen] = React.useState(false);
  const [clickedCancelAddEmailModal, setClickedCancelAddEmailModal] = React.useState(false);
  const [stage, setStage] = React.useState(stages.profileData);
  const [selected, setSelected] = React.useState(tggleVal.profileData);
  const [email, setEmail] = React.useState(emailAdded ? member.email : '');
  const [modalTitle, setModalTitle] = React.useState(stages.profileData);
  const [changePhoto, setChangePhoto] = React.useState(false);
  const [selectedImage, setSelectedImage] = React.useState(null);
  const [photo, setPhoto] = React.useState(member.photo || null);
  const [socProvider, setSocProvider] = React.useState(member.socProvider);
  const [password, setPassword] = React.useState('');
  const [password2, setPassword2] = React.useState('');
  const [password3, setPassword3] = React.useState('');
  const [name, setName] = React.useState(
    member.firstName || '',
  );
  const [surname, setSurname] = React.useState(
    member.lastName || '',
  );
  const [alertType, setAlertType] = React.useState('');
  const [message, setMessage] = React.useState('');
  const [alertStatus, setAlertStatus] = React.useState(false);
  const [deleteCount, setDeleteCount] = React.useState(10);
  const [deleteStatus, setDeleteStatus] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [darkTheme, setDarkTheme] = React.useState(screenMode || constants.SCREEN_MODE.normal);
  const [birthday, setBirthday] = React.useState((member.birthday && member.birthday !== undefined) ? new Date(member.birthday) : null);
  const [msgType, setMsgType] = React.useState('');
  const [msgTypes, setMsgTypes] = React.useState([]);
  const [validateFields, setValidateFields] = React.useState([]);
  const [customDay, setCustomDay] = React.useState(null);
  const [customMonth, setCustomMonth] = React.useState(null);
  const [customYear, setCustomYear] = React.useState(null);
  const [editProfilePopoverOpen, setEditProfilePopoverOpen] = React.useState(false);
  const [verifiedFlag, setVerifiedFlag] = React.useState(false);

  const intvl = useRef(null);
  const dayRef = useRef(null);
  const monthRef = useRef(null);
  const yearRef = useRef(null);
  React.useEffect(() => {
    console.log(stage, t(`home.${stage}`), 'modal title check');
    Object.values(stages).map((e) => {
      if (e === stage) {
        // setModalTitle(t(`home.${stage}`));
        setModalTitle(stage);
      }
    });

    if (member.birthday) {
      const fDate = moment(new Date(member.birthday), 'YYYY/MM/DD');
      const day = fDate.format('DD');
      const month = fDate.format('MM');
      const year = fDate.format('YYYY');
      setCustomDay(day);
      setCustomMonth(month);
      setCustomYear(year);
    }

    if (member.email && member.verifiedEmail) {
      setVerifiedFlag(true);
    } else {
      setVerifiedFlag(false);
    }
  }, [stage, open]);

  React.useEffect(() => {
    setDarkTheme(screenMode);
  }, [screenMode]);

  React.useEffect(() => {
    if (member.photo !== photo) {
      setPhoto(member.photo);
    }
  }, [member.photo]);

  React.useEffect(() => {
    try {
      console.log('test loggedInUserDataFetched', loggedInUserDataFetched, emailAdded, localStorage.getItem('cancelAddEmailModal'), stage, checkEmail);
      if (loggedInUserDataFetched) { //x // && localStorage.getItem('justLoggedIn')
        if (!emailAdded && !clickedCancelAddEmailModal && !localStorage.getItem('cancelAddEmailModal') && stage !== stages.promotion && checkEmail === true) { //x
          console.log('setOpen 2', !open);
          setOpen(!open);
          setStage(stages.promotion);
          setAlertType(false);
          setMessage(null);
        }
        localStorage.removeItem('justLoggedIn'); //x
        // localStorage.removeItem('cancelAddEmailModal');
      }
    } catch (err) {
      console.log(err);
    }
  }, [checkEmail, stage]);

  const handleTab = React.useCallback(
    (title) => {
      if (title !== selected) {
        setSelected(title);
        Object.values(titles).map((e) => {
          if (e === title) {
            setStage(
              title === titles.settings ? stages.settings : stages.profileData,
            );
          }
          return null;
        });
        setAlertStatus(false);
        setName(member.firstName || '');
        setSurname(member.lastName || '');
        setPhoto(member.photo);
        setChangePhoto(false);
        setAlertStatus(false);
        setAlertType('');
        setMessage('');
        setMsgType('');
        setMsgTypes([]);
        setValidateFields([]);
      }
    }, [selected],
  );

  const updateSetChangePhoto = (val) => {
    setChangePhoto(val);

    if (val) {
      ReactGA.event({
        category: 'Menu',
        action: 'Edit Profile - Photo',
      });
    }
  };

  const uploadAvatarFile = () => {
    if (!selectedImage) return;

    setLoading(true);
    updateAvatarFileUpload1(selectedImage).then((e) => {
      if (e.status === constants.FROUNT_SIDE_STATUS.success) {
        setPhoto(e.url);
      } else {
        setPhoto(member.photo);
      }
      setLoading(false);
    });
    setChangePhoto(false);
  };

  const cancelUploadAvatar = () => {
    // setSelectedImage(member.photo);
    setSelectedImage(null);
    setChangePhoto(!changePhoto);
    setLoading(false);
  };

  const handleInputChange = React.useCallback((value, type) => {
    if (type === constants.PROFILE_FIELDS.email) {
      if (member.email === value && member.verifiedEmail) {
        setVerifiedFlag(true);
      } else {
        setVerifiedFlag(false);
      }

      setEmail(value);
    }
    if (type === constants.PROFILE_FIELDS.name) { setName(value); }
    if (type === constants.PROFILE_FIELDS.surname) { setSurname(value); }
    if (type === constants.PROFILE_FIELDS.password) { setPassword(value); }
    if (type === constants.PROFILE_FIELDS.password2) { setPassword2(value); }
    if (type === constants.PROFILE_FIELDS.password3) { setPassword3(value); }

    if (type === constants.PROFILE_FIELDS.birthday) {
      console.log('handleInputChange birthday', value);

      const fDate = moment(value, 'YYYY/MM/DD');
      const day = fDate.format('DD');
      const month = fDate.format('MM');
      const year = fDate.format('YYYY');
      console.log(day, month, year, '=changeBirthday');
      setCustomDay(day);
      setCustomMonth(month);
      setCustomYear(year);

      // setBirthday(value);

      checkValidDate(day, month, year);
    }

    if (type === constants.PROFILE_FIELDS.birthdayMobile) {
      //if (value.length === 10) {

      console.log('handleInputChange birthday', value);

      if (value.day && value.month && value.year && !isNaN(new Date(`${value.year}/${value.month}/${value.day}`))) {
        setCustomDay(value.day);
        setCustomMonth(value.month);
        setCustomYear(value.year);

        checkValidDate(value.day, value.month, value.year);
      } else {
        setBirthday(null);
      }
    }

    console.log(type, value, 'check name type');
  }, []);


  const handleDarkTheme = React.useCallback(() => {
    setDarkTheme(constants.SCREEN_MODE.dark);
  });
  const handleLightTheme = React.useCallback(() => {
    setDarkTheme(constants.SCREEN_MODE.light);
  });
  const handleNormalTheme = React.useCallback(() => {
    setDarkTheme(constants.SCREEN_MODE.normal);
  });

  const handleToggleTheme = React.useCallback((theme) => {
    if (theme === constants.SCREEN_MODE.dark) { setDarkTheme(constants.SCREEN_MODE.dark); }
    if (theme === constants.SCREEN_MODE.light) { setDarkTheme(constants.SCREEN_MODE.light); }
    if (theme === constants.SCREEN_MODE.normal) { setDarkTheme(constants.SCREEN_MODE.normal); }

    try {
      localStorage.setItem('screenMode', JSON.stringify(theme)); //x
    } catch (err) {
      console.log(err);
    }
  });

  const handleCloseButton = React.useCallback(() => {

    console.log('handleCloseButton', stage, open);

    console.log('setOpen 3', !open);
    if (stage === stages.profileData) { setOpen(false); }
    if (stage === stages.settings) { setOpen(false); }
    if (stage === stages.promotion) {
      try {
        setClickedCancelAddEmailModal(true);
        localStorage.setItem('cancelAddEmailModal', 'true'); //x
      } catch (err) {
        console.log(err);
      }
      setOpen(false);
    }
    // if (
    //   stage === stages.changeEmail ||
    //   stage === stages.addEmail ||
    //   stages.changePass === stage ||
    //   stages.confirmation === stage
    // )
    setStage(stages.profileData);
    setSelected(tggleVal.profileData);
    setModalTitle(stages.profileData);
    setName(member.firstName || '');
    setEmail(member.email || '');
    setSurname(member.lastName || '');
    setSelectedImage(null);
    setChangePhoto(false);
    setAlertStatus(false);
    setAlertType('');
    setMessage('');
    setLoading(false);
    setDeleteStatus(false);
    setDeleteCount(10);
    clearInterval(intvl.current);
    setMsgType('');
    setMsgTypes([]);
    setValidateFields([]);
    setCustomDay(null);
    setCustomMonth(null);
    setCustomYear(null);
    setBirthday(member.birthday ? new Date(member.birthday) : null);
  }, [stage]);

  const handleStage = (nextStage) => {
    console.log('handleStage', stage, nextStage);

    if (stage !== nextStage) {
      if (stage === stages.promotion && nextStage === stages.addEmail) {
        setClickedCancelAddEmailModal(true);
        try {
          localStorage.setItem('cancelAddEmailModal', 'true'); //x
        } catch (err) {
          console.log(err);
        }
      }
      setStage(nextStage);
      setAlertType(false);
      setMessage(null);
    }
  };

  const updateUserProfile = ({ form }) => {
    console.log('updateUserProfile', form);
    setLoading(true);

    updateProfile1(form, !emailAdded).then((e) => {
      if (e.status === constants.FROUNT_SIDE_STATUS.success) {
        setAlertStatus(true);
        setAlertType(constants.FROUNT_SIDE_STATUS.success);
        if (e.emailChanged) {
          setMessage(t('member.successReAuth'));
          setTimeout(() => {
            signOut();
            history.push('/landing');
          }, 8000);
        } else {
          // setMessage(t('support.changeSuccess'));
          handleStage(stages.profileData);
        }
        setTimeout(() => {
          setAlertStatus(false);
          setAlertType(false);
          setMessage(null);
          handleCloseButton();
          setMsgType('');
        }, 7500);
      } else {
        console.log(e, e.message, 'upateError===');
        let text = '';
        if (e.message && e.message === 'auth/requires-recent-login') {
          text = t('member.reAuth');
        } else if (e.message && e.message === 'auth/email-already-in-use') {
          text = t('member.auth/email-already-in-use', { email });
        } else if (e.message && e.message === 'auth/email-already-exists') {
          text = t('member.linkedEmail');
        } else {
          text = t(`member.${e.message}`);
        }
        setAlertStatus(true);
        setAlertType(constants.FROUNT_SIDE_STATUS.danger);
        setMessage(text ? text : (e.message) ? e.message : t('member.actionFailed'));
        setMsgType(e?.msgType || '');
        setMsgTypes(e?.msgTypes || []);
      }
      setLoading(false);
    });
  };

  const handleUpdateUser = () => {
    const form = {
      photo,
      firstName: name,
      lastName: surname,
      email,
      contactEmail: email,
      changeEmail: stage === stages.changeEmail || stage === stages.addEmail,
      curPassword: password,
      password: password2,
      password2: password3,
      changePassword: stage === stages.changePass,
      socProvider: member.socProvider || '',
      birthday,
    };

    const validFields = [];

    console.log('handleUpdateUser', name, surname, stage, email);

    if (!name) { validFields.push({ field: constants.PROFILE_FIELDS.surname, msg: errorMessages.missingFirstName }); }
    if (!surname) { validFields.push({ field: constants.PROFILE_FIELDS.name, msg: errorMessages.missingLastName }); }
    // if (!email) { validFields.push({ field: constants.PROFILE_FIELDS.email, msg: errorMessages.missingEmail }); }
    // if ((customDay || customMonth || customYear) && !birthday) { validFields.push({ field: constants.PROFILE_FIELDS.birthday, msg: errorMessages.wrongDate }); }
    if (stage === stages.changeEmail || stage === stages.addEmail) {
      if (!EmailValidator.validate(email)) { validFields.push({ field: constants.PROFILE_FIELDS.email, msg: errorMessages.wrongEmail }); }
    }

    console.log('handleUpdateUser 1', birthday);

    if (!birthday && stage !== stages.changeEmail && stage !== stages.addEmail){
      if (customDay || customMonth || customYear) {
        validFields.push({field: constants.PROFILE_FIELDS.birthday, msg: errorMessages.wrongDate});
      } else {
        if (!member.verifiedEmail) {
          validFields.push({field: constants.PROFILE_FIELDS.birthday, msg: errorMessages.missingBirthday});
        }
      }
    }

    if (stage === stages.changePass) {
      if (!password) { validFields.push({ field: constants.PROFILE_FIELDS.password, msg: errorMessages.missingPassword }); }
      if (!password2) { validFields.push({ field: constants.PROFILE_FIELDS.password2, msg: errorMessages.missingPassword }); }
      if (!password3) { validFields.push({ field: constants.PROFILE_FIELDS.password3, msg: errorMessages.missingPassword }); }
    }

    console.log('validFields', stage, validFields);
    console.log('form', form);

    if (validFields && validFields.length > 0) {
      setValidateFields(validFields);
      if (!((stage === stages.changePass && password) && (password2 && password3))) {
        return;
      }
    }
    console.log(photo, 'updateImageCheck');

    console.log('updateUserProfile call', form);
    updateUserProfile({ form });
  };

  const deleteUser = async () => {
    try {
      setLoading(true);
      requestDeleteUser1().then((e) => {
        if (e.status === constants.FROUNT_SIDE_STATUS.success) {
          setAlertStatus(true);
          setAlertType(constants.FROUNT_SIDE_STATUS.success);
          setMessage(t('member.changeSuccess'));
          setDeleteStatus(true);
          setMsgType('');
          let deleteC = 0;
          deleteC = deleteCount;
          console.log(deleteStatus, deleteCount, deleteC, 'before chgeck interval');
          intvl.current = setInterval(() => {
            console.log(deleteStatus, deleteCount, deleteC, 'chgeck interval');
            deleteC -= 1;
            setDeleteCount(deleteC);
          }, 1000);

          setTimeout(() => {
            setAlertStatus(false);
            setAlertType(false);
            setMessage(null);
            handleCloseButton();
            setDeleteStatus(false);
          }, 10000);
        } else {
          let text = '';
          if (e.message && e.message === 'auth/requires-recent-login') {
            text = t('member.reAuth');
          } else if (e.message && e.message === 'auth/email-already-in-use') {
            text = t('member.emailUsed');
          } else {
            text = t(`member.${e.message}`);
          }
          setAlertStatus(true);
          setAlertType(constants.FROUNT_SIDE_STATUS.danger);
          setMessage(e.message ? text : t('member.actionFailed'));
          setTimeout(() => {
            setAlertStatus(false);
            setAlertType(false);
            setMessage(null);
            setMsgType('');
            setMsgTypes([]);
          }, 10000);
        }
        setLoading(false);
      });
    } catch (err) {
      console.log('err: ', err);
    }
  };

  const onBeforeFileLoad = (elem) => {
    try {
      setLoading(true);
      const typeList = 'image/jpg,image/png,image/jpeg, image/pjp, image/pjpeg, image/jfif';
      if (!typeList.includes(elem.target.files[0].type) || elem.target.files[0].type == '') {
        setAlertStatus(true);
        setAlertType(constants.FROUNT_SIDE_STATUS.warning);
        setMessage(t('support.unsupportedFile'));
        setTimeout(() => {
          setAlertStatus(false);
          setAlertType(false);
          setMessage(null);
          setMsgType('');
        }, 10000);
        setLoading(false);
        return elem.target.value = '';
      }

      if (elem.target.files[0].size / 1024 > 3072) {
        setAlertStatus(true);
        setAlertType(constants.FROUNT_SIDE_STATUS.warning);
        setMessage(t('support.fileUploadWarning'));
        setTimeout(() => {
          setAlertStatus(false);
          setAlertType(false);
          setMessage(null);
          setMsgType('');
          setMsgTypes([]);
        }, 10000);
        setLoading(false);
        elem.target.value = '';
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      return elem.target.value = '';
    }
  };

  const handleReactGA = (newOpenVal) => {
    console.log('handleReactGA', newOpenVal);
    if (newOpenVal) {
      ReactGA.event({
        category: 'Menu',
        action: 'Edit Profile',
      });
    }
  };

  const checkValidDate = (day, month, year) => {
    console.log('checkValidDate', day, month, year);
    if (day && month && year) {
      const newDate = new Date(`${year}/${month}/${day}`);
      if (constants.SMALL_DAY_MONTHS.includes(month)) {
        if (day > 30) {
          setBirthday(null);
        } else if (month === '02') {
          let leapYear = false;
          if ((!(year % 4) && year % 100) || !(year % 400)) leapYear = true;
          if ((!leapYear && (day >= 29)) || (leapYear && day > 29)) {
            setBirthday(null);
          } else if (Date.parse(newDate) < Date.parse(constants.LIMIT_DATE_RANGE.minDate) || Date.parse(newDate) > Date.parse(constants.LIMIT_DATE_RANGE.maxDate)) {
            setBirthday(null);
          } else {
            setBirthday(newDate);
          }
        } else if (Date.parse(newDate) < Date.parse(constants.LIMIT_DATE_RANGE.minDate) || Date.parse(newDate) > Date.parse(constants.LIMIT_DATE_RANGE.maxDate)) {
          setBirthday(null);
        } else {
          setBirthday(newDate);
        }
      } else if (Date.parse(newDate) < Date.parse(constants.LIMIT_DATE_RANGE.minDate) || Date.parse(newDate) > Date.parse(constants.LIMIT_DATE_RANGE.maxDate)) {
        setBirthday(null);
      } else {
        setBirthday(newDate);
      }
    }
  }

  const handleKeyDay = (e) => {
    const value = e.key;
    let newChat = '';

    if (value.toString() === 'Backspace' || value.toString() === 'Escape' || value.toString() === 'Delete') {
      setCustomDay(null);
      setBirthday(null);
    } else if (!isNaN(value)) {
      if (!customDay && parseInt(value, 10) === 0) return;
      if (!customDay && parseInt(value, 10) > 3 && parseInt(value, 10) !== 0) {
        newChat = `0${value}`;
        monthRef.current.focus();
      } else if (customDay && customDay.length > 1) {
        const firstChat = customDay.slice(0, 1);
        const secondChat = customDay.slice(1, 2);
        if (
          (parseInt(firstChat, 10) !== 0 && parseInt(value, 10) === 0)
          || (!customDay && parseInt(value, 10) === 0)
          || (parseInt(firstChat, 10) === 0 && parseInt(secondChat, 10) > 3 && parseInt(value, 10) === 0)
        ) {
          newChat = customDay;
          e.target.blur();
          monthRef.current.focus();
        } else if (parseInt(secondChat, 10) > 3) {
          if (value > 3) {
            monthRef.current.focus();
            e.target.blur();
          }
          newChat = `0${value}`;
        } else if (parseInt(secondChat, 10) === 3) {
          if (parseInt(firstChat, 10) === 0) {
            if (value > 1) {
              newChat = `0${value}`;
            } else {
              newChat = secondChat + value;
            }
          } else {
            newChat = `0${value}`;
          }
          monthRef.current.focus();
          e.target.blur();
        } else if (parseInt(firstChat, 10) === 0) {
          newChat = secondChat + value;
          monthRef.current.focus();
          e.target.blur();
        } else {
          newChat = `0${value}`;
        }
      } else {
        newChat = `0${value}`;
      }
      setCustomDay(newChat);
    }

    console.log(customMonth, customYear, 'checkDay');
    checkValidDate(newChat, customMonth, customYear);
  };

  const handleKeyMonth = (e) => {
    const value = e.key;
    let newChat = '';

    if (value.toString() === 'Backspace' || value.toString() === 'Escape' || value.toString() === 'Delete') {
      setCustomMonth(null);
      setBirthday(null);
    } else if (!isNaN(value)) {
      if (!customMonth && parseInt(value, 10) === 0) return;
      if (!customMonth && parseInt(value, 10) > 1 && parseInt(value, 10) !== 0) {
        newChat = `0${value}`;
        yearRef.current.focus();
      } else if (customMonth && customMonth.length > 1) {
        const firstChat = customMonth.slice(0, 1);
        const secondChat = customMonth.slice(1, 2);
        if (
          (parseInt(firstChat, 10) !== 0 && parseInt(value, 10) === 0)
          || (!customMonth && parseInt(value, 10) === 0)
          || (parseInt(firstChat, 10) === 0 && parseInt(secondChat, 10) > 1 && parseInt(value, 10) === 0)
        ) {
          newChat = customMonth;
          e.target.blur();
          yearRef.current.focus();
        } else if (parseInt(secondChat, 10) > 1) {
          if (parseInt(value, 10) > 1) {
            yearRef.current.focus();
            e.target.blur();
          }
          newChat = `0${value}`;
        } else if (parseInt(secondChat, 10) === 1) {
          if (parseInt(value, 10) < 3) {
            newChat = secondChat + value;
          } else {
            newChat = `0${value}`;
          }
          yearRef.current.focus();
          e.target.blur();
        } else if (parseInt(firstChat, 10) === 0) {
          if (parseInt(value, 10) < 3) {
            newChat = secondChat + value;
          } else {
            newChat = `0${value}`;
            if (parseInt(value, 10) > 1) {
              yearRef.current.focus();
              e.target.blur();
            }
          }
        } else {
          newChat = `0${value}`;
        }
      } else {
        newChat = `0${value}`;
      }
      setCustomMonth(newChat);
    }
    checkValidDate(customDay, newChat, customYear);
  };

  const handleKeyYear = (e) => {
    const value = e.key;
    let newChat = '';

    if (value.toString() === 'Backspace' || value.toString() === 'Escape' || value.toString() === 'Delete') {
      setCustomYear(null);
      setBirthday(null);
    } else if (!isNaN(value)) {
      if (!customYear && parseInt(value, 10) === 0) return;
      if (!customYear && parseInt(value, 10) !== 0) {
        newChat = `000${value}`;
      } else if (customYear && customYear.length === 4) {
        const firstChat = customYear.slice(0, 1);
        const secondChat = customYear.slice(1, 2);
        const thirdChat = customYear.slice(2, 3);
        const fourthChat = customYear.slice(3, 4);
        if (customYear.length === 4 && parseInt(value, 10) === 0 && parseInt(firstChat, 10) !== 0) {
          newChat = customYear;
        } else if (customYear.length === 4 && parseInt(value, 10) !== 0 && parseInt(firstChat, 10) !== 0) {
          newChat = `000${value}`;
        } else if (parseInt(secondChat, 10) !== 0 && parseInt(firstChat, 10) === 0) {
          newChat = secondChat + thirdChat + fourthChat + value;
        } else if (parseInt(thirdChat, 10) !== 0 && parseInt(secondChat, 10) === 0) {
          newChat = `0${thirdChat}${fourthChat}${value}`;
        } else if (parseInt(fourthChat, 10) !== 0 && parseInt(thirdChat, 10) === 0) {
          newChat = `00${fourthChat}${value}`;
        }
      } else {
        newChat = `000${value}`;
      }
      setCustomYear(newChat);
    }
    checkValidDate(customDay, customMonth, newChat);
  };

  if (deleteCount <= 0 && deleteStatus) {
    signOut();
    history.push('/landing');
    setDeleteCount(10);
  }

  const isInAppFrame = config.isInAppFrame();

  console.log('isOpenopen', open, stage);

  return (
    <>
      {!overlayMode ? (
        <>
          <div
            className="player-edit-pencil"
            id="player-edit-pencil-popover"
            onMouseEnter={() => setEditProfilePopoverOpen(true)}
            onMouseLeave={() => setEditProfilePopoverOpen(false)}
            style={triggerStyle}
            onClick={() => {
              console.log('setOpen 4', !open);
              setOpen(!open);
              handleStage(stages.profileData);
              handleReactGA(!open);
            }}
          >
            <Media alt="x" src={pencilImg} />
          </div>
          <IconPopover text={t('popovers.editProfile')} placement="left" targetId="player-edit-pencil-popover" popoverOpen={editProfilePopoverOpen} />
        </>
      ) : (
        <div
          style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: 1}}
            onClick={() => {
              console.log('setOpen 5', !open);
            setOpen(!open);
            handleStage(stages.profileData);
            handleReactGA(!open);
          }}
        />
      )}
      <CustomModal
        warning={Boolean(modalTitle === titles.confirmation)}
        onButtonClose={handleCloseButton}
        title={modalTitle}
        addClassName="edit-profile-modal"
        titleTranslateFlag
        isOpen={open}
        footer={<Footer handleStage={handleStage} setOpen={setOpen} stage={stage} loading={loading} setThemeFunction={setThemeFunc} handleUpdateUser={handleUpdateUser} darkTheme={darkTheme} toggleScreenMode={toggleScreenMode} handleCloseButton={handleCloseButton} deleteUser={deleteUser} />}
        body={(
          <div className={`update-profile-section update-profile-section-${stage} ${config.isInAppFrame() && 'update-profile-section-iframe'}`}>
            {/* {stage === stages.profileData || stage === stages.settings ? (
              <div className="update-profile-section-tabs mb-4">
                <SwitchToggle
                  selected={selected}
                  handleTab={(title) => handleTab(title)}
                  values={tggleVal}
                  screenMode={screenMode}
                  t={t}
                />
              </div>
            ) : null} */}
            {(alertStatus && alertType && message && msgType === '') && (
              <Fragment>
                <ErrorNotification
                  text={message}
                  isVisible={alertStatus}
                />
                {/* <Alert className="new-design-alert" color={alertType} isOpen={alertStatus}>
                  <h5 className="alert-heading alert-heading-message">{alertType === constants.FROUNT_SIDE_STATUS.success ? t('common.success') : t('support.appearError')}</h5>
                  <p>{message}</p>
                </Alert> */}
              </Fragment>
            )}
            {stage === stages.promotion && (
              <>
                {t('home.promotionBody')}
              </>
            )}
            {stage === stages.profileData && (
              <>
                {!changePhoto ? (
                  <Profile
                    screenMode={screenMode}
                    member={member}
                    photo={photo || (screenMode === constants.SCREEN_MODE.light ? defaultImageLight : defaultImage)}
                    selectedImage={selectedImage}
                    onClick={() => updateSetChangePhoto(!changePhoto)}
                    loading={loading}
                  />
                ) : (
                  <div className="update-profile-section-avatar">
                    <Avatar
                      width={140}
                      height={140}
                      imageWidth={140}
                      imageHeight={140}
                      onCrop={(e) => setSelectedImage(e)}
                      onBeforeFileLoad={onBeforeFileLoad}
                      label={t('home.uploadPhoto')}
                      labelStyle={{ fontSize: 11, textTransform: 'upperCase', display: 'flex', textAlign: 'center', alignItems: 'center', justifyContent: 'center', height: '100%'/*, fontVariant: 'all-petite-caps'*/ }}
                    />
                    <div className="edit-photo-action">
                      <div
                        className="cancel-button"
                        disabled={loading}
                        onClick={cancelUploadAvatar}
                      >
                        {t('common.cancel')}
                      </div>
                      <div
                        className="ok-button"
                        disabled={loading}
                        onClick={uploadAvatarFile}
                      >
                        {t('common.ok')}
                      </div>
                    </div>
                  </div>
                )}
                <div className="update-profile-section-add-email">
                  {!emailAdded ? (
                    <Button
                      className="modal-body-button add-email-button"
                      onClick={() => handleStage(stages.addEmail)}
                    >
                      {t(`home.${stages.addEmail}`)}
                    </Button>
                  ) : (
                    <>
                      <div className="add-email-section">
                        <TextInput
                          contentEditable={true}
                          width='100%'
                          inputType={constants.PROFILE_FIELDS.email}
                          placeholder={t('home.email')}
                          initialValue={email}
                          onChange={(value, type) =>
                            handleInputChange(value, type)
                          }
                          maxLength={25}
                          loading
                        />
                      </div>
                      <div className='update-profile-email-error'>
                        <Alert className="new-design-alert" color={constants.FROUNT_SIDE_STATUS.danger} isOpen={validateFields && validateFields.filter(msgT => msgT.field === constants.PROFILE_FIELDS.email).length > 0}>
                          <p>{t(`common:member.${validateFields.filter(msgT => msgT.field === constants.PROFILE_FIELDS.email)[0]?.msg}`)}</p>
                        </Alert>
                      </div>
                    </>
                  )}
                  <div className='d-flex'>
                    <div className="add-name-section">
                      <TextInput
                        contentEditable={true}
                        width={156}
                        inputType={constants.PROFILE_FIELDS.name}
                        placeholder={t('home.name')}
                        initialValue={member.firstName}
                        onChange={(value, type) =>
                          handleInputChange(value, type)
                        }
                        maxLength={25}
                      />
                      <div className='update-profile-email-error'>
                        <Alert className="new-design-alert" color={constants.FROUNT_SIDE_STATUS.danger} isOpen={validateFields && validateFields.filter(msgT => msgT.field === constants.PROFILE_FIELDS.surname).length > 0}>
                          <p>{t(`common:member.${validateFields.filter(msgT => msgT.field === constants.PROFILE_FIELDS.surname)[0]?.msg}`)}</p>
                        </Alert>
                      </div>
                    </div>
                    <div className="add-surname-section">
                      <TextInput
                        contentEditable={true}
                        width={156}
                        inputType={constants.PROFILE_FIELDS.surname}
                        placeholder={t('home.surname')}
                        initialValue={member.lastName}
                        onChange={(value, type) =>
                          handleInputChange(value, type)
                        }
                        maxLength={25}
                      />
                      <div className='update-profile-email-error'>
                        <Alert className="new-design-alert" color={constants.FROUNT_SIDE_STATUS.danger} isOpen={validateFields && validateFields.filter(msgT => msgT.field === constants.PROFILE_FIELDS.name).length > 0}>
                          <p>{t(`common:member.${validateFields.filter(msgT => msgT.field === constants.PROFILE_FIELDS.name)[0]?.msg}`)}</p>
                        </Alert>
                      </div>
                    </div>
                  </div>
                  <div className="add-dob-section">
                    <TextInput
                      contentEditable={true}
                      width='100%'
                      inputType={isMobile ? constants.PROFILE_FIELDS.birthdayMobile : constants.PROFILE_FIELDS.birthday}
                      placeholder={t('home.datePlaceholder')}
                      initialValue={birthday}
                      onChange={(value, type) =>
                        handleInputChange(value, type)
                      }
                      birthDisable={member.birthday}
                      dRef={dayRef}
                      mRef={monthRef}
                      yRef={yearRef}
                      handleKeyDay={handleKeyDay}
                      handleKeyMonth={handleKeyMonth}
                      handleKeyYear={handleKeyYear}
                      mbirthday={member.birthday || null}
                      customDay={customDay}
                      customMonth={customMonth}
                      customYear={customYear}
                      dropUp={isInAppFrame}
                    />
                  </div>
                  <div className='update-profile-email-error'>
                    <Alert className="new-design-alert" color={constants.FROUNT_SIDE_STATUS.danger} isOpen={validateFields && validateFields.filter(msgT => msgT.field === constants.PROFILE_FIELDS.birthday).length > 0}>
                      <p>{t(`common:member.${validateFields.filter(msgT => msgT.field === constants.PROFILE_FIELDS.birthday)[0]?.msg}`)}</p>
                    </Alert>
                  </div>
                  {/* <div>
                    <Alert className="new-design-alert" color={constants.FROUNT_SIDE_STATUS.danger} isOpen={validateFields && validateFields.filter(msgT => msgT.field === constants.PROFILE_FIELDS.birthday).length > 0}>
                      <p>{ t(`common:member.${validateFields.filter(msgT => msgT.field === constants.PROFILE_FIELDS.birthday)[0]?.msg}`) }</p>
                    </Alert>
                  </div> */}
                </div>
                <div className='change-email-password-section-wrapper'>
                  {emailAdded && (
                    <div
                      className="change-email-section bottom-button-center"
                      style={{ ...deleteProfileStyle, color: '#FFC900' }}
                      onClick={() => handleStage(stages.changeEmail)}
                    >
                      {t(`home.${stages.changeEmail}`)}
                    </div>
                  )}
                  {socProvider === constants.SOC_PROVIDERS.password && (
                    <div
                      className="change-email-section bottom-button-center"
                      style={{ ...deleteProfileStyle, color: '#FFC900' }}
                      onClick={() => handleStage(stages.changePass)}
                    >
                      {t(`home.${stages.changePass}`)}
                    </div>
                  )}
                  <div
                    className="change-email-section bottom-button-center"
                    style={deleteProfileStyle}
                    onClick={() => handleStage(stages.confirmation)}
                  >
                    {t(`home.${stages.deleteAccount}`)}
                  </div>
                </div>
              </>
            )}
            {
              stage === stages.settings && (
                <div className="mt-4 mb-2">
                  <div className="mb-2">{t('home.chooseTheme')}</div>
                  <SwitchSelect options={constants.SCREEN_MODE_OPTIONS} selectedOption={darkTheme} selectFunc={handleToggleTheme} trans />
                </div>
              )
            }

            {stage === stages.addEmail && (
              <>
                <TextInput
                  width={320}
                  contentEditable={true}
                  inputType={constants.PROFILE_FIELDS.email}
                  secondaryPlaceholder={t('home.emailAddress')}
                  initialValue={email}
                  onChange={(value, type) => handleInputChange(value, type)}
                  maxLength={100}
                />
                <div className='update-profile-email-error'>
                  <Alert
                    className="edit-profile-input"
                    color={verifiedFlag ? constants.FROUNT_SIDE_STATUS.success : constants.FROUNT_SIDE_STATUS.danger}
                    isOpen={!(validateFields && validateFields.filter(msgT => msgT.field === constants.PROFILE_FIELDS.email).length > 0)}
                  >
                    <p>{verifiedFlag ? t('home.verified') : t('home.notVerified')}</p>
                  </Alert>
                  <Alert className="edit-profile-input" color={constants.FROUNT_SIDE_STATUS.danger} isOpen={validateFields && validateFields.filter(msgT => msgT.field === constants.PROFILE_FIELDS.email).length > 0}>
                    <p>{t(`common:member.${validateFields.filter(msgT => msgT.field === constants.PROFILE_FIELDS.email)[0]?.msg}`)}</p>
                  </Alert>
                </div>
              </>
            )}
            {stage === stages.changeEmail && (
              <>
                <TextInput
                  width={320}
                  contentEditable={true}
                  inputType={constants.PROFILE_FIELDS.email}
                  secondaryPlaceholder={t('home.newEmail')}
                  initialValue={email}
                  onChange={(value, type) => handleInputChange(value, type)}
                  maxLength={100}
                />
                {/* <Label style={{ marginLeft: 10, fontSize: 14 }}>{verifiedFlag ? t('home.verified') : t('home.notVerified')}</Label> */}
                <div className='update-profile-email-error'>
                  <Alert
                    className="edit-profile-input"
                    color={verifiedFlag ? constants.FROUNT_SIDE_STATUS.success : constants.FROUNT_SIDE_STATUS.danger}
                    isOpen={!(validateFields && validateFields.filter(msgT => msgT.field === constants.PROFILE_FIELDS.email).length > 0)}
                  >
                    <p>{verifiedFlag ? t('home.verified') : t('home.notVerified')}</p>
                  </Alert>
                  <Alert className="edit-profile-input" color={constants.FROUNT_SIDE_STATUS.danger} isOpen={validateFields && validateFields.filter(msgT => msgT.field === constants.PROFILE_FIELDS.email).length > 0}>
                    <p>{t(`common:member.${validateFields.filter(msgT => msgT.field === constants.PROFILE_FIELDS.email)[0]?.msg}`)}</p>
                  </Alert>
                </div>
              </>
            )}
            {stage === stages.changePass && (
              <div style={{ justifyContent: 'space-between', height: 192 }}>
                <TextInput
                  type={constants.PROFILE_FIELDS.password}
                  width={320}
                  contentEditable={true}
                  inputType={constants.PROFILE_FIELDS.password}
                  secondaryPlaceholder={t('home.oldPass')}
                  initialValue={password}
                  onChange={(value, type) => handleInputChange(value, type)}
                  maxLength={100}
                />
                <div className='update-profile-email-error'>
                  {console.log("validFields: message:", message)}
                  {console.log("validFields: msgType:", msgType)}
                  <Alert className="edit-profile-input" color={constants.FROUNT_SIDE_STATUS.danger} isOpen={(message && msgType === 'password') || (validateFields && validateFields.filter(msgT => msgT.field === constants.PROFILE_FIELDS.password).length > 0)}>
                    {
                      (validateFields && validateFields.filter(msgT => msgT.field === constants.PROFILE_FIELDS.password).length > 0) ? <p>{t(`common:member.${validateFields.filter(msgT => msgT.field === constants.PROFILE_FIELDS.password)[0]?.msg}`)}</p> : (
                      <p>
                        {message}
                      </p>
                    )}
                  </Alert>
                </div>
                <TextInput
                  type={constants.PROFILE_FIELDS.password}
                  width={320}
                  marginTop={8}
                  contentEditable={true}
                  inputType={constants.PROFILE_FIELDS.password2}
                  secondaryPlaceholder={t('home.newPass')}
                  initialValue={password2}
                  onChange={(value, type) => handleInputChange(value, type)}
                  maxLength={100}
                />
                <div className='update-profile-email-error'>
                  <Alert className="edit-profile-input" color={constants.FROUNT_SIDE_STATUS.danger} isOpen={(message && msgType === 'password2') || (validateFields && validateFields.filter(msgT => msgT.field === constants.PROFILE_FIELDS.password2).length > 0)}>
                    {
                      (validateFields && validateFields.filter(msgT => msgT.field === constants.PROFILE_FIELDS.password2).length > 0) ? <p>{t(`common:member.${validateFields.filter(msgT => msgT.field === constants.PROFILE_FIELDS.password2)[0]?.msg}`)}</p> : <p>{message}</p>
                    }
                  </Alert>
                </div>
                <TextInput
                  type={constants.PROFILE_FIELDS.password}
                  width={320}
                  marginTop={8}
                  contentEditable={true}
                  inputType={constants.PROFILE_FIELDS.password3}
                  secondaryPlaceholder={t('home.newPassAgain')}
                  initialValue={password3}
                  onChange={(value, type) => handleInputChange(value, type)}
                  maxLength={100}
                />
                <div className='update-profile-email-error'>
                  <Alert className="edit-profile-input" color={constants.FROUNT_SIDE_STATUS.danger} isOpen={(message && msgType === 'password3') || validateFields && validateFields.filter(msgT => msgT.field === constants.PROFILE_FIELDS.password3).length > 0}>
                    {
                      (validateFields && validateFields.filter(msgT => msgT.field === constants.PROFILE_FIELDS.password3).length > 0) ? <p>{t(`common:member.${validateFields.filter(msgT => msgT.field === constants.PROFILE_FIELDS.password3)[0]?.msg}`)}</p> : <p>{message}</p>
                    }

                  </Alert>
                </div>
              </div>
            )}
            {stage === stages.confirmation && (
              <div className="confirm-section text-center">
                {!deleteStatus ? t('home.deleteContent') : t('member.deleteSuccess', { deleteCount })}
              </div>
            )}
          </div>
        )}
      />
    </>
  );
};

const CheckBox = ({ title }) => {
  return (
    <div className="upadte-profile-checkbox-section"
      style={{
        display: 'flex',
        height: 25,
        flexDirection: 'row',
      }}
    >
      <input type="checkbox" style={{ width: 25, height: 25 }} />
      <p style={checkBoxTitleStyle}>{title}</p>
    </div>
  );
};

UpdateProfileComponent.propTypes = {
  member: PropTypes.shape(),
  Layout: PropTypes.func.isRequired,
  updateProfile: PropTypes.func.isRequired,
  updateAvatarFileUpload: PropTypes.func.isRequired,
  requestDeleteUser: PropTypes.func.isRequired,
  signOut: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  screenMode: PropTypes.string,
  toggleScreenMode: PropTypes.func.isRequired,
  setThemeFunc: PropTypes.func.isRequired,
  overlayMode: PropTypes.bool,
  isMobile: PropTypes.bool,
};

UpdateProfileComponent.defaultProps = {
  member: {},
  overlayMode: false,
  isMobile: false,
};

const mapStateToProps = state => ({
  loggedInUserDataFetched: state.member.loggedInUserDataFetched || false,
});

const mapDispatchToProps = {
  updateProfile1: updateProfile,
  updateAvatarFileUpload1: updateAvatarFileUpload,
  requestDeleteUser1: requestDeleteUser,
  signOut: logout,
  setThemeFunc: handleSetTheme,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  withRouter(withTranslation('common')(UpdateProfileComponent)),
);
