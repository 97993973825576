import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import Moment from 'react-moment';
import { size, filter } from 'lodash';

import DateTimePicker from 'react-widgets/lib/DateTimePicker';
import 'react-widgets/dist/css/react-widgets.css';

import Button from 'reactstrap/lib/Button';
import Pagination from 'reactstrap/lib/Pagination';
import PaginationItem from 'reactstrap/lib/PaginationItem';
import PaginationLink from 'reactstrap/lib/PaginationLink';
import Modal from 'reactstrap/lib/Modal';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import Label from 'reactstrap/lib/Label';
import Input from 'reactstrap/lib/Input';
import Form from 'reactstrap/lib/Form';
import FormGroup from 'reactstrap/lib/FormGroup';

import {
  ADMIN_BANS_RANGE,
} from '../../../constants/constants';
import { PopoverBody, UncontrolledPopover } from 'reactstrap';

class AllBans extends React.Component { 
  static propTypes = {
    member: PropTypes.shape({
      email: PropTypes.string,
    }),
    allBans: PropTypes.arrayOf(PropTypes.shape({})),
    allUsers: PropTypes.shape({}),
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    editBan: PropTypes.func.isRequired,
    unblockUser: PropTypes.func.isRequired,
    fetchBansRange: PropTypes.func.isRequired,
  }

  static defaultProps = {
    member: {},
    allBans: [],
    allUsers: {},
  }

  constructor(props) {
    super(props);
    this.state = {
      currentPage: 0,
      pageSize: 150,
      userId: null,
      reason: '',
      endDate: new Date(),
    };

    this.handleClick = this.handleClick.bind(this);
    this.editBan = this.editBan.bind(this);
    this.toggle = this.toggle.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.changeEndDate = this.changeEndDate.bind(this);
  }

  componentWillMount() {
  }

  componentDidUpdate(nextProps) {
    const { fetchBansRange, allBans } = this.props;

    if (allBans && nextProps.allBans && size(allBans) !== size(nextProps.allBans)) {
      console.log("runBan");
      fetchBansRange(ADMIN_BANS_RANGE.start, ADMIN_BANS_RANGE.end);
    }
  }

  handleChange = (event) => {
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

    this.setState({
      [event.target.name]: value,
    });
  }

  changeEndDate = (date) => {
    this.setState({ endDate: date });
  }

  unblockUser = (uid) => {
    const { unblockUser } = this.props;

    unblockUser(uid);
  }

  openModal(uid) {
    const { allBans } = this.props;
    const user = filter(allBans, ['key', uid])[0];

    this.setState({
      userId: uid,
      openModal: true,
      reason: user.reason,
      endDate: new Date(user.endDate),
    });
  }

  editBan() {
    const { editBan } = this.props;
    const {
      userId, reason, endDate,
    } = this.state;

    editBan(userId, endDate, reason);
    this.setState({ openModal: false });
  }

  toggle() {
    this.setState(prevState => ({
      openModal: !prevState.openModal,
    }));
  }

  handleClick(e, index) {
    e.preventDefault();
    this.setState({
      currentPage: index,
    });
  }

  table() {
    const {
      t,
      allBans,
    } = this.props;
    const {
      currentPage,
      pageSize,
    } = this.state;

    const pagesCount = Math.ceil(size(allBans).length / pageSize);

    return (
      <Fragment>
        {size(allBans) > 0 && allBans
          .sort((a, b) => (b.blocked - a.blocked))
          .slice(
            currentPage * pageSize,
            (currentPage + 1) * pageSize,
          )
          .map((item, index) => (
            <Fragment key={item.key}>
              <tr key={item.key} className={`allUsers-table-row ${index % 2 === 0 ? ('odd') : ('even')}`}>
                <td className="allUsers-table-col">
                  {item.name}
                </td>
                <td className="allUsers-table-col">
                  <Moment format="DD-MM-YYYY, HH:mm" locale="lv">
                    {item.endDate}
                  </Moment>
                </td>
                <td className="allUsers-table-col">
                  {item.reason}
                </td>
                <td className="allUsers-table-col">
                  {item.uid}
                </td>
                <td className="allUsers-table-col">
                  <Button color="primary" onClick={() => this.openModal(item.key)}>
                    Edit
                  </Button>
                </td>
                <td className="allUsers-table-col">
                  {item.blocked ? (
                    <Button color="danger" onClick={() => this.unblockUser(item.key)}>
                      UnBlock
                    </Button>
                  ) : (
                    'Ended'
                  )}
                </td>
              </tr>
            </Fragment>
          ))}
        {Object.keys(allBans).length > pageSize && (
          <div className="pagination-wrapper">
            <Pagination aria-label="Page navigation example">
              <PaginationItem disabled={currentPage === 0}>
                <PaginationLink onClick={e => this.handleClick(e, 0)} href="#">
                  {`‹‹‹`}
                </PaginationLink>
              </PaginationItem>

              <PaginationItem disabled={currentPage <= 0}>
                <PaginationLink
                  onClick={e => this.handleClick(e, currentPage - 1)}
                  previous
                  href="#"
                />
              </PaginationItem>

              {[...Array(pagesCount)].map((page, i) => {
                if (i > currentPage - 3 && i < currentPage + 3) {
                  return (
                    <PaginationItem active={i === currentPage} key={page}>
                      <PaginationLink onClick={e => this.handleClick(e, i)} href="#">
                        {i + 1}
                      </PaginationLink>
                    </PaginationItem>
                  );
                }

                return null;
              })}

              <PaginationItem disabled={currentPage >= pagesCount - 1}>
                <PaginationLink
                  onClick={e => this.handleClick(e, currentPage + 1)}
                  next
                  href="#"
                />
              </PaginationItem>

              <PaginationItem disabled={currentPage >= pagesCount - 1}>
                <PaginationLink onClick={e => this.handleClick(e, pagesCount - 1)} href="#">
                  {`›››`}
                </PaginationLink>
              </PaginationItem>

            </Pagination>
          </div>
        )}
      </Fragment>
    );
  }

  render() {
    const { t } = this.props;
    const {
      endDate,
      reason,
      openModal,
    } = this.state;

    return (
      <Fragment>
        <UncontrolledPopover popperClassName={`popover`} placement="right" trigger="focus" target="admin-help-button-blocked-players">
          <PopoverBody className="popover-body">
            The players who the administration has banned for violating the game rules. Admin can edit the ban or unblock the user.
          </PopoverBody>
        </UncontrolledPopover>
        <div className="admin-page-content">
          <h2>
            {/* {t('bans')} */}
            Blocked players
            <Button className="admin-help-button" id="admin-help-button-blocked-players">
              ?
            </Button>
          </h2>
          <table style={{ width: '100%', fontSize: 12 }}>
            <colgroup>
              <col span="1" className="" />
            </colgroup>
            <thead>
              <tr>
                <th style={{ textAlign: 'center' }}>
                  {/* {t('name')} */}
                  Name
                </th>
                <th style={{ textAlign: 'center' }}>
                  {/* {t('endsIn')} */}
                  End Date
                </th>
                <th style={{ textAlign: 'center' }}>
                  {/* {t('reason')} */}
                  Reason
                </th>
                <th style={{ textAlign: 'center' }}>
                  {/* {t('playerId')} */}
                  Player Id
                </th>
                <th style={{ textAlign: 'center' }}>
                  {/* {t('edit')} */}
                  Edit
                </th>
                <th style={{ textAlign: 'center' }}>
                  {/* {t('unblock')} */}
                  UnBlock
                </th>
              </tr>
            </thead>
            <tbody>
              {this.table()}
            </tbody>
          </table>
        </div>
        <Modal container={`div > div`} isOpen={openModal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>
            {/* {t('edit')} */}
            Edit
          </ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup>
                <Label for="endDate">
                  {/* {t('until')} */}
                  Until
                </Label>
              {/*  <DatetimePickerTrigger
                  id="endDate"
                  shortcuts={shortcuts}
                  moment={endDate}
                  onChange={this.changeEndDate}
                >
                  <input type="text" value={endDate.format('YYYY-MM-DD HH:mm')} readOnly />
                </DatetimePickerTrigger> */}
                <DateTimePicker
                  format="DD.MM.YYYY HH:mm"
                  culture="lv"
                  onChange={this.changeEndDate}
                  value={endDate}
                  defaultValue={new Date()}
                />
              {/*  <ReactDateTime
                  closeOnSelect
                  onChange={this.changeEndDate}
                  value={endDate}
                  id="endDate"
                />  */}
              </FormGroup>
              <FormGroup>
                <Label for="reason">
                  {/* {t('reason')} */}
                  Reason
                </Label>
                <Input
                  type="text"
                  name="reason"
                  id="reason"
                  value={reason}
                  onChange={this.handleChange}
                />
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.editBan}>Edit</Button>
            {' '}
            <Button color="secondary" onClick={this.toggle}>Close</Button>
          </ModalFooter>
        </Modal>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  allBans: state.admin.allBans || [],
});


export default connect(
  mapStateToProps,
  null,
)(withTranslation('admin')(AllBans));
