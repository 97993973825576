import React from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';
import ReactGA from "react-ga";

import { connect } from 'react-redux';
import { map } from 'lodash';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Input from 'reactstrap/lib/Input';
import Label from 'reactstrap/lib/Label';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import Button from 'reactstrap/lib/Button';

import SoundButton from '../UI/SoundButton';
import LanguageSelect from '../UI/LanguageSettingSelect';

import buttonClickedSound from '../../../sounds/click_feedback.flac';
 import sound from '../../../sounds/click_feedback.flac';

import IconPopover from './IconPopover';

import {
  changeNotificationSettings, toggleAllNotificationSettings, setDefaultSettings, changeGameSetting, toggleSound
} from '../../../actions/userSettings';

import {
  getUserAutoPlay,
  toggleAutoPlay,
  toggleShowHint,
  getUserShowHint,
  updateAutoMergeRooms,
  getUserShowIgnoredRooms,
  toggleShowIgnoredRooms,
  updateDisabledEmoticons,
  updateDisabledReceivingGifts,
  updateDisabledShowGifts,
} from '../../../actions/member';

import {
  FONT_MODE,
  USER_SYSTEM_SETTINGS,
  sendLanguage,
  MENU_NAVIGATION,
} from '../../../constants/constants';

class UserSettings extends React.Component {
  static propTypes = {
    uid: PropTypes.string,
    t: PropTypes.func.isRequired,
    notificationSettings: PropTypes.shape({
      leaveRoom: PropTypes.bool,
      lastRound: PropTypes.bool,
      quitRound: PropTypes.bool,
      ignorePlayer: PropTypes.bool,
      quitAccount: PropTypes.bool,
      purchaseGift: PropTypes.bool,
      removeGift: PropTypes.bool,
      newDesign: PropTypes.bool,
      showAfterRound: PropTypes.bool,
      showAtRoomEnd: PropTypes.bool,

    }),
    gameSettings: PropTypes.shape({
      doubleClickPlay: PropTypes.bool,
    }),
    userSettings: PropTypes.shape({
      soundOn: PropTypes.bool,
      default: PropTypes.shape({
        soundOn: PropTypes.bool,
      }),
    }),
    i18n: PropTypes.shape({
      language: PropTypes.bool,
      changeLanguage: PropTypes.func.isRequired,
    }),
    changeSetting: PropTypes.func.isRequired,
    changeNotification: PropTypes.func.isRequired,
    setDefault: PropTypes.func.isRequired,
    toggleAllNotifications: PropTypes.func.isRequired,
    toggleNewDesign: PropTypes.func.isRequired,
    showUserSettingsModal: PropTypes.func.isRequired,
    changeSound: PropTypes.func.isRequired,
    changeAutoPlay: PropTypes.func.isRequired,
    autoMergeRooms: PropTypes.func.isRequired,
    setAutoMergeRooms: PropTypes.func.isRequired,
    disabledAutoPlay: PropTypes.bool,
    fetchAutoPlay: PropTypes.func.isRequired,
    showHint: PropTypes.bool,
    changeShowHint: PropTypes.func.isRequired,
    fetchShowHint: PropTypes.func.isRequired,
    showIgnoredRooms: PropTypes.bool,
    changeShowIgnoredRooms: PropTypes.func.isRequired,
    fetchShowIgnoredRooms: PropTypes.func.isRequired,
    fontMode: PropTypes.string,
    newDesign: PropTypes.bool,
  };

  static defaultProps = {
    uid: null,
    notificationSettings: {},
    gameSettings: {},
    userSettings: {},
    i18n: {},
    disabledAutoPlay: false,
    showHint: false,
    fontMode: FONT_MODE.normal,
    newDesign: null,
  };

  constructor(props) {
    super(props);

    const { i18n } = this.props;

    let localLanguage = i18n.language || sendLanguage.lv;

    try {
      localLanguage = localStorage.getItem('language'); //x
    } catch (err) {
      console.log(err);
    }

    this.state = {
    //  createRoomClicked: false,
    //  gameBetChoserOpen: false,
      beforeSettings: [],
      curLang: i18n.language || localLanguage || sendLanguage.lv,
      devMode: false,
      disabledAutoPlayMode: props.disabledAutoPlay || false,
      newDesignState: props.newDesign || false,
      autoMergeRoomsState: props.autoMergeRooms || false,
      disabledEmoticonsState: props.disabledEmoticons || false,
      disabledReceivingGiftsState: props.disabledReceivingGifts || false,
      disabledShowGiftsState: props.disabledShowGifts || false,
      showHintMode: props.showHint,
      showIgnoredRoomsMode: props.showIgnoredRooms,
    };

    this.buttonClickedAudio = new Audio(buttonClickedSound);
    this.audio = new Audio(sound);
  }

  componentDidMount() {
    const {
      notificationSettings, userSettings, gameSettings, setDefault, uid, i18n, fetchAutoPlay, fetchShowHint, soundOn, fetchShowIgnoredRooms,
    } = this.props;
    // const { beforeSettings } = this.state;

    fetchAutoPlay();
    fetchShowHint();
    fetchShowIgnoredRooms();

    const curLang = i18n.language;

    const { hostname } = window.location;

    console.log('componentDidMount notificationSettings', notificationSettings);

    let devMode = false;
    if (hostname.includes('dev.') || hostname.includes('localhost')) {
      devMode = true;
    }

    this.setState({ devMode, curLang: curLang || 'lv' });

    const tempSettings = [];
    if (!notificationSettings || Object.keys(notificationSettings).length === 0) {
      setDefault(uid);
    }

    if (notificationSettings) {
      map(notificationSettings, (item, key) => {
        if (item) {
          tempSettings.push(key);
        } else {
          try {
            if (key === USER_SYSTEM_SETTINGS.newDesign && localStorage.getItem('newDesign')) { //x
              tempSettings.push(key);
            }
          } catch (err) {
            console.log(err);
          }
        }
      });
    }
    if (!notificationSettings.leaveRoom && !notificationSettings.lastRound && !notificationSettings.quitRound && !notificationSettings.ignorePlayer
      && !notificationSettings.quitAccount && !notificationSettings.purchaseGift && !notificationSettings.removeGift) {
      tempSettings.push(USER_SYSTEM_SETTINGS.dontShowAnyNotification);
    }

    console.log('userSettings test', userSettings[uid], soundOn);

    /* if (userSettings[uid]) {
      if (userSettings[uid].soundOn) {
        tempSettings.push(USER_SYSTEM_SETTINGS.soundOn);
      }
    } else if (userSettings.default) {
      if (userSettings.default.soundOn) {
        tempSettings.push(USER_SYSTEM_SETTINGS.soundOn);
      }
    } */

    if (soundOn) {
      tempSettings.push(USER_SYSTEM_SETTINGS.soundOn);
    }

    if (gameSettings && gameSettings.doubleClickPlay) {
      tempSettings.push(USER_SYSTEM_SETTINGS.doubleClickPlay);
    }

    this.setState({
      beforeSettings: [...tempSettings],
    });
  }

  componentDidUpdate = (nextProps) => {
    const { disabledAutoPlay, newDesign, autoMergeRooms, disabledEmoticons, disabledReceivingGifts, disabledShowGifts } = this.props;
    // const { disabledAutoPlayMode } = this.state;

    if (disabledAutoPlay !== nextProps.disabledAutoPlay) {
      this.setState({
        disabledAutoPlayMode: disabledAutoPlay,
      });
    }

    if (newDesign !== nextProps.newDesign) {
      this.setState({
        newDesignState: newDesign,
      });
    }

    if (autoMergeRooms !== nextProps.autoMergeRooms) {
      this.setState({
        autoMergeRoomsState: autoMergeRooms,
      });
    }

    if (disabledEmoticons !== nextProps.disabledEmoticons) {
      this.setState({
        disabledEmoticonsState: disabledEmoticons,
      });
    }

    if (disabledReceivingGifts !== nextProps.disabledReceivingGifts) {
      this.setState({
        disabledReceivingGiftsState: disabledReceivingGifts,
      });
    }

    if (disabledShowGifts !== nextProps.disabledShowGifts) {
      this.setState({
        disabledShowGiftsState: disabledShowGifts,
      });
    }
  }

  beforeChangeSetting = (setting) => {
    const { beforeSettings } = this.state;

    console.log('beforeChangeSetting', setting);

    const tempSettings = beforeSettings;
    // console.log(tempSettings, "startCheck")

    if (setting === USER_SYSTEM_SETTINGS.dontShowAnyNotification) {
      // !notificationSettings.leaveRoom && !notificationSettings.lastRound && !notificationSettings.quitRound && !notificationSettings.ignorePlayer
      //     && !notificationSettings.quitAccount && !notificationSettings.purchaseGift && !notificationSettings.removeGift
      if (!tempSettings.includes(USER_SYSTEM_SETTINGS.dontShowAnyNotification)) {
        if (tempSettings.indexOf(USER_SYSTEM_SETTINGS.leaveRoom) !== -1) tempSettings.splice(tempSettings.indexOf(USER_SYSTEM_SETTINGS.leaveRoom), 1);
        if (tempSettings.indexOf(USER_SYSTEM_SETTINGS.lastRound) !== -1) tempSettings.splice(tempSettings.indexOf(USER_SYSTEM_SETTINGS.lastRound), 1);
        if (tempSettings.indexOf(USER_SYSTEM_SETTINGS.quitRound) !== -1) tempSettings.splice(tempSettings.indexOf(USER_SYSTEM_SETTINGS.quitRound), 1);
        if (tempSettings.indexOf(USER_SYSTEM_SETTINGS.ignorePlayer) !== -1) tempSettings.splice(tempSettings.indexOf(USER_SYSTEM_SETTINGS.ignorePlayer), 1);
        if (tempSettings.indexOf(USER_SYSTEM_SETTINGS.quitAccount) !== -1) tempSettings.splice(tempSettings.indexOf(USER_SYSTEM_SETTINGS.quitAccount), 1);
        if (tempSettings.indexOf(USER_SYSTEM_SETTINGS.purchaseGift) !== -1) tempSettings.splice(tempSettings.indexOf(USER_SYSTEM_SETTINGS.purchaseGift), 1);
        if (tempSettings.indexOf(USER_SYSTEM_SETTINGS.removeGift) !== -1) tempSettings.splice(tempSettings.indexOf(USER_SYSTEM_SETTINGS.removeGift), 1);
        tempSettings.push(USER_SYSTEM_SETTINGS.dontShowAnyNotification);
      } else if (tempSettings.indexOf(USER_SYSTEM_SETTINGS.dontShowAnyNotification) !== -1 && beforeSettings.includes(USER_SYSTEM_SETTINGS.leaveRoom) && beforeSettings.includes(USER_SYSTEM_SETTINGS.lastRound) && beforeSettings.includes(USER_SYSTEM_SETTINGS.quitRound)
        && beforeSettings.includes(USER_SYSTEM_SETTINGS.ignorePlayer) && beforeSettings.includes(USER_SYSTEM_SETTINGS.quitAccount) && beforeSettings.includes(USER_SYSTEM_SETTINGS.purchaseGift) && beforeSettings.includes(USER_SYSTEM_SETTINGS.removeGift)) {
        if (tempSettings.indexOf(USER_SYSTEM_SETTINGS.leaveRoom) !== -1) tempSettings.splice(tempSettings.indexOf(USER_SYSTEM_SETTINGS.leaveRoom), 1);
        if (tempSettings.indexOf(USER_SYSTEM_SETTINGS.lastRound) !== -1) tempSettings.splice(tempSettings.indexOf(USER_SYSTEM_SETTINGS.lastRound), 1);
        if (tempSettings.indexOf(USER_SYSTEM_SETTINGS.quitRound) !== -1) tempSettings.splice(tempSettings.indexOf(USER_SYSTEM_SETTINGS.quitRound), 1);
        if (tempSettings.indexOf(USER_SYSTEM_SETTINGS.ignorePlayer) !== -1) tempSettings.splice(tempSettings.indexOf(USER_SYSTEM_SETTINGS.ignorePlayer), 1);
        if (tempSettings.indexOf(USER_SYSTEM_SETTINGS.quitAccount) !== -1) tempSettings.splice(tempSettings.indexOf(USER_SYSTEM_SETTINGS.quitAccount), 1);
        if (tempSettings.indexOf(USER_SYSTEM_SETTINGS.purchaseGift) !== -1) tempSettings.splice(tempSettings.indexOf(USER_SYSTEM_SETTINGS.purchaseGift), 1);
        if (tempSettings.indexOf(USER_SYSTEM_SETTINGS.removeGift) !== -1) tempSettings.splice(tempSettings.indexOf(USER_SYSTEM_SETTINGS.removeGift), 1);
        tempSettings.push(USER_SYSTEM_SETTINGS.dontShowAnyNotification);
      } else {
        tempSettings.splice(tempSettings.indexOf(USER_SYSTEM_SETTINGS.dontShowAnyNotification), 1);
        if (tempSettings.indexOf(USER_SYSTEM_SETTINGS.leaveRoom) === -1) tempSettings.push(USER_SYSTEM_SETTINGS.leaveRoom);;
        if (tempSettings.indexOf(USER_SYSTEM_SETTINGS.lastRound) === -1) tempSettings.push(USER_SYSTEM_SETTINGS.lastRound);
        if (tempSettings.indexOf(USER_SYSTEM_SETTINGS.quitRound) === -1) tempSettings.push(USER_SYSTEM_SETTINGS.quitRound);
        if (tempSettings.indexOf(USER_SYSTEM_SETTINGS.ignorePlayer) === -1) tempSettings.push(USER_SYSTEM_SETTINGS.ignorePlayer);
        if (tempSettings.indexOf(USER_SYSTEM_SETTINGS.quitAccount) === -1) tempSettings.push(USER_SYSTEM_SETTINGS.quitAccount);
        if (tempSettings.indexOf(USER_SYSTEM_SETTINGS.purchaseGift) === -1) tempSettings.push(USER_SYSTEM_SETTINGS.purchaseGift);
        if (tempSettings.indexOf(USER_SYSTEM_SETTINGS.removeGift) === -1) tempSettings.push(USER_SYSTEM_SETTINGS.removeGift);
      }
    } else {
      console.log(tempSettings, setting, 'checkSound2');
      if (tempSettings.indexOf(setting) === -1) {
        console.log(tempSettings, 'pushCheck');
        tempSettings.push(setting);
      } else {
        console.log(tempSettings, 'removeCheck');
        tempSettings.splice(tempSettings.indexOf(setting), 1);
      }
      if (tempSettings.indexOf(USER_SYSTEM_SETTINGS.leaveRoom) !== -1 && tempSettings.indexOf(USER_SYSTEM_SETTINGS.lastRound) !== -1 && tempSettings.indexOf(USER_SYSTEM_SETTINGS.quitRound) !== -1 && tempSettings.indexOf(USER_SYSTEM_SETTINGS.ignorePlayer) !== -1 && tempSettings.indexOf(USER_SYSTEM_SETTINGS.quitAccount) === -1 && tempSettings.indexOf(USER_SYSTEM_SETTINGS.purchaseGift) !== -1 && tempSettings.indexOf(USER_SYSTEM_SETTINGS.removeGift) !== -1) {
        if (tempSettings.indexOf(USER_SYSTEM_SETTINGS.dontShowAnyNotification) !== -1) {
          tempSettings.slice(tempSettings.indexOf(USER_SYSTEM_SETTINGS.dontShowAnyNotification), 1);
        }
      }
      if (tempSettings.indexOf(USER_SYSTEM_SETTINGS.leaveRoom) === -1 && tempSettings.indexOf(USER_SYSTEM_SETTINGS.lastRound) === -1 && tempSettings.indexOf(USER_SYSTEM_SETTINGS.quitRound) === -1 && tempSettings.indexOf(USER_SYSTEM_SETTINGS.ignorePlayer) === -1 && tempSettings.indexOf(USER_SYSTEM_SETTINGS.quitAccount) === -1 && tempSettings.indexOf(USER_SYSTEM_SETTINGS.purchaseGift) === -1 && tempSettings.indexOf(USER_SYSTEM_SETTINGS.removeGift) === -1) {
        if (tempSettings.indexOf(USER_SYSTEM_SETTINGS.dontShowAnyNotification) === -1) {
          tempSettings.push(USER_SYSTEM_SETTINGS.dontShowAnyNotification);
        }
      }
    }

    this.setState({
      beforeSettings: [...tempSettings],
    });
    console.log(tempSettings, 'checkSettings');
  }

  changeSetting = () => {
    const {
      i18n,
      changeNotification,
      changeShowHint,
      changeSetting,
      userSettings,
      showHint,
      showIgnoredRooms,
      uid,
      gameSettings,
      notificationSettings,
      toggleNewDesign,
      changeSound,
      disabledAutoPlay,
      changeAutoPlay,
      // toggleAllNotifications,
      showUserSettingsModal,
      soundOn,
      autoMergeRooms,
      setAutoMergeRooms,
      changeShowIgnoredRooms,

      disabledEmoticons,
      disabledReceivingGifts,
      disabledShowGifts,
      setDisabledEmoticons,
      setDisabledReceivingGifts,
      setDisabledShowGifts,
    } = this.props;
    const {
      beforeSettings,
      disabledAutoPlayMode,
      showHintMode,
      showIgnoredRoomsMode,
      curLang,
      newDesignState,
      autoMergeRoomsState,
      disabledEmoticonsState,
      disabledReceivingGiftsState,
      disabledShowGiftsState,
    } = this.state;

    console.log('disabledReceivingGifts 222', disabledReceivingGifts, disabledReceivingGiftsState);

    //  console.log('changeSetting', { data, setting, gameSettings });

    // if (data && setting === 'doubleClickPlay') {
    //   if (data.type === 'single' && gameSettings.doubleClickPlay) {
    //     changeSetting(setting, uid);
    //   } else if (data.type === 'double' && !gameSettings.doubleClickPlay) {
    //     changeSetting(setting, uid);
    //   }
    // //  changeSetting(setting, uid);
    // } else {
    //   if (setting === 'newDesign' && notificationSettings.newDesign) {
    //     localStorage.removeItem('newDesign');
    //     toggleNewDesign(false);
    //   } else if (setting === 'newDesign' && !notificationSettings.newDesign) {
    //     localStorage.setItem('newDesign', JSON.stringify(true));
    //     toggleNewDesign(true);
    //   }
    //   changeNotification(setting, uid);
    // }

    console.log('changeSetting', USER_SYSTEM_SETTINGS);

    map(USER_SYSTEM_SETTINGS, (item) => {
      if (item === USER_SYSTEM_SETTINGS.doubleClickPlay) {
        if ((!gameSettings?.doubleClickPlay && beforeSettings.indexOf(item) !== -1) || (gameSettings?.doubleClickPlay && beforeSettings.indexOf(item) === -1)) {
          changeSetting(item, uid);
        }
      } else if (item === USER_SYSTEM_SETTINGS.dontShowAnyNotification) {
        console.log('ignore');
      } else if (item === USER_SYSTEM_SETTINGS.soundOn) {
        if (soundOn !== beforeSettings.includes(USER_SYSTEM_SETTINGS.soundOn)) { //userSettings[uid]?.soundOn
          const playPromise = this.audio.play();
          if (playPromise !== undefined) {
            playPromise
              .then(() => {
                // Automatic playback started!
                // Show playing UI.
                console.log('audio played auto');
              })
              .catch(() => {
                // Auto-play was prevented
                // Show paused UI.
                console.log('playback prevented');
              });
          }
          changeSound(uid);

          ReactGA.event({
            category: 'Sound',
            action: 'Change sound'
          });
          // console.log('changeCallsound');
        }
      } else if (item === USER_SYSTEM_SETTINGS.newDesign) {
        /* if (beforeSettings.indexOf(item) === -1) {
           localStorage.removeItem('newDesign'); //x
           toggleNewDesign(false);
         } else {
           localStorage.setItem('newDesign', true); //x
           toggleNewDesign(true);
         } */
        /* if (!newDesignState) {
          try {
            localStorage.removeItem('newDesign'); //x
          } catch (err) {
            console.log(err);
          }
          toggleNewDesign(false);
        } else {
          try {
            localStorage.setItem('newDesign', true); //x
          } catch (err) {
            console.log(err);
          }
          toggleNewDesign(true);
        }
        changeNotification(USER_SYSTEM_SETTINGS.newDesign, uid); */
      } else if (item === USER_SYSTEM_SETTINGS.autoPlay) {
        if (disabledAutoPlayMode !== disabledAutoPlay) {
          changeAutoPlay(disabledAutoPlayMode);
        }
      } else if (item === USER_SYSTEM_SETTINGS.autoMergeRooms) {
        console.log('setAutoMergeRooms', autoMergeRoomsState, autoMergeRooms);
        if (autoMergeRoomsState !== autoMergeRooms) {
          setAutoMergeRooms(autoMergeRoomsState);
        }

      } else if (item === USER_SYSTEM_SETTINGS.disabledEmoticons) {
        console.log('setDisabledEmoticons', disabledEmoticonsState, disabledEmoticons);
        if (disabledEmoticonsState !== disabledEmoticons) {
          setDisabledEmoticons(disabledEmoticonsState);
        }
      } else if (item === USER_SYSTEM_SETTINGS.disabledReceivingGifts) {
        console.log('setDisabledReceivingGifts', disabledReceivingGiftsState, disabledReceivingGifts);
        if (disabledReceivingGiftsState !== disabledReceivingGifts) {
          setDisabledReceivingGifts(disabledReceivingGiftsState);
        }
      } else if (item === USER_SYSTEM_SETTINGS.disabledShowGifts) {
        console.log('setDisabledShowGifts', disabledShowGiftsState, disabledShowGifts);
        if (disabledShowGiftsState !== disabledShowGifts) {
          setDisabledShowGifts(disabledShowGiftsState);
        }
      } else if (item === USER_SYSTEM_SETTINGS.showHint) {
        if (showHintMode !== showHint) {
          changeShowHint(showHintMode);
        }
      } else if (item === USER_SYSTEM_SETTINGS.showIgnoredRooms) {
        if (showIgnoredRoomsMode !== showIgnoredRooms) {
          changeShowIgnoredRooms(showIgnoredRoomsMode);
        }
      } else {
        if ((!notificationSettings[item] && beforeSettings.indexOf(item) !== -1) || (notificationSettings[item] && beforeSettings.indexOf(item) === -1)) {
          changeNotification(item, uid);

          /*  if (!newDesignState) {
            try {
              localStorage.removeItem('newDesign'); //x
            } catch (err) {
              console.log(err);
            }
            toggleNewDesign(false);

            ReactGA.event({
              category: 'Menu',
              action: 'Old Design',
            });
          } else {
            try {
              localStorage.setItem('newDesign', true); //x
            } catch (err) {
              console.log(err);
            }
            toggleNewDesign(true);

            ReactGA.event({
              category: 'Menu',
              action: 'New Design',
            });
          } * /

          /* if (item === USER_SYSTEM_SETTINGS.newDesign && beforeSettings.indexOf(item) !== -1) {
            try {
              localStorage.setItem('newDesign', true); //x
            } catch (err) {
              console.log(err);
            }
            toggleNewDesign(true);

            ReactGA.event({
              category: 'Menu',
              action: 'New Design',
            });
          } else if (item === USER_SYSTEM_SETTINGS.newDesign && beforeSettings.indexOf(item) === -1) {
            try {
              localStorage.removeItem('newDesign'); //x
            } catch (err) {
              console.log(err);
            }
            toggleNewDesign(false);

            ReactGA.event({
              category: 'Menu',
              action: 'Old Design',
            });
          }
          changeNotification(item, uid); */
        }
      }
    })

    i18n.changeLanguage(curLang);

    ReactGA.event({
      category: 'Language',
      action: 'Change Language',
    });

    try {
      if (window && window.localStorage && window.localStorage.setItem) { //x
        localStorage.setItem('language', curLang); //x
      }
    } catch (err) {
      console.log(err);
    }

    showUserSettingsModal();
  }

  toggleAllNotifications = () => {
    const { toggleAllNotifications, uid } = this.props;

    toggleAllNotifications(uid);
  };

  playButtonSound = () => {
    const { soundOn, uid } = this.props;

      if (soundOn) {
        const playPromise = this.buttonClickedAudio.play();
        if (playPromise !== undefined) {
          playPromise
            .then(() => {
              // Automatic playback started!
              // Show playing UI.
              console.log('audio played auto');
            })
            .catch(() => {
              // Auto-play was prevented
              // Show paused UI.
              console.log('playback prevented');
            });
        }
      }
  }

  toggleLanguageFunc = (lang) => {
    // const { i18n } = this.props;

    this.setState({ curLang: lang });

    // i18n.changeLanguage(lang);

    // ReactGA.event({
    //   category: 'Language',
    //   action: 'Change Language'
    // });
  }

  handleChange = (event) => {
    this.setState(previous => ({
      disabledAutoPlayMode: !previous.disabledAutoPlayMode,
    }));
  };

  handleChangeNewDesign = () => {
    console.log('handleChangeNewDesign');
    this.setState(previous => ({
      newDesignState: !previous.newDesignState,
    }))
  }

  handleHintChange = (event) => {
    this.setState(previous => ({
      showHintMode: !previous.showHintMode,
    }));
  };

  handleIgnoredRoomsChange = () => {
    this.setState(previous => ({
      showIgnoredRoomsMode: !previous.showIgnoredRoomsMode
    }))
  }

  handleAutoMergeChange = (event) => {
    this.setState(previous => ({
      autoMergeRoomsState: !previous.autoMergeRoomsState,
    }));
  };

  handleChangeDisabledEmoticons = (event) => {
    this.setState(previous => ({
      disabledEmoticonsState: !previous.disabledEmoticonsState,
    }));
  };

  handleChangeDisabledReceivingGifts = (event) => {
    this.setState(previous => ({
      disabledReceivingGiftsState: !previous.disabledReceivingGiftsState,
    }));
  };

  handleChangeDisabledShowGifts = (event) => {
    this.setState(previous => ({
      disabledShowGiftsState: !previous.disabledShowGiftsState,
    }));
  };

  mouseEnterSettingsPopover = (name) => {
    this.setState({ settingsPopover: name });
  }

  mouseLeaveSettingsPopover = () => {
    this.setState({ settingsPopover: null });
  }

  buyVip = () => {
    const { changeTab, showUserSettingsModal } = this.props;

    changeTab(MENU_NAVIGATION.buyPagePremiumPacks);
    showUserSettingsModal();
  }

  render() {
    const {
      t,
      // notificationSettings,
      // gameSettings,
      // i18n,
      uid,
      showUserSettingsModal,
      // disabledAutoPlay,
      showHint,
      fontMode,
      autoMergeRooms,
      vipUntil,
      offset,
      disabledReceivingGifts,
    } = this.props;

    const {
      beforeSettings,
      curLang,
      devMode,
      disabledAutoPlayMode,
      newDesignState,
      showHintMode,
      autoMergeRoomsState,
      showIgnoredRoomsMode,
      disabledEmoticonsState,
      disabledReceivingGiftsState,
      disabledShowGiftsState,
      settingsPopover,
    } = this.state;

    console.log('showHint', showHint);

    //  const {
    //    privateRoomConfirm,
    //    popoverOpen,
    //  } = this.state;

    console.log('disabledReceivingGifts 222', disabledReceivingGifts, disabledReceivingGiftsState);


    console.log('autoMergeRoomsState', autoMergeRoomsState, autoMergeRooms);

    return (
      <>
        <div className="user-settings">
          {/*  <SoundButton uid={uid} />
              <LanguageSelect />  */}
          <Row>
            <Col sm="6">
              <Row>
                <Col sm="12" className="user-settings-header">
                  {t('showConfirmationWindow')}
                </Col>
                <Col sm="12">
                  <Input
                    type="checkbox"
                    className="user-settings-checkbox"
                    onClick={() => this.beforeChangeSetting('leaveRoom')}
                    checked={beforeSettings.indexOf(USER_SYSTEM_SETTINGS.leaveRoom) !== -1}
                    readOnly
                  />
                  <Label className="user-settings-label" onClick={() => this.beforeChangeSetting('leaveRoom')}>
                    <div className="game-type-text">{t('leaveRoom')}</div>
                  </Label>
                </Col>
                <Col sm="12">
                  <Input
                    type="checkbox"
                    className="user-settings-checkbox"
                    onClick={() => this.beforeChangeSetting('lastRound')}
                    checked={beforeSettings.indexOf(USER_SYSTEM_SETTINGS.lastRound) !== -1}
                    readOnly
                  />
                  <Label className="user-settings-label" onClick={() => this.beforeChangeSetting('lastRound')}>
                    <div className="game-type-text">{t('lastRound')}</div>
                  </Label>
                </Col>
                <Col sm="12">
                  <Input
                    type="checkbox"
                    className="user-settings-checkbox"
                    onClick={() => this.beforeChangeSetting('quitRound')}
                    checked={beforeSettings.indexOf(USER_SYSTEM_SETTINGS.quitRound) !== -1}
                    readOnly
                  />
                  <Label className="user-settings-label" onClick={() => this.beforeChangeSetting('quitRound')}>
                    <div className="game-type-text">{t('quitRound')}</div>
                  </Label>
                </Col>
                <Col sm="12">
                  <Input
                    type="checkbox"
                    className="user-settings-checkbox"
                    onClick={() => this.beforeChangeSetting('ignorePlayer')}
                    checked={beforeSettings.indexOf(USER_SYSTEM_SETTINGS.ignorePlayer) !== -1}
                    readOnly
                  />
                  <Label className="user-settings-label" onClick={() => this.beforeChangeSetting('ignorePlayer')}>
                    <div className="game-type-text">{t('ignorePlayer')}</div>
                  </Label>
                </Col>
                <Col sm="12">
                  <Input
                    type="checkbox"
                    className="user-settings-checkbox"
                    onClick={() => this.beforeChangeSetting('quitAccount')}
                    checked={beforeSettings.indexOf(USER_SYSTEM_SETTINGS.quitAccount) !== -1}
                    readOnly
                  />
                  <Label className="user-settings-label" onClick={() => this.beforeChangeSetting('quitAccount')}>
                    <div className="game-type-text">{t('quitAccount')}</div>
                  </Label>
                </Col>
                <Col sm="12">
                  <Input
                    type="checkbox"
                    className="user-settings-checkbox"
                    onClick={() => this.beforeChangeSetting('purchaseGift')}
                    checked={beforeSettings.indexOf(USER_SYSTEM_SETTINGS.purchaseGift) !== -1}
                    readOnly
                  />
                  <Label className="user-settings-label" onClick={() => this.beforeChangeSetting('purchaseGift')}>
                    <div className="game-type-text">{t('purchaseGift')}</div>
                  </Label>
                </Col>
                <Col sm="12">
                  <Input
                    type="checkbox"
                    className="user-settings-checkbox"
                    onClick={() => this.beforeChangeSetting('removeGift')}
                    checked={beforeSettings.indexOf(USER_SYSTEM_SETTINGS.removeGift) !== -1}
                    readOnly
                  />
                  <Label className="user-settings-label" onClick={() => this.beforeChangeSetting('removeGift')}>
                    <div className="game-type-text">{t('removeGift')}</div>
                  </Label>
                </Col>

                {/* {devMode ? (
                  <Col sm="12">
                    <Input
                      type="checkbox"
                      className="user-settings-checkbox"
                      onClick={this.handleChangeNewDesign}
                      checked={newDesignState}
                      readOnly
                    />
                    <Label className="user-settings-label" onClick={this.handleChangeNewDesign}>
                      <div className="game-type-text">{t('newDesign')}</div>
                    </Label>
                  </Col>
                ) : (null)} */}
                <div className="user-settings-divider" />
                <Col sm="12">
                  <Input
                    type="checkbox"
                    className="user-settings-checkbox"
                    onClick={() => this.beforeChangeSetting(USER_SYSTEM_SETTINGS.dontShowAnyNotification)}
                    checked={!beforeSettings.includes(USER_SYSTEM_SETTINGS.leaveRoom) && !beforeSettings.includes(USER_SYSTEM_SETTINGS.lastRound) && !beforeSettings.includes(USER_SYSTEM_SETTINGS.quitRound) && !beforeSettings.includes(USER_SYSTEM_SETTINGS.ignorePlayer) && !beforeSettings.includes(USER_SYSTEM_SETTINGS.quitAccount) && !beforeSettings.includes(USER_SYSTEM_SETTINGS.purchaseGift) && !beforeSettings.includes(USER_SYSTEM_SETTINGS.removeGift)}
                    readOnly
                  />
                  <Label className="user-settings-label" onClick={() => this.beforeChangeSetting(USER_SYSTEM_SETTINGS.dontShowAnyNotification)}>
                    <div className="game-type-text">{t('dontShowAnyNotification')}</div>
                  </Label>
                </Col>
              </Row>
            </Col>

            <Col sm="6">
              <Row style={{ height: '100%', display: 'block' }}>
                <Col sm="12" className="user-settings-header">
                  {t('showResultWindow')}
                </Col>
                <Col sm="12">
                  <Input
                    type="checkbox"
                    className="user-settings-checkbox"
                    checked={beforeSettings.indexOf(USER_SYSTEM_SETTINGS.showAfterRound) !== -1}
                    onClick={() => this.beforeChangeSetting('showAfterRound')}
                    readOnly
                  />
                  <Label className="user-settings-label" onClick={() => this.beforeChangeSetting('showAfterRound')}>
                    <div className="game-type-text">{t('showAfterRound')}</div>
                  </Label>
                </Col>
                <Col sm="12">
                  <Input
                    type="checkbox"
                    className="user-settings-checkbox"
                    onClick={() => this.beforeChangeSetting('showAtRoomEnd')}
                    checked={beforeSettings.indexOf(USER_SYSTEM_SETTINGS.showAtRoomEnd) !== -1}
                    readOnly
                  />
                  <Label className="user-settings-label" onClick={() => this.beforeChangeSetting('showAtRoomEnd')}>
                    <div className="game-type-text">{t('showAtRoomEnd')}</div>
                  </Label>
                </Col>

                <Col sm="12" className="user-settings-header">
                  {t('others')}
                </Col>
                <Col sm="12">
                  <Input
                    type="checkbox"
                    className="user-settings-checkbox"
                    onChange={this.handleChange}
                    checked={!disabledAutoPlayMode}
                    readOnly
                  />
                  <Label className="user-settings-label" onClick={this.handleChange}>
                    <div className="game-type-text">{t('autoPlay')}</div>
                  </Label>
                </Col>
                <Col sm="12">
                  <Input
                    type="checkbox"
                    className="user-settings-checkbox"
                    onChange={this.handleAutoMergeChange}
                    checked={autoMergeRoomsState}
                    readOnly
                  />
                  <Label className="user-settings-label" onClick={this.handleAutoMergeChange}>
                    <div className="game-type-text">{t('autoMergeRooms')}</div>
                  </Label>
                </Col>

                <Col sm="12">
                  <Input
                    type="checkbox"
                    className="user-settings-checkbox"
                    onChange={this.handleHintChange}
                    checked={!showHintMode}
                    readOnly
                  />
                  <Label className="user-settings-label" onClick={this.handleHintChange}>
                    <div className="game-type-text">{t('showHint')}</div>
                  </Label>
                </Col>

                <Col sm="12">
                  <Input
                    type="checkbox"
                    className="user-settings-checkbox"
                    onChange={this.handleIgnoredRoomsChange}
                    checked={showIgnoredRoomsMode}
                    readOnly
                  />
                  <Label className="user-settings-label" onClick={this.handleIgnoredRoomsChange}>
                    <div className="game-type-text">{t('showIgnoredRooms')}</div>
                  </Label>
                </Col>

                {devMode ? (
                  <>
                    <Col sm="12" className="user-settings-header">
                      {t('vip')}
                    </Col>
                    <Col sm="12">
                      <Input
                        type="checkbox"
                        className="user-settings-checkbox"
                        onChange={this.handleChangeDisabledEmoticons}
                        checked={!disabledEmoticonsState || (!vipUntil || vipUntil < (Date.now() + offset))}
                        readOnly
                        disabled={(!vipUntil || vipUntil < (Date.now() + offset))}
                      />
                      <Label
                        disabled={(!vipUntil || vipUntil < (Date.now() + offset))}
                        className="user-settings-label"
                        id="disabled-emoticons-toggle"
                        onClick={this.handleChangeDisabledEmoticons}
                        onMouseEnter={() => this.mouseEnterSettingsPopover("disabled-emoticons-toggle")}
                        onMouseLeave={() => this.mouseLeaveSettingsPopover()}
                      >
                        <div className="game-type-text">{t('disableEmoticons')}</div>
                        <IconPopover
                          fontMode={fontMode}
                          placement="right"
                          popoverOpen={(!vipUntil || vipUntil < (Date.now() + offset)) && settingsPopover === "disabled-emoticons-toggle"}
                          targetId="disabled-emoticons-toggle"
                        >
                          <div className="game-type-text text-align-center">
                            {t('vipSettingPopover')}
                            <Button className="notification-footer-button" color="secondary" onClick={this.buyVip}>
                              {t('common:bonusPage.buyVIP')}
                            </Button>
                          </div>
                        </IconPopover>
                      </Label>
                      {/* <IconPopover
                        fontMode={fontMode}
                        text={t('disableEmoticonsPopover')}
                        placement="right"
                        popoverOpen={(!vipUntil || vipUntil < (Date.now() + offset)) && settingsPopover === "disabled-emoticons-toggle"}
                        targetId="disabled-emoticons-toggle"
                      /> */}
                    </Col>
                    <Col sm="12">
                      <Input
                        type="checkbox"
                        className="user-settings-checkbox"
                        onChange={this.handleChangeDisabledReceivingGifts}
                        checked={!disabledReceivingGiftsState || (!vipUntil || vipUntil < (Date.now() + offset))}
                        readOnly
                        disabled={(!vipUntil || vipUntil < (Date.now() + offset))}
                      />
                      <Label
                        disabled={(!vipUntil || vipUntil < (Date.now() + offset))}
                        className="user-settings-label"
                        id="disabled-receive-gifts-toggle"
                        onClick={this.handleChangeDisabledReceivingGifts}
                        onMouseEnter={() => this.mouseEnterSettingsPopover("disabled-receive-gifts-toggle")}
                        onMouseLeave={() => this.mouseLeaveSettingsPopover()}
                      >
                        <div className="game-type-text">{t('disabledReceiveGifts')}</div>
                        <IconPopover
                          fontMode={fontMode}
                          placement="right"
                          popoverOpen={(!vipUntil || vipUntil < (Date.now() + offset)) && settingsPopover === "disabled-receive-gifts-toggle"}
                          targetId="disabled-receive-gifts-toggle"
                        >
                          <div className="game-type-text text-align-center">
                            {t('vipSettingPopover')}
                            <Button className="notification-footer-button" color="secondary" onClick={this.buyVip}>
                              {t('common:bonusPage.buyVIP')}
                            </Button>
                          </div>
                        </IconPopover>
                      </Label>
                      {/* <IconPopover
                        fontMode={fontMode}
                        text={t('disabledReceiveGiftsPopover')}
                        placement="right"
                        popoverOpen={(!vipUntil || vipUntil < (Date.now() + offset)) && settingsPopover === "disabled-receive-gifts-toggle"}
                        targetId="disabled-receive-gifts-toggle"
                      /> */}
                    </Col>

                    <Col sm="12">
                      <Input
                        type="checkbox"
                        className="user-settings-checkbox"
                        onChange={this.handleChangeDisabledShowGifts}
                        checked={!disabledShowGiftsState || (!vipUntil || vipUntil < (Date.now() + offset))}
                        readOnly
                        disabled={(!vipUntil || vipUntil < (Date.now() + offset))}
                      />
                      <Label
                        disabled={(!vipUntil || vipUntil < (Date.now() + offset))}
                        className="user-settings-label"
                        id="disabled-show-gifts-toggle"
                        onClick={this.handleChangeDisabledShowGifts}
                        onMouseEnter={() => this.mouseEnterSettingsPopover("disabled-show-gifts-toggle")}
                        onMouseLeave={() => this.mouseLeaveSettingsPopover()}
                      >
                        <div className="game-type-text">{t('disabledShowGifts')}</div>
                        <IconPopover
                          fontMode={fontMode}
                          placement="right"
                          popoverOpen={(!vipUntil || vipUntil > (Date.now() + offset)) && settingsPopover === "disabled-show-gifts-toggle"}
                          targetId="disabled-show-gifts-toggle"
                        >
                          <div className="game-type-text text-align-center">
                            {t('vipSettingPopover')}
                            <Button className="notification-footer-button" style={{ minWidth: 'fit-content' }} color="secondary" onClick={this.buyVip}>
                              {t('common:bonusPage.buyVIP')}
                            </Button>
                          </div>
                        </IconPopover>
                      </Label>
                      
                    </Col>
                  </>
                ) : (null)}

                {/* <div className="user-settings-space" /> */}
                <div className="user-settings-toggles">

                  <Col sm="12">
                    <Row>
                      <Col sm="3" className="user-settings-list">
                        <Label className="user-settings-label user-settings-label-margin">
                          {t('cardPlay')}
                          :
                        </Label>
                      </Col>
                      <Col sm="9" style={{marginTop: 'auto', marginBottom: 'auto'}}>
                        {/*  <Input
                          type="checkbox"
                          className="user-settings-checkbox"
                          onClick={() => this.changeSetting('doubleClickPlay')}
                          checked={notificationSettings.doubleClickPlay}
                          readOnly
                        />
                        <Label className="user-settings-label" onClick={() => this.changeSetting('doubleClickPlay')}>
                          <div className="game-type-text">{t('doubleClickPlay')}</div>
                        </Label>  */}
                        <Label check className={`user-settings-play user-settings-play-${fontMode}`} sm="6">
                          <Input
                            type="radio"
                            name="radio1"
                            onClick={() => this.beforeChangeSetting('doubleClickPlay', { type: 'single' })}
                            checked={beforeSettings.indexOf(USER_SYSTEM_SETTINGS.doubleClickPlay) === -1}
                            readOnly
                          />
                          {' '}
                          {t('cardPlaySingle')}
                        </Label>
                        <Label check className={`user-settings-play user-settings-play-${fontMode}`} sm="6">
                          <Input
                            type="radio"
                            name="radio2"
                            onClick={() => this.beforeChangeSetting('doubleClickPlay', { type: 'double' })}
                            checked={beforeSettings.indexOf(USER_SYSTEM_SETTINGS.doubleClickPlay) !== -1}
                            readOnly
                          />
                          {' '}
                          {t('cardPlayDouble')}
                        </Label>
                      </Col>
                    </Row>
                  </Col>
                  <Col sm="12">
                    <Row>
                      <Col sm="3" className="user-settings-list">
                        <Label className="user-settings-label">
                          {t('language')}
                          :
                        </Label>
                      </Col>
                      <Col sm="9">
                        <LanguageSelect customClassName={`language-settings-${fontMode}`} beforeSettings={beforeSettings} beforeChangeSetting={this.beforeChangeSetting} toggleLanguageFunc={this.toggleLanguageFunc} curLang={curLang}/>
                      </Col>
                    </Row>
                  </Col>

                  <Col sm="12">
                    <Row>
                      <Col sm="3" className="user-settings-list">
                        <Label className="user-settings-label">
                          {t('sound')}
                          :
                        </Label>
                      </Col>
                      <Col sm="9">
                        <SoundButton customClassName={`sound-button-settings-${fontMode}`} uid={uid} beforeChangeSetting={this.beforeChangeSetting} beforeSettings={beforeSettings} />
                      </Col>
                    </Row>
                  </Col>
                </div>
              </Row>
            </Col>
          </Row>
        </div>
        <ModalFooter>
          <Button className="modal-footer-button" color="secondary" onClick={this.changeSetting}>
            {t('common:common.save')}
          </Button>
          <Button className="modal-footer-button" color="secondary" onClick={showUserSettingsModal}>
            {t('common:common.cancel')}
          </Button>
        </ModalFooter>
      </>
    );
  }
}

const mapStateToProps = state => {
  const { uid } = state.member;

  return ({
    //  notificationSettings: notificationSettings || {},
    notificationSettings: state.userSettings[uid] ? (state.userSettings[uid].notificationSettings) : (state.userSettings.default ? state.userSettings.default.notificationSettings : {}),
    gameSettings: state.userSettings[uid] ? (state.userSettings[uid].gameSettings) : (state.userSettings.default ? state.userSettings.default.gameSettings : {}),
    uid: uid,
    userSettings: state.userSettings || {},
    disabledAutoPlay: state.member.disabledAutoPlay || false,
    showHint: state.member.showHint || false,
    showIgnoredRooms: state.member?.showIgnoredRooms || false,
    // soundOn: (state.userSettings[uid] && (state.userSettings[uid].soundOn || state.userSettings[uid].soundOn === false)) ? (state.userSettings[uid].soundOn) : (state.userSettings.default ? state.userSettings.default.soundOn : false),
    soundOn: state.userSettings.soundOn,
    autoMergeRooms: state.member.autoMergeRooms || null,
    disabledEmoticons: state.member.disabledEmoticons || null,
    disabledReceivingGifts: state.member.disabledReceivingGifts || null,
    disabledShowGifts: state.member.disabledShowGifts || null,
    vipUntil: state.member.vipUntil || null,
    offset: state.member.offset || null,
  });
}

const mapDispatchToProps = {
  changeNotification: changeNotificationSettings,
  changeSetting: changeGameSetting,
  toggleAllNotifications: toggleAllNotificationSettings,
  setDefault: setDefaultSettings,
  changeSound: toggleSound,
  changeAutoPlay: toggleAutoPlay,
  changeShowHint: toggleShowHint,
  fetchAutoPlay: getUserAutoPlay,
  fetchShowHint: getUserShowHint,
  fetchShowIgnoredRooms: getUserShowIgnoredRooms,
  changeShowIgnoredRooms: toggleShowIgnoredRooms,
  setAutoMergeRooms: updateAutoMergeRooms,
  setDisabledEmoticons: updateDisabledEmoticons,
  setDisabledReceivingGifts: updateDisabledReceivingGifts,
  setDisabledShowGifts: updateDisabledShowGifts,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('settings')(UserSettings));
