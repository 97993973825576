import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Pagination, PaginationItem, PaginationLink, PopoverBody } from 'reactstrap';
import Moment from 'react-moment';

import { size } from 'lodash';
import { getLogRocketUsers } from '../../../actions/admin';
import { UncontrolledPopover } from 'reactstrap/lib';

const LogRocket = ({ toggleLogRocket }) => {
  const dispatch = useDispatch();
  const logRocketUsers = useSelector(state => state.admin.logRocketUsers || {});

  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(50);
  const pagesCount = Math.ceil(size(logRocketUsers) / pageSize);

  useEffect(() => {
    dispatch(getLogRocketUsers());
  }, []);

  const changeLogRocket = (uid) => {
    toggleLogRocket(uid);
    dispatch(getLogRocketUsers());
  };

  const handleClick = (e, index) => {
    e.preventDefault();
    setCurrentPage(index);
  };

  console.log(logRocketUsers, "logRocketUsers")
  return (
    <Fragment>
      <UncontrolledPopover popperClassName={`popover`} placement="right" trigger="focus" target="admin-help-button-logrocket">
        <PopoverBody className="popover-body">
          The list of all players whose sessions are being captured by the LogRocket tool. Here an admin can disable the capturing. Please be aware: the amount of sessions per month is limited, so be mindful and enable this only for a user when it's needed and useful.
        </PopoverBody>
      </UncontrolledPopover>
      <div className="admin-page-content">
        <h2>
          LogRocket Users
          <Button className="admin-help-button" id="admin-help-button-logrocket">
            ?
          </Button>
        </h2>
        <table style={{ width: '100%', fontSize: 12 }}>
          <colgroup>
            <col span="1" className="" />
          </colgroup>
          <thead>
            <tr>
              <th style={{ textAlign: 'center' }}>
                Name
              </th>
              <th style={{ textAlign: 'center' }}>
                PlayerId
              </th>
              <th style={{ textAlign: 'center' }}>
                LogRocket
              </th>
            </tr>
          </thead>
          <tbody>
            {size(logRocketUsers) > 0 && Object.keys(logRocketUsers).slice(
              currentPage * pageSize,
              (currentPage + 1) * pageSize,
            ).map((key, index) => (
              <Fragment key={logRocketUsers[key].uid}>
                <tr key={logRocketUsers[key].uid} className={`allUsers-table-row ${index % 2 === 0 ? ('odd') : ('even')}`}>
                  <td className="allUsers-table-col">
                    {logRocketUsers[key].name}
                  </td>
                  <td className="allUsers-table-col">
                    {logRocketUsers[key].uid || key}
                  </td>
                  <td className="allUsers-table-col">
                    <Button color="warning" onClick={() =>{ toggleLogRocket(logRocketUsers[key].uid); dispatch(getLogRocketUsers()); }}>
                      Disable LogRocket
                    </Button>
                  </td>
                </tr>
              </Fragment>
            ))}
            {Object.keys(logRocketUsers).length > pageSize && (
              <div className="pagination-wrapper">
                <Pagination aria-label="Page navigation example">

                  <PaginationItem disabled={currentPage === 0}>
                    <PaginationLink onClick={e => handleClick(e, 0)} href="#" style={{ color: '#000' }}>
                      {`‹‹‹`}
                    </PaginationLink>
                  </PaginationItem>

                  <PaginationItem disabled={currentPage <= 0}>
                    <PaginationLink
                      onClick={e => handleClick(e, currentPage - 1)}
                      previous
                      href="#"
                      style={{ color: '#000' }}
                    />
                  </PaginationItem>

                  {[...Array(pagesCount)].map((page, i) => {
                    if (i > currentPage - 3 && i < currentPage + 3) {
                      return (
                        <PaginationItem active={i === currentPage} key={page}>
                          <PaginationLink onClick={e => handleClick(e, i)} href="#" style={i === currentPage ? { color: '#fff' } : { color: '#000' }}>
                            {i + 1}
                          </PaginationLink>
                        </PaginationItem>
                      );
                    }

                    return null;
                  })}

                  <PaginationItem disabled={currentPage >= pagesCount - 1}>
                    <PaginationLink
                      onClick={e => handleClick(e, currentPage + 1)}
                      next
                      href="#"
                      style={{ color: '#000' }}
                    />
                  </PaginationItem>

                  <PaginationItem disabled={currentPage >= pagesCount - 1}>
                      <PaginationLink onClick={e => handleClick(e, pagesCount - 1)} href="#" style={{ color: '#000' }}>
                        {`›››`}
                      </PaginationLink>
                    </PaginationItem>
                </Pagination>
              </div>
            )}
          </tbody>
        </table>
      </div>
    </Fragment>
  );
};

export default LogRocket;
