import React from 'react';

// import {
//   Input,
// } from 'reactstrap';
import classNames from 'classnames';
import i18next from 'i18next';

const CustomBirthday = ({
  customDay,
  customMonth,
  customYear,
  handleCustomDay,
  handleCustomMonth,
  handleCustomYear,
  dRef,
  mRef,
  yRef,
  handleKeyDay,
  handleKeyMonth,
  handleKeyYear,
  mbirthday,
  birthday,
  fontMode, // for old design only
  dPlaceholder,
  mPlaceholder,
  yPlaceholder,
}) => {

  console.log(handleCustomMonth, "customDay")
  return (
    <div className={classNames('custom-birthday', fontMode ? `custom-birthday-${fontMode}` : '', fontMode && mbirthday ? 'custom-birthday-disable-old' : '', {
      'custom-birthday-disable': mbirthday
    })}>
      <input type='text' className='custom-birthday-day' placeholder={dPlaceholder} ref={dRef} value={customDay || dPlaceholder} onKeyDown={handleKeyDay} disabled={birthday} />
      <span className='custom-birthday-slot'>/</span>
      <input type='text' className='custom-birthday-month' placeholder={mPlaceholder} ref={mRef} value={customMonth || mPlaceholder} onKeyDown={handleKeyMonth} disabled={birthday} />
      <span className='custom-birthday-slot'>/</span>
      <input type='text' className={i18next.language === 'lv' ? 'custom-birthday-year custom-birthday-year-lv' : 'custom-birthday-year'} placeholder={yPlaceholder} ref={yRef} value={customYear || yPlaceholder} maxLength={4} onKeyDown={handleKeyYear} disabled={birthday} />
    </div>
  );
};

export default CustomBirthday;
