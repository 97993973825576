import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import Modal from 'reactstrap/lib/Modal';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import Button from 'reactstrap/lib/Button';
import Input from 'reactstrap/lib/Input';
import Label from 'reactstrap/lib/Label';

import {
  getMultiAccountPairs,
  addMultiAccountPair,
  removeMultiAccountPair,
  addPoints,
} from '../../../actions/admin';
import { PopoverBody, UncontrolledPopover } from 'reactstrap';

class SuspiciousPlayers extends React.Component {
  static propTypes = {
    showNotification: PropTypes.func.isRequired,
    fetchData: PropTypes.func.isRequired,
    addPair: PropTypes.func.isRequired,
    removePair: PropTypes.func.isRequired,
  }

  static defaultProps = {
    // member: {},
    // suspiciousPlayers: [],
  }

  constructor(props) {
    super(props);
    this.state = {
      openAddAccountModal: false,
      openAddGroupModal: false,
      addAccountGroupIndex: null,
      addAccountId: '',
      addAccountIdOther: '',
    };
  }

  componentDidMount() {
    const { fetchData } = this.props;
    fetchData();
  }

  toggleAddAccountModal = (groupIndex) => {
    this.setState(prevState => ({
      addAccountId: '',
      openAddAccountModal: !prevState.openAddAccountModal,
      addAccountGroupIndex: groupIndex,
    }));
  }

  toggleAddGroupModal = () => {
    this.setState(prevState => ({
      addAccountId: '',
      addAccountIdOther: '',
      openAddGroupModal: !prevState.openAddGroupModal,
    }));
  }

  handleAddAccountIdChange = (event) => {
    const newValue = event.target.value;
    this.setState({ addAccountId: newValue });
  }

  handleAddAccountIdOtherChange = (event) => {
    const newValue = event.target.value;
    this.setState({ addAccountIdOther: newValue });
  }

  addAccount = async () => {
    const { showNotification, addPair } = this.props;
    const { addAccountGroupIndex, addAccountId } = this.state;
    // TODO: use those variables to add account into a multi account group

    if (addAccountId !== '') {
      await addPair(addAccountGroupIndex, addAccountId, false);

      this.toggleAddAccountModal();
      showNotification('Success', `Added ${addAccountId} to multi account group ${addAccountGroupIndex} successfully!`, 'success');
    } else {
      showNotification('Warning', 'Account ID must be entered!', 'warning');
    }
  }

  addGroup = async () => {
    const { showNotification, addPair } = this.props;
    const { addAccountId, addAccountIdOther } = this.state;
    // TODO: use those variables to add a new multi account group

    if (addAccountId !== '' && addAccountIdOther !== '') {
      await addPair(addAccountId, addAccountIdOther, true);

      this.toggleAddGroupModal();
      showNotification('Success', `Created a new multi account group successfully!`, 'success');
    } else {
      showNotification('Warning', 'Account IDs must be entered!', 'warning');
    }
  }

  deletePair = (groupIndex, playerIndex, length) => {
    const { showNotification, removePair } = this.props;

    if (groupIndex && playerIndex && length) {
      removePair(groupIndex, playerIndex, length).then((res) => {
        console.log('removePair res', removePair);

        showNotification('Success', `Removed ${playerIndex} from multi account group ${groupIndex} successfully!`, 'success');
      });
    }
  }

  getSortedMultiAccountKeys = (multiAccounts) => {
    return Object.keys(multiAccounts).sort((a, b) => {
      const dateA = multiAccounts[a][a].dateCreated;
      const dateB = multiAccounts[b][b].dateCreated;
      return dateA - dateB;
    });
  }

  render() {
    const { multiAccountPlayers2 } = this.props;

    const { openAddAccountModal, openAddGroupModal, addAccountId, addAccountIdOther } = this.state;

    console.log('multiAccountPlayers', multiAccountPlayers2);

    const multiAccountPlayers = [
      [{name: "Name Lastname1-1", id: "0HutQWpxQQSWMPbD4YJd9FLXmI72"},{name: "Name Lastname1-2", id: "0HutQWpxQQSWMPbD4YJd9FLXmI72"},{name: "Name Lastname1-3", id: "0HutQWpxQQSWMPbD4YJd9FLXmI72"},{name: "Name Lastname1-4", id: "0HutQWpxQQSWMPbD4YJd9FLXmI72"},{name: "Name Lastname1-5", id: "0HutQWpxQQSWMPbD4YJd9FLXmI72"},{name: "Name Lastname1-6", id: "0HutQWpxQQSWMPbD4YJd9FLXmI72"},{name: "Name Lastname1-7", id: "0HutQWpxQQSWMPbD4YJd9FLXmI72"},{name: "Name Lastname1-8", id: "0HutQWpxQQSWMPbD4YJd9FLXmI72"}],
      [{name: "Name Lastname2-1", id: "0HutQWpxQQSWMPbD4YJd9FLXmI72"},{name: "Name Lastname2-2", id: "0HutQWpxQQSWMPbD4YJd9FLXmI72"},{name: "Name Lastname2-3", id: "0HutQWpxQQSWMPbD4YJd9FLXmI72"},{name: "Name Lastname2-4", id: "0HutQWpxQQSWMPbD4YJd9FLXmI72"}],
      [{name: "Name Lastname3-1", id: "0HutQWpxQQSWMPbD4YJd9FLXmI72"},{name: "Name Lastname3-2", id: "0HutQWpxQQSWMPbD4YJd9FLXmI72"},{name: "Name Lastname3-3", id: "0HutQWpxQQSWMPbD4YJd9FLXmI72"},{name: "Name Lastname3-4", id: "0HutQWpxQQSWMPbD4YJd9FLXmI72"}],
      [{name: "Name Lastname4-1", id: "0HutQWpxQQSWMPbD4YJd9FLXmI72"},{name: "Name Lastname4-2", id: "0HutQWpxQQSWMPbD4YJd9FLXmI72"},{name: "Name Lastname4-3", id: "0HutQWpxQQSWMPbD4YJd9FLXmI72"},{name: "Name Lastname4-4", id: "0HutQWpxQQSWMPbD4YJd9FLXmI72"}],
    ];

    if (!multiAccountPlayers2) {
      return null;
    }
    return (
      <Fragment>
          <UncontrolledPopover popperClassName={`popover`} placement="right" trigger="focus" target="admin-help-button-multi-accounts">
            <PopoverBody className="popover-body">
              The admin can link possible multi-accounts so that they cannot play in the same rooms. This can be used as an alternative penalty for average cheaters.
            </PopoverBody>
          </UncontrolledPopover>
          <div className="admin-page-content">
            <h2>
              Multi Accounts
              <Button className="admin-help-button" id="admin-help-button-multi-accounts">
                ?
              </Button>
            </h2>
            <div className="my-3">
              <div className="multi-accounts-add-group" onClick={this.toggleAddGroupModal}>
                Add New Group
              </div>
            </div>
            {this.getSortedMultiAccountKeys(multiAccountPlayers2).map((groupIndex) => (
              <div key={`group-${groupIndex}`} className="my-2">
                <div className="multi-accounts-player-add" onClick={() => this.toggleAddAccountModal(groupIndex)}>
                  +
                </div>
                {multiAccountPlayers2[groupIndex] ? Object.keys(multiAccountPlayers2[groupIndex]).map((playerIndex) => (
                  <div key={`group-${groupIndex}-player-${playerIndex}`} className="multi-accounts-player">
                    <div className="multi-accounts-player-info">
                      <div className="multi-accounts-player-info-name">{multiAccountPlayers2[groupIndex][playerIndex].name}</div>
                      <div className="multi-accounts-player-info-id">{multiAccountPlayers2[groupIndex][playerIndex].uid}</div>
                    </div>
                    <div className="multi-accounts-player-delete" onClick={() => this.deletePair(groupIndex, playerIndex, Object.keys(multiAccountPlayers2[groupIndex]).length)}>
                      x
                    </div>
                  </div>

                )):(null)}
              </div>
            ))}
            {/* { multiAccountPlayers.map((item, groupIndex) => (
              <div key={`group-${groupIndex}`} className="my-2">
                <div className="multi-accounts-player-add" onClick={() => this.toggleAddAccountModal(groupIndex)}>
                  +
                </div>
                { item.map((player, playerIndex) => (
                  <div key={`group-${groupIndex}-player-${playerIndex}`} className="multi-accounts-player">
                    <div className="multi-accounts-player-info">
                      <div className="multi-accounts-player-info-name">{player.name}</div>
                      <div className="multi-accounts-player-info-id">{player.id}</div>
                    </div>
                    <div className="multi-accounts-player-delete" onClick={() => this.deletePair(groupIndex, playerIndex)}>
                      x
                    </div>
                  </div>

                ))}
              </div>
            ))} */}
          </div>

        <Modal container={`div > div`} isOpen={openAddAccountModal} toggle={this.toggleAddAccountModal} style={{ maxWidth: '65%' }}>
          <ModalHeader toggle={this.toggleAddAccountModal}>
            Add account
          </ModalHeader>
          <ModalBody>
            <Label for="addAccountId">Enter account ID:</Label>
            <Input
              type="text"
              name="addAccountId"
              id="addAccountId"
              value={addAccountId}
              onChange={this.handleAddAccountIdChange}
            />
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.addAccount}>Add</Button>
            <Button color="secondary" onClick={this.toggleAddAccountModal}>Cancel</Button>
          </ModalFooter>
        </Modal>

        <Modal container={`div > div`} isOpen={openAddGroupModal} toggle={this.toggleAddGroupModal} style={{ maxWidth: '65%' }}>
          <ModalHeader toggle={this.toggleAddGroupModal}>
            Add multi account group
          </ModalHeader>
          <ModalBody>
            <Label for="addAccountId">Enter account ID:</Label>
            <Input
              type="text"
              name="addAccountId"
              id="addAccountId"
              value={addAccountId}
              onChange={this.handleAddAccountIdChange}
            />
            <br />

            <Label for="addAccountIdOther">Enter other account ID:</Label>
            <Input
              type="text"
              name="addAccountIdOther"
              id="addAccountIdOther"
              value={addAccountIdOther}
              onChange={this.handleAddAccountIdOtherChange}
            />
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.addGroup}>Add</Button>
            <Button color="secondary" onClick={this.toggleAddGroupModal}>Cancel</Button>
          </ModalFooter>
        </Modal>

      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  multiAccountPlayers2: state.admin.multiAccountPlayers || {},
});

const mapDispatchToProps = {
  fetchData: getMultiAccountPairs,
  addPair: addMultiAccountPair,
  removePair: removeMultiAccountPair,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('admin')(SuspiciousPlayers));
