import isEqual from 'react-fast-compare';

import Store from '../store/game';

export const initialState = Store;

export default function gameReducer(state = initialState, action) {
  switch (action.type) {
    case 'IN_ROOM_TOKEN': {
      return {
        ...state,
        inRoomToken: action.data || null,
      };
    }

    case 'CARDS_REPLACE': {
      return {
        ...state,
        cards: action.data || [],
      };
    }

    case 'CARDS_REPLACE_STUDENT_ZOLE_VISIBLE': {
      console.log('CARDS_REPLACE_STUDENT_ZOLE_VISIBLE', action.data);
      return {
        ...state,
        visibleCards: action.data || [],
      };
    }

    case 'CARDS_REPLACE_STUDENT_ZOLE_OTHER_VISIBLE': {
      console.log('CARDS_REPLACE_STUDENT_ZOLE_OTHER_VISIBLE', action.data);
      return {
        ...state,
        otherVisibleCards: action.data || [],
      };
    }

    case 'CARDS_REPLACE_STUDENT_ZOLE_HIDDEN': {
      console.log('CARDS_REPLACE_STUDENT_ZOLE_HIDDEN', action.data);
      return {
        ...state,
        hiddenCards: action.data || [],
      };
    }

    case 'CARDS_REPLACE_STUDENT_ZOLE_OTHER_HIDDEN': {
      console.log('CARDS_REPLACE_STUDENT_ZOLE_OTHER_HIDDEN', action.data);
      return {
        ...state,
        otherHiddenCards: action.data || [],
      };
    }

    case 'AUTOMATED_REPLACE': {
      return {
        ...state,
        automated: action.data || false,
      };
    }

    case 'CARDS_UPDATE': {
      if (action.key || action.key === 0) {
        const newArr = state.cards.slice() || [];

        if (action.data) {
          newArr[action.key] = action.data;
        } else {
          newArr.splice(action.key, 1);
        }

        return {
          ...state,
          cards: newArr,
        };
      }
      return state;
    }

    case 'GAME_SETTINGS': {
      return {
        ...state,
        gameSettings: action.data,
      };
    }

    case 'CURRENT_TABLE_REPLACE': {
      console.log('CURRENT_TABLE_REPLACE', action.data);
      return {
        ...state,
        currentTable: action.data,
      };
    }

    case 'FIRST_TO_GO_REPLACE': {
      return {
        ...state,
        firstToGo: action.data,
      };
    }

    case 'CURRENT_TURN_REPLACE': {
      return {
        ...state,
        currentTurn: action.data,
      };
    }

    case 'SITTING_OUT_REPLACE': {
      // console.log('ROOM_INITIAL_DATA SITTING_OUT_REPLACE', action.data);
      return {
        ...state,
        sittingOut: action.data,
      };
    }

    case 'LARGE_PLAYER_REPLACE': {
      return {
        ...state,
        largePlayer: action.data,
      };
    }

    case 'CURRENT_TYPE_REPLACE': {
      return {
        ...state,
        currentType: action.data,
      };
    }

    case 'NEXT_TIMESTAMP_REPLACE': {
      return {
        ...state,
        nextTimeStamp: action.data,
      };
    }

    case 'UNLIMITED_TIMER_DATA': {
      return {
        ...state,
        unlimitedTimerData: action.data,
      };
    }

    case 'GLOBAL_PARAMS_REPLACE': {
      return {
        ...state,
        globalParams: action.data,
      };
    }

    case 'GLOBAL_PARAMS_CHANGE': {
      const { roomData, key } = action.data;

      if (
        state.globalParams
        && state.globalParams[key]
        && isEqual(state.globalParams[key], roomData)
      ) {
        return state;
      }

      if (state) {
        return {
          ...state,
          globalParams: {
            ...state.globalParams,
            [key]: roomData,
          },
        };
      }
      return {
        ...state,
        globalParams: {
          [key]: roomData,
        },
      };
    }

    case 'GLOBAL_PARAMS_ADDED': {
      const { roomData, key } = action.data;

      console.log('GLOBAL_PARAMS_ADDED', action.data);

      if (
        state
        && state.globalParams
        && state.globalParams[key]
        && state.globalParams[key] === roomData
      ) {
        return state;
      }

      if (state) {
        return {
          ...state,
          globalParams: {
            ...state.globalParams,
            [key]: roomData,
          },
        };
      }
      return {
        ...state,
        globalParams: {
          [key]: roomData,
        },
      };
    }

    case 'GLOBAL_PARAMS_REMOVED': {
      const { key } = action.data;

      if (state) {
        return {
          ...state,
          globalParams: {
            ...state.globalParams,
            [key]: null,
          },
        };
      }
      return {
        ...state,
        globalParams: {
          [key]: null,
        },
      };
    }

    case 'PLAYERS_REPLACE': {
      return {
        ...state,
        players: action.data,
      };
    }

    case 'PLAYER_VALUE_UPDATE': {
      return {
        ...state,
        players: {
          ...state.players,
          [action.player]: {
            ...state.players[action.player],
            [action.key]: action.data,
          },
        },
      };
    }

    case 'PLAYERS_CHILD_REPLACE': {
      if (action.data && action.key === 'playerList') {
        return {
          ...state,
          players: {
            ...state.players,
            [action.key]: {
              ...action.data,
            },
          },
        };
      }

      if (action.data) {
        return {
          ...state,
          players: {
            ...state.players,
            [action.key]: {
              ...state.players[action.key],
              emotion: action.data.emotion,
              ...action.data,
            },
          },
        };
      }
      return {
        ...state,
        players: {
          ...state.players,
          [action.key]: null,
        },
      };
    }

    case 'PLAYER_POSITION_REPLACE': {
      console.log('PLAYER_POSITION_REPLACE', action.data);
      return {
        ...state,
        myPos: action.data,
      };
    }

    case 'SET_PREVIOUS_ROUND_DATA': {
      return {
        ...state,
        previousRound: action.data,
      };
    }

    case 'ROOM_FALLBACK_DATA': {
      if (state.gameState && state.gameState === 'results') {
        return {
          ...state,
        };
      }

      console.log('testcurrentTable ROOM_FALLBACK_DATA', action.data);

      if ((action.data.nextTimestamp && action.data.nextTimestamp >= state.nextTimeStamp)
      || (action.data.gameState === 'results' && action.data.nextTimestampAdjusted && action.data.nextTimestamp !== state.nextTimeStamp && action.data.nextTimestampAdjusted > state.nextTimeStamp)
      ) {
        const { myPos, currentTable } = state;

        if (myPos && currentTable && (currentTable.length || currentTable.length === 0) && (currentTable.length !== 3 || (currentTable.length === 3
          && currentTable[currentTable.length - 1] && currentTable[currentTable.length - 1].player !== myPos))) {
          if (state.cards && state.cards.length > 3 && !action.data.cards) {
            return {
              ...state,
              currentType: action.data.currentType,
              currentTurn: action.data.currentTurn,
              currentTable: action.data.currentTable || [],
              largePlayer: action.data.largePlayer,
              nextTimeStamp: action.data.nextTimestamp,
              sittingOut: action.data.sittingOut,
              //  cards: action.data.cards || [],
              globalParams: {
                ...state.globalParams,
                talking: action.data.talking || null,
                gameState: action.data.gameState,
                roomClosed: action.data.roomClosed || false,
                closeReason: action.data.closeReason || null,
                gameResult: action.data.gameResult || null,
                party: action.data.party || state.globalParams.party,
              },
            };
          }
          return {
            ...state,
            currentType: action.data.currentType,
            currentTurn: action.data.currentTurn,
            currentTable: action.data.currentTable || [],
            largePlayer: action.data.largePlayer,
            nextTimeStamp: action.data.nextTimestamp,
            sittingOut: action.data.sittingOut,
            cards: action.data.cards || [],
            globalParams: {
              ...state.globalParams,
              talking: action.data.talking || null,
              gameState: action.data.gameState,
              roomClosed: action.data.roomClosed || false,
              closeReason: action.data.closeReason || null,
              gameResult: action.data.gameResult || null,
              party: action.data.party || state.globalParams.party,
            },
          };
        }

        if (state.cards && state.cards.length > 3 && !action.data.cards) {
          return {
            ...state,
            currentType: action.data.currentType,
            currentTurn: action.data.currentTurn,
            currentTable: action.data.currentTable || [],
            largePlayer: action.data.largePlayer,
            nextTimeStamp: action.data.nextTimestamp,
            sittingOut: action.data.sittingOut,
            //  cards: action.data.cards || [],
            globalParams: {
              ...state.globalParams,
              talking: action.data.talking || null,
              gameState: action.data.gameState,
              roomClosed: action.data.roomClosed || false,
              closeReason: action.data.closeReason || null,
              gameResult: action.data.gameResult || null,
              party: action.data.party || state.globalParams.party,
            },
          };
        }
        return {
          ...state,
          currentType: action.data.currentType,
          currentTurn: action.data.currentTurn,
          currentTable: action.data.currentTable || [],
          largePlayer: action.data.largePlayer,
          nextTimeStamp: action.data.nextTimestamp,
          sittingOut: action.data.sittingOut,
          cards: action.data.cards || [],
          globalParams: {
            ...state.globalParams,
            talking: action.data.talking || null,
            gameState: action.data.gameState,
            roomClosed: action.data.roomClosed || false,
            closeReason: action.data.closeReason || null,
            gameResult: action.data.gameResult || null,
            party: action.data.party || state.globalParams.party,
          },
        };
      }


      return {
        ...state,
      };
    }

    case 'ROOM_FALLBACK_DATA_TABLE': {
      console.log('testcurrentTable ROOM_FALLBACK_DATA_TABLE', action.data);

      if (action.data.gameState === 'play' && (action.data.lastTable && action.data.lastTable[2] && state.myPos && state.myPos !== action.data.lastTable[2].player) && action.data.nextTimestamp && action.data.nextTimestamp > state.nextTimeStamp) {
      //  console.log('update room fallback table', { data: action.data, state });

        return {
          ...state,
        };
      }
      return {
        ...state,
      };
    }

    case 'ROOM_INITIAL_DATA': {
      let myPos = null;

      // console.log('ROOM_INITIAL_DATA', action.data);

      if (action.data.nextTimestamp && (!state.nextTimeStamp || (action.data.nextTimestamp > state.nextTimeStamp))) {
        if (action.uid && action.data && action.data.players && action.data.players.playerList) {
          myPos = action.data.players.playerList[action.uid];
        }

        return {
          ...state,
          sittingOut: action.data.sittingOut || null,
          currentType: action.data.currentType,
          currentTurn: action.data.currentTurn,
          currentTable: action.data.currentTable || [],
          largePlayer: action.data.largePlayer,
          nextTimeStamp: action.data.nextTimestamp,
          players: action.data.players,
          globalParams: action.data.globalParams,
          cards: action.data.cards || [],
          myPos: myPos || null,
        };
      }
      return {
        ...state,
      };
    }

    case 'SET_LAST_TOKEN': {
      return {
        ...state,
        lastToken: action.data,
      };
    }

    case 'SET_ROOM_ID': {
      return {
        ...state,
        roomId: action.data,
      };
    }

    case 'SET_DEBUG_PARAM': {
      if (action.paramID === 'talking') {
        return {
          ...state,
          globalParams: {
            ...state.globalParams,
            [action.paramID]: action.value,
          },
        };
      }
      return {
        ...state,
        [action.paramID]: action.value,
      };
    }

    case 'USER_ACTION_RESPONSE': {
      console.log('testcurrentTable USER_ACTION_RESPONSE', action.data);
      if ((action.data.nextTimestamp && action.data.nextTimestamp > state.nextTimeStamp)
      || (action.data.gameState === 'results' && action.data.nextTimestampAdjusted && action.data.nextTimestampAdjusted > state.nextTimeStamp)) {
        return {
          ...state,
          currentType: action.data.currentType || state.currentType,
          currentTurn: action.data.currentTurn || state.currentTurn,
          currentTable: action.data.currentTable || state.currentTable,
          largePlayer: action.data.largePlayer || state.largePlayer,
          nextTimeStamp: action.data.nextTimestamp || state.nextTimeStamp,
          cards: action.data.cards || state.cards,
          globalParams: {
            ...state.globalParams,
            talking: action.data.talking || state.globalParams.talking,
            gameState: action.data.gameState || state.globalParams.gameState,
            roomClosed: action.data.roomClosed || state.globalParams.roomClosed || null,
            closeReason: action.data.closeReason || state.globalParams.closeReason || null,
            gameResult: action.data.gameResult || state.globalParams.gameResult || null,
          },
        };
      }
      //  console.log('dont update user action');
      return {
        ...state,
      };
    }

    case 'USER_ACTION_RESPONSE_TABLE': {
      console.log('testcurrentTable USER_ACTION_RESPONSE_TABLE', action.data);

      if (state.currentTable && state.currentTable.length && state.currentTable.length === 3) {
        return {
          ...state,
          currentTable: action.data.currentTable || state.currentTable,
        };
      }
      return {
        ...state,
      };
    }

    case 'USER_ACTION_RESPONSE_CARDS': {
      if (state.cards && state.cards.length && state.cards.length === 10) {
        return {
          ...state,
          cards: action.data.cards || state.cards,
        };
      }
      return {
        ...state,
      };
    }

    case 'USER_ACTION_REMOVE_CARD': {
      const { cards } = state;

      if (action.data && cards && cards.length && cards.includes(action.data)) {
        const newCards = [...cards];

        const index = newCards.indexOf(action.data);
        newCards.splice(index, 1);

        return {
          ...state,
          cards: newCards,
        };
      }
      return {
        ...state,
      };
    }

    case 'USER_ACTION_RESET_TABLE': {
      if (action.data) {
        return {
          ...state,
          currentTable: action.data || [],
        };
      }
      return {
        ...state,
      };
    }

    case 'RESET_STORE': {
      const { roomId } = action;
      return {
        ...initialState,
        lastToken: state.lastToken,
        lastRoom: roomId || null,
      };
    }

    case 'CARDS_ON_TABLE': {
      return {
        ...state,
        cardsOnTable: action.data || [],
      };
    }

    default:
      return state;
  }
}
