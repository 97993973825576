import React from 'react';
import Media from 'reactstrap/lib/Media';

import computerImg from '../../../../../../images/icons/computer.png';
import phoneImg from '../../../../../../images/icons/tablet.svg';
import androidImg from '../../../../../../images/icons/android.svg';
import iosImg from '../../../../../../images/icons/apple.svg';
import draugiemImg from '../../../../../../images/icons/draugiem.svg';
import facebookImg from '../../../../../../images/icons/facebook.svg';
import inboxImg from '../../../../../../images/icons/inbox.svg';
import mobileWeb from '../../../../../../images/icons/mobile-browser-development.svg';

const MessageAppInfo = ({ message }) => {

  const renderPlatformIcon = (platform) => {
    const platformInfo = platform.toLowerCase();
    switch (platformInfo) {
      case 'draugiem':
        return <Media src={draugiemImg} className="allUsers-table-col-img" />
      case 'facebook':
        return <Media src={facebookImg} className="allUsers-table-col-img" />
      case 'inbox':
        return <Media src={inboxImg} className="allUsers-table-col-img" />
      case 'android':
        return 'ANDROID'
      case 'ios':
        return 'IOS'
      case 'mweb':
        return 'WEB';
      case 'web':
        return 'WEB';
      default:
        return 'WEB';
    }
  }
  console.log(message, "contacts us2 admin")

  return (
  <div className="d-flex flex-column">
    {message && (
      <>
        {message?.osVersion && (
          <span>{`OS: ${message?.env} ${message?.osVersion}`}</span>
        )}
        {message?.appVersion && (
          <span>{`App: ${message?.appVersion}`}</span>
        )}
      </>
    )}
    {message && message?.env && (message?.env.toLowerCase() !== 'ios' || message?.env.toLowerCase() !== 'android') && (
      <>
        <span className='d-flex flex-row justify-start '>
          <span>Platform:</span>
          {renderPlatformIcon(message?.env ? message.env.toLowerCase() : '')}
        </span>
        <span>{`Design: ${message?.design ? message?.design.toUpperCase() : ''}`}</span>
      </>
    )}
  </div>
);
}

export default MessageAppInfo;
