import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import isEqual from 'react-fast-compare';

import myTurnSound from '../../../../../sounds/my_turn4.mp3';
import dealingCardsSound from '../../../../../sounds/dealing_card.mp3';

import PlayerCard from './PlayerCard';

const erci = ['♥-9', '♥-K', '♥-10', '♥-A'];
const kreisti = ['♣︎-9', '♣︎-K', '♣︎-10', '♣︎-A'];
const piki = ['♠︎-9', '♠︎-K', '♠︎-10', '♠︎-A'];
const trumpji = ['♦︎-7', '♦︎-8', '♦︎-9', '♦︎-K', '♦︎-10', '♦︎-A', '♦︎-J', '♥-J', '♠︎-J', '♣︎-J', '♦︎-Q', '♥-Q', '♠︎-Q', '♣︎-Q'];

const cardOrder = ['♥-9', '♥-K', '♥-10', '♥-A', '♠︎-9', '♠︎-K', '♠︎-10', '♠︎-A', '♣︎-9', '♣︎-K', '♣︎-10', '♣︎-A', '♦︎-7', '♦︎-8', '♦︎-9', '♦︎-K', '♦︎-10', '♦︎-A', '♦︎-J', '♥-J', '♠︎-J', '♣︎-J', '♦︎-Q', '♥-Q', '♠︎-Q', '♣︎-Q'];

class PlayerCards extends React.Component {
  static propTypes = {
    cards: PropTypes.arrayOf(PropTypes.string),
    gameState: PropTypes.string,
    playCard: PropTypes.func.isRequired,
    currentTurnUid: PropTypes.string,
    memberUid: PropTypes.string,
    currentTable: PropTypes.arrayOf(PropTypes.shape()),
    selectedCard: PropTypes.string,
    doubleClickSelectedCard: PropTypes.shape(),
    currentTurn: PropTypes.string,
    soundOn: PropTypes.bool,
    myPos: PropTypes.string,
    tableIsInProgress: PropTypes.bool.isRequired,
    cardsOnTable: PropTypes.arrayOf(PropTypes.string),
    currentType: PropTypes.string,
    largePlayer: PropTypes.string,
    cardsOnLoading: PropTypes.bool,
  }

  static defaultProps = {
    cards: [],
    gameState: null,
    currentTurnUid: null,
    memberUid: null,
    currentTable: null,
    selectedCard: null,
    doubleClickSelectedCard: null,
    currentTurn: null,
    soundOn: false,
    myPos: null,
    cardsOnTable: [],
    currentType: null,
    largePlayer: null,
    cardsOnLoading: true,
  }

  constructor(props) {
    super(props);
    this.state = {
      cards: [],
      playSound: false,
      hasPlayedCard: false,
    };

    this.myTurnAudio = new Audio(myTurnSound);
    this.dealingCardsAudio = new Audio(dealingCardsSound);

    window.addEventListener('resize', this.handleResize);
  }

  componentDidMount() {
    const {
      cards,
      currentTable,
      memberUid,
      currentTurnUid,
    } = this.props;

    this.handleResize();

    const cardsClone = [...cards];

    cardsClone.sort((a, b) => cardOrder.indexOf(b) - cardOrder.indexOf(a));

    if (currentTurnUid && memberUid
        && currentTurnUid.toString() === memberUid.toString()) {
      if (currentTable && currentTable.length === 0) {
        const mappedCards = cardsClone.map(card => ({ card, allowed: true }));

        this.setState({ cards: [...mappedCards] });
      } else {
        const firstCard = currentTable[0];

        let playerHasCards = [];

        if (firstCard.card && kreisti.indexOf(firstCard.card) !== -1) {
          playerHasCards = cardsClone.filter(value => kreisti.includes(value));
        } else if (firstCard.card && erci.indexOf(firstCard.card) !== -1) {
          playerHasCards = cardsClone.filter(value => erci.includes(value));
        } else if (firstCard.card && piki.indexOf(firstCard.card) !== -1) {
          playerHasCards = cardsClone.filter(value => piki.includes(value));
        } else if (firstCard.card && trumpji.indexOf(firstCard.card) !== -1) {
          playerHasCards = cardsClone.filter(value => trumpji.includes(value));
        }

        const mappedCards = cardsClone.map((card) => {
          if (playerHasCards && playerHasCards.length === 0) {
            return { card, allowed: true };
          }
          if (playerHasCards && playerHasCards.length > 0 && playerHasCards.includes(card)) {
            return { card, allowed: true };
          }
          return { card, allowed: false };
        });

        this.setState({ cards: [...mappedCards] });
      }
    } else {
      const mappedCards = cardsClone.map(card => ({ card, allowed: false }));

      this.setState({ cards: [...mappedCards] });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const {
      tableIsInProgress,
      selectedCard,
      gameState,
      currentTurnUid,
      memberUid,
      currentTable,
      cards,
      cardsOnTable,
    } = this.props;

    const {
      hoveredCard,
      cards: stateCards,
      width,
      height,
    } = this.state;

    if (nextProps.tableIsInProgress !== tableIsInProgress) {
      return true;
    }

    if (nextProps.selectedCard !== selectedCard) {
      return true;
    }

    if (nextProps.gameState !== gameState) {
      return true;
    }

    if (nextProps.currentTurnUid !== currentTurnUid) {
      return true;
    }

    if (nextProps.memberUid !== memberUid) {
      return true;
    }

    if (!isEqual(nextProps.currentTable, currentTable)) {
      return true;
    }

    if (!isEqual(nextState.cards, stateCards)) {
      return true;
    }

    if (!isEqual(nextState.hoveredCard, hoveredCard)) {
      return true;
    }

    if (!isEqual(nextProps.cards, cards)) {
      return true;
    }

    if (nextState.width !== width) {
      return true;
    }

    if (nextState.height !== height) {
      return true;
    }
    if (!isEqual(nextProps.cardsOnTable, cardsOnTable)) {
      return true;
    }

    return false;
  }

  componentDidUpdate(prevProps) {
    const {
      cards,
      currentTurnUid,
      memberUid,
      currentTable,
      currentTurn,
      soundOn,
      tableIsInProgress,
      gameState,
      myPos,
    //  isFullscreen,
    } = this.props;

    const {
      playSound, hasPlayedCard, cards: prevCards,
    } = this.state;

    const turnChanged = prevProps.currentTurn !== currentTurn;

    const cardsClone = [...cards];

    cardsClone.sort((a, b) => cardOrder.indexOf(b) - cardOrder.indexOf(a));

    if (soundOn && playSound && !tableIsInProgress) {
      this.setState({ playSound: false });

      setTimeout(() => {
        const playPromise = this.myTurnAudio.play();
        if (playPromise !== undefined) {
          playPromise
            .then(_ => {
              // Automatic playback started!
              // Show playing UI.
              console.log("audio played auto");
            })
            .catch(error => {
              // Auto-play was prevented
              // Show paused UI.
              console.log("playback prevented");
            });
        }
      }, 350);
    }

    if (soundOn && ((cards && cards.length === 8 && gameState === 'results' && prevProps.gameState !== gameState) || (cards && cards.length === 8 && cards.length !== prevProps.cards.length && gameState === 'results'))) {
      try {
        const playPromise = this.dealingCardsAudio.play();
        if (playPromise !== undefined) {
          playPromise
            .then(_ => {
              // Automatic playback started!
              // Show playing UI.
              console.log("audio played auto");
            })
            .catch(error => {
              // Auto-play was prevented
              // Show paused UI.
              console.log("playback prevented");
            });
        }
      } catch (err) {
        console.log(err);
      }
    }
    let dataToUpdate = null;

    if (currentTurnUid && memberUid
      && currentTurnUid.toString() === memberUid.toString()) {
      if (currentTable && currentTable.length === 0) {
        const mappedCards = cardsClone.map(card => ({ card, allowed: true }));

        if (turnChanged && gameState === 'play') {
          dataToUpdate = { cards: [...mappedCards], playSound: true, hasPlayedCard: false };
        } else {
          dataToUpdate = { cards: [...mappedCards], hasPlayedCard: false };
        }
      } else if (currentTable) {
        const firstCard = currentTable[0];

        const lastCard = currentTable[currentTable.length - 1];

        if (lastCard && lastCard.player && lastCard.player === myPos) {
          const mappedCards = cardsClone.map(card => ({ card, allowed: false }));

          dataToUpdate = { cards: [...mappedCards], hasPlayedCard: true };
        } else if (firstCard) {
          let playerHasCards = [];

          if (firstCard && firstCard.card) {
            if (kreisti.indexOf(firstCard.card) !== -1) {
              playerHasCards = cardsClone.filter(value => kreisti.includes(value));
            } else if (erci.indexOf(firstCard.card) !== -1) {
              playerHasCards = cardsClone.filter(value => erci.includes(value));
            } else if (piki.indexOf(firstCard.card) !== -1) {
              playerHasCards = cardsClone.filter(value => piki.includes(value));
            } else if (firstCard.card && trumpji.indexOf(firstCard.card) !== -1) {
              playerHasCards = cardsClone.filter(value => trumpji.includes(value));
            }
          }

          const mappedCards = cardsClone.map((card) => {
            if (playerHasCards && playerHasCards.length === 0) {
              return { card, allowed: true };
            }
            if (playerHasCards && playerHasCards.length > 0 && playerHasCards.includes(card)) {
              return { card, allowed: true };
            }
            return { card, allowed: false };
          });

          if (turnChanged && gameState === 'play') {
            dataToUpdate = { cards: [...mappedCards], playSound: true, hasPlayedCard: false };
          } else {
            dataToUpdate = { cards: [...mappedCards], hasPlayedCard: false };
          }
        } else {
          const mappedCards = cardsClone.map(card => ({ card, allowed: true }));

          if (turnChanged && gameState === 'play') {
            dataToUpdate = { cards: [...mappedCards], playSound: true, hasPlayedCard: false };
          } else {
            dataToUpdate = { cards: [...mappedCards], hasPlayedCard: false };
          }
        }
      }
    } else {
      const mappedCards = cardsClone.map(card => ({ card, allowed: false }));

      dataToUpdate = { cards: [...mappedCards], hasPlayedCard: false };
    }

    if (dataToUpdate) {
    //  console.log('dataToUpdate', {
    //    dataToUpdate, cardsClone, currentTurnUid, currentTable: currentTable.length,
    //  });
      if (isEqual(dataToUpdate.cards, prevCards)) {
        delete dataToUpdate.cards;

        if (hasPlayedCard === dataToUpdate.hasPlayedCard) {
          delete dataToUpdate.hasPlayedCard;
        }

        this.setState(dataToUpdate);
      } else if (dataToUpdate.cards && prevCards && (dataToUpdate.cards.length || dataToUpdate.cards.length === 0) && prevCards.length && dataToUpdate.cards.length === prevCards.length - 1) {
      //  console.log('cards updated with one removed');
        setTimeout(() => {
          this.setState(dataToUpdate);
        }, 10);
      } else {
        this.setState(dataToUpdate);
      }
    }
  }

  handleResize = () => {
    const { width, height } = this.state;

    const { innerWidth: newWidth, innerHeight: newHeight } = window;

    if (!width || width - 10 > newWidth || width + 10 < newWidth) {
      this.setState({ width: newWidth, height: newHeight });
    } else if (!height || height - 10 > newHeight || height + 10 < newHeight) {
      this.setState({ width: newWidth, height: newHeight });
    }
  }

  changeHoveredCard = (index, card, type, allowed) => {
    const { gameState } = this.props;

    //  console.log('changeHoveredCard', { card, index });

    if (allowed && type === 'over' && gameState !== 'choose') {
      this.setState({ hoveredCard: index });
    } else {
      this.setState({ hoveredCard: null });
    }
  }

  renderCards = () => {
    const {
      gameState,
      playCard,
      selectedCard,
      tableIsInProgress,
      memberUid,
      currentTurnUid,
      doubleClickSelectedCard,
      cardsOnTable,
      currentType,
      largePlayer,
      myPos,
      cardsOnLoading,
    } = this.props;

    const {
      cards,
      hasPlayedCard,
      hoveredCard,
    } = this.state;

   // console.log('renderCards',{ gameState, currentType, cardsOnLoading, tableIsInProgress, playCard, cards });

    const items = [];

    if (cards) {
      cards.map((card, index) => {
        items.push(
          <PlayerCard
            card={card.card}
            allowed={card.allowed}
            index={index}
            cardsLength={cards.length}
            gameState={gameState}
            playCard={playCard}
            selectedCard={selectedCard}
            tableIsInProgress={tableIsInProgress}
            memberUid={memberUid}
            currentTurnUid={currentTurnUid}
            doubleClickSelectedCard={doubleClickSelectedCard ? doubleClickSelectedCard.card : null}
            hasPlayedCard={hasPlayedCard}
            hoveredCard={hoveredCard}
            changeHoveredCard={this.changeHoveredCard}
            cardsOnTable={cardsOnTable}
            currentType={currentType}
            largePlayer={largePlayer}
            myPos={myPos}
            cardsOnLoading={cardsOnLoading}
          />
        )
        return null;
      });
    }

    return items;
  }

  render() {
    const {
      gameState,
      currentTable,
      cardsRemAnimInProgress,
    /*  playCard,
      selectedCard,
      tableIsInProgress,
      memberUid,
      currentTurnUid,
      doubleClickSelectedCard, */
    } = this.props;

    const {
    //  cards,
    //  hasPlayedCard,
    //  hoveredCard,
    } = this.state;

    // console.log('render PlayerCards');

    // cards-${(cards && cards.length % 2 === 0) ? 'even' : 'odd'}
    // ${(cards && cards.length !== 8 && gameState === 'results') ? 'display-none' : ''}

    return (
      <div
        className={`cards cards2 ${(gameState && gameState === 'choose') ? 'cards2-choose' : ''} ${(cardsRemAnimInProgress && gameState === 'results') ? 'display-none' : ''}`}
      >
        {this.renderCards()}
        {/*  {cards && cards.map((card, index) => (
          <PlayerCard
            card={card.card}
            allowed={card.allowed}
            index={index}
            cardsLength={cards.length}
            gameState={gameState}
            playCard={playCard}
            selectedCard={selectedCard}
            tableIsInProgress={tableIsInProgress}
            memberUid={memberUid}
            currentTurnUid={currentTurnUid}
            doubleClickSelectedCard={doubleClickSelectedCard ? doubleClickSelectedCard.card : null}
            hasPlayedCard={hasPlayedCard}
            hoveredCard={hoveredCard}
            changeHoveredCard={this.changeHoveredCard}
          />
        ))} */}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  memberUid: state.member.uid,
  //  cards:state.game.cards,
  currentTurn: state.game.currentTurn,
  currentTurnUid: (state.game.currentTurn && state.game.players && state.game.players[state.game.currentTurn]) ? state.game.players[state.game.currentTurn].uid : '',
  currentTable: state.game.currentTable,
  myPos: state.game.myPos,
  largePlayer: state.game.largePlayer,
  gameState: state.game.globalParams.gameState,
  cardsOnTable: state.game.cardsOnTable,
  currentType: state.game.currentType,
});

// const mapDispatchToProps = {

// };

export default connect(mapStateToProps, null)(PlayerCards);
