import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { Button, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Pagination, PaginationItem, PaginationLink, PopoverBody } from 'reactstrap';

import * as constants from '../../../constants/constants';
import en from '../../../constants/translations/en.json';
import lv from '../../../constants/translations/lv.json';
import ru from '../../../constants/translations/ru.json';

import {
  getTranslationsFiles,
  updateTranslationsFile,
} from '../../../actions/admin';
import { capitalizeFirstLetter } from '../../../utils/stringUtils';
import { get, set } from 'lodash';
import { UncontrolledPopover } from 'reactstrap/lib';

let languageMap = {
  'english': en,
  'latvian': lv,
  'russian': ru,
};

const languages = Object.keys(languageMap);

const searchByOptions = ["Text Key", "Translation"];

class TextKeys extends React.Component {
  static propTypes = {
    // bots: PropTypes.shape({}),
    showNotification: PropTypes.func.isRequired,
  }

  static defaultProps = {
    // bots: {},
  }

  constructor(props) {
    super(props);
    this.state = {
      selectedLanguage: languages[0],
      selectedSearchByOption: searchByOptions[0],
      searchForValue: '',
      translationEntries: [],
      toggleEditMode: false,
      textKey: '',
      translation: '',
      translationInitial: '',
      currentPage: 1,
      entriesPerPage: 50,
      devMode: true,
    };
  }


  componentDidMount() {
    const { fetchTranslationsFiles } = this.props;
    const { selectedLanguage, devMode } = this.state;

    /*
    fetchTranslationsFiles().then((res) => {
      if (res) {
        console.log('fetchTranslationsFiles', res, languageMap);
        languageMap = { ...res };

        this.setState({
          translationEntries: this.flattenTranslations(languageMap[selectedLanguage]),
        });
      }
    }); */

    this.getTranslationsFiles();

    // fetchBots();
  //  this.setState({
  //    translationEntries: this.flattenTranslations(languageMap[selectedLanguage]),
  //  });
  }

  handleLanguageChange = (event) => {
    const newSelectedLanguage = event.target.value;
    this.setState({
      selectedLanguage: newSelectedLanguage,
      translationEntries: this.flattenTranslations(languageMap[newSelectedLanguage]),
      currentPage: 1,
    });
  }

  handleDevModeChange = (event) => {
    const newSelectedMode = event.target.value;

    if (newSelectedMode === 'dev') {
      this.setState({ devMode: true });

      this.getTranslationsFiles(true);
    } else if (newSelectedMode === 'prod') {
      this.setState({ devMode: false });

      this.getTranslationsFiles(false);
    }
  }

  getTranslationsFiles = (devModeOverride) => {
    const { fetchTranslationsFiles } = this.props;
    const { selectedLanguage, devMode } = this.state;

    console.log('getTranslationsFiles devMode', devMode, devModeOverride);
    let devModeToUse = devMode;
    if (devModeOverride || devModeOverride === false) {
      devModeToUse = devModeOverride;
    }

    console.log('devModeToUse', devModeToUse);

    fetchTranslationsFiles(devModeToUse).then((res) => {
      if (res) {
        console.log('fetchTranslationsFiles', res, languageMap);
        languageMap = { ...res };

        this.setState({
          translationEntries: this.flattenTranslations(languageMap[selectedLanguage]),
          editModeOpen: false,
          textKey: '',
          translation: '',
          currentPage: 1,
        });
      }
    });
  }

  handleSearchByOptionChange = (event) => {
    this.setState({
      selectedSearchByOption: event.target.value,
      currentPage: 1,
    });
  }

  handleSearchForValueChange = (event) => {
    this.setState({
      searchForValue: event.target.value,
      currentPage: 1,
    });
  }

  handleTextKeyChange = (event) => {
    this.setState({
      textKey: event.target.value,
    });
  }

  handleTranslationChangeLv = (event) => {
    this.setState({
      translationLv: event.target.value,
    });
  }
  handleTranslationChangeEn = (event) => {
    this.setState({
      translationEn: event.target.value,
    });
  }
  handleTranslationChangeRu = (event) => {
    this.setState({
      translationRu: event.target.value,
    });
  }

  flattenTranslations = (obj, prefix = '') => {
    let translations = [];

    for (let key in obj) {
      if (typeof obj[key] === 'object') {
        translations = translations.concat(this.flattenTranslations(obj[key], `${prefix}${key}/`));
      } else {
        translations.push({
          key: `${prefix}${key}`,
          value: obj[key]
        });
      }
    }

    return translations.sort((a, b) => a.key.localeCompare(b.key));
  };

  displayTextKey = (key) => {
    const parts = key.split('/');
    const lastPart = parts.pop();
  
    return (
      <>
        <span style={{color: 'rgb(194, 194, 194)'}}>
          {parts.join(' / ')}
          {' / '}
        </span>
        <span>{lastPart}</span>
      </>
    );
  }

  toggleEditMode = (translationEntry) => {
    const { editModeOpen } = this.state;
    const textKey = translationEntry ? translationEntry.key : '';
    const textKeyPath = textKey ? textKey.replace(/\//g, '.') : '';
    // const translation = translationEntry ? translationEntry.value : '';

    const translationLv = translationEntry ? get(languageMap.latvian, textKeyPath) : '';
    const translationEn = translationEntry ? get(languageMap.english, textKeyPath) : '';
    const translationRu = translationEntry ? get(languageMap.russian, textKeyPath) : '';

    this.setState({
      editModeOpen: !editModeOpen,
      textKey: textKey,
      // translationInitial: translation,
      // translation: translation,

      translationLvInitial: translationLv,
      translationLv: translationLv,
      translationEnInitial: translationEn,
      translationEn: translationEn,
      translationRuInitial: translationRu,
      translationRu: translationRu,
    });
  }

  saveEntry = () => {
    // TODO: save changes in JSON file and also in loaded translations so translation files dont need to be reloaded
    const { updateTranslations, showNotification } = this.props;
    const { devMode, textKey, translationLv, translationLvInitial, translationEn, translationEnInitial, translationRu, translationRuInitial, selectedLanguage } = this.state;

    const updateLvTranslation = translationLv !== translationLvInitial;
    const updateEnTranslation = translationEn !== translationEnInitial;
    const updateRuTranslation = translationRu !== translationRuInitial;

    // let lang;
    // if (selectedLanguage === 'latvian') {
    //   lang = 'lv';
    // } else if (selectedLanguage === 'russian') {
    //   lang = 'ru';
    // } else if (selectedLanguage === 'english') {
    //   lang = 'en';
    // }

    // const path = textKey.split('/');
    const lodashPath = textKey.replace(/\//g, '.');

    let promises = [];

    let newLanguageMapLv, newLanguageMapEn, newLanguageMapRu;
    if (updateLvTranslation) {
      const newLanguageMapLv = { ...languageMap.latvian };
      set(newLanguageMapLv, lodashPath, translationLv);

      const changes = {
        key: `[${textKey}]`,
        before: `"${translationLvInitial}"`,
        after: `"${translationLv}"`,
      }
      promises.push(updateTranslations(newLanguageMapLv, 'lv', changes, devMode));
    }
    if (updateEnTranslation) {
      const newLanguageMapEn = { ...languageMap.english };
      set(newLanguageMapEn, lodashPath, translationEn);

      const changes = {
        key: `[${textKey}]`,
        before: `"${translationEnInitial}"`,
        after: `"${translationEn}"`,
      }
      promises.push(updateTranslations(newLanguageMapEn, 'en', changes, devMode));
    }
    if (updateRuTranslation) {
      const newLanguageMapRu = { ...languageMap.russian };
      set(newLanguageMapRu, lodashPath, translationRu);

      const changes = {
        key: `[${textKey}]`,
        before: `"${translationRuInitial}"`,
        after: `"${translationRu}"`,
      }
      promises.push(updateTranslations(newLanguageMapRu, 'ru', changes, devMode));
    }

    Promise.all(promises)
      .then((responses) => {
        const allSuccess = responses.every((res) => res && res.status && res.status === 'success');
        if (allSuccess) {
          showNotification('Success', 'Translations updated successfully!', 'success');
          this.getTranslationsFiles();
        } else {
          showNotification('Error!', 'Failed to update translations!', 'danger');
        }
      })
      .catch((error) => {
        showNotification('Error!', 'An error occurred while updating translations!', 'danger');
      });
    //*************************** */

    // const newLanguageMap = { ...languageMap[selectedLanguage] };

    // console.log('newLanguageMap', newLanguageMap);

    // if (path.length === 1) {
    //   newLanguageMap[path[0]] = translation;
    // } else if (path.length === 2) {
    //   newLanguageMap[path[0]][path[1]] = translation;
    // } else if (path.length === 3) {
    //   newLanguageMap[path[0]][path[1]][path[2]] = translation;
    // } else if (path.length === 4) {
    //   newLanguageMap[path[0]][path[1]][path[2]][path[3]] = translation;
    // } else if (path.length === 5) {
    //   newLanguageMap[path[0]][path[1]][path[2]][path[3]][path[4]] = translation;
    // }

    // console.log('languageMap new', newLanguageMap);


    // const changes = {
    //   key: `[${textKey}]`,
    //   before: `"${translationInitial}"`,
    //   after: `"${translation}"`,
    // }
    // updateTranslations(newLanguageMap, lang, changes).then((res) => {
    //   console.log('updateTranslations res', res);

    //   if (res && res.status && res.status === 'success') {
    //     showNotification('Success', 'Translation updated successfully!', 'success');
    //     this.getTranslationsFiles();
    //   } else {
    //     showNotification('Error!', 'Failed to update translation!', 'danger');
    //   }
    // });
  }

  deleteEntry = () => {
    // TODO: delete entry from JSON and also from loaded translations so translation files dont need to be reloaded
  }
  
  render() {
    const {
      // t,
      // fetchUserGamesHistory,
    } = this.props;

    const {
      devMode, translationEntries, selectedLanguage, selectedSearchByOption, searchForValue, editModeOpen, textKey, translationLv, translationEn, translationRu, currentPage, entriesPerPage,
    } = this.state;

    const startIndex = (currentPage - 1) * entriesPerPage;
    const endIndex = startIndex + entriesPerPage;

    const filteredTranslationEntries = translationEntries.filter(entry =>
      (selectedSearchByOption === 'Text Key' && entry.key.toLowerCase().includes(searchForValue.toLowerCase()))
      || (selectedSearchByOption === 'Translation' && entry.value.toLowerCase().includes(searchForValue.toLowerCase()))
    );
    const currentPageEntries = filteredTranslationEntries.slice(startIndex, endIndex);
    const totalPages = Math.ceil(filteredTranslationEntries.length / entriesPerPage);

    return (
      <Fragment>
        <UncontrolledPopover popperClassName={`popover`} placement="right" trigger="focus" target="admin-help-button-text-keys">
          <PopoverBody className="popover-body">
            An admin can search for the text keys or translations that are used in the Web version. The mobile app strings currently are not available here, also the admin panel strings are not translated into any other language apart from English. 
            <br /><br />
            Note: the changes are saved on dev, and are being deployed on the prod as other normal code changes. 
          </PopoverBody>
        </UncontrolledPopover>
        <div className="admin-page-content">
          <h2>
            Text Keys
            <Button className="admin-help-button" id="admin-help-button-text-keys">
              ?
            </Button>
          </h2>

          <Label for="languageSelect">Select Dev mode</Label>
          <Input type="select" id="languageSelect" value={devMode ? 'dev' : 'prod'} onChange={this.handleDevModeChange} style={{ width: "10%", marginBottom: "5px" }}>
            <option key="dev" value="dev">Development mode</option>
            <option key="prod" value="prod">Production mode</option>
          </Input>

          <Label for="languageSelect">Select Language</Label>
          <Input type="select" id="languageSelect" value={selectedLanguage} onChange={this.handleLanguageChange} style={{width: "10%", marginBottom: "5px"}}>
            {languages.map((language) => (
              <option key={language} value={language}>{capitalizeFirstLetter(language)}</option>
            ))}
          </Input>

          <div style={{display: "inline-block", width: "10%"}}>
            <Label for="searchBy">Search By</Label>
            <Input type="select" id="searchBy" value={selectedSearchByOption} onChange={this.handleSearchByOptionChange}>
              {searchByOptions.map((searchByOption) => (
                <option key={searchByOption} value={searchByOption}>{searchByOption}</option>
              ))}
            </Input>
          </div>
          <div style={{display: "inline-block", width: "88%", marginLeft: "2%"}}>
            <Label for="searchFor">Search For</Label>
            <Input type="text" id="searchFor" value={searchForValue} onChange={this.handleSearchForValueChange} />
          </div>

          <table style={{marginTop: "10px"}}>
            <thead>
              <tr>
                <th style={{minWidth: "22%"}}>
                  Text Key
                </th>
                <th>Translation</th>
              </tr>
            </thead>
            <tbody>
              {currentPageEntries.map((entry, index) => (
                <tr key={entry.key} onClick={() => this.toggleEditMode(entry)} style={{cursor: 'pointer', backgroundColor: index % 2 === 0 ? 'rgba(0, 0, 0, 0.14)' : 'transparent'}}>
                  <td style={{minWidth: "22%"}}>
                    {this.displayTextKey(entry.key)}
                  </td>
                  <td>{entry.value}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <Pagination>
          <PaginationItem disabled={currentPage === 1}>
            <PaginationLink onClick={() => this.setState({ currentPage: 1 })}>
              {'<<'}
            </PaginationLink>
          </PaginationItem>

          <PaginationItem disabled={currentPage === 1}>
            <PaginationLink previous onClick={() => this.setState({ currentPage: currentPage - 1 })} />
          </PaginationItem>
          {Array.from({ length: Math.ceil(filteredTranslationEntries.length / entriesPerPage) }, (v, i) => i + 1).slice(Math.max(0, currentPage - 14), Math.min(totalPages, currentPage + 14)).map((page) => (
            <PaginationItem key={page} active={page === currentPage}>
              <PaginationLink onClick={() => this.setState({ currentPage: page })}>
                {page}
              </PaginationLink>
            </PaginationItem>
          ))}


          <PaginationItem disabled={currentPage === Math.ceil(filteredTranslationEntries.length / entriesPerPage)}>
            <PaginationLink next onClick={() => this.setState({ currentPage: currentPage + 1 })} />
          </PaginationItem>

          <PaginationItem>
            <PaginationLink onClick={() => this.setState({ currentPage: Math.ceil(filteredTranslationEntries.length / entriesPerPage) })}>
              {'>>'}
            </PaginationLink>
          </PaginationItem>
        </Pagination>

        <Modal container={'div > div'} isOpen={editModeOpen} size="lg">
          <ModalHeader>
            Edit Translation
          </ModalHeader>
          <ModalBody>
            <Label for="textKey">Text Key:</Label>
            <Input type="text" id="textKey" value={textKey} onChange={this.handleTextKeyChange} disabled />

            <Label for="translation">Translation LV:</Label>
            <Input type="text" id="translationLV" value={translationLv} onChange={this.handleTranslationChangeLv} />

            <Label for="translation">Translation EN:</Label>
            <Input type="text" id="translationEN" value={translationEn} onChange={this.handleTranslationChangeEn} />

            <Label for="translation">Translation RU:</Label>
            <Input type="text" id="translationRU" value={translationRu} onChange={this.handleTranslationChangeRu} />
          </ModalBody>
          <ModalFooter>
            <Button className="btn notification-footer-button" onClick={this.saveEntry}>Save</Button>
            <Button className="btn notification-footer-button" onClick={this.toggleEditMode}>Cancel</Button>
          </ModalFooter>
        </Modal>

      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  userAchievements: state.admin.userAchievements || {},
});
const mapDispatchToProps = {
  fetchTranslationsFiles: getTranslationsFiles,
  updateTranslations: updateTranslationsFile,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('admin')(TextKeys));

// export default withTranslation('admin')(TextKeys);
