import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { connect } from 'react-redux';
import { map, filter, size } from 'lodash';
import Button from 'reactstrap/lib/Button';
import Media from 'reactstrap/lib/Media';
import Label from 'reactstrap/lib/Label';

import moment from 'moment';
import Moment from 'react-moment';

import momentLocalizer from 'react-widgets-moment';

// import member from '../../../store/member';
import * as constants from '../../../constants/constants';

import treeSvg from '../../../images/icons/tree-svg.svg';
import reminderImg from '../../../images/redesign/common/game.png';
import coinImg from '../../../images/coin.svg';

import TournamentTimer from '../Redesign/Menu/Tournaments/Components/TournamentTimer';

import {
  // getCampaignBanner,
  // getCampaignsBanner,
  getDiscounts,
  getEventsBanner,
} from '../../../actions/shop';
import {
  checkFrequencyModalFunc,
} from '../../../actions/member';
import DateFormat from '../UI/DateFormat';
import { getTournamentTypeEvent } from '../../../utils/tournamentUtils';
import { formatMinutes } from '../../../utils/stringUtils';

moment.locale('lv');
momentLocalizer();

class EventModals extends React.Component {
    static propTypes = {
      t: PropTypes.func.isRequired,
      discounts: PropTypes.shape([]),
      eventsData: PropTypes.shape([]),
      switchTabs: PropTypes.func.isRequired,
      fetchDiscounts: PropTypes.func.isRequired,
      fetchEventsBanner: PropTypes.func.isRequired,
      fontMode: PropTypes.func.isRequired,
      isEventOption: PropTypes.func.isRequired,
      designPlatform: PropTypes.shape({}),
      toggleNewDesign: PropTypes.func.isRequired,
      checkFrequencyModal: PropTypes.func.isRequired,
      member: PropTypes.shape({}),
      registeredTournament: PropTypes.string,
    }

    static defaultProps = {
      eventsData: [],
      discounts: [],
      designPlatform: {},
      member: {},
    }

    constructor(props) {
      super(props);
      this.state = {
        selectEvent: {},
        selectDiscount: {},
        toDate: (new Date()).getDate(),
        reminderModal: true,
        currentTime: Date.now(),
        activeHover: false,
      };

      this.timerInterval = null;

    }

    componentDidMount() {
      const {
        fetchDiscounts,
        fetchEventsBanner,
        eventsData,
        discounts,
        tournaments,
        offset,
      } = this.props;
      const { selectEvent } = this.state;

      const { hostname } = window.location;

      if (selectEvent && selectEvent?.descriptionTournamentId && tournaments) {
        if (tournaments.find((element) => element.id === selectEvent?.descriptionTournamentId)) {
          this.setupInterval();
        }
      }

      let devMode = false;

      if (hostname && (hostname.includes('dev') || hostname.includes('localhost'))) {
        devMode = true;
      }

      this.setState({
        devMode,
      })

      fetchEventsBanner(devMode);
      fetchDiscounts(devMode);

      // Moved to routes/Auth.js to not refresh on design swap
      /* try {
        localStorage.removeItem('checkedEvents');
        localStorage.removeItem('checkedDiscounts');
      } catch (err) {
      } */

      if (eventsData) {
        let usersEvent = [];

        try {
          if (localStorage.getItem('checkedEvents')) {
            usersEvent = JSON.parse(localStorage.getItem('checkedEvents'));
          }
        } catch (err) {
        }
        let count = 0;

        eventsData.sort((a, b) => (a.startDate - b.startDate));

        map(eventsData, (item, key) => {
          // console.log('eventsData item', item.startDate, (Date.now() + offset));
          if (!usersEvent.includes(item.key) && count < 1) {
            count++;
            this.setState({
              selectEvent: item,
            });
          }
        });
      }

      if (discounts) {
        let usersDiscounts = [];

        try {
          if (localStorage.getItem('checkedDiscounts')) {
            usersDiscounts = JSON.parse(localStorage.getItem('checkedDiscounts'));

            console.log('usersDiscounts checkedDiscounts', usersDiscounts);
          }
        } catch (err) {
        }

        console.log('usersDiscounts', usersDiscounts);

        let count = 0;
        map(discounts, (item, key) => {
          if (!usersDiscounts.includes(item.key) && count < 1) {
            count++;
            this.setState({
              selectDiscount: item,
            });
          }
        });
      }
    }

//select event and key have to set correct !!!!! issue

    componentDidUpdate(prevProps, prevState) {
      const { eventsData, discounts, tournaments, offset } = this.props;
      const { selectEvent } = this.state;

      let prevTournamentFound;
      let tournamentFound;
      if (prevState.selectEvent && prevState.selectEvent?.descriptionTournamentId && prevProps.tournaments) {
        prevTournamentFound = prevProps.tournaments.find((element) => element.id === prevState.selectEvent?.descriptionTournamentId);
      }
      if (selectEvent && selectEvent?.descriptionTournamentId && tournaments) {
        tournamentFound = tournaments.find((element) => element.id === selectEvent?.descriptionTournamentId);
      }

      if (tournamentFound !== prevTournamentFound) {
        if (tournamentFound) {
          this.setupInterval();
        } else {
          this.clearInterval();
        }
      }


      if (eventsData !== prevProps.eventsData && size(eventsData)) {
        let usersEvent = [];

        try {
          if (localStorage.getItem('checkedEvents')) {
            usersEvent = JSON.parse(localStorage.getItem('checkedEvents'));
          }
        } catch (err) {
          console.log(err);
        }

        eventsData.sort((a, b) => (a.startDate - b.startDate));

        let eventCount = 0;
        map(eventsData, (item) => {
          // console.log('eventsData item', item.startDate, (Date.now() + offset));

          if (!usersEvent.includes(item.key) && eventCount < 1) {
            eventCount += 1;
            this.setState({
              selectEvent: item,
            });
          }
        });
      }

      if (discounts !== prevProps.discounts && size(discounts)) {
        let usersDiscounts = [];

        try {
          if (localStorage.getItem('checkedDiscounts')) {
            usersDiscounts = JSON.parse(localStorage.getItem('checkedDiscounts'));
          }
        } catch (err) {
          console.log(err);
        }
        let discountCount = 0;

        map(discounts, (item) => {
          if (!usersDiscounts.includes(item.key) && discountCount < 1) {
            discountCount += 1;
            this.setState({
              selectDiscount: item,
            });
          }
        });
      }
    }

    componentWillUnmount() {
      this.clearInterval();
    }

    setupInterval() {
      this.clearInterval();

      this.timerInterval = setInterval(() => {
        this.setState({ currentTime: Date.now() });
      }, 2000);
    }

    clearInterval() {
      if (this.timerInterval) {
        clearInterval(this.timerInterval);
        this.timerInterval = null;
      }
    }

    closeModal = () => {
      const { eventsData, toggleEvent } = this.props;
      const { selectEvent } = this.state;
      let usersEvent = [];

      try {
        if (localStorage.getItem('checkedEvents')) {
          usersEvent = JSON.parse(localStorage.getItem('checkedEvents'));
          usersEvent.push(selectEvent.key);
          localStorage.setItem('checkedEvents', JSON.stringify(usersEvent));
        } else {
          usersEvent.push(selectEvent.key);
          localStorage.setItem('checkedEvents', JSON.stringify(usersEvent));
        }
      } catch (err) {
        console.log(err);
      }

      this.setState({
        selectEvent: '',
      });

      console.log('closeModal', eventsData, usersEvent);

      let count = 0;
      if (eventsData) {
        eventsData.sort((a, b) => (a.startDate - b.startDate));

        map(eventsData, (item) => {
          // count += 1;

          console.log('count', count, usersEvent.includes(item.key), item);

          if (!usersEvent.includes(item.key) && count < 1) { // && count < 1
            count += 1;
            this.setState({
              selectEvent: item,
            });
          }
        });
      }

      // toggleEvent();
    }

    closeDiscountModal = () => {
      const { discounts } = this.props;
      const { selectDiscount } = this.state;
      let usersDiscounts = [];

      console.log('closeDiscountModal', selectDiscount);

      try {
        if (localStorage.getItem('checkedDiscounts')) {
          usersDiscounts = JSON.parse(localStorage.getItem('checkedDiscounts'));
          usersDiscounts.push(selectDiscount.key);
          localStorage.setItem('checkedDiscounts', JSON.stringify(usersDiscounts));
        } else {
          usersDiscounts.push(selectDiscount.key);
          localStorage.setItem('checkedDiscounts', JSON.stringify(usersDiscounts));
        }
      } catch (err) {
        console.log('closeDiscountModal err');

        console.log(err);
      }

      this.setState({
        selectDiscount: '',
      });
      let count = 0;
      if (discounts) {
          Object.keys(discounts).map(item => {
            count += 1;
            if (!usersDiscounts.includes(item.key) && count < 1) {
              this.setState({
                selectDiscount: item,
              });
            }
          });
      }
    }

    openPage = (type, page, externalLinkFlag) => {
      const { eventsData, switchTabs } = this.props;
      const { selectDiscount } = this.state;
      // let usersEvent = [];

      console.log('openPage', type, page, externalLinkFlag);

      try {
        if (externalLinkFlag) {
          window.open(page, '_blank');
        } else {
          const switchTab = constants.MENU_NAVIGATION_TYPE.filter(item => item.label === page)[0].id;

          if (size(selectDiscount.selectedDiscountPackage) > 0 &&
            size(filter(selectDiscount.selectedDiscountPackage, item => { return constants.MONEYPACKS_TYPES.includes(item) })) === 0 &&
            size(filter(selectDiscount.selectedDiscountPackage, item => { return constants.PREMIUMPACKS_TYPES.includes(item) })) > 0
          ) {
            switchTabs(constants.MENU_NAVIGATION.buyPagePremiumPacks);
          } else {
            switchTabs(switchTab);
          }
        }
      } catch (err) {

      }

      if (type === 'event') {
        this.closeModal();
      } else {
        this.closeDiscountModal();
      }
    //  console.log(switchTab, "switchTab");

      // if (page === 'tournaments') {
      //   switchTabs(switchTab);
      // } else if (page === 'top') {
      //   switchTabs('2');
      // } else if (page === 'rooms') {
      //   switchTabs('1');
      // } else if (page === 'shop') {
      //   switchTabs('5');
      // } else if (page === 'shop_discounts') {
      //   switchTabs('5.5');
      // }
      // if (localStorage.getItem('checkedEvents')) {
      //   usersEvent = JSON.parse(localStorage.getItem('checkedEvents'));
      //   usersEvent.push(selectEvent);
      //   localStorage.setItem("checkedEvents", JSON.stringify(usersEvent));
      // } else {
      //   usersEvent.push(selectEvent);
      //   localStorage.setItem("checkedEvents", JSON.stringify(usersEvent));
      // }
      // let tab = '1';
      // this.setState({
      //   selectEvent: '',
      // });
      // let count = 0;
      // Object.keys(eventsData).map(key => {
      //   count++;
      //   if (!usersEvent.includes(key) && count < 1) {
      //     this.setState({
      //       selectEvent: key,
      //     });
      //   }
      // });
    }

    toggleNewDesignFunc = () =>  {
      const {toggleNewDesign, checkFrequencyModal, } = this.props;

      toggleNewDesign(true);
      this.setState({
        reminderModal: false,
      })
      checkFrequencyModal();
    };

    closeFrequencyFunc = () => {
      const { checkFrequencyModal } = this.props;
      this.setState({
        reminderModal: false,
      })
      checkFrequencyModal();
    }

    toggleActive = () => {
      const { activeHover } = this.state;
      this.setState({ activeHover: !activeHover });
    }

    checkRange = (value, from, to) => {
      if (from !== null && to !== null) return value >= from && value <= to;
      if (from !== null) return value >= from;
      if (to !== null) return value <= to;
      return true;
    };

    render() {
      const {
        t,
        eventsData,
        discounts,
        i18n,
        isEventOption,
        go,
        designPlatform,
        member,
        registeredTournament,
        tournaments,
        offset,
      } = this.props;
      const {
        selectEvent,
        selectDiscount,
        toDate,
        reminderModal,
        devMode,
        currentTime,
        activeHover,
      } = this.state;
      const checkFrequency = member.checkFrequency || null;
      let isFrequency = false;
      if (designPlatform && designPlatform.frequency > 0 && designPlatform.setFrequency) {
        const frequencyDate = moment.duration(moment(new Date()).diff(moment(new Date(designPlatform.setFrequency)))).days();
        isFrequency = frequencyDate % designPlatform.frequency === 0 ? true : false;
      }

      const eventOpensTournamentPage = selectEvent && (selectEvent.pageToOpen === 'tournaments' || selectEvent.pageToOpen === 'futureTournaments'
        || selectEvent.pageToOpen === 'activeTournaments' || selectEvent.pageToOpen === 'plannedTournaments' || selectEvent.pageToOpen === 'tournamentsHistory');

      let eventTournament = {};
      if (selectEvent && selectEvent?.descriptionTournamentId && tournaments) {
        try {
          const tournamentFound = tournaments.find((element) => element.id === selectEvent?.descriptionTournamentId);

          if (tournamentFound) {
            eventTournament = tournamentFound;
          }
        } catch (err) {
          console.log(err);
        }
      }

        const {
          lvl, bal, totalPnts, gPlayed,
        } = member;
        const levelFrom = (selectEvent.levelFrom || selectEvent.levelFrom === 0) ? parseInt(selectEvent.levelFrom, 10) : null;
        const levelTo = (selectEvent.levelTo || selectEvent.levelTo === 0) ? parseInt(selectEvent.levelTo, 10) : null;
        const pointsFrom = (selectEvent.pointsFrom || selectEvent.pointsFrom === 0) ? parseInt(selectEvent.pointsFrom, 10) : null;
        const pointsTo = (selectEvent.pointsTo || selectEvent.pointsTo === 0) ? parseInt(selectEvent.pointsTo, 10) : null;
        const balanceFrom = (selectEvent.balanceFrom || selectEvent.balanceFrom === 0) ? parseInt(selectEvent.balanceFrom, 10) : null;
        const balanceTo = (selectEvent.balanceTo || selectEvent.balanceTo === 0) ? parseInt(selectEvent.balanceTo, 10) : null;
        const playedGamesFrom = (selectEvent.playedGamesFrom || selectEvent.playedGamesFrom === 0) ? parseInt(selectEvent.playedGamesFrom, 10) : null;
        const playedGamesTo = (selectEvent.playedGamesTo || selectEvent.playedGamesTo === 0) ? parseInt(selectEvent.playedGamesTo, 10) : null;

        const isLevelPass = this.checkRange(lvl, levelFrom, levelTo);
        const isPointsPass = this.checkRange(totalPnts, pointsFrom, pointsTo);
        const isBalPass = this.checkRange(bal, balanceFrom, balanceTo);
        const isPlayedGamesPass = this.checkRange(gPlayed, playedGamesFrom, playedGamesTo);

        let isRestriction = true;
        if (isLevelPass && isPointsPass && isBalPass && isPlayedGamesPass) {
          isRestriction = false;
        }

      const shouldShowCountdown = ((eventTournament.startDate - (1000 * 60 * 5)) < currentTime) && (eventTournament.startDate > currentTime);
      const shouldShowHourCountdown = (eventTournament.startDate - (1000 * 60 * 60)) < currentTime;

      // console.log('eventsData222', eventsData, selectEvent);

      return (
        <>
          {
            (!!eventsData &&
              eventsData.length > 0 &&
              selectEvent &&
              !isEventOption &&
              Date.now() >= selectEvent.startDate &&
              Date.now() < selectEvent.endDate &&
              ((eventOpensTournamentPage && !registeredTournament) || (eventOpensTournamentPage && registeredTournament && (eventTournament && shouldShowCountdown)) || !eventOpensTournamentPage)) ? (
                <div className="event-section" style={eventTournament ? {height: 'auto', maxHeight: '100vh'} : {}}>
                  <div className="event-header" style={eventTournament ? {height: 'auto', maxHeight: !selectEvent.imageUrl ? 'calc(100vh - 71px)' : 'calc(100vh - 260px)'} : {}}>
                    <div className="event-title">
                      {i18n.language === 'en' ? selectEvent.titleEN : (i18n.language === 'lv' ? selectEvent.titleLV : (i18n.language === 'ru' ? selectEvent.titleRU : selectEvent.titleLV))}
                    </div>
                    <div className="event-close events-header-close" onClick={() => this.closeModal()}>x</div>
                    {/* <div className="event-sub-title">
                      <DateFormat formatType={constants.FORMAT_DATE_TYPE.fullDateMinute} date={new Date(selectEvent?.startDate || null)} />
                      <Label className="event-sub-spaces">~</Label>
                      <DateFormat formatType={constants.FORMAT_DATE_TYPE.fullDateMinute} date={new Date(selectEvent?.endDate || null)} />
                    </div> */}
                    <div className="event-description">
                      {(eventTournament && eventOpensTournamentPage) ? (
                        <>
                          {registeredTournament && (eventTournament && shouldShowCountdown) ? (
                            <div style={{ textAlign: 'center', marginTop: '10%', fontWeight: 700 }}>
                              <span>{`${t('tournaments.tournamentStartsIn')} `}</span>
                              <TournamentTimer t={t} time={eventTournament.startDate || 0} offset={offset} showLetters={true} />
                            </div>
                          ) : (
                            <>
                              <span className="d-block"><span className="font-weight-bold">{t('tournaments.gameType')}: </span>{getTournamentTypeEvent(eventTournament, t)}</span>
                              <span className="d-block"><span className="font-weight-bold">{t('tournaments.bet')}: </span>{eventTournament.bet}</span>
                              <span className="d-block"><span className="font-weight-bold">{t('tournaments.joiningFee')}: </span>
                                {eventTournament.entryFee}
                                <Media className="d-inline-block" style={{width: 25}} src={coinImg} />
                              </span>
                              <span className="d-block"><span className="font-weight-bold">{t('tournaments.bonus')}: </span>
                                {eventTournament.bonus}
                                <Media className="d-inline-block" style={{width: 25}} src={coinImg} />
                              </span>
                              <span className="d-block"><span className="font-weight-bold">{t('tournaments.intervalBetweenRounds')}: </span>{eventTournament.newRoundPause} {t('common.minutesShort')}</span>
                              <span className="d-block"><span className="font-weight-bold">{t('tournaments.numberOfRounds')}: </span>{eventTournament.rounds}</span>
                              <span className="d-block"><span className="font-weight-bold">{t('tournaments.numberOfGamesPerRound')}: </span>{eventTournament.roundLength}</span>
                              <span className="d-block"><span className="font-weight-bold">{t('tournaments.startOfTournament')}: </span>
                                <Moment format="DD-MM-YYYY HH:mm" locale="lv">
                                  {eventTournament.startDate}
                                </Moment>
                              </span>
                              <span className="d-block"><span className="font-weight-bold">{t('tournaments.maxNumOfPlayers')}: </span>{eventTournament.maxPlayers}</span>
                              <span className="d-block"><span className="font-weight-bold">{t('tournaments.playerPerRoomLimit')}: </span>{`${eventTournament?.playerPerRoomLimit ? (eventTournament.playerPerRoomLimit === 4 ? t('tournaments.playerPerRoomLimit4Only') : t('tournaments.playerPerRoomLimit3Only')) : t('tournaments.playerPerRoomLimitClasic')}`}</span>
                              <span className="d-block">
                                <span className="font-weight-bold">{t('tournaments.currentBank', { percentage: eventTournament.winnerPercent })}: </span>
                                {(eventTournament.totalBank + eventTournament.bonus) || 0}
                                <Media className="d-inline-block" style={{width: 25}} src={coinImg} />
                              </span>
                              <span className="d-block"><span className="font-weight-bold">{t('tournaments.participantsShort')}: </span>{eventTournament.registeredPlayers}</span>
                              <span className="d-block"><span className="font-weight-bold">{t('tournaments.maxLength')}: </span>{formatMinutes(eventTournament.maxLengthTime, i18n.language)}</span>

                              {eventTournament.bannerPrizeChecked && (
                                <span className="d-block">
                                  <span className="font-weight-bold">{t('tournaments.specialPrize')}: </span>
                                  {i18n.language === 'en' ? eventTournament.specialPrizeEN : (i18n.language === 'lv' ? eventTournament.specialPrizeLV : (i18n.language === 'ru' ? eventTournament.specialPrizeRU : eventTournament.specialPrizeLV))}
                                </span>
                              )}

                              {(shouldShowHourCountdown) && (
                                <>
                                  <span>{`${t('tournaments.tournamentStartsIn')} `}</span>
                                  <TournamentTimer t={t} time={eventTournament.startDate || 0} offset={offset} showLetters={true} />
                                </>
                              )}
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          {i18n.language === 'en' ? selectEvent.descriptionEN : (i18n.language === 'lv' ? selectEvent.descriptionLV : (i18n.language === 'ru' ? selectEvent.descriptionRU : selectEvent.descriptionLV))}
                        </>
                      )}

                    </div>
                  </div>
                  {selectEvent.imageUrl && (
                    <div className="event-body">
                      <img src={selectEvent.imageUrl} alt="X" className="event-body-img" />
                    </div>
                  )}
                  <div className="event-footer">
                    <Button className="event-footer-button" color="secondary" onClick={() => this.openPage('event', selectEvent.pageToOpen, selectEvent.externalLink)}>
                      <Media src={treeSvg} className="event-tree-svg-before" />
                      <label className="openPage">{eventOpensTournamentPage ? t('common.openTournament') : t('common.open')}</label>
                      <Media src={treeSvg} className="event-tree-svg-end" />
                    </Button>
                    {eventOpensTournamentPage && selectEvent?.restrictions && isRestriction && (
                      <>
                        <div className='event-tournament-restrictions' onMouseEnter={() => this.toggleActive()} onMouseLeave={() => this.toggleActive()}>{t('common.restrictions')}</div>
                        <div className={`event-tournament-restrictions-hover buy-page-info-hover-profile ${activeHover ? null : 'display-none'}`}>
                          <div className='d-flex flex-column'>
                            {selectEvent?.levelFrom && selectEvent?.levelTo && (
                              <span>{`${t('common.level')}: ${selectEvent.levelFrom}–${selectEvent.levelTo}`}</span>
                            )}
                            {selectEvent?.pointsFrom && selectEvent?.pointsTo && (
                              <span>{`${t('common.points')}: ${selectEvent.pointsFrom}–${selectEvent.pointsTo}`}</span>
                            )}
                            {selectEvent?.balanceFrom && selectEvent?.balanceTo && (
                              <span>{`${t('common.balance')}: ${selectEvent.balanceFrom}–${selectEvent.balanceTo}`}</span>
                            )}
                            {selectEvent?.playedGamesFrom && selectEvent?.playedGamesTo && (
                              <span>{`${t('common.playedGames')}: ${selectEvent.playedGamesFrom}–${selectEvent.playedGamesTo}`}</span>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
            ) : (null)
          }
          {
            (!!discounts && discounts.length > 0 && selectDiscount && selectDiscount.showInBanner && Date.now() >= selectDiscount.startDate && Date.now() < selectDiscount.endDate) ? (
              <div className="discount-event-section">
                <div className="event-header">
                  <div className="event-title">
                    {i18n.language === 'en' ? selectDiscount.titleEN : (i18n.language === 'lv' ? selectDiscount.titleLV : (i18n.language === 'ru' ? selectDiscount.titleRU : selectDiscount.titleLV))}
                  </div>
                  <div className="event-close events-header-close" onClick={() => this.closeDiscountModal()}>x</div>
                  {/* <div className="event-sub-title">
                    <DateFormat formatType={constants.FORMAT_DATE_TYPE.fullDateMinute} date={new Date(selectDiscount?.startDate || null)} />
                    <Label className="event-sub-spaces">~</Label>
                    <DateFormat formatType={constants.FORMAT_DATE_TYPE.fullDateMinute} date={new Date(selectDiscount?.endDate || null)} />
                  </div> */}
                  <div className="event-description">
                    {i18n.language === 'en' ? selectDiscount.descriptionEN : (i18n.language === 'lv' ? selectDiscount.descriptionLV : (i18n.language === 'ru' ? selectDiscount.descriptionRU : selectDiscount.descriptionLV))}
                  </div>
                </div>
                <div className="event-body">
                  <img src={selectDiscount.imageUrl} alt="X" className="event-body-img" />
                </div>
                <div className="event-footer">
                  <Button className="event-footer-button" color="secondary" onClick={() => this.openPage('discount', 'shop_discounts')}>
                    <Media src={treeSvg} className="event-tree-svg-before" />
                    <label className="openPage">{t('common.open')}</label>
                    <Media src={treeSvg} className="event-tree-svg-end" />
                  </Button>
                </div>
              </div>
            ) : (null)
          }
          {
            designPlatform && designPlatform.showReminder && isFrequency && ((designPlatform.devMode && devMode) || (!designPlatform.devMode && !devMode)) && (!checkFrequency || (Date.now() > checkFrequency && moment().format("YYYY-MM-DD") !== moment(new Date(checkFrequency)).format("YYYY-MM-DD"))) && reminderModal && (
              <div className="discount-event-section">
                <div className="event-header">
                  <div className="event-title">
                    {t('common.reminderTitle')}
                  </div>
                  <div className="event-close events-header-close" onClick={() => this.closeFrequencyFunc()}>x</div>
                  <div className="event-description">
                    {t('common.reminderDescription')}
                  </div>
                </div>
                <div className="event-body">
                  <img src={reminderImg} alt="X" className="event-body-img" />
                </div>
                <div className="event-footer">
                  <Button className="event-footer-button" color="secondary" onClick={() => this.toggleNewDesignFunc()}>
                    <Media src={treeSvg} className="event-tree-svg-before" />
                    <label className="openPage">{t('common.reminderButton')}</label>
                    <Media src={treeSvg} className="event-tree-svg-end" />
                  </Button>
                </div>
              </div>
            )
          }
        </>
      );
    }
}

const mapStateToProps = state => ({
  eventsData: state.shop.eventsData || [],
  discounts: state.shop.discounts || [],
  member: state.member || null,
  offset: state.member.offset || 0,
  registeredTournament: state.member.registeredTournament,
  tournaments: (state.tournaments && state.tournaments.tournaments) ? state.tournaments.tournaments : [],
});

const mapDispatchToProps = {
  fetchEventsBanner: getEventsBanner,
  fetchDiscounts: getDiscounts,
  checkFrequencyModal: checkFrequencyModalFunc,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(EventModals));
