import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Button from 'reactstrap/lib/Button';
import { useTranslation } from 'react-i18next';

const ScoreTableRow = React.memo(({
  player1,
  player2,
  player3,
  pule,
  player1ShortName,
  player2ShortName,
  player3ShortName,
  myPos,
  index,
  roundIndex,
  fetchPartyLog,
  isEndResultTable,
  pulesTitle,
}) => {
  const { t } = useTranslation('game');
  return (
    <Fragment>
      <td className="score-table-id-col">
        {isEndResultTable ? (
          <div className="score-table-id-col-div">
            {pulesTitle ? t('pules') : index}
          </div>
        ) : (
          index
        )}
      </td>
      <td className="score-table-col score-table-col-1">
        <Fragment>
          {myPos === 'player1' && (
            <div>
              {player2}
            </div>
          )}
          {myPos === 'player2' && (
            <div>
              {player3}
            </div>
          )}
          {myPos === 'player3' && (
            <div>
              {player1}
            </div>
          )}
        </Fragment>
      </td>
      <td className="score-table-col score-table-col-2">
        <Fragment>
          {myPos === 'player1' && (
            <div>
              {player1}
            </div>
          )}
          {myPos === 'player2' && (
            <div>
              {player2}
            </div>
          )}
          {myPos === 'player3' && (
            <div>
              {player3}
            </div>
          )}
        </Fragment>
      </td>
      <td className="score-table-col score-table-col-3">
        <Fragment>
          {myPos === 'player1' && (
            <div>
              {player3}
            </div>
          )}
          {myPos === 'player2' && (
            <div>
              {player1}
            </div>
          )}
          {myPos === 'player3' && (
            <div>
              {player2}
            </div>
          )}
        </Fragment>
      </td>
      <td className="score-table-pules-col">
        {pule === 'P' && (
          <div>P</div>
        )}
        {pule === 'P-' && (
          <span className="strikeout-new-normal strikeout-extension">P</span>
        )}
        {pule === 'player1' && (
          <div className="score-table-pules-col-div">
            { player1ShortName }
          </div>
        )}
        {pule === 'player1-' && (
          <span className="score-table-pules-col-div strikeout-new-normal strikeout-extension">
            { player1ShortName }
          </span>
        )}
        {pule === 'player2' && (
          <div className="score-table-pules-col-div">
            { player2ShortName }
          </div>
        )}
        {pule === 'player2-' && (
          <span className="score-table-pules-col-div strikeout-new-normal strikeout-extension">
            { player2ShortName }
          </span>
        )}
        {pule === 'player3' && (
          <div className="score-table-pules-col-div">
            { player3ShortName }
          </div>
        )}
        {pule === 'player3-' && (
          <span className="score-table-pules-col-div strikeout-new-normal strikeout-extension">
            { player3ShortName }
          </span>
        )}
      </td>
      {isEndResultTable ? (
        <td className="score-table-info-col">
          <div>
            <Button color="link" className="end-result-modal-info-button" onClick={() => fetchPartyLog(roundIndex + 1, pulesTitle)}>
              {t('info')}
            </Button>
          </div>
        </td>
      ) : (null)}
    </Fragment>
  );
});

ScoreTableRow.propTypes = {
  player1: PropTypes.number,
  player2: PropTypes.number,
  player3: PropTypes.number,
  pule: PropTypes.string,
  player1ShortName: PropTypes.string,
  player2ShortName: PropTypes.string,
  player3ShortName: PropTypes.string,
  myPos: PropTypes.string,
  index: PropTypes.number,
  roundIndex: PropTypes.number,
  fetchPartyLog: PropTypes.func,
  isEndResultTable: PropTypes.bool,
  pulesTitle: PropTypes.string,
};

ScoreTableRow.defaultProps = {
  player1: null,
  player2: null,
  player3: null,
  pule: null,
  player1ShortName: null,
  player2ShortName: null,
  player3ShortName: null,
  myPos: null,
  index: null,
  roundIndex: null,
  fetchPartyLog: null,
  isEndResultTable: false,
  pulesTitle: '',
};

export default ScoreTableRow;
