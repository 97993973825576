import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Button from 'reactstrap/lib/Button';

import Moment from 'react-moment';

import ScrollAreaWrapper from '../../../Components/ScrollAreaWrapper';

import { connect } from 'react-redux';
import { getBetValue } from '../../../../../../common/services/data-service';
import { renderTextWithLineBreaksAndSymbolColor, trimString } from '../../../../../../utils/stringUtils';
import { Media } from 'reactstrap';

import defaultImageLight from '../../../../../../images/redesign/light-mode/common/default_image.svg';
import defaultImageNormal from '../../../../../../images/redesign/normal-mode/common/default_image.webp';
import defaultImageDark from '../../../../../../images/redesign/common/default_image.svg';

import { getCardImage } from '../../../../../../utils/cardInfoUtils';
import { SCREEN_MODE } from '../../../../../../constants/constants';

const PartyLog = React.memo(({
  t,
  partyLog,
  players,
  tournamentRound,
  screenMode,
}) => {

  if (!partyLog) {
    return null;
  }

  if (!partyLog.length) {
    return null;
  }

  console.log('players', players);

  let secondBurriedCard = false;

  const defaultImage = screenMode === SCREEN_MODE.light ? defaultImageLight : (screenMode === SCREEN_MODE.normal ? defaultImageNormal : defaultImageDark);

  partyLog.sort((a, b) => a.time - b.time);

  return (
      <>
        <div className="partylog-scrollarea">
          {partyLog.map((logData) => {
            if (logData.key === 'gameResult') {
              return null;
            }
            if (!logData.type) {
              return null;
            }
            if (logData.type === 'endRoomPules' || logData.type === 'playerLeft' || logData.type === 'playerJoined' || logData.type === 'roundOver' || logData.type === 'setLast') {
              return null;
            }

            const data = logData.data || {};

            let hasDivider = false;

            if (logData.type === 'determineStrongest' || ((logData.type === 'selectType' || logData.type === 'selectTypeAutomated') && data.type !== 'parasta' && data.type !== 'garam')
              || logData.type === 'burriedCard' || logData.type === 'burriedCardAutomated' || logData.type === 'roundResult') {
              if ((logData.type === 'burriedCard' || logData.type === 'burriedCardAutomated') && !secondBurriedCard) {
                secondBurriedCard = true;
              } else {
                hasDivider = true;
              }
            }

            if ((logData.type === 'selectType' || logData.type === 'selectTypeAutomated') && data.type === 'garam' && data.action === 'galdins') {
              hasDivider = true;
            }

            else if (logData.type === 'updatePoints' || logData.type === 'roomClosed') {
              hasDivider = true;
            }

            let selectTypeText;

            if (logData.type === 'selectType' || logData.type === 'selectTypeAutomated') {
              if (data.type === 'parasta') {
                selectTypeText = t('game:regular');
              } else if (data.type === 'zole') {
                selectTypeText = t('game:zole');
              } if (data.type === 'maza') {
                selectTypeText = t('game:smallZole');
              }
            }

            let logString;
            switch (logData.type) {
              case 'cardsDealt':
                logString = t('dealtCards');
                break;

              case 'cardPlayed':
              case 'cardPlayedAutomated':
                if (players[data.player]) {
                  logString = `${players[data.player].name} ${t('playedCard')} ${data.playedCard}`;
                }
                break;

              case 'burriedCard':
              case 'burriedCardAutomated':
                logString = `${t('burriedCardText', { burriedCard: data.burriedCard, newPnts: data.newPnts })}`;
                break;

              case 'selectType':
              case 'selectTypeAutomated':
                if (data.type === 'garam') {
                  if (data.action !== 'galdins') {
                    logString = t('passedText', { player: data.player });
                  } else {
                    logString = t('passedTextGaldins', { player: data.player });
                  }
                } else {
                  if (data.type === 'zole') {
                    logString = `${t('player')} ${data.player} ${t('selectZole')}`;
                  } else if (data.type === 'maza') {
                    logString = `${t('player')} ${data.player} ${t('selectSmZole')}`;
                  } else {
                    logString = t('tookTableText', { player: data.player });
                  }
                  // logString = t('selectTypeText', { player: data.player, type: selectTypeText });
                }
                break;

              case 'determineStrongest':
                logString = t('determineStrongestText', { card: data.card, winPlayer: data.winPlayer, tablePoints: data.tablePoints || '0' });
                break;

              case 'playerQuit':
                //logString = t('playerQuit');
                logString = t('playerQuit2', {player: logData.data.player});
                break;

              case 'startAutomated':
                logString = t('startAutomated', { player: data.playerName });
                break;

              case 'endAutomated':
                logString = t('endAutomated', { player: data.playerName });
                break;

              case 'roomClosed':
                console.log(data);
                if (data.type && data.type === 'lastRound') {
                  if (data.playerUid === 'tournament') {
                    if (tournamentRound === 'last'){
                      logString = t('lastRoundTournamentLastLog')
                    } else {
                      logString = t('admin:lastRoundTournament', { round: tournamentRound });
                    }
                  } else {
                    logString = t('lastRound', { player: data.player });
                  }
                } else if (data.type && data.type === 'leftRoom') {
                  logString = t('leftRoom', { player: data.player });
                } else if (data.type && data.type === 'endAutomatedRoom') {
                  logString = t('endAutomatedRoom', { player: data.player });
                } else if (data.type && data.type === 'missedTurn') {
                  logString = t('missedTurnMessage', { player: data.player });
                }
                break;

              case 'roundResult':
                if (data.type === 'galdins') {
                  //  logString = `${data.winner} ${t('loses')} ${data.type}`;
                  logString = `${data.winner} ${t('lostGame')} ${t('galdins')} ${t('withPoints')} ${data.largeTricks} ${t('tricksPlural')} (+${data.largePoints})`;
                  // largePoints largeTricks
                } else {
                  logString = `${t('largePlayer')} (${data.largePlayer}) ${data.winner === 'large' ? t('wonGame') : t('lostGame')}`;
                  if (data.scoreType === 'parasts') {
                    //logString += ` ${t('withPoints')} ${data.largePoints} ${t('points')}`;
                    if (data.largePoints === 0 && data.winner === 'large') {
                      logString += ` ${t('mazoZoli')}`;
                    } else {
                      logString += ` ${t('withPoints')} ${data.largePoints} ${t('points4')}`;
                    }
                  } else if (data.scoreType === 'jani') {
                    logString += ` ${t('withJani')}  ${t('withPoints')} ${data.largePoints} ${t('points4')}`;
                  } else if (data.scoreType === 'bezstikis') {
                    logString += ` ${t('withBezstikis')}`;
                  }
                }
                break;

              case 'newPule':
                if (data.type) {
                  logString = t('commonPuleAdded');
                } else {
                  logString = t('privatePuleAdded', { player: data.player });
                }
                break;

              case 'puleChange':
                if (data.changeTo === 'P-') {
                  logString = t('roundPuleChanged2', { game: data.party, pule: data.changeTo });
                } else {
                  logString = t('roundPuleChangedPersonal', { game: data.party, pule: data.changeTo });
                }
                break;

              case 'updatePoints':
                if (data.pointsChange < 0) {
                  logString = t('updatePointsNegative', { player: data.playerName, points: Math.abs(data.pointsChange), balance: Math.abs(data.balChange) });
                } else {
                  logString = t('updatePoints', { player: data.playerName, points: data.pointsChange, balance: data.balChange });
                }
                break;
              case 'leftRoomPenalty':
                let pointsChange = null;
                let balChange = null;

                let playersNames = {};
                Object.keys(players).map((key) => {
                  playersNames[players[key].uid] = players[key].name;
                });

                if (data && data.fullData && data.playerUid) {
                  Object.keys(data.fullData).map((key) => {
                    const pointsData = data.fullData[key];
                    const playerName = playersNames[key];

                    if (pointsData) {
                      balChange = pointsData.change;
                      pointsChange = pointsData.newPoints - pointsData.oldPoints;
                    }

                    if ((data.pointsChange || pointsChange) < 0) {
                      logString = (logString || '') + `${t('updatePointsNegative', { player: playerName, points: Math.abs(data.pointsChange || pointsChange), balance: Math.abs(data.balChange || balChange) })} \n`;
                    } else {
                      logString = (logString || '') + `${t('updatePoints', { player: playerName, points: data.pointsChange || pointsChange, balance: data.balChange || balChange })}\n`;
                    }
                  });

                  /* if ((data.pointsChange || pointsChange) < 0) {
                    logString = `${t('updatePointsNegative', { player: data.playerName, points: data.pointsChange || pointsChange, balance: data.balChange || balChange })} \n`;
                  } else {
                    logString = `${t('updatePoints', { player: data.playerName, points: data.pointsChange || pointsChange, balance: data.balChange || balChange })}\n`;
                  } */
                }
              break;

              default: break;
            }

            return (
              <>
                <Row className={hasDivider ? `end-result-party-modal-divide-${screenMode}` : ''}>
                  <Col sm="2" className="end-result-party-modal-party-log-time">
                    <Moment format="HH:mm:ss" locale="lv">
                      {logData.time}
                    </Moment>
                  </Col>
                  <Col sm="10">
                    <div>
                      { renderTextWithLineBreaksAndSymbolColor(logString) }
                    </div>
                  </Col>
                </Row>
                
                <Row>
                  { logData.type === 'cardsDealt' && (
                    <Col xs="12" className="py-2">
                      {Object.keys(players).map(key => {
                        console.log('logDatalogData', logData);
                        if (key === 'playerList' || !Object.keys(players[key]).length) { return null; }
                        return (
                          <div key={key} className="end-result-party-modal-cards">
                            <div className="end-result-party-modal-cards-photo-container"> {/* left part, photo only */}
                              <Media src={players[key].photo ? players[key].photo : defaultImage} className={`end-result-party-modal-cards-photo-container-img ${!players[key].photo && `end-result-party-modal-cards-photo-container-img-default-${screenMode}`}`} />
                            </div>
                            <div className="end-result-party-modal-cards-info"> {/* right part, name and cards */}
                              <div className="end-result-party-modal-cards-info-title">
                                {trimString(players[key].name, 14)}
                              </div>
                              <div className="end-result-party-modal-cards-info-card-container">
                                {(logData.data[key] && logData.data[key].cards) ? logData.data[key].cards.map((cardKey, cardIndex) => {
                                  return (
                                    <div key={`${key}-${cardIndex}`} className={`end-result-party-modal-cards-info-card-container-wrapper end-result-party-modal-cards-info-card-container-wrapper-${cardIndex}`}>
                                      <Media src={getCardImage(cardKey)} className="end-result-party-modal-cards-info-card-container-wrapper-card" />
                                    </div>
                                  );
                                }) : (null)}

                                {(logData.data[key] && logData.data[key].visible) ? logData.data[key].visible.map((cardKey, cardIndex) => {
                                  return (
                                    <div key={`${key}-${cardIndex}`} className={`end-result-party-modal-cards-info-card-container-wrapper end-result-party-modal-cards-info-card-container-wrapper-${4+cardIndex}`}>
                                      <Media src={getCardImage(cardKey)} className="end-result-party-modal-cards-info-card-container-wrapper-card" />
                                    </div>
                                  );
                                }) : (null)}

                                {(logData.data[key] && logData.data[key].hidden) ? logData.data[key].hidden.map((cardKey, cardIndex) => {
                                  return (
                                    <div key={`${key}-${cardIndex}`} className={`end-result-party-modal-cards-info-card-container-wrapper end-result-party-modal-cards-info-card-container-wrapper-${8+cardIndex}`}>
                                      <Media src={getCardImage(cardKey)} className="end-result-party-modal-cards-info-card-container-wrapper-card" />
                                    </div>
                                  );
                                }) : (null)}
                              </div>
                            </div>
                          </div>
                        );
                      })}

                      <div className="end-result-party-modal-cards-table">
                        <div className="end-result-party-modal-cards-info-table"> {/* right part, name and cards */}
                          <div className="end-result-party-modal-cards-info-table-title">
                            {t('previousRound:tableCards')}
                          </div>
                          <div className="end-result-party-modal-cards-info-card-container">
                            {logData.data.cardsOnTable ? logData.data.cardsOnTable.map((cardKey, cardIndex) => {
                              return (
                                <div key={`cardsOnTable-${cardIndex}`} className={`end-result-party-modal-cards-info-card-container-wrapper end-result-party-modal-cards-info-card-container-wrapper-${cardIndex}`}>
                                  <Media src={getCardImage(cardKey)} className="end-result-party-modal-cards-info-card-container-wrapper-card" />
                                </div>
                              );
                            }):(null)}
                          </div>
                        </div>
                      </div>
                    </Col>
                  )}
                </Row>

              </>
            );
          })}
        </div>
      </>
  );
});

PartyLog.propTypes = {
  currentTable: PropTypes.arrayOf(PropTypes.shape()),
  cardsAdded: PropTypes.shape(),
  myPos: PropTypes.string,
  tournamentRound: PropTypes.string,
  screenMode: PropTypes.string,
};

PartyLog.defaultProps = {
  currentTable: [],
  cardsAdded: {},
  myPos: '',
  screenMode: SCREEN_MODE.normal,
};

const mapStateToProps = (state, ownProps) => {
  if (state.game) {
    return ({
      myPos: state.game.myPos || null,
      globalParams: state.game.globalParams || {},
      players: state.game.players,
    });
  }
  return null;
};

export default connect(null, null)(PartyLog);
