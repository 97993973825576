import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import Row from 'reactstrap/lib/Row';

import isEqual from 'react-fast-compare';

import myTurnSound from '../../../../../sounds/my_turn4.mp3';
import dealingCardsSound from '../../../../../sounds/dealing_card.mp3';

import PlayerCard from '../Cards/PlayerCard';
import VisibleCard from './VisibleCard';

const erci = ['♥-9', '♥-K', '♥-10', '♥-A'];
const kreisti = ['♣︎-9', '♣︎-K', '♣︎-10', '♣︎-A'];
const piki = ['♠︎-9', '♠︎-K', '♠︎-10', '♠︎-A'];
const trumpji = ['♦︎-7', '♦︎-8', '♦︎-9', '♦︎-K', '♦︎-10', '♦︎-A', '♦︎-J', '♥-J', '♠︎-J', '♣︎-J', '♦︎-Q', '♥-Q', '♠︎-Q', '♣︎-Q'];

const cardOrder = ['♥-9', '♥-K', '♥-10', '♥-A', '♠︎-9', '♠︎-K', '♠︎-10', '♠︎-A', '♣︎-9', '♣︎-K', '♣︎-10', '♣︎-A', '♦︎-7', '♦︎-8', '♦︎-9', '♦︎-K', '♦︎-10', '♦︎-A', '♦︎-J', '♥-J', '♠︎-J', '♣︎-J', '♦︎-Q', '♥-Q', '♠︎-Q', '♣︎-Q'];

class PlayerCards extends React.Component {
  static propTypes = {
    cards: PropTypes.arrayOf(PropTypes.string),
    gameState: PropTypes.string,
    playCard: PropTypes.func.isRequired,
    currentTurnUid: PropTypes.string,
    memberUid: PropTypes.string,
    currentTable: PropTypes.arrayOf(PropTypes.shape()),
    selectedCard: PropTypes.string,
    doubleClickSelectedCard: PropTypes.shape(),
    currentTurn: PropTypes.string,
    soundOn: PropTypes.bool,
    myPos: PropTypes.string,
    tableIsInProgress: PropTypes.bool.isRequired,
    cardsOnTable: PropTypes.arrayOf(PropTypes.string),
    currentType: PropTypes.string,
    largePlayer: PropTypes.string,
    cardsOnLoading: PropTypes.bool,
  }

  static defaultProps = {
    cards: [],
    gameState: null,
    currentTurnUid: null,
    memberUid: null,
    currentTable: null,
    selectedCard: null,
    doubleClickSelectedCard: null,
    currentTurn: null,
    soundOn: false,
    myPos: null,
    cardsOnTable: [],
    currentType: null,
    largePlayer: null,
    cardsOnLoading: true,
  }

  constructor(props) {
    super(props);
    this.state = {
      cards: [],
      playSound: false,
      hasPlayedCard: false,
    };

    this.myTurnAudio = new Audio(myTurnSound);
    this.dealingCardsAudio = new Audio(dealingCardsSound);

    window.addEventListener('resize', this.handleResize);
  }

  componentDidMount() {
    const {
      cards,
      visibleCards,
      otherVisibleCards,
      currentTable,
      memberUid,
      currentTurnUid,
    } = this.props;

    this.handleResize();

    const cardsClone = [...cards];
    const visibleCardsClone = [...visibleCards];
    const otherVisibleCardsClone = [...otherVisibleCards];

    cardsClone.sort((a, b) => cardOrder.indexOf(b) - cardOrder.indexOf(a));

    const mappedOtherVisibleCards = otherVisibleCardsClone.map(card => ({ card, allowed: false }));

    if (currentTurnUid && memberUid
      && currentTurnUid.toString() === memberUid.toString()) {
      if (currentTable && currentTable.length === 0) {
        const mappedCards = cardsClone.map(card => ({ card, allowed: true }));
        const mappedVisibleCards = visibleCardsClone.map(card => ({ card, allowed: true }));

     //   console.log('mappedCards', mappedCards);

        this.setState({ cards: [...mappedCards], visibleCards: [...mappedVisibleCards], otherVisibleCards: [...mappedOtherVisibleCards] });
      } else {
        const firstCard = currentTable[0];

        let playerHasCards1 = [];
        let playerHasCards2 = [];

        if (firstCard.card && kreisti.indexOf(firstCard.card) !== -1) {
          playerHasCards1 = cardsClone.filter(value => kreisti.includes(value));
          playerHasCards2 = visibleCardsClone.filter(value => kreisti.includes(value));
        } else if (firstCard.card && erci.indexOf(firstCard.card) !== -1) {
          playerHasCards1 = cardsClone.filter(value => erci.includes(value));
          playerHasCards2 = visibleCardsClone.filter(value => erci.includes(value));
        } else if (firstCard.card && piki.indexOf(firstCard.card) !== -1) {
          playerHasCards1 = cardsClone.filter(value => piki.includes(value));
          playerHasCards2 = visibleCardsClone.filter(value => piki.includes(value));
        } else if (firstCard.card && trumpji.indexOf(firstCard.card) !== -1) {
          playerHasCards1 = cardsClone.filter(value => trumpji.includes(value));
          playerHasCards2 = visibleCardsClone.filter(value => trumpji.includes(value));
        }

       // console.log('playerHasCards2', playerHasCards2, playerHasCards1);

        const playerHasCards = [...playerHasCards1, ...playerHasCards2];

        const mappedCards = cardsClone.map((card) => {
          if (playerHasCards && playerHasCards.length === 0) {
            return { card, allowed: true };
          }
          if (playerHasCards && playerHasCards.length > 0 && playerHasCards.includes(card)) {
            return { card, allowed: true };
          }
          return { card, allowed: false };
        });

        const mappedVisibleCards = visibleCardsClone.map(card => {
          if (playerHasCards && playerHasCards.length === 0) {
            return { card, allowed: true };
          }
          if (playerHasCards && playerHasCards.length > 0 && playerHasCards.includes(card)) {
            return { card, allowed: true };
          }
          return { card, allowed: false };
        });

      //  console.log('mappedCards', mappedCards);

        this.setState({ cards: [...mappedCards], visibleCards: [...mappedVisibleCards], otherVisibleCards: [...mappedOtherVisibleCards] });
      }
    } else {
      const mappedCards = cardsClone.map(card => ({ card, allowed: false }));
      const mappedVisibleCards = visibleCardsClone.map(card => ({ card, allowed: false }));

    //  console.log('mappedCards', mappedCards);

      this.setState({ cards: [...mappedCards], visibleCards: [...mappedVisibleCards], otherVisibleCards: [...mappedOtherVisibleCards] });

      // this.setState({ cards: [...mappedCards] });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const {
      tableIsInProgress,
      selectedCard,
      gameState,
      currentTurnUid,
      memberUid,
      currentTable,
      cards,
      visibleCards,
      otherVisibleCards,
      cardsOnTable,
    } = this.props;

    const {
      hoveredCard,
      cards: stateCards,
      visibleCards: visibleStateCards,
      otherVisibleCards: otherVisibleStateCards,
      width,
      height,
    } = this.state;

    if (nextProps.tableIsInProgress !== tableIsInProgress) {
      return true;
    }

    if (nextProps.selectedCard !== selectedCard) {
      return true;
    }

    if (nextProps.gameState !== gameState) {
      return true;
    }

    if (nextProps.currentTurnUid !== currentTurnUid) {
      return true;
    }

    if (nextProps.memberUid !== memberUid) {
      return true;
    }

    if (!isEqual(nextProps.currentTable, currentTable)) {
      return true;
    }


    if (!isEqual(nextState.hoveredCard, hoveredCard)) {
      return true;
    }

    if (!isEqual(nextState.cards, stateCards)) {
      return true;
    }

    if (!isEqual(nextProps.cards, cards)) {
      return true;
    }

    if (!isEqual(nextState.visibleCards, visibleStateCards)) {
      return true;
    }

    if (!isEqual(nextProps.visibleCards, visibleCards)) {
      return true;
    }

    if (!isEqual(nextState.otherVisibleCards, otherVisibleStateCards)) {
      return true;
    }

    if (!isEqual(nextProps.otherVisibleCards, otherVisibleCards)) {
      return true;
    }

    if (nextState.width !== width) {
      return true;
    }

    if (nextState.height !== height) {
      return true;
    }

    if (!isEqual(nextProps.cardsOnTable, cardsOnTable)) {
      return true;
    }

    return false;
  }

  componentDidUpdate(prevProps) {
    const {
      cards,
      visibleCards,
      otherVisibleCards,
      currentTurnUid,
      memberUid,
      currentTable,
      currentTurn,
      soundOn,
      tableIsInProgress,
      gameState,
      myPos,
      //  isFullscreen,
    } = this.props;

    const {
      playSound, hasPlayedCard, cards: prevCards,
    } = this.state;

    const turnChanged = prevProps.currentTurn !== currentTurn;

    const cardsClone = [...cards];
    const visibleCardsClone = [...visibleCards];
    const otherVisibleCardsClone = [...otherVisibleCards];

    cardsClone.sort((a, b) => cardOrder.indexOf(b) - cardOrder.indexOf(a));

    const mappedOtherVisibleCards = otherVisibleCardsClone.map(card => ({ card, allowed: false }));

    if (soundOn && playSound && !tableIsInProgress) {
      this.setState({ playSound: false });

      setTimeout(() => {
        const playPromise = this.myTurnAudio.play();
        if (playPromise !== undefined) {
          playPromise
            .then(_ => {
              // Automatic playback started!
              // Show playing UI.
              console.log("audio played auto");
            })
            .catch(error => {
              // Auto-play was prevented
              // Show paused UI.
              console.log("playback prevented");
            });
        }
      }, 350);
    }

    if (soundOn && ((cards && cards.length === 4 && gameState === 'results' && prevProps.gameState !== gameState) || (cards && cards.length === 4 && cards.length !== prevProps.cards.length && gameState === 'results'))) {
      try {
        const playPromise = this.dealingCardsAudio.play();
        if (playPromise !== undefined) {
          playPromise
            .then(_ => {
              // Automatic playback started!
              // Show playing UI.
              console.log("audio played auto");
            })
            .catch(error => {
              // Auto-play was prevented
              // Show paused UI.
              console.log("playback prevented");
            });
        }
      } catch (err) {
        console.log(err);
      }
    }
    let dataToUpdate = null;

    if (currentTurnUid && memberUid
      && currentTurnUid.toString() === memberUid.toString()) {
      if (currentTable && currentTable.length === 0) {
        const mappedCards = cardsClone.map(card => ({ card, allowed: true }));
        const mappedVisibleCards = visibleCardsClone.map(card => ({ card, allowed: true }));

      //  console.log('mappedCards', mappedCards);

        if (turnChanged && gameState === 'play') {
          dataToUpdate = { cards: [...mappedCards], visibleCards: [...mappedVisibleCards], otherVisibleCards: [...mappedOtherVisibleCards], playSound: true, hasPlayedCard: false };
        } else {
          dataToUpdate = { cards: [...mappedCards], visibleCards: [...mappedVisibleCards], otherVisibleCards: [...mappedOtherVisibleCards], hasPlayedCard: false };
        }
      } else if (currentTable) {
        const firstCard = currentTable[0];

        const lastCard = currentTable[currentTable.length - 1];

        if (lastCard && lastCard.player && lastCard.player === myPos) {
          // const mappedCards = cardsClone.map(card => ({ card, allowed: false }));
          const mappedCards = cardsClone.map(card => ({ card, allowed: false }));
          const mappedVisibleCards = visibleCardsClone.map(card => ({ card, allowed: false }));

       //   console.log('mappedCards', mappedCards);

          dataToUpdate = { cards: [...mappedCards], visibleCards: [...mappedVisibleCards], otherVisibleCards: [...mappedOtherVisibleCards], hasPlayedCard: true };
        } else if (firstCard) {
          // let playerHasCards = [];
          let playerHasCards1 = [];
          let playerHasCards2 = [];

          if (firstCard && firstCard.card) {
            if (kreisti.indexOf(firstCard.card) !== -1) {
              // playerHasCards = cardsClone.filter(value => kreisti.includes(value));
              playerHasCards1 = cardsClone.filter(value => kreisti.includes(value));
              playerHasCards2 = visibleCardsClone.filter(value => kreisti.includes(value));
            } else if (erci.indexOf(firstCard.card) !== -1) {
              // playerHasCards = cardsClone.filter(value => erci.includes(value));
              playerHasCards1 = cardsClone.filter(value => erci.includes(value));
              playerHasCards2 = visibleCardsClone.filter(value => erci.includes(value));
            } else if (piki.indexOf(firstCard.card) !== -1) {
              // playerHasCards = cardsClone.filter(value => piki.includes(value));
              playerHasCards1 = cardsClone.filter(value => piki.includes(value));
              playerHasCards2 = visibleCardsClone.filter(value => piki.includes(value));
            } else if (firstCard.card && trumpji.indexOf(firstCard.card) !== -1) {
              // playerHasCards = cardsClone.filter(value => trumpji.includes(value));
              playerHasCards1 = cardsClone.filter(value => trumpji.includes(value));
              playerHasCards2 = visibleCardsClone.filter(value => trumpji.includes(value));
            }
          }

        //  console.log('playerHasCards2', playerHasCards2, playerHasCards1);

          const playerHasCards = [...playerHasCards1, ...playerHasCards2];

          const mappedCards = cardsClone.map((card) => {
            if (playerHasCards && playerHasCards.length === 0) {
              return { card, allowed: true };
            }
            if (playerHasCards && playerHasCards.length > 0 && playerHasCards.includes(card)) {
              return { card, allowed: true };
            }
            return { card, allowed: false };
          });

          const mappedVisibleCards = visibleCardsClone.map(card => {
            if (playerHasCards && playerHasCards.length === 0) {
              return { card, allowed: true };
            }
            if (playerHasCards && playerHasCards.length > 0 && playerHasCards.includes(card)) {
              return { card, allowed: true };
            }
            return { card, allowed: false };
          });

        //  console.log('mappedCards', mappedCards);

          if (turnChanged && gameState === 'play') {
            dataToUpdate = { cards: [...mappedCards], visibleCards: [...mappedVisibleCards], otherVisibleCards: [...mappedOtherVisibleCards], playSound: true, hasPlayedCard: false };
          } else {
            dataToUpdate = { cards: [...mappedCards], visibleCards: [...mappedVisibleCards], otherVisibleCards: [...mappedOtherVisibleCards], hasPlayedCard: false };
          }
        } else {
          const mappedCards = cardsClone.map(card => ({ card, allowed: true }));
          const mappedVisibleCards = visibleCardsClone.map(card => ({ card, allowed: true }));

       //   console.log('mappedCards', mappedCards);

          if (turnChanged && gameState === 'play') {
            dataToUpdate = { cards: [...mappedCards], visibleCards: [...mappedVisibleCards], otherVisibleCards: [...mappedOtherVisibleCards], playSound: true, hasPlayedCard: false };
          } else {
            dataToUpdate = { cards: [...mappedCards], visibleCards: [...mappedVisibleCards], otherVisibleCards: [...mappedOtherVisibleCards], hasPlayedCard: false };
          }
        }
      }
    } else {
      // const mappedCards = cardsClone.map(card => ({ card, allowed: false }));

      const mappedCards = cardsClone.map(card => ({ card, allowed: false }));
      const mappedVisibleCards = visibleCardsClone.map(card => ({ card, allowed: false }));

   //   console.log('mappedCards', mappedCards);

      dataToUpdate = { cards: [...mappedCards], visibleCards: [...mappedVisibleCards], otherVisibleCards: [...mappedOtherVisibleCards], hasPlayedCard: false };
    }

    if (dataToUpdate) {
     // console.log('dataToUpdate', dataToUpdate);
      //  console.log('dataToUpdate', {
      //    dataToUpdate, cardsClone, currentTurnUid, currentTable: currentTable.length,
      //  });
      if (isEqual(dataToUpdate.cards, prevCards)) {
      //  console.log('delete cards');
        // delete dataToUpdate.cards;

        if (hasPlayedCard === dataToUpdate.hasPlayedCard) {
          delete dataToUpdate.hasPlayedCard;
        }

        this.setState(dataToUpdate);
      } else if (dataToUpdate.cards && prevCards && (dataToUpdate.cards.length || dataToUpdate.cards.length === 0) && prevCards.length && dataToUpdate.cards.length === prevCards.length - 1) {
        //  console.log('cards updated with one removed');
        setTimeout(() => {
          this.setState(dataToUpdate);
        }, 10);
      } else {
        this.setState(dataToUpdate);
      }
    }
  }

  handleResize = () => {
    const { width, height } = this.state;

    const { innerWidth: newWidth, innerHeight: newHeight } = window;

    if (!width || width - 10 > newWidth || width + 10 < newWidth) {
      this.setState({ width: newWidth, height: newHeight });
    } else if (!height || height - 10 > newHeight || height + 10 < newHeight) {
      this.setState({ width: newWidth, height: newHeight });
    }
  }

  changeHoveredCard = (index, card, type, allowed) => {
    const { gameState } = this.props;

    //  console.log('changeHoveredCard', { card, index });

    if (allowed && type === 'over' && gameState !== 'choose') {
      this.setState({ hoveredCard: index });
    } else {
      this.setState({ hoveredCard: null });
    }
  }

  renderCards = (visibleCards, hiddenCards, nonPlayable) => {
    const {
      gameState,
      playCard,
      selectedCard,
      tableIsInProgress,
      memberUid,
      currentTurnUid,
      doubleClickSelectedCard,
      cardsOnTable,
      currentType,
      largePlayer,
      myPos,
      cardsOnLoading,
    } = this.props;

    const {
      cards,
      hasPlayedCard,
      hoveredCard,
    } = this.state;

    const items = [];

  //  console.log('render cards', visibleCards, hiddenCards, cards);

    if (visibleCards) {
      visibleCards.map((card, index) => {
      //  console.log('hiddenCards[index]', hiddenCards[index]);
        items.push(
          <VisibleCard
            isVisibleCard={true}
            hasHiddenCard={hiddenCards[index] ? true : false}
            card={card.card}
            allowed={nonPlayable || card.allowed}
            nonPlayable={nonPlayable}
            index={index}
            cardsLength={cards.length}
            gameState={gameState}
            playCard={playCard}
            selectedCard={selectedCard}
            tableIsInProgress={tableIsInProgress}
            memberUid={memberUid}
            currentTurnUid={currentTurnUid}
            doubleClickSelectedCard={doubleClickSelectedCard ? doubleClickSelectedCard.card : null}
            hasPlayedCard={hasPlayedCard}
            hoveredCard={nonPlayable ? null : hoveredCard}
            changeHoveredCard={this.changeHoveredCard}
            cardsOnTable={cardsOnTable}
            currentType={currentType}
            largePlayer={largePlayer}
            myPos={myPos}
            cardsOnLoading={cardsOnLoading}
          />
        )
        return null;
      });
    }

    return items;
  }

  renderCards2 = () => {
    const {
      gameState,
      playCard,
      selectedCard,
      tableIsInProgress,
      memberUid,
      currentTurnUid,
      doubleClickSelectedCard,
      cardsOnTable,
      currentType,
      largePlayer,
      myPos,
      cardsOnLoading,
    } = this.props;

    const {
      cards,
      hasPlayedCard,
      hoveredCard,
    } = this.state;

  //  console.log('renderCards2',{ cards });

    const items = [];

    if (cards) {
      cards.map((card, index) => {
        items.push(
          <PlayerCard
            card={card.card}
            allowed={card.allowed}
            index={index}
            cardsLength={cards.length}
            gameState={gameState}
            playCard={playCard}
            selectedCard={selectedCard}
            tableIsInProgress={tableIsInProgress}
            memberUid={memberUid}
            currentTurnUid={currentTurnUid}
            doubleClickSelectedCard={doubleClickSelectedCard ? doubleClickSelectedCard.card : null}
            hasPlayedCard={hasPlayedCard}
            hoveredCard={hoveredCard}
            changeHoveredCard={this.changeHoveredCard}
            cardsOnTable={cardsOnTable}
            currentType={currentType}
            largePlayer={largePlayer}
            myPos={myPos}
            cardsOnLoading={cardsOnLoading}
          />
        )
        return null;
      });
    }

    return items;
  }

  render() {
    const {
      gameState,
      hiddenCards,
      // visibleCards,
      otherHiddenCards,
      currentTable,
      // otherVisibleCards,
    } = this.props;

    const {
      visibleCards,
      otherVisibleCards,
    } = this.state;

    console.log('visibleCardsvisibleCards', visibleCards, hiddenCards, otherVisibleCards, otherHiddenCards)

    return (
      <>
      <div
        className={`cards2 student-zole-cards`}
      >
          <Row style={{ position: 'relative', top: 45 }}>
          {this.renderCards(otherVisibleCards, otherHiddenCards, true)}
        </Row>
        <Row style={{ position: 'relative', top: 185 }}>
          {this.renderCards(visibleCards, hiddenCards, false)}
        </Row>
      </div>
      <div
        className={`cards cards2 ${(gameState && gameState === 'choose') ? 'cards2-choose' : ''} ${(currentTable && currentTable.length && gameState === 'results') ? 'display-none' : ''}`}
      >
        {this.renderCards2()}
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  memberUid: state.member.uid,
  //  cards:state.game.cards,
  currentTurn: state.game.currentTurn,
  currentTurnUid: (state.game.currentTurn && state.game.players && state.game.players[state.game.currentTurn]) ? state.game.players[state.game.currentTurn].uid : '',
  currentTable: state.game.currentTable,
  myPos: state.game.myPos,
  largePlayer: state.game.largePlayer,
  gameState: state.game.globalParams.gameState,
  cardsOnTable: state.game.cardsOnTable,
  currentType: state.game.currentType,
});

// const mapDispatchToProps = {

// };

export default connect(mapStateToProps, null)(PlayerCards);
