import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';

import Lottie from 'react-lottie';

import { connect } from 'react-redux';
import { addPaymentInfo, updatePaymentInfoStatus } from '../../../actions/shop';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Media from 'reactstrap/lib/Media';
import * as constants from '../../../constants/constants';

import bonusPageImg from '../../../images/icons/gift.svg';
import shopImg from '../../../images/icons/shoping_cart.webp';

import lowBalAnimImg from '../../../images/Menu/lowBal.json';

class HeaderRight extends React.Component {
  static propTypes = {
    balance: PropTypes.number,
    offset: PropTypes.number,
    lastBonusSpin: PropTypes.number,
    changeTab: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    //  i18n: PropTypes.shape({}).isRequired,
    activeTab: PropTypes.string,
    setAddPaymentInfo: PropTypes.func.isRequired,
    setHandlePaymentInfoStatus: PropTypes.func.isRequired,
    name: PropTypes.string,
    uid: PropTypes.string,
  }

  static defaultProps = {
    activeTab: '',
    balance: null,
    offset: 0,
    lastBonusSpin: null,
    name: '',
    uid: '',
  }

  constructor(props) {
    super(props);
    this.state = {
      playLowBalAnim: false,
    };
  }

  componentDidMount() {
    const { balance } = this.props;

    if (balance < 162) {
      setTimeout(() => {
        this.setState({ playLowBalAnim: true });
      }, 1500);
    }
  }

  componentDidUpdate() {
    const { balance } = this.props;

    if (balance < 162) {
      setTimeout(() => {
        this.setState({ playLowBalAnim: true });
      }, 1500);
    }
  }

  handleToBuyPage = () => {
    const { changeTab, setAddPaymentInfo, name, uid } = this.props;
    changeTab(constants.MENU_NAVIGATION.buyPageMoneyPacks);

    setAddPaymentInfo({
      uid,
      name,
      source: constants.PAYMENT_SOURCE.shop,
    })
  }

  render() {
    const {
      changeTab, activeTab, t, balance, lastBonusSpin, offset,
    } = this.props;

    const { playLowBalAnim } = this.state;

    const notSpined = (lastBonusSpin && new Date(lastBonusSpin).setHours(0, 0, 0, 0) !== new Date(Date.now() + offset).setHours(0, 0, 0, 0)) || (!lastBonusSpin);

    return (
      <Fragment>
        <Row className="header-right">
          <Col xs="6" sm="6" className="header-right-daily bonus-tab" onClick={() => changeTab('4')}>
            <div className={`daily-bonus ${activeTab === '4' && 'active'} ${notSpined && 'not-spined'}`}>
              <div className="daily-bonus-wrapper d-flex align-items-center">
                <Media src={bonusPageImg} className="daily-bonus-image" />
                <div className="daily-bonus-text">
                  {t('headerRight.dailyBonus')}
                </div>
              </div>
            </div>
          </Col>
          <Col xs="6" sm="6" className="header-right-buy shop-tab" onClick={() => this.handleToBuyPage()}>
            <div className={`buy-button ${(constants.BUY_PAGE_TABS.includes(activeTab)) && 'active'}`}>
              <div className="buy-button-wrapper d-flex align-items-center">
                <Media src={shopImg} className="buy-button-image" />
                <div className="buy-button-text">
                  {t('headerRight.buy')}
                </div>

                {(balance < 162) && (
                <div
                  className="low-bal-anim-image"
                >
                  <Lottie
                    options={{
                      loop: false,
                      autoplay: true,
                      animationData: lowBalAnimImg,
                      rendererSettings: {
                        preserveAspectRatio: 'xMidYMid slice',
                      },
                    }}
                    height={50}
                    width={50}
                    isStopped={false}
                    isPaused={!playLowBalAnim}
                  />
                </div>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  balance: parseFloat(state.member.balance),
  lastBonusSpin: state.member.lastBonusSpin,
  offset: state.member.offset || 0,
  name: state.member.name,
  uid: state.member.uid,
});

const mapDispatchToProps = {
  setAddPaymentInfo: addPaymentInfo,
  setHandlePaymentInfoStatus: updatePaymentInfoStatus,
};


export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(HeaderRight));
