import React from 'react';
import PropTypes from 'prop-types';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Media from 'reactstrap/lib/Media';
import Button from 'reactstrap/lib/Button';

import { connect } from 'react-redux';
import PlayerMobileProfile from './PlayerProfile';

import dailyBonusButton from '../../../../../../images/redesign/Daily bonus button.svg';
import shopButton from '../../../../../../images/redesign/Shop button.webp';
import { MENU_NAVIGATION, PAYMENT_SOURCE } from '../../../../../../constants/constants';
import { addPaymentInfo } from '../../../../../../actions/shop';

const LayoutMobileFooter = React.memo(({
  t,
  changeTab,
  screenMode,
  toggleScreenMode,
  requestDeleteUser,
  updateProfile,
  userAvatarFileUpload,
  name,
  uid,
  setAddPaymentInfo,
}) => {
  const handleToBuyPage = () => {
    changeTab(MENU_NAVIGATION.buyPageMoneyPacks);

    setAddPaymentInfo({
      uid,
      name,
      source: PAYMENT_SOURCE.shop,
    });
  };

  return (
    <Row className={`layout-mobile-footer layout-mobile-footer-${screenMode}`}>
      <Col xs="4" className="layout-mobile-footer-bonus">
        <div className="layout-mobile-footer-link layout-mobile-footer-bonus-link">
          <Media className="layout-mobile-footer-link-image" src={dailyBonusButton} onClick={() => changeTab('4')} />
          <Button className="layout-mobile-footer-link-button" onClick={() => changeTab('4')}>
            {t('headerRight.mobile_dailyBonus')}
          </Button>
        </div>
      </Col>
      <Col xs="4" className="layout-mobile-footer-profile">
        <PlayerMobileProfile t={t} screenMode={screenMode} toggleScreenMode={toggleScreenMode} toggle={changeTab} requestDeleteUser={requestDeleteUser} updateProfile={updateProfile} userAvatarFileUpload={userAvatarFileUpload} />
      </Col>
      <Col xs="4" className="layout-mobile-footer-shop">
        <div className="layout-mobile-footer-link layout-mobile-footer-shop-link">
          <Media className="layout-mobile-footer-link-image" src={shopButton} onClick={() => handleToBuyPage()} />
          <Button className="layout-mobile-footer-link-button" onClick={() => changeTab(MENU_NAVIGATION.buyPageMoneyPacks)}>
            {t('headerRight.mobile_buy')}
          </Button>
        </div>
      </Col>
    </Row>
  );
});

LayoutMobileFooter.propTypes = {
  t: PropTypes.func.isRequired,
  changeTab: PropTypes.func.isRequired,
  screenMode: PropTypes.string,
  toggleScreenMode: PropTypes.func.isRequired,
  name: PropTypes.string,
  uid: PropTypes.string,
  setAddPaymentInfo: PropTypes.func.isRequired,
};

LayoutMobileFooter.defaultProps = {
  name: '',
  uid: '',
};

const mapStateToProps = state => ({
  name: state.member.name,
  uid: state.member.uid,
});

const mapDispatchToProps = {
  setAddPaymentInfo: addPaymentInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(LayoutMobileFooter);
