import React from 'react';
import PropTypes from 'prop-types';

import CircularProgressbar from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import { connect } from 'react-redux';


import {
  roomDataFallbackNew,
} from '../../../actions/game';

import {
  getTimeOffset,
} from '../../../actions/member';

class TurnTimer extends React.Component {
  static propTypes = {
    nextTimeStamp: PropTypes.number,
    endRoom: PropTypes.func.isRequired,
    closeResultModal: PropTypes.func.isRequired,
    offset: PropTypes.number,
    gameResultModalShown: PropTypes.bool,
    disableTimer: PropTypes.bool,
    fastGame: PropTypes.bool,
    lightningGame: PropTypes.bool,
    unlimitedGame: PropTypes.bool,
    roomClosed: PropTypes.bool,
    gameState: PropTypes.string,
    speed: PropTypes.number,
    isRunning: PropTypes.bool,
    roomId: PropTypes.string,
    currentTurn: PropTypes.string,
    myPos: PropTypes.string,
    showEndResultModal: PropTypes.bool,
    getOffset: PropTypes.func.isRequired,
    fetchRoomDataFallback: PropTypes.func.isRequired,
  };

  static defaultProps = {
    nextTimeStamp: null,
    offset: 0,
    gameResultModalShown: false,
    disableTimer: null,
    fastGame: null,
    lightningGame: null,
    unlimitedGame: null,
    roomClosed: false,
    gameState: null,
    speed: 25,
    isRunning: null,
    roomId: null,
    currentTurn: null,
    myPos: null,
    showEndResultModal: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      percentage: 100,
      timer: '',
      closeResultModalCalled: false,
      endRoomCalled: false,
    };
  }

  componentDidMount() {
    const { lightningGame, unlimitedGame, fastGame } = this.props;

    let nextTimerTime;

    let resultsSpeed = 6;

    if (lightningGame) {
      resultsSpeed = 2;
    } else if (fastGame) {
      resultsSpeed = 3;
    } else if (unlimitedGame) {
      resultsSpeed = 7;
    }

    if (this.props.isRunning) {
      if (this.props.nextTimeStamp && this.props.nextTimeStamp !== null && (this.props.offset || this.props.offset === 0)) {
        nextTimerTime = ((this.props.nextTimeStamp - (Date.now() + this.props.offset - 500)) / 1000);

      //  console.log('nextTimerTime', nextTimerTime);

        this.setState({
          percentage: (nextTimerTime / (this.props.gameState === 'results' ? resultsSpeed : this.props.speed)) * 100,
          timer: Math.ceil(nextTimerTime),
        });
      } else {
        this.setState({
          timer: 0,
        });
      }
    }

    this.intervalID = setInterval(() => {
      const {
        nextTimeStamp, offset, gameState, isRunning, speed,
      } = this.props;

     // console.log('isRunning', isRunning, nextTimeStamp);

      if (isRunning) {
        if (nextTimeStamp && nextTimeStamp !== null && (offset || offset === 0)) {
          nextTimerTime = ((nextTimeStamp - (Date.now() + offset - 500)) / 1000);

        //  console.log('nextTimerTime', nextTimerTime);

          this.setState({
            percentage: (nextTimerTime / (gameState === 'results' ? resultsSpeed : speed)) * 100,
            timer: Math.ceil(nextTimerTime),
          });
        } else {
          this.setState({
            timer: 0,
          });
        }
      }
    }, 1000);
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { timer } = this.state;
    const nextTimer = nextState.timer;

    if ((timer || timer === 0) && (nextTimer || nextTimer === 0) && timer !== nextTimer) {
      return true;
    }

    return false;
  }

  componentDidUpdate(prevProps, prevState) {
    const { timer } = this.state;
    const prevTimer = prevState.timer;

    if (timer !== prevTimer) {
      const {
        roomClosed,
        gameState,
        nextTimeStamp,
        gameResultModalShown,
        disableTimer,
        currentTurn,
        myPos,
        fastGame,
        lightningGame,
        unlimitedGame,
        isRunning,
        speed,
        getOffset,
        showEndResultModal,
      } = this.props;

      /*  const { refetchLimit } = this.state;

      if (!refetchLimit) {
        this.setState({ refetchLimit: Math.round((speed / 2)) + 1 });
      } */

      let refetchLimit = Math.round((speed / 2));

      if (speed > 20) {
        refetchLimit = Math.round((speed / 3));
      }

      if (speed + 1 < timer) {
        getOffset();
      }

     // if (isRunning && currentTurn === null && ((timer === refetchLimit - 3) || timer === (refetchLimit + 3))) {
      //  this.fetchRoomDataFallback(true);
    //  }


      if (gameState === 'results') {
      //  if (currentTurn !== myPos && (timer === -4)) {
        //  this.fetchRoomDataFallback(false);
      //  }

       // if (timer === -1) {
        //  this.fetchRoomDataFallback(false);
      //  }

        console.log('call closeResultModal', nextTimeStamp, timer, gameResultModalShown, showEndResultModal);

        if (nextTimeStamp && timer < 0 && gameResultModalShown && !showEndResultModal) {
          this.closeResultModal();
        }
      } else {
        if (gameState === 'choose' && currentTurn !== myPos && (timer === refetchLimit)) {
          this.fetchRoomDataFallback(false);
        }

       /* if ((gameState === 'play' || gameState === 'burry') && !fastGame && currentTurn !== myPos && (timer === refetchLimit + 9 || timer === refetchLimit + 5)) {
        //  this.fetchRoomDataFallback(false);
        } else if (gameState === 'choose' && !fastGame && currentTurn !== myPos && (timer === refetchLimit + 8 || timer === refetchLimit + 5)) {
        //  this.fetchRoomDataFallback(false);
        } else if (currentTurn === myPos && timer === (((refetchLimit - 2) * 2) + 1)) {
        //  this.fetchRoomDataFallback(true);
        } else if (!fastGame && timer === refetchLimit - 6) {
        //  this.fetchRoomDataFallback(true);
        } else if (fastGame && currentTurn !== myPos && (timer === refetchLimit || timer === refetchLimit + 4)) {
        //  this.fetchRoomDataFallback(true);
        } else if (timer === refetchLimit + 1) {
        //  getOffset();
        //  fetchRoomDataFallback();
        } else if (timer === 3 || timer === -1 || timer === -5) {
        //  this.fetchRoomDataFallback(false);
        } */

        if (!roomClosed && nextTimeStamp && timer < 0 && !disableTimer) {
          this.endRoom();
          getOffset();
        }
      }
    }
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  fetchRoomDataFallback = (tryDb) => {
    const { fetchRoomDataFallback, roomId } = this.props;

    const { hostname } = window.location;

    if (roomId) {
      fetchRoomDataFallback(roomId, hostname, tryDb || false);
    }
  }

  closeResultModal = () => {
    const { closeResultModal } = this.props;
    const { closeResultModalCalled } = this.state;

    console.log('closeResultModal', closeResultModalCalled);

    if (!closeResultModalCalled) {
      this.setState({ closeResultModalCalled: true });

      closeResultModal();

      setTimeout(() => {
        this.setState({ closeResultModalCalled: false });
      }, 2000);
    }
  };

  endRoom = () => {
    const { endRoom } = this.props;
    const { endRoomCalled, timer } = this.state;

    if (!endRoomCalled) {
      let timeout = 4000;
      if (timer > -2 && timer <= 0) {
        timeout = 2000;
      } else if (timer > -3 && timer <= -2) {
        timeout = 4000;
      } else if (timer > -5 && timer <= -3) {
        timeout = 8000;
      } else if (timer > -6 && timer <= -5) {
        timeout = 12000;
      } else if (timer > -8 && timer <= -6) {
        timeout = 25000;
      } else if (timer <= -8) {
        timeout = 45000;
      }

      this.setState({ endRoomCalled: true });

      endRoom();

      setTimeout(() => {
        this.setState({ endRoomCalled: false });
      }, timeout);
    }
  };

  render() {
    const { speed, nextTimeStamp } = this.props;

    const {
      timer,
      percentage,
    } = this.state;

    if (!timer && timer !== 0) {
      return null;
    }

    if (!nextTimeStamp) {
      return null;
    }

   // console.log('percentage', timer, percentage);

    // progress={percentage < 0 ? 0 : percentage}

    return (
      <div className="turn-timer-wrapper">

        <CircularProgressbar
          percentage={percentage}
          text={`${timer < 0 ? '0' : (timer > speed ? speed : timer)}`}
          counterClockwise="true"
          className="timer"
          background
          styles={{
            path: {
              stroke: 'rgba(251,216,90)',
              strokeLinecap: 'butt',
              transition: `stroke-dashoffset ${percentage > 97 ? '0' : '1'}s linear 0s`,
              zIndex: 50,
            },
            trail: {
              stroke: 'transparent',
            },
            text: {
              fill: '#fff',
              fontSize: '32px',
              fontWeight: 600,
              zIndex: 30,
            },
            background: {
              fill: 'rgba(197,79,79,0.6)',
              zIndex: 10,
            },
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  offset: state.member.offset,
  nextTimeStamp: state.game.nextTimeStamp,
  gameState: state.game.globalParams.gameState,
  currentTurn: state.game.globalParams.currentTurn,
  closeReason: state.game.globalParams.closeReason,
  disableTimer: state.game.globalParams.disableTimer,
  fastGame: state.game.globalParams.fastGame,
  lightningGame: state.game.globalParams.lightningGame,
  unlimitedGame: state.game.globalParams.unlimitedGame,
  roomClosed: state.game.globalParams.roomClosed,
  speed: state.game.globalParams.speed,
});

const mapDispatchToProps = {
  getOffset: getTimeOffset,
  fetchRoomDataFallback: roomDataFallbackNew,
};

export default connect(mapStateToProps, mapDispatchToProps)(TurnTimer);
